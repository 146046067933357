import initialState from './initialState';
import * as types from './actionTypes';
import { IUiHistory, TUiHistory } from '../types/type.ui-history';

export default function(state: IUiHistory = initialState.uiHistory, action: TUiHistory) : IUiHistory {
    switch (action.type){
        case types.SET_SELECTED_PIPELINE_TAB:
            return {
                ...state,
                selectedPipelineTab: action.selectedPipelineTab
            }
        case types.SET_SELECTED_MENU_TAB:
            return {
                ...state,
                selectedMenuTab: action.selectedMenuTab
            }
        case types.SET_PIPELINE_SETTING:
            return {
                ...state,
                pipelineSetting: action.pipelineSetting
            }
        case types.SET_REPORT_SETTING:
            return {
                ...state,
                reportSetting: action.reportSetting
            }
        case types.SET_PIPELINE_TYPE:
            return {
                ...state,
                pipelineType: action.pipelineType
            }
        case types.RESET_UI_SETTING:
            return {
                ...initialState.uiHistory,
                selectedPipelineTab: state.selectedPipelineTab,
                selectedMenuTab: state.selectedMenuTab
            };
        case types.SET_BATCH_SETTING:
            return {
                ...state,
                batchSetting: action.batchSetting
            }
        default: return state;
    }
}
