import _ from "lodash";
import swal from "sweetalert";
import axios from "axios";

import * as types from "../reducers/actionTypes";
import * as constants from "../utils/constants";
import { TS3, IS3Files } from "../types/type.s3";
import { TAction } from "../types/action";
import { GET, POST, DELETE, REQUEST } from "../utils/httprequest";
import {
  IProjectFilesResponse,
  IPresignUploadResponse,
  IMetadataResponse,
  IGeneralResponse,
} from "../types/type.response";
import { MAX_FILE_SIZE, SEQUENCING } from "../utils/constants";
import { getVersion } from "./action.version-notification";
import { getOneProject } from "./action.project";
import {
  getPreviousPipelineOutputs,
  updateMetadata,
} from "./action.pipeline-input";

const allowedPrefixes = [
  `${constants.FILE_EXPLORER_ADMIN_FOLDER}/`,
  `${constants.FILE_EXPLORER_WORKSPACE_FOLDER}/`,
  `${constants.FILE_EXPLORER_DISCOVERY}/`,
];
const isAllowedPrefix = (key: string): boolean => {
  // only allows prefixes started with this
  for (let i = 0; i < allowedPrefixes.length; i++) {
    if (key.indexOf(allowedPrefixes[i]) === 0) {
      return true;
    }
  }
  return false;
};

export const s3GetFiles =
  (
    projectId: number,
    prefix: string,
    isSequencingFolder = false,
    getPreviousResult: boolean = false
  ): TAction<TS3> =>
  (dispatch, getState) => {
    if (_.isEmpty(prefix)) {
      dispatch({ type: types.S3_GET_FILES_REQUEST });
    }

    GET(`project/${projectId}/files`, { params: { prefix } })
      .then((response: IProjectFilesResponse) => {
        dispatch(getVersion(response.app_version));
        const resData = response.data;
        if (_.isEmpty(resData.CommonPrefixes) && _.isEmpty(resData.Contents)) {
          throw Error("no files");
        }

        // push folder
        const newFiles: Array<IS3Files> = [];
        for (let i = 0; i < resData.CommonPrefixes.length; i++) {
          const key = resData.CommonPrefixes[i].Prefix;
          const keyWithoutProject = key.replace(`project-${projectId}/`, "");
          if (isAllowedPrefix(keyWithoutProject)) {
            newFiles.push({
              key: keyWithoutProject,
            });
          }
        }

        // push files
        for (let i = 0; i < resData.Contents.length; i++) {
          const data = resData.Contents[i];
          const keyWithoutProject = data.Key.replace(
            `project-${projectId}/`,
            ""
          );
          if (isAllowedPrefix(keyWithoutProject)) {
            if (isSequencingFolder) {
              // get file extension
              const splits = keyWithoutProject.split(".");
              const ext = splits[splits.length - 1];
              if (constants.EXTENSION_EXPLORER_DISCOVERY.includes(ext)) {
                newFiles.push({
                  key: keyWithoutProject,
                  size: data.Size,
                  modified: new Date(data.LastModified).getTime(),
                  s3Url: _.get(resData, "s3BaseUrl", "") + data.Key,
                });
              }
            } else {
              newFiles.push({
                key: keyWithoutProject,
                size: data.Size,
                modified: new Date(data.LastModified).getTime(),
                s3Url: _.get(resData, "s3BaseUrl", "") + data.Key,
              });
            }
          }
        }

        // merge the files returned from the S3 with the state
        // instead of replacing the files in the state
        const { s3 } = getState();
        const allFiles = _.unionBy(s3.files, newFiles, "key");

        dispatch({
          type: types.S3_GET_FILES_SUCCESS,
          data: allFiles,
        });
      })
      .then(() => {
        if (getPreviousResult) {
          dispatch(
            getPreviousPipelineOutputs(false, false, {
              projectId: projectId.toString(),
              pipelineId: "0",
            })
          );
        }
      })
      .then(() => {
        dispatch(updateMetadata("projectId", projectId));
        dispatch(s3GetSequencingFiles(0, ""));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getVersion(error.response.data.app_version));
        }
        dispatch({ type: types.S3_GET_FILES_FAILED });
      });
  };

/**
 * A helper function to upload to S3
 * @params {File[]} files an array of files to be uploaded
 * @params {integer} projectId projectId
 * @params {string} prefix, the S3 folder path
 * @params {()} onSuccess the function that is called upon success
 * @params {()} onFailed when failed
 */
export const s3UploadFiles =
  (
    files: Array<File>,
    projectId: number,
    prefix: string,
    isLoadFilesAfterCompleted: boolean = true
  ): TAction<TS3> =>
  (dispatch, getState) => {
    const id = projectId;
    dispatch({
      type: types.S3_UPLOAD_START,
      total: files.length,
    });

    const data = {
      files: files.map((file) => {
        return { name: file.name };
      }),
      prefix,
    };

    // 1. Get signed URL to upload
    POST(`project/${id}/presign-upload-any`, data)
      // 2. Upload to AWS
      .then((response: IPresignUploadResponse) => {
        dispatch(getVersion(response.app_version));
        const signedUrls = response.data.map((signedUrl) => {
          return {
            ...signedUrl,
            file: files.find((element) => element.name === signedUrl.key),
          };
        });

        // this is a recursive function that recursively
        // upload array items. Every upload will dispatch a progress
        // that will update redux store.
        // we use xhr because it supports progress event.
        const upload = (items: Array<any>) => {
          if (items.length === 0) {
            if (isLoadFilesAfterCompleted)
              dispatch(s3GetFiles(projectId, prefix));
            return;
          }

          const item = items.pop();
          const { signedUrl: url, file } = item;

          const formData = new FormData();
          formData.append("file", file);

          const xhr = new XMLHttpRequest();
          xhr.upload.addEventListener(
            "progress",
            (evt) => {
              if (evt.lengthComputable) {
                const percentComplete = Math.floor(
                  (evt.loaded / evt.total) * 100
                );
                dispatch({
                  type: types.S3_UPLOAD_PROGRESSING,
                  progress: percentComplete,
                  uploadedFiles: response.data,
                });
              }
            },
            false
          );
          xhr.open("PUT", url);
          xhr.addEventListener("error", () => {
            dispatch({
              type: types.S3_UPLOAD_PROGRESSING,
              progress: 100,
              uploadedFiles: [],
            });
            swal({
              title: "Error uploading",
              text: "Failed to upload one of your files as you may not have sufficient right to access the workspace or are trying to upload a folder. If the error persistently happens, please contact administrator.",
              closeOnClickOutside: true,
            });
            upload(items);
          });
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                // success
                upload(items);
              }
            }
          };
          const _send = xhr.send;
          xhr.send = () => _send.call(xhr, file);
          xhr.send(formData);
        };

        // call the recursive function that we have created above
        // to upload the files
        upload(signedUrls);
      })
      // catch any error
      .catch((error) => {
        dispatch(getVersion(error.response.data.app_version));
        console.log(error);
      });
  };

export const s3UploadFileSimple =
  (
    files: Array<File>,
    projectId: number,
    prefix: string,
    onSuccess: Function | null = null,
    onFailed: Function | null = null
  ): TAction<TS3> =>
  (dispatch, getState) => {
    const id = projectId;
    const data = {
      files: files.map((file) => {
        return { name: file.name };
      }),
      prefix,
    };

    // 1. Get signed URL to upload
    POST(`project/${id}/presign-upload-any`, data)
      // 2. Upload to AWS
      .then((response: IPresignUploadResponse) => {
        dispatch(getVersion(response.app_version));
        response.data.map((presignedItem) => {
          const { key, signedUrl } = presignedItem;
          const file = files.find((element) => element.name === key);
          if (file) {
            const options = {
              data: file,
              headers: {
                "Content-Type": file.type,
              },
            };
            REQUEST(signedUrl, "put", options).then(() => {
              const formattedFile = {
                name: file.name,
                key: `${prefix}${file.name}`,
                size: file.size,
                modified: file.lastModified,
                s3Url: presignedItem.s3Url,
                relativeKey: presignedItem.relativeKey,
                localFile: file,
              };
              onSuccess!(formattedFile);
            });
          }
        });
      });
  };

export const s3MultipartUpload =
  (
    file: File,
    projectId: number,
    prefix: string,
    onSuccess: Function | null = null
  ): TAction<TS3> =>
  (dispatch, getState) => {
    const id = projectId;
    const data = {
      file: { name: file.name },
      prefix,
    };

    // 1. Start multipart upload
    POST(`project/${id}/start-multipart`, data)
      // 2. Upload the parts to AWS
      .then(async (response) => {
        dispatch(getVersion(response.app_version));
        const { uploadId } = response.data;
        try {
          const FILE_CHUNK_SIZE = MAX_FILE_SIZE;
          const fileSize = file.size;
          const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
          let promisesArray: Array<any> = [];
          let start, end, blob;

          // slice file into parts
          for (let index = 1; index < NUM_CHUNKS + 1; index++) {
            start = (index - 1) * FILE_CHUNK_SIZE;
            end = index * FILE_CHUNK_SIZE;
            blob =
              index < NUM_CHUNKS ? file.slice(start, end) : file.slice(start);

            // get signed url of each part
            let getUploadUrlResp = await POST(`project/${id}/get-signed-part`, {
              ...data,
              partNumber: index,
              uploadId,
            });
            let { signedUrl } = getUploadUrlResp.data;
            // console.log('Presigned URL ' + index + ': ' + signedUrl + ' filetype ' + file.type);

            // upload each part object
            let uploadResp = axios.put(signedUrl, blob, {
              headers: { "Content-Type": file.type },
              // onUploadProgress: function(progressEvent) {
              //   const percentComplete = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
              //   console.log(`(${index}/${promisesArray.length}) ${progressEvent.loaded} / ${progressEvent.total} = ${percentComplete}`);
              // }
            });
            // console.log('   Upload no ' + index + '; Etag: ' + uploadResp.headers.ETag);
            promisesArray.push(uploadResp);
          }

          let resolvedArray = await Promise.all(promisesArray);
          // console.log(resolvedArray, ' resolvedAr');

          let uploadPartsArray: Array<any> = [];
          resolvedArray.forEach((resolvedPromise, index) => {
            uploadPartsArray.push({
              ETag: resolvedPromise.headers.etag,
              PartNumber: index + 1,
            });
          });

          // 3. complete the multipart upload
          POST(`project/${id}/complete-multipart`, {
            ...data,
            parts: uploadPartsArray,
            uploadId,
          }).then((response) => {
            const formattedFile = {
              name: file.name,
              key: `${prefix}${file.name}`,
              size: file.size,
              modified: file.lastModified,
              s3Url: response.data.s3Url,
              relativeKey: response.data.relativeKey,
              localFile: file,
            };
            onSuccess!(formattedFile);
          });
        } catch (err) {
          console.log(err);
        }
      });
  };

/**
 * A function that allows user to upload file to S3
 * The file will be put under workspace/ key (folder).
 * At the beginning of the upload process, the function
 * will get signed URL from s3. Upon receiving signed URL,
 * the function will use axios to put the files in the
 * workspace/
 */
export const uploadToS3Workspace =
  (files: Array<File>, projectId: number): TAction<TS3> =>
  (dispatch) => {
    const onSuccess = () => {
      swal({
        title: "Please wait",
        text: "Your file still uploading.....",
        closeOnClickOutside: true,
      });
      dispatch(
        s3GetFiles(projectId, `${constants.FILE_EXPLORER_WORKSPACE_FOLDER}/`)
      );
    };

    const onFailed = (error: any) => {};

    dispatch(s3UploadFiles(files, projectId, "presign-upload-workspace"));
  };

/**
 * Prefix example: workspace/parent-folder/sub-folder/
 */
export const s3CreateFolder =
  (projectId: number, prefix: string): TAction<TS3> =>
  (dispatch) => {
    POST(`project/${projectId}/folder`, { prefix })
      .then((response) => {
        dispatch(getVersion(response.app_version));
        // get the items from parents folder
        dispatch(s3GetFiles(projectId, prefix));
      })
      .catch((error) => {
        dispatch(getVersion(error.response.data.app_version));
        swal({
          title: "Error",
          text: "Cannot create folder",
          closeOnClickOutside: true,
        });
      });
  };

export const s3ChangeFolder =
  (prefix: string): TAction<TS3> =>
  (dispatch, getState) => {
    const { id: projectId } = getState().projects.selected_project;

    if (!isAllowedPrefix(prefix)) {
      return false;
    }

    // get the metadata of the folder
    GET(`project/${projectId}/metadata`, { params: { key: prefix } })
      .then((response: IMetadataResponse) => {
        const metadata = response.data;
        dispatch({
          type: types.S3_SELECT_FOLDER,
          data: prefix,
          metadata,
        });
      })
      .catch((error) => {
        dispatch({ type: types.S3_GET_FILES_FAILED });
      });
  };

/**
 * To be used to check the S3 Object metadata.
 * If object is not found, it will return false.
 */
/*
// unused function
export const s3Metadata = (projectId: number, key: string) : TAction<TS3> => (dispatch, getState) => {
  if (!isAllowedPrefix(key)) {
    return false;
  }

  const { user } = getState();
  axios.get(`${config.API_URL}/project/${projectId}/files`, {
    headers: { Authorization: `Bearer ${user.token}` },
    params: {
      key,
    },
  })
    .then((res) => {
      dispatch({
        type: types.S3_GET_FILES_SUCCESS,
        data: allFiles,
      });
    })
    .catch((error) => {
      if (_.get(error, 'response.status', 0) === 401) {
        dispatch({ type: types.LOGOUT_REQUEST });
      }
      dispatch({ type: types.S3_GET_FILES_FAILED });
    });
}
*/

export const addFilesToUploadList =
  (files: Array<File>, path: string): TAction<TS3> =>
  (dispatch) => {
    files.map((file: File) => {
      console.log(file.name);
      dispatch({
        type: types.S3_ADD_FILE,
        data: file,
      });
    });
  };

/**
 * a Helper function to return bucketname from S3 HTTP URL
 */
export const getBucketFromS3Url = (s3Url: string): string => {
  const cleanUrl = s3Url.replace(/^https?:\/\//, "");
  const splits: Array<string> = cleanUrl.split(".");
  return splits[0];
};

/**
 * An action get get a presigned download URL from S3
 * @param {*} selected the selected key
 */
export const s3GetDownloadUrl = (selected: { key: string }): TAction<TS3> => {
  return (dispatch, getState) => {
    const { id } = getState().projects.selected_project;
    const selectedKey = _.get(selected, "key");

    GET(`project/${id}/download`, { params: { key: selectedKey } })
      .then((response) => {
        const baseUrl = getBucketFromS3Url(response.data);

        dispatch({
          type: types.SELECT_PROJECT_FILES_SUCCESS,
          data: {
            selectedKey,
            selectedUrl: response.data,
            s3Path: `s3://${baseUrl}/${selected.key}`,
          },
        });
      })
      .catch((error) => {
        dispatch({ type: types.RESET_FILE_BROWSER });
      });
  };
};

/**
 * A helper function to remove s3://bucketname from file name
 * example: s3://immunoscape-cytographer-5/workspace/5.csv
 * becomes: workspace/5.csv
 */
export const formatFileName = (fullpathFilename: string): string => {
  if (!fullpathFilename) {
    return fullpathFilename;
  }
  const s3 = fullpathFilename.substring(0, 5);
  if (s3 !== "s3://") {
    return fullpathFilename;
  }

  const splits = fullpathFilename.split("/");
  splits.splice(0, 3);
  const ret = splits.join("/");
  return ret;
};

export const deleteObject =
  (key: string, isFolder: boolean = false): TAction<TS3> =>
  (dispatch, getState) => {
    const { id: projectId } = getState().projects.selected_project;
    const data = {
      key,
      isFolder,
    };

    DELETE(`project/${projectId}/files/delete`, { data }).then((response) => {
      dispatch({
        type: types.S3_DELETE_FILE,
        file: key,
        isFolder,
      });
    });
  };

export const renameObject =
  (key: string, name: string): TAction<TS3> =>
  (dispatch, getState) => {
    const { id: projectId } = getState().projects.selected_project;
    const data = {
      key,
      name,
    };

    POST(`project/${projectId}/rename-file`, data).then((response) => {
      dispatch({
        type: types.S3_RENAME_FILE,
        file: key,
        newName: name,
      });
    });
  };

export const workspaceDownload =
  (path: string, fileName: string): TAction<TS3> =>
  (dispatch, getState) => {
    const { id: projectId } = getState().projects.selected_project;
    const data = {
      path,
      fileName,
    };

    POST(`project/${projectId}/workspace-download`, data)
      .then((response: IGeneralResponse) => {
        if (response.status == "success") {
          swal("Success", response.message, "success");
        }
      })
      .catch((error) => {
        swal.close!();
      });
  };

export const s3GetSequencingFiles =
  (defaultTabId: number = 0, prefix: string = ""): TAction<TS3> =>
  (dispatch, getState) => {
    if (_.isEmpty(prefix)) {
      dispatch({ type: types.S3_GET_FILES_REQUEST });
    }

    const { s3, uiHistory, pipeline } = getState();
    const projectId = pipeline.projectId ? pipeline.projectId : "0";
    const tabId = pipeline.tabId ? pipeline.tabId : defaultTabId;

    if (
      !projectId ||
      (tabId !== SEQUENCING && tabId !== 0) ||
      uiHistory.pipelineType === "preprocessing"
    ) {
      // remove files in discovery folder
      const files = s3.files.filter((file) => {
        const splits = file.key.split("/");
        const mainFolder = splits[0];

        if (mainFolder === constants.FILE_EXPLORER_DISCOVERY) {
          return false;
        } else {
          return true;
        }
      });

      dispatch({
        type: types.S3_GET_FILES_SUCCESS,
        data: files,
      });

      return;
    }

    GET(`project/${projectId}/sequencing-files`, { params: { prefix } })
      .then((response: IProjectFilesResponse) => {
        dispatch(getVersion(response.app_version));
        const resData = response.data;
        if (_.isEmpty(resData.CommonPrefixes) && _.isEmpty(resData.Contents)) {
          throw Error("no files");
        }

        // push folder
        const newFiles: Array<IS3Files> = [];
        for (let i = 0; i < resData.CommonPrefixes.length; i++) {
          const key = resData.CommonPrefixes[i].Prefix;
          if (isAllowedPrefix(key)) {
            newFiles.push({
              key: key,
            });
          }
        }

        // push files
        for (let i = 0; i < resData.Contents.length; i++) {
          const data = resData.Contents[i];
          if (isAllowedPrefix(data.Key)) {
            newFiles.push({
              key: data.Key,
              size: data.Size,
              modified: new Date(data.LastModified).getTime(),
              s3Url: _.get(resData, "s3BaseUrl", "") + data.Key,
            });
          }
        }

        // merge the files returned from the S3 with the state
        // instead of replacing the files in the state
        const allFiles = _.unionBy(s3.files, newFiles, "key");

        dispatch({
          type: types.S3_GET_FILES_SUCCESS,
          data: allFiles,
        });
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getVersion(error.response.data.app_version));
        }
        dispatch({ type: types.S3_GET_FILES_FAILED });
      });
  };

export const validateS3Path = (
  data: {
    s3Path: string;
    projectId: number | null;
  },
  onSuccess: Function = () => {}
): TAction<TS3> => {
  return (dispatch) => {
    swal({
      title: "Please wait",
      text: "Validating S3 Path.....",
      closeOnClickOutside: false,
      buttons: { visible: false },
    });

    POST(`project/validate-s3path/${data.projectId}`, { s3Path: data.s3Path })
      .then((response) => {
        // swal.close!();
        dispatch({
          type: types.S3_VALIDATE_PATH,
          data: response.data,
        });
        dispatch(getVersion(response.app_version));
        onSuccess();
      })
      .catch((error) => {
        swal.close!();
        dispatch({ type: types.S3_GET_FILES_FAILED });
        if (error.response) {
          dispatch(getVersion(error.response.data.app_version));
          swal("Error", error.response.data.message, "error");
        } else {
          swal("Error", error.message, "error");
        }
      });
  };
};
