import React from 'react';
import { Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import FileSelector from '../../../components/FileSelector/FileSelector';
import { IPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { INPUT_SMALL } from '../../../utils/constants';


const OntTcrConsensus = () => {
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const { input, isAdvanced } = pipeline;

    return (
        <div className="onttcrconsensus">
            <h4 className="subtitle">ONT TCR Consensus</h4>
            <div className="pipeline-form">
                <FormGroupCustom
                    pipeline="onttcrconsensus"
                    id="inputonttcrconsensus"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            accept={['zip', '.zip']}
                            isMultiple
                        />
                    </Col>
                </FormGroupCustom>
            </div>
        </div>
    );
}

export default OntTcrConsensus;
