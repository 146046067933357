import { ICoordinatesFiles, TCoordinatesFiles } from '../types/type.coordinates-files';
import * as types from './actionTypes';
import initialState from './initialState';

export default function(state: ICoordinatesFiles = initialState.coordinatesFiles, action: TCoordinatesFiles): ICoordinatesFiles {
    switch (action.type){
        case types.GET_COORDINATES_FILES_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false
            }

        case types.GET_COORDINATES_FILES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                files: action.data
            }

        case types.GET_COORDINATES_FILES_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }

        case types.UPLOAD_COORDINATES_FILES:
            return {
                ...state,
                isLoading: false,
                isError: false,
                filesToUpload: action.filesToUpload
            }

        case types.REMOVE_COORDINATES_FILES:
            const { files, filesToUpload } = state;
            const fileName = action.value;

            const newFiles = files.filter(data => data.fileName.substring(0, data.fileName.lastIndexOf('_coordinates.')) !== fileName);
            const newFilesToUpload = filesToUpload.filter(data => data.name.substring(0, data.name.lastIndexOf('.')) !== fileName);

            return {
                ...state,
                files: newFiles,
                filesToUpload: newFilesToUpload
            }

        default: return state;
    }
}