import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import classNames from 'classnames';

const floatPrecision = (floatValue: string | number, precision: number) => {
    floatValue = parseFloat(floatValue.toString());
    if (isNaN(floatValue)) { return parseFloat('0').toFixed(precision) } else {
        const power = Math.pow(10, precision);
        floatValue = (Math.round(floatValue * power) / power).toFixed(precision);
        return floatValue.toString();
    }
}
const getFileSize = (size: number) => {
    if (size > 1024) {
        const kbSize = size / 1024;
        if (kbSize > 1024) {
            const mbSize = kbSize / 1024;
            return `${floatPrecision(mbSize, 2)} MB`;
        }
        return `${Math.round(kbSize)} kB`;
    }
    return `${size} B`;
}

const FileSelectorDetail = (item) => {
    // item =>
    // browserProps: {}
    // depth: 1
    // fileKey: "workspace/PHENOTYPE_TT_FLOW_SYMPHONY.csv"
    // isDeleting: false
    // isDraft: false
    // isOpen: ""
    // isRenaming: false
    // isSelected: false
    // keyDerived: true
    // modified: 1595383453000
    // name: "PHENOTYPE_TT_FLOW_SYMPHONY.csv"
    // relativeKey: "workspace/PHENOTYPE_TT_FLOW_SYMPHONY.csv"
    // s3Url: "s3://dev-immunoscape-cytographer-ap-southeast-1/project-1/workspace/PHENOTYPE_TT_FLOW_SYMPHONY.csv"
    // size: 871
    const onSelectFile = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        event.stopPropagation();
        item.browserProps.select(item.fileKey, 'file');
    }

    const additionalProps: any = {};
    if (!item.isAdded) {
        additionalProps.onClick = onSelectFile;
    }

    return (
        <tr
            className={classNames('file', {
                selected: item.isSelected,
                added: item.isAdded
            })}
            {...additionalProps}
        >
            <td className="name">
                <div style={{ paddingLeft: `${item.depth * 16}px` }}>
                    <div>
                        {item.isAdded ? (
                            <span>
                                <i className="fa fa-file-o" aria-hidden="true"></i>
                                {item.name}
                            </span>
                        ) : (
                            <a href="#" download="download" onClick={(event) => {
                                event && event.preventDefault();
                            }}>
                                <i className="fa fa-file-o" aria-hidden="true"></i>
                                {item.name}
                            </a>
                        )}
                    </div>
                </div>
            </td>
            <td className="size">{getFileSize(item.size)}</td>
            <td className="modified">
                {_.isUndefined(item.modified) ? '-' : moment(item.modified, 'x').fromNow()}
            </td>
        </tr>
    );
}

export default FileSelectorDetail;
