import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { updateMetadata } from '../../../actions/action.pipeline-input';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { INPUT_SMALL } from '../../../utils/constants';

const Debarcode = () => {
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced, isShiny } = pipeline;

    useEffect(() => {
        if (!isShiny) {
            dispatch(updateMetadata('isShiny', true));
        }
    }, [isShiny])

    return (
        <div className="debarcode">
            <h4 className="subtitle">Debarcode</h4>
            <div className="pipeline-form">
                {/* samples */}
                <FormGroupCustom
                    pipeline="debarcode"
                    id="inputdebarcode"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            accept={['fcs', '.fcs']}
                            checkFcsMetadata
                            isMultiple
                            // isShowPreviousPipeline
                        />
                    </Col>
                </FormGroupCustom>
            </div>
        </div>
    );
}

export default Debarcode;
