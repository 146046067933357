import React, { useEffect, useState } from 'react';
import {
    Col, Input
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { updateInput, getMetasampleColNames, getMetasampleRowNames } from '../../../actions/action.pipeline-input';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import InputCustom from '../../../components/Form/InputCustom';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { GATING_SUBSETTING_STEP, INPUT_SMALL } from '../../../utils/constants';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';
import MetasampleEditor from '../../../components/Views/MetasampleEditor';
import Toggle from '../../../components/Form/Toggle';
import { defaultFileUpload, getExtension } from '../helper';
import { getCoordinatesFiles } from '../../../actions/action.coordinates-files';
import { ICoordinatesFiles } from '../../../types/type.coordinates-files';
import { IProfile } from '../../../types/type.user';

const Batch = () => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced } = pipeline;
    const [responses, setResponses] = useState<Array<string>>([]);
    const [templates, setTemplates] = useState<Array<string>>([]);
    const coordinatesFiles: ICoordinatesFiles = useSelector((state: IStore) => state.coordinatesFiles);
    const projectId: number = useSelector((state : IStore) => state.projects.selected_project.id)!;
    const profile: IProfile = useSelector((state : IStore) => state.user.profile);

    // const handleInput = (e: any) => {
    //     const { name, value } = e.target;
    //     dispatch(updateInput(name, value));
    // }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        let value = e.target.value;

        dispatchInput(name, value);
    }
    

    const dispatchInput = (key: string, value: any) => dispatch(updateInput(key, value));

    const handleToggle = (value: boolean, key: string) => {
        const toggledValue = !value;
        dispatchInput(key, toggledValue ? true : null);
    }

    useEffect(() => {
        if (!_.isEmpty(input.metaSample)) {
            dispatch(updateInput('response', ''));
            dispatch(getMetasampleColNames(setResponses));
            dispatch(getMetasampleRowNames(setTemplates));
        } else if(input.metaSampleEditor !== undefined){
            if(input.metaSampleEditor.length !== 0 && input.metaSample.length === 0){
                dispatch(updateInput('response', ''))
                dispatch(() => setResponses(Object.keys(input.metaSampleEditor[0]).slice(1)));
                dispatch(updateInput('template', ''))
                dispatch(() => setTemplates(Object.values(input.metaSampleEditor.map( row => row.name))));
            }
        }

    }, [input.metaSample]);

    useEffect(() => {
        if(input.metaSampleEditor !== undefined){
            if(input.metaSampleEditor.length !== 0 && input.metaSample.length === 0){
                dispatch(updateInput('response', ''))
                dispatch(() => setResponses(Object.keys(input.metaSampleEditor[0]).slice(1)));
                dispatch(updateInput('template', ''))
                dispatch(() => setTemplates(Object.values(input.metaSampleEditor.map(row => row.name))));
            }
        }
    }, [input.metaSampleEditor])

    useEffect(() => {
        if (input.input.length > 0) {
            const prefix = defaultFileUpload(profile);
        }
    }, [input.input]);

    return (
        <TabbedMetaContainer
            name="Batch Independent Analysis"
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.input)}
            containerClass="batch"
            metamarker={(!_.isEmpty(input.input)) && (
                <FormGroupCustom
                    pipeline="batch"
                    id="metamarkerbatch"
                    input={input.metaMarker}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        {(input.protein && input.protein.length > 0) ? (
                            <ProteinSelector
                                columns={['keep']}
                                protein={input.protein}
                                tray={input.metaMarker}
                                trayName='metaMarker'
                            />
                        ) : (
                            <FileSelector
                                name="metaMarker"
                                tray={input.metaMarker}
                                isMultiple={false}
                                accept={['csv', '.csv']}
                                checkFcsMetadata={false}
                                getPreviousPipelineOutput={false}
                            />
                        )}
                    </Col>
                </FormGroupCustom>
            )}
            metasample={
                <FormGroupCustom
                    pipeline="batch"
                    id="metasamplebatch"
                    input={input.metaSample}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <MetasampleEditor input='input' />
                    </Col>
                </FormGroupCustom>
            }
        >
            <div className="pipeline-form">
                {/* input */}
                <FormGroupCustom
                    pipeline="batch"
                    id="inputbatch"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            isMultiple={true}
                            accept={['fcs', '.fcs']}
                            checkFcsMetadata={false}
                            getPreviousPipelineOutput={true}
                            isShowPreviousPipeline
                            isPreviousPipelineFromPreProcessing
                            previousPipelineParams = {{
                                preprocessingStep: GATING_SUBSETTING_STEP.toString()
                            }}
                        />
                    </Col>
                </FormGroupCustom>

                {/* response */}
                <FormGroupCustom
                    pipeline="batch"
                    id="patientcolbatch"
                    input={input.patientCol}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select"
                            name="patientCol"
                            id="batch_patientCol"
                            placeholder=" patientCol"
                            onChange={handleInput}
                            value={input.patientCol}
                        >
                            <option value="" disabled>Select Patient Column</option>
                            {responses.map((patientCol) => (
                                <option value={patientCol}>{patientCol}</option>
                            ))}
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                                {/* Template */}
                                <FormGroupCustom
                    pipeline="batch"
                    id="templatebatch"
                    input={input.template}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select"
                            name="template"
                            id="batch_template"
                            placeholder=" template"
                            onChange={handleInput}
                            value={input.template}
                        >
                            <option value="" disabled>Select Template File</option>
                            {templates.map((template) => (
                                <option value={template}>{template}</option>
                            ))}
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                

                {/* transformation */}
                <FormGroupCustom
                    pipeline="batch"
                    id="transformationbatch"
                    input={input.transformation}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select" name="transformation"
                            onChange={handleInput} value={input.transformation}>
                            <option value="" disabled>Select Transformation</option>
                            <option value="cytof">cytof</option>
                            <option value="fluor">fluor</option>
                            <option value="linear1">linear1</option>
                            <option value="linear2">linear2</option>
                            <option value="arcsinh5">arcsinh5</option>
                            <option value="arcsinh150">arcsinh150</option>
                            <option value="auto">auto</option>
                            <option value="none">none</option>
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                {/* doAnnotation */}
                <FormGroupCustom
                    pipeline="batch"
                    id="annotationbatch"
                    input={input.doAnnotation}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doAnnotation}
                            onToggle={(v: boolean) => handleToggle(v, 'doAnnotation')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* doDiscovery */}
                <FormGroupCustom
                    pipeline="batch"
                    id="discoverybatch"
                    input={input.doDiscovery}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doDiscovery}
                            onToggle={(v: boolean) => handleToggle(v, 'doDiscovery')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* Depth Score */}
                <FormGroupCustom
                    pipeline="batch"
                    id="depthscorecutoffbatch"
                    input={input.depthScoreCutoff}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="depthScoreCutoff" type="number" step="0.10" min="0.00" max="1.00" defaultValue="0.10"
                            onChange={handleInput} value={input.depthScoreCutoff}
                        />
                    </Col>
                </FormGroupCustom>

                {/* Selection Quantile */}
                <FormGroupCustom
                    pipeline="batch"
                    id="selectionquantilebatch"
                    input={input.selectionQuantile}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="selectionQuantile" type="number" step="0.10" min="0.00" max="1.00" defaultValue="0.50"
                            onChange={handleInput} value={input.selectionQuantile}
                        />
                    </Col>
                </FormGroupCustom>

                {/* Zip file */}
                <FormGroupCustom
                    pipeline="batch"
                    id="zipfile"
                    input={input.zipFile}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='zipfile'
                            tray={input.zipFile}
                            accept={['zip', '.zip']}
                            pattern='^((?!raw).)*.zip$'
                        />
                    </Col>
                </FormGroupCustom>

                {/* prefix */}
                <FormGroupCustom
                    pipeline="batch"
                    id="prefixbatch"
                    input={input.prefix}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Input
                            name="prefix"
                            id="batch_prefix"
                            onChange={handleInput}
                            value={input.prefix}
                        />
                    </Col>
                </FormGroupCustom>

                {/* seed */}
                <FormGroupCustom
                    pipeline="batch"
                    id="seedbatch"
                    input={input.seed}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="seed" type="number" step="any"
                            onChange={handleInput} value={input.seed}
                        />
                    </Col>
                </FormGroupCustom>

            </div>
        
        </TabbedMetaContainer>
    );
}

export default Batch;
