import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  FormGroup,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import swal from "sweetalert";
import _ from "lodash";
import { Link } from "react-router-dom";
import classnames from "classnames";

import {
  updateMetadata,
  toggleAdvancedParams,
  resetPipelineParams,
  updateInput,
} from "../../../actions/action.pipeline-input";
import { IStore } from "../../../types/store";
import { IProject } from "../../../types/type.project";
import { IPipeline, TPipeline } from "../../../types/type.pipeline";
import { TDispatch } from "../../../types/action";
import { IVisiblePipeline } from "../../../types/type.visible-pipeline";
import {
  INPUT_SMALL,
  LABEL_SMALL,
  PIPELINE_NAME_MAX_LENGTH,
  PIPELINE_NAME_MAX_LENGTH_ERROR_MESSAGE,
} from "../../../utils/constants";
import Robot from "./Robot";
import PreProcessing from "./PreProcessing";
import Tetramer from "./Tetramer";
import HighDimensionalAnalysis from "./HighDimensionalAnalysis";
import HeatMap from "./HeatMap";
import Analysis from "./Analysis";
import ManualGating from "./ManualGating";
import Toggle from "../../../components/Form/Toggle";
import PipelineFormWrapper from "../../../components/Views/PipelineFormWrapper";
import Batch from "./Batch";
import GenerateMetaFiles from "./GenerateMetaFiles";
import TcrAnalysis from "./TcrAnalysis";
import CytofTemplate from "./CytofTemplate";
import BeadNormalization from "./BeadNormalization";
import Tetdecon2021 from "./Tetdecon2021";
import ManualTetdecon2021 from "./ManualTetdecon2021";
import Debarcode from "./Debarcode";
import ScatterPlot from "./ScatterPlot";
import ResultUpload from "./ResultUpload";
import Pyir from "./Pyir";
import Igblast from "./Igblast";
import HighDimensionalInteractive from "./HighDimensionalInteractive";
import Url2s3 from "./Url2s3";
import SequencingGating from "./SequencingGating";
import Histogram from "./Histogram";
import TcrQuery from "./TcrQuery";
import Stitchr from "./Stitchr";
import TcrSearch from "./TcrSearch";
import OntTcrConsensus  from "./OntTcrConsensus";
import Pfda from "./Pfda";

export const PREPROCESSING = 5;
export const ROBOT = 1;
export const TETRAMER = 4;
export const TSNE = 2;
export const HEATPLOT = 3;
export const ANALYSIS = 8;
export const MANUAL_GATING = 9;
export const BATCH = 12;
export const GENERATE_META_FILES = 13;
export const TCR_ANALYSIS = 14;
export const CYTOF_RUN_TEMPLATE = 15;
export const BEAD_NORMALIZATION = 16;
export const TETDECON_2021 = 17;
export const MANUAL_TETDECON_2021 = 18;
export const DEBARCODE = 19;
export const SCATTER_PLOT = 20;
export const RESULT_UPLOAD = 21;
export const PYIR = 22;
export const TSNE_INTERACTIVE = 23;
export const URL2S3 = 24;
export const SEQUENCING_GATING = 25;
export const HISTOGRAM = 26;
export const TCR_QUERY = 27;
export const STITCHR = 28;
export const TCR_SEARCH = 29;
export const ONT_TCR_CONSENSUS = 30;
export const PFDA = 31;
export const IGBLAST = 32;

interface IProps {
  history: any;
  location: any;
}

const Form = ({ history, location }: IProps) => {
  const pipelineType: string = useSelector(
    (state: IStore) => state.uiHistory.pipelineType
  );
  const project: IProject = useSelector(
    (state: IStore) => state.projects.selected_project
  );
  const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
  const visiblePipelines: IVisiblePipeline = useSelector(
    (state: IStore) => state.visiblePipelines
  );
  const dispatch = useDispatch<TDispatch<TPipeline>>();
  const { data } = visiblePipelines;
  const {
    projectId,
    input,
    tabId,
    pipelineId,
    pipelineScript,
    name,
    description,
    isAdvanced,
    isShiny,
  } = pipeline;

  const metadata = (e: any) => {
    dispatch(updateMetadata(e.target.name, e.target.value));
    if (e.target.name === "name") {
      // update prefix following session name
      const hasPrefix = _.has(input, "prefix");
      if (hasPrefix) {
        dispatch(updateInput("prefix", e.target.value));
      }
    }
  };

  const isPropsOk = () => {
    let requiredInput: Array<{
      name: string;
      value: string;
      alternativeData?: string;
      isCustomMessage?: boolean;
    }> = [];
    const err = (name: string, isCustomMessage?: boolean) => {
      if (isCustomMessage) {
        swal("Failed", `${name}`, "warning");
      } else {
        swal("Failed", `Missing ${name} Parameter/Files`, "warning");
      }
      return false;
    };

    // initialise required fields
    switch (parseInt(pipelineId as string)) {
      case ROBOT:
        // in robot, input and table are required
        requiredInput.push({ name: "input", value: "Peptide Sequences" });
        requiredInput.push({ name: "table", value: "SAV Channels" });
        break;

      case TETRAMER:
        // in tetdecon, only inputCase, inputControl, metaMarker, clusterCodes is required
        requiredInput.push({ name: "inputCase", value: "Sample Target" });
        requiredInput.push({
          name: "inputControl",
          value: "Sample Background",
        });
        requiredInput.push({ name: "clusterCodes", value: "Cluster Codes" });
        requiredInput.push({
          name: "metaMarker",
          value: "Marker Information",
          alternativeData: "protein",
        });
        break;

      case PREPROCESSING:
        // preprocessing
        requiredInput.push({ name: "input", value: "Samples" });
        break;

      case ANALYSIS:
        // in analysis, only input and metaSample is required
        requiredInput.push({ name: "input", value: "Samples" });
        requiredInput.push({
          name: "metaSample",
          value: "Sample Selection/Information",
          alternativeData: "metaSampleEditor",
        });
        break;

      case MANUAL_GATING:
        // in manual gating, only input, metaMarker, and metaSample is required
        requiredInput.push({ name: "input", value: "Samples" });
        if (isShiny) {
          requiredInput.push({
            name: "metaMarker",
            value: "Marker Information",
            alternativeData: "protein",
          });
        } else {
          requiredInput.push({ name: "coordinatesFile", value: "Coordinates" });
        }
        break;

      case CYTOF_RUN_TEMPLATE:
        break;

      case TETDECON_2021:
        // in tetdecon, only inputCase, inputControl, metaMarker, clusterCodes is required
        requiredInput.push({ name: "input", value: "Sample Target" });
        requiredInput.push({ name: "clusterCodes", value: "Cluster Codes" });
        requiredInput.push({
          name: "markerCsv",
          value: "Marker Information",
          alternativeData: "protein",
        });
        break;

      case TCR_ANALYSIS:
        requiredInput.push({ name: "h5ad", value: "Input 10x File" });
        requiredInput.push({ name: "bulk", value: "Input Bulk File" });
        requiredInput.push({ name: "input", value: "Gated FCS file(s)" });
        requiredInput.push({ name: "tcr", value: "TCR Sequences File" });
        break;

      case SCATTER_PLOT:
        requiredInput.push({ name: "input", value: "Input Data to Plot" });
        requiredInput.push({
          name: "bulk",
          value: "Marker Intensities for Coloring",
        });
        break;

      case RESULT_UPLOAD:
        requiredInput.push({ name: "resultType", value: "Result Type" });
        requiredInput.push({ name: "files", value: "Result Files" });
        break;

      case PYIR:
        requiredInput.push({
          name: "input",
          value: "Sequences (one or more .fasta or .seq file)",
        });
        break;

      case IGBLAST:
        requiredInput.push({
          name: "input",
          value: "Sequences (one or more .fasta or .seq file)",
        });
        break;

      case SEQUENCING_GATING:
        requiredInput.push({ name: "input", value: "Sample (one .fcs only)" });
        break;

      case TCR_QUERY:
        break;

      case STITCHR:
        break;

      case TCR_SEARCH:
        const regex = /^[a-zA-Z0-9\-]+_TRAV[^\.]+\.TRAJ[^\.]+\.[ARNDBCEQZGHILKMFPSTWYV]+\.\.TRBV[^\.]+\.TRBJ[^\.]+\.[ARNDBCEQZGHILKMFPSTWYV]+$/;
        
        if (!regex.test(input.queryClonotype)) {
          return err("Query Clonotype not valid", true);        
        }

        break;

      case ONT_TCR_CONSENSUS:
        requiredInput.push({ name: "input", value: "Sample (one .zip only)" });
        break;

      default:
        // default, need input
        requiredInput.push({ name: "input", value: "Samples" });
    }

    // check the required input and throw an error if anything happens
    for (let d of requiredInput) {
      if (d.alternativeData) {
        if (!_.has(input, d.name) && _.has(input, d.alternativeData)) {
          return err(d.value);
        }
        if (_.isEmpty(input[d.name]) && _.isEmpty(input[d.alternativeData])) {
          return err(d.value);
        }
      } else {
        if (!(d.name in input)) {
          return err(d.value);
        }
        if (!input[d.name]) {
          return err(d.value);
        }
        if (_.isArray(input[d.name]) && input[d.name].length === 0) {
          return err(d.value);
        }
      }
    }

    return true;
  };

  const onToggleAdvanced = () => {
    dispatch(toggleAdvancedParams());
  };

  const resetPipeline = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(resetPipelineParams(["pipelineScript"]));
  };

  const runPipeline = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const metaSample = pipeline.input.metaSampleEditor;
    const keyToCheck = "Display_ID";
    if (!_.isEmpty(metaSample) && metaSample[0][keyToCheck] !== undefined) {
      const uniqueValue = _.uniqBy(metaSample, keyToCheck);
      if (uniqueValue.length !== metaSample.length) {
        swal(
          "Failed",
          "Your Display_ID column has duplicated entries, please revise",
          "warning"
        );
        return;
      }
    }

    if (isPropsOk()) {
      checkShinyPipelines();
      history.push({ pathname: "/pipeline/summary" });
    }
  };

  const getTabName = (): string => {
    const selectedTab = data.find((element) => element.id == tabId);
    return selectedTab ? selectedTab.name : "";
  };

  const getPipelineName = (): string => {
    const selectedTab = data.find((element) => element.id == tabId);
    const allPipeline = selectedTab ? selectedTab.pipelines : [];
    const pipeline = allPipeline.find((element) => element.id == pipelineId);
    return pipeline ? pipeline.name : "";
  };

  // to make sure the shiny pipelines return true
  const checkShinyPipelines = () => {
    const shinyPipelines = [
      MANUAL_GATING,
      GENERATE_META_FILES,
      BEAD_NORMALIZATION,
      MANUAL_TETDECON_2021,
      DEBARCODE,
      SCATTER_PLOT,
      HISTOGRAM,
      TCR_QUERY,
      STITCHR,
    ];
    if (_.includes(shinyPipelines, pipelineId) && !isShiny) {
      dispatch(updateMetadata("isShiny", true));
    }
  };

  useEffect(() => {
    checkShinyPipelines();
  }, []);

  return (
    <PipelineFormWrapper location={location}>
      <div className="pipeline execution new">
        <Breadcrumb tag="nav">
          <BreadcrumbItem
            tag="a"
            href={`#/projects/${pipelineType}/${projectId}`}
          >
            Projects
          </BreadcrumbItem>
          <BreadcrumbItem active tag="span">
            Run Pipeline
          </BreadcrumbItem>
        </Breadcrumb>
        <form onSubmit={runPipeline}>
          <h1 className="title">Run New Pipeline Session</h1>
          <div className="card basic">
            <h4 className="substitle">Select Pipeline</h4>
            <p className="text-danger">
              <i>* denotes a mandatory field</i>
            </p>
            <FormGroup row>
              <Label for="project" sm={LABEL_SMALL} className="required">
                Project
              </Label>
              <Col sm={INPUT_SMALL}>
                <span className="option-label">{project.name}</span>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="tabId" sm={LABEL_SMALL} className="required">
                Tab
              </Label>
              <Col sm={INPUT_SMALL}>
                <span className="option-label">{getTabName()}</span>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="pipelineId" sm={LABEL_SMALL} className="required">
                Pipeline Type
              </Label>
              <Col sm={INPUT_SMALL}>
                <span className="option-label">{getPipelineName()}</span>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label
                for="name"
                sm={LABEL_SMALL}
                className="label-custom required"
              >
                Session Name
              </Label>
              <Col sm={INPUT_SMALL}>
                <Input
                  required
                  name="name"
                  placeholder="e.g. Analysing Sample A"
                  onChange={metadata}
                  value={name}
                  maxLength={PIPELINE_NAME_MAX_LENGTH}
                  autoFocus
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="sessionDescription" sm={LABEL_SMALL}>
                Description
              </Label>
              <Col sm={INPUT_SMALL}>
                <Input
                  type="textarea"
                  name="description"
                  placeholder="Description about why you are running this session"
                  onChange={metadata}
                  value={description}
                  maxLength={255}
                />
              </Col>
            </FormGroup>

            {projectId && pipelineId && (
              <FormGroup row>
                <Label for="sessionDescription" sm={LABEL_SMALL}>
                  Advanced Parameters
                </Label>
                <Col sm={INPUT_SMALL}>
                  <Toggle value={isAdvanced} onToggle={onToggleAdvanced} />
                </Col>
              </FormGroup>
            )}

            <p>
              <i>** Select Project and Pipeline Type to show more parameters</i>
            </p>
          </div>

          {projectId && pipelineId && (
            <div
              className={classnames({
                "card parameters": _.includes(
                  [
                    ROBOT,
                    GENERATE_META_FILES,
                    CYTOF_RUN_TEMPLATE,
                    BEAD_NORMALIZATION,
                    DEBARCODE,
                    RESULT_UPLOAD,
                    PYIR,
                    IGBLAST,
                    URL2S3,
                    SEQUENCING_GATING,
                    TCR_SEARCH,
                    ONT_TCR_CONSENSUS
                  ],
                  pipelineId
                ),
              })}
            >
              {pipelineId == ROBOT && <Robot />}
              {pipelineId == PREPROCESSING && <PreProcessing />}
              {pipelineId == TETRAMER && <Tetramer />}
              {pipelineId == TSNE && <HighDimensionalAnalysis />}
              {pipelineId == HEATPLOT && <HeatMap />}
              {pipelineId == ANALYSIS && <Analysis />}
              {pipelineId == MANUAL_GATING && <ManualGating />}
              {pipelineId == BATCH && <Batch />}
              {pipelineId == GENERATE_META_FILES && <GenerateMetaFiles />}
              {pipelineId == TCR_ANALYSIS && <TcrAnalysis />}
              {pipelineId == CYTOF_RUN_TEMPLATE && <CytofTemplate />}
              {pipelineId == BEAD_NORMALIZATION && <BeadNormalization />}
              {pipelineId == TETDECON_2021 && <Tetdecon2021 />}
              {pipelineId == MANUAL_TETDECON_2021 && <ManualTetdecon2021 />}
              {pipelineId == DEBARCODE && <Debarcode />}
              {pipelineId == SCATTER_PLOT && <ScatterPlot />}
              {pipelineId == RESULT_UPLOAD && <ResultUpload />}
              {pipelineId == PYIR && <Pyir />}
              {pipelineId == IGBLAST && <Igblast />}
              {pipelineId == TSNE_INTERACTIVE && <HighDimensionalInteractive />}
              {pipelineId == URL2S3 && <Url2s3 />}
              {pipelineId == SEQUENCING_GATING && <SequencingGating />}
              {pipelineId == HISTOGRAM && <Histogram />}
              {pipelineId == TCR_QUERY && <TcrQuery />}
              {pipelineId == STITCHR && <Stitchr />}
              {pipelineId == TCR_SEARCH && <TcrSearch />}
              {pipelineId == ONT_TCR_CONSENSUS && <OntTcrConsensus />}
              {pipelineId == PFDA && <Pfda />}
            </div>
          )}

          {projectId && pipelineId && (
            <div className="text-right mt-4">
              <Link
                to={`/projects/${pipelineType}/${projectId}`}
                style={{ marginTop: "20px" }}
              >
                <button
                  className="btn-primary"
                  type="button"
                  style={{ marginRight: "14px" }}
                >
                  <i className="fa fa-arrow-left"></i> Return to Dashboard
                </button>
              </Link>
              <button
                type="button"
                className="btn-danger btn-form-summary"
                style={{
                  width: "150px",
                  marginRight: "14px",
                  borderRadius: "3px",
                }}
                onClick={resetPipeline}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn-primary btn-form-summary"
                style={{ width: "150px" }}
              >
                Next
              </button>
            </div>
          )}
        </form>
      </div>
    </PipelineFormWrapper>
  );
};

export default Form;
