import swal from 'sweetalert';
import * as types from '../reducers/actionTypes';
import { TAction } from '../types/action';
import { DELETE, GET, POST, PUT } from '../utils/httprequest';
import { IBatchResponse, IBatchDetailResponse, IGeneralResponse } from '../types/type.response';
import { TBatch, TBatchCreate, TBatchStepCreate } from '../types/type.batch';
import initialState from '../reducers/initialState';
import { resetPipelineParams } from './action.pipeline-input';
import { getVersion } from './action.version-notification';

export const getBatch = (projectId: number): TAction<TBatch> => {
    return (dispatch) => {
        dispatch({
            type: types.GET_BATCH_REQUEST,
        });
        
        GET(`batches?projectId=${projectId}`)
        .then((response: IBatchResponse) => {
            dispatch(getVersion(response.app_version));
            dispatch({
                type: types.GET_BATCH_SUCCESS,
                data: response.data
            });
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            
            dispatch({
                type: types.GET_BATCH_FAILURE,
                message: error.response.statusText,
            });
        });
    }
};

export const getOneBatch = (id: number): TAction<TBatch> => {
    return (dispatch) => {
        dispatch({
            type: types.GET_ONE_BATCH_REQUEST,
        });
        
        GET(`batches/${id}`)
        .then((response: IBatchDetailResponse) => {
            dispatch(getVersion(response.app_version));
            dispatch({
                type: types.GET_ONE_BATCH_SUCCESS,
                data: response.data
            });
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.GET_ONE_BATCH_FAILURE,
                message: error.response.data.message,
            });
        });
    }
};

export const setSelectedBatch = (batchId: number | null, projectId: number): TAction<TBatch> => {
    return (dispatch, getState) => {
        const { selected_batch } = getState().batch;
        dispatch({
            type: types.GET_ONE_BATCH_SUCCESS,
            data: {
                ...selected_batch,
                id: batchId,
                projectId,
            }
        });

        // reset uploads when change batch
        if (batchId !== selected_batch.id) {
            dispatch(resetPipelineParams());
        }
    }
};

export const changeSelectedBatchData = (key: string, value: any): TAction<TBatch> => {
    return (dispatch, getState) => {
        const { selected_batch } = getState().batch;
        dispatch({
            type: types.GET_ONE_BATCH_SUCCESS,
            data: {
                ...selected_batch,
                [key]: value,
            }
        });
    }
};

export const resetSelectedBatch = (): TAction<TBatch> => {
    return (dispatch) => {
        dispatch({
            type: types.GET_ONE_BATCH_SUCCESS,
            data: initialState.batch.selected_batch
        });
    }
};

export const createBatch = async (batchData: TBatchCreate, stepData?: TBatchStepCreate) => {
    swal({
        title: 'Please wait',
        text: 'Creating Batch.....',
        closeOnClickOutside: false,
        buttons: { visible: false }
    });

    return await POST('batches', {
        ...batchData
    })
    .then(async (response: IBatchDetailResponse) => {
        if(stepData){
            await createBatchStep({
                ...stepData,
                batchId: response.data.id!,
            });
        }
    
        swal.close!();
        return response;
    })
    .catch((error) => {
        if (swal.close) swal.close();
        swal('Error', error.response.statusText, 'error');
    });
};

export const createBatchStep = async (stepData: TBatchStepCreate) => {
    return await POST('batches/step', stepData)
    // .then((response: IGeneralResponse) => {
    //     swal('Success', 'Success creating new Batch', 'success');
    // })
    .catch((error) => {
        if (swal.close) swal.close();
        swal('Error', error.response.statusText, 'error');
    });
};


export const getLastInput = async (batchId: number) => {
    return await GET(`batches/files/${batchId}?maxStep=5`);
};

export const unskipBatchStep = async (id: number) => {
    return await DELETE(`batches/step/${id}`)
    .catch((error) => {
        if (swal.close) swal.close();
        swal('Error', error.response.statusText, 'error');
    });
};

export const archiveBatch = (id: number, projectId: number, onSuccess?: Function): TAction<TBatch> => {
    return (dispatch) => {
        dispatch({
            type: types.ARCHIVE_REQUEST,
        });

        swal({
            title: 'Please wait',
            text: 'Archiving.....',
            closeOnClickOutside: false,
            buttons: { visible: false }
        });

        DELETE(`batches/${id}`)
            .then((response: IGeneralResponse) => {
                GET(`batches?projectId=${projectId}`)
                    .then((refresh: IBatchResponse) => {
                        dispatch(getVersion(response.app_version));
                        dispatch({
                            type: types.GET_BATCH_SUCCESS,
                            data: refresh.data
                        });
                        swal.close!();
                        swal('Success', response.message, 'success').then(() => {
                            if (onSuccess) {
                                onSuccess();
                            }
                        });
                    })
            })
            .catch((error) => {
                dispatch(getVersion(error.response.data.app_version));
                swal.close!();
                swal('Failed', 'Archive failed', 'warning');
                dispatch({
                    type: types.ARCHIVE_RESULT,
                    message: error.response.statusText,
                });
            });
    }
}

export const updateBatch = (id: number, data: TBatchCreate, onSuccess?: Function): TAction<TBatch> => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_BATCH_REQUEST,
        });
        
        PUT(`batches/${id}`, data)
        .then((response: IGeneralResponse) => {
            dispatch(getVersion(response.app_version));
            dispatch({
                type: types.UPDATE_BATCH_SUCCESS,
                message: response.message
            });
            swal('Success', response.message, 'success');
            if (onSuccess) {
                onSuccess();
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.UPDATE_BATCH_FAILURE,
                message: error.response.data.message,
            });
        });
    }
}