import React, { Component, useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import FileBrowser, { Icons } from 'react-keyed-file-browser'
import _ from 'lodash';
import swal from 'sweetalert';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import classNames from 'classnames';

import {
  addFileToTray,
  removeFileFromTray,
  readFcsMetadata,
  readFcsMetadataFromS3,
  removeFileFromTrayWithoutClearingProtein,
} from '../../actions/action.file-selector';
import * as constants from '../../utils/constants';
import { TPipeline, IPipeline } from '../../types/type.pipeline';
import { TDispatch } from '../../types/action';
import { IStore } from '../../types/store';
import { TS3, IS3 } from '../../types/type.s3';
import { IProfile } from '../../types/type.user';
import { MAX_FILE_SIZE } from '../../utils/constants';
import { s3GetDownloadUrl, s3GetFiles, s3UploadFileSimple, formatFileName, s3MultipartUpload, validateS3Path } from '../../actions/action.s3';
import FileSelectorDetail from '../Views/FileSelectorDetail';
import { defaultFileUpload, getExtension } from '../../views/Pipeline/helper';
import { getPreviousPipelineOutputs, updateInput } from '../../actions/action.pipeline-input';
import { ICoordinatesFiles } from '../../types/type.coordinates-files';
import { getCoordinatesFiles, removeCoordinatesFiles } from '../../actions/action.coordinates-files';
import { Input } from 'reactstrap';

// const prevFilesRef = useRef<Array<any>>([]);

interface IProps {
  name: string,
  tray: Array<any>,
  isMultiple: boolean,
  checkFcsMetadata: boolean,
  accept: Array<any>,
  isShowPreviousPipeline: boolean,
  isShowWorkspace: boolean,
  isExtendWorkspace: boolean,
  isPreviousPipelineFromPreProcessing: boolean,
  isOnlyPipelineFromPreProcessing: boolean,
  clearMetadata?: boolean,
  mixedExtension?: boolean,
  isFolderOnly?: boolean,
  pattern?: string,
  isConcatenateCsv?: boolean
  mathchFilenameWithInput?: {
    prefix: string,
    suffix: string,
  },
  previousPipelineParams?: {
    pipelineId?: string,
    projectId?: string,
    preprocessingStep?: string,
  }
  metadataName?: string,
  uploadPath?: string
  isHideWorkspace?: boolean,
  isHideUpload?: boolean,
  validateFilename?: string,
  isCoordinatesFiles: boolean,
  isShowGetCoordinatesFiles: boolean,
  isShowS3Paths: boolean,
}

interface IFileTray {
  handleClick?: Function,
  files: Array<any>,
  isCoordinatesFiles: boolean
}

const UPLOAD_ALL_FILE = 'UPLOAD_ALL_FILE';
const UPLOAD_QUEUE_FILE = 'UPLOAD_QUEUE_FILE';

const FileSelector = (props : IProps) => {
  const profile: IProfile = useSelector((state : IStore) => state.user.profile);
  const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
  const coordinatesFiles: ICoordinatesFiles = useSelector((state: IStore) => state.coordinatesFiles);
  const s3: IS3 = useSelector((state : IStore) => state.s3);
  const dispatch = useDispatch<TDispatch<TPipeline | TS3>>();
  const { projectId, input } = pipeline;
  const { files, isLoading } = s3;

  const [file, setFile] = useState<any>({});
  const [isFileInTray, setIsFileInTray] = useState<boolean>(false);
  const [isShowPreviousPipeline, setIsShowPreviousPipeline] = useState<boolean>(props.isShowPreviousPipeline);
  const [isShowWorkspace, setIsShowWorkspace] = useState<boolean>(props.isShowWorkspace);
  const [isExtendWorkspace, setIsExtendWorkspace] = useState<boolean>(props.isExtendWorkspace);
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false);
  const [localFiles, setLocalFiles] = useState<Array<any>>(files);
  const [filesBeingUploaded, setFilesBeingUploaded] = useState<Array<any>>([]);
  const [selectedFolder, setSelectedFolder] = useState<string>(`${constants.FILE_EXPLORER_WORKSPACE_FOLDER}/`);
  const [isFolderSelected, setIsFolderSelected] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>('');
  const [errorFile, setErrorFile] = useState<any>(null);

  const [fileUploadType, setFileUploadType] = useState<string>(UPLOAD_QUEUE_FILE);
  const [bulkFiles, setBulkFiles] = useState<Array<any>>([]);
  const [notIncludedFiles, setNotIncludedFiles] = useState<Array<any>>([]);
  const [isShowS3PathsFields, setIsShowS3PathsFields] = useState<boolean>(false);
  const [s3Paths, setS3Paths] = useState<string>('');

  const rootFolders = [
    constants.FILE_EXPLORER_ADMIN_FOLDER,
    constants.FILE_EXPLORER_WORKSPACE_FOLDER,
  ];

  const removeQueueFile = () => {
    if (!_.isEmpty(bulkFiles)) {
      setBulkFiles(bulkFiles.splice(1, bulkFiles.length-1));
    }
  }

  const isFolderEmpty = (): boolean => {
    // cannot delete if the folder is not empty
    let count = 0;
    for (let i = 0; i < localFiles.length; i++) {
      const element = localFiles[i];
      if (element.key.substring(0, selectedFolder.length) === selectedFolder) {
        if (element.size) {
          return false;
          // count++;
        }
      }
      // if (count > 1) {
      //   return false;
      // }
    }

    return true;
  }

  const escapeRegex = ( string  ) =>  string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');

  const isInTray = () => {
    const inputArray = input[props.name] ? input[props.name] : [];
    for (let i = 0; i < inputArray.length; i++) {
      const element = inputArray[i];
      if (new RegExp(escapeRegex(selectedFolder)).test(element)){
        return true;
      }
    }
    return false;
  }

  const handleValidateFileName = (files: Array<{ name: string }>) => {
    if (!props.mathchFilenameWithInput) return true;
    if (_.isEmpty(input.input) || _.isEmpty(files)) return true;
    const { prefix, suffix } = props.mathchFilenameWithInput;

    let isError = false;
    const inputNames = _.map(input.input, (s3File) => {
      const filename = s3File.substring(s3File.lastIndexOf("/")+1, s3File.lastIndexOf("."));
      return `${prefix}${filename}${suffix}`;
    });

    for (let file of files) {
      if (!_.includes(inputNames, file.name)) {
        isError = true;
        break;
      }
    }

    return isError;
  }

  /**
   * Remove a file from tray
   *
   * @memberof FileSelector
   */
  const handleRemoveFileFromTray = (file: any) => {
    const path = file.s3Url ? file.s3Url : file;
    // kalau component ini harus menge-cek metadata di S3, 
    // maka panggil handle remove tray yang memakai metadata
    // kalau tidak, panggil handle remove yang tidak mengecek metadata di S3
    if (props.checkFcsMetadata && props.clearMetadata) {
      dispatch(removeFileFromTray(props.name, props.metadataName!, path));
    } else {
      dispatch(removeFileFromTrayWithoutClearingProtein(props.name, path));
    }
    if ((props.name !== 'coordsFolder' && props.name !== 'coordsFile') && (input.coordsFolder || input.coordsFile)){
      dispatch(removeCoordinatesFiles(path));
    }

    if (path === file.s3Url) {
      setIsFileInTray(false);
    }
  }

  /**
   * Adding a file to tray
   *
   * @memberof FileSelector
   */
  const handleAddFileToTray = (currentFile: any = file) => {
    const inputArray = input[props.name];
    const extension = getExtension(currentFile.key);
    const mixedErrorMessage = 'Mixed files detected. Please use multiple fcs files or one csv file. \nRemoving csv files...';

    _.remove(notIncludedFiles, (f) => f === currentFile);

    // check mixed extensions
    if (props.mixedExtension && !_.isEmpty(inputArray)) {
      const templateExtension = getExtension(inputArray[0]);
      // let isMixed = false;

      if (templateExtension !== extension) {
        removeQueueFile();
        setErrorFile(currentFile);
        currentFile.message = 'mixed extension';
        notIncludedFiles.push(currentFile);
        swal('failed', mixedErrorMessage, 'warning');
        return;
      }

      // if (isMixed) {
      //   removeQueueFile();
      //   setErrorFile(currentFile);
      //   currentFile.message = 'mixed extension';
      //   notIncludedFiles.push(currentFile);
      //   swal('failed', mixedErrorMessage, 'warning');
      //   return;
      // }
      
      // const findFcs = _.find(inputArray, (i) => getExtension(i) === 'fcs');
      // const findCsv = _.find(inputArray, (i) => getExtension(i) === 'csv');
      // const mixedErrorMessage = 'Mixed files detected. Please use multiple fcs files or one csv file. \nRemoving csv files...';
      // if (findFcs && extension === 'csv') {
      //   removeQueueFile();
      //   setErrorFile(currentFile);
      //   currentFile.message = 'mixed extension';
      //   notIncludedFiles.push(currentFile);
      //   swal('failed', mixedErrorMessage, 'warning');
      //   return;
      // }

      // if (findCsv) {
      //   if (extension === 'fcs') {
      //     removeQueueFile();
      //     setErrorFile(currentFile);
      //     currentFile.message = 'mixed extension';
      //     notIncludedFiles.push(currentFile);
      //     swal('failed', mixedErrorMessage, 'warning');
      //   }
        // if (!props.isConcatenateCsv || extension === 'fcs') {
        //   props.tray.map(item => {
        //     handleRemoveFileFromTray(item);
        //   });
        // }
      // }
    }

    // if tray is not for multiple files, then remove
    // all files in tray
    if (!props.isMultiple) {
      setBulkFiles([]);
      props.tray.map(item => {
        handleRemoveFileFromTray(item);
      });
    }

    // if need to check FCS metadata before adding file to tray then: 
    // -. check if is fcs or not
    // 1. get the FCS metadata from S3
    // 2. Process FCS with filter and sort
    // 3. Compare with input.protein in redux
    // 4. If same, then add the file to tray and update redux input.protein
    // 5. Else, throw a sweetalert error screen
    if (props.checkFcsMetadata && _.includes(['fcs', '.fcs', 'csv', '.csv'], extension)) {
      const onSuccess = () => {
        dispatch(addFileToTray(props.name, currentFile.s3Url));

        if(input.coordsFolder){
          input.coordsFolder.forEach(element => {
            dispatch(removeFileFromTrayWithoutClearingProtein('coordsFolder', element));
          });
        }

        if(input.coordsFile){
          input.coordsFile.forEach(element => {
            dispatch(removeFileFromTrayWithoutClearingProtein('coordsFile', element));
          });
        }

        removeQueueFile();
      };
      const onFail = (msg?: string) => {
        setErrorFile(currentFile);

        removeQueueFile();
        currentFile.message = msg || 'difference channel names';
        notIncludedFiles.push(currentFile);

        swal(
          'failed',
          msg || 'The FCS files have differing Channel Names. Please fix Channel Names',
          'error'
        );
      }

      if ('localFile' in currentFile) {
        dispatch(readFcsMetadata(props.metadataName!, currentFile.localFile, onSuccess, onFail));
        console.log("readFcsMetadata");
      } else {
        dispatch(readFcsMetadataFromS3(props.metadataName!, currentFile, onSuccess, onFail));
        console.log("readFcsMetadataFromS3");
      }
    } else {
      dispatch(addFileToTray(props.name, currentFile.s3Url));
    }
    setIsFileInTray(true);
  }

  /**
   * Handle select folder
   *
   * @memberof FileSelector
   */
  const handleSelectFolder = (folder: { key: string  }) => {
    if (!folder) {
      return;
    }
    setIsFolderSelected(true);
    setSelectedFolder(folder.key);
    
    // because the previous pipeline folder, the files have been prepopulated
    // for other folders, we need to fetch from s3
    if (typeof (folder) !== 'undefined' && !folder.key.includes(constants.FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER)) {
      dispatch(s3GetFiles(projectId!, folder.key));
    }

    if (props.isFolderOnly) {
      if (!props.isMultiple) {
        props.tray.map(item => {
          dispatch(removeFileFromTrayWithoutClearingProtein(props.name, item));
        });
      }
      dispatch(addFileToTray(props.name, folder.key));
    }
  }

  const handleBulkFilesToTray = (e: any) => {
    e.preventDefault();
    const validatedFiles: Array<any> = [];

    for (let i = 0; i < localFiles.length; i++) {
      const element = localFiles[i];
      const fileKey = element.key.substring(selectedFolder.length);
      if (element.key.substring(0, selectedFolder.length) === selectedFolder && fileKey.indexOf('/') < 0) {
        // process file only
        if (element.size && !props.tray.includes(element.s3Url)) { // check if file is not already in tray
          if (!props.isMultiple) { // prevent multiple file added to tray when only single file is allowed
            break;
          }
          validatedFiles.push(element);
        }
      }
    }

    setBulkFiles(validatedFiles);
  }

  const handleBulkRemoveFilesToTray = (e: any) => {
    e.preventDefault();
    const inputArray = input[props.name];
    const itemToRemove: Array<any> = _.filter(inputArray, (o) => {
      return new RegExp(selectedFolder).test(o);
    });
    for (let i = 0; i < itemToRemove.length; i++) {
      handleRemoveFileFromTray(itemToRemove[i]);
    }
  }

  /**
   * Select an active file.
   *
   * @memberof FileSelector
   */
  const handleSelectFile = (currentFile: any) => {
    setFile(currentFile);
    // validate file name if needed
    if (props.mathchFilenameWithInput) {
      const filename = currentFile.key.substring(currentFile.key.lastIndexOf("/")+1);
      const isError = handleValidateFileName([{
        name: filename
      }]);
      if (isError) {
        swal(
          'failed',
          `Cutoff file name must be correspond with "Sample Target" name and must contain suffix "${props.mathchFilenameWithInput.suffix}"`,
          'warning'
        );
        return;
      }
    }
    handleAddFileToTray(currentFile);
  }

  /**
   * filter files to only show the accepted format
   *
   * @memberof FileSelector
   */
  const handleShowFile = () => {
    let currentFiles = _.cloneDeep(files);
    if (props.accept.length > 0) {
      currentFiles = _.intersectionWith(files, props.accept, (a, b) => {
        const filename = a.key;
        const extension = getExtension(filename);
        return extension === b;
      });
    }
    return files;
  }

  /**
   * @todo close file detail component
   *
   * @memberof FileSelector
   */
  const onCloseDetail = (event: any) => {
    setIsShowDetail(false);
  }

  const handleToggleExtendWorkspace = (event: any) => {
    event.preventDefault();
    setIsExtendWorkspace(!isExtendWorkspace);
  }

  const handleShowPrevious = (e: any) => {
    e.preventDefault();
    setIsShowWorkspace(false);
    setIsShowPreviousPipeline(true);
  }

  const validateUploadedFilename = (name: string): boolean => {
    if (!props.validateFilename) return false;
    return new RegExp(props.validateFilename, 'g').test(name);
  }

  /**
   * Adding file to Dropzone area
   * 1. Upload the files
   * 2. Add the files to tray, but show that it's uploading
   * 3. Once finish upload, update files' uploading status in the file tray
   *
   * @memberof FileSelector
   */
  const handleAddFilesToDropzone = (currentFiles: Array<File>, rejected: Array<File>) => {
    const today = moment().format('YYYY-MM-DD');
    const userName = profile.username || profile.email;
    const prefix = props.uploadPath || `${constants.FILE_EXPLORER_WORKSPACE_FOLDER}/uploads/${userName}_${today}/`;
    const smallFiles: Array<File> = [];

    const onSuccess = (file) => {
      setFolderName(prefix);
      handleAddFileToTray(file);
      if(fileUploadType === UPLOAD_QUEUE_FILE){
        setFileUploadType(UPLOAD_ALL_FILE);
      }
    }
  
    // validate file name if needed
    if (props.mathchFilenameWithInput) {
      const isError = handleValidateFileName(currentFiles);
      if (isError) {
        swal(
          'failed',
          `Cutoff file name must be correspond with "Sample Target" name and must contain suffix "${props.mathchFilenameWithInput.suffix}"`,
          'warning'
        );
        return;
      }
    }

    if (props.validateFilename) {
      const errorFiles: string[] = [];
      for (let index in currentFiles) {
        const name = currentFiles[index].name;
        const nameWithoutExt = name.substring(0, name.lastIndexOf('.'));
        const passed = validateUploadedFilename(nameWithoutExt);
        if (!passed) {
          errorFiles.push(name);
          delete currentFiles[index];
        }
      }
      if (errorFiles.length) {
        swal('failed', `File(s) ${errorFiles.join(', ')} error`, 'warning');
      }
    }

    if(fileUploadType === UPLOAD_QUEUE_FILE){
      uploadQueue(currentFiles, smallFiles, prefix, onSuccess);
    }else if ( fileUploadType === UPLOAD_ALL_FILE ){
      uploadAll(currentFiles, smallFiles, prefix, onSuccess);
    }
  }

  const uploadQueue = (currentFiles: Array<File>, smallFiles: Array<File>, prefix: string, onSuccess) => {
    const filesBeingUpload = _.cloneDeep(filesBeingUploaded);
    for (let index = 0; index < currentFiles.length; index++) {
      filesBeingUpload.push(currentFiles[index]);
      if (index == 0) {
        if (currentFiles[index].size < MAX_FILE_SIZE) {
          smallFiles.push(currentFiles[index]);
        } else {
          // multipart upload
          dispatch(s3MultipartUpload(currentFiles[0], projectId!, prefix, onSuccess));
        }
      }
    }
    
    setFilesBeingUploaded(currentFiles);

    if (smallFiles.length > 0) {
      dispatch(s3UploadFileSimple(smallFiles, projectId!, prefix, onSuccess));
    }
  }

  const uploadAll = (currentFiles: Array<File>, smallFiles: Array<File>, prefix: string, onSuccess) => {
    const filesBeingUpload = _.cloneDeep(filesBeingUploaded);
    for (let index = 0; index < currentFiles.length; index++) {
      if(!props.tray.includes(currentFiles[index].name)){
        filesBeingUpload.push(filesBeingUpload[index]);
        if (currentFiles[index].size < MAX_FILE_SIZE) {
          smallFiles.push(currentFiles[index]);
        } else {
          // multipart upload
          dispatch(s3MultipartUpload(currentFiles[index], projectId!, prefix, onSuccess));
        }
      }
    }
    setFilesBeingUploaded(currentFiles);

    if (smallFiles.length > 0) {
      dispatch(s3UploadFileSimple(smallFiles, projectId!, prefix, onSuccess));
    }
  }

  const handleShowWorkspace = (event: any) => {
    event.preventDefault();
    setIsShowWorkspace(true);
    setIsShowPreviousPipeline(false);
  }

  /**
   * Render the action box
   *
   * @memberof FileSelector
   */
  const renderDetail = (props: any) => (
    <span></span>
  );

  const handleGetCoordinatesFiles = (event: any) => {
      event.preventDefault();
      const prefix = defaultFileUpload(profile);
      dispatch(getCoordinatesFiles(coordinatesFiles.filesToUpload, projectId!, prefix));
  }

  const handleS3Paths = (event: any) => {
    event.preventDefault();
    setIsShowS3PathsFields(!isShowS3PathsFields);
  }

  const handleValidate = (event: any) => {
    event.preventDefault();
    const data = {
      s3Path: s3Paths,
      projectId: projectId
    };
    const onSuccess = () => {
      setS3Paths('');
    };

    dispatch(validateS3Path(data, onSuccess));
  }

  const dropzoneStyle = {};

  useEffect(() => {
    setS3Paths('');
    if (s3.validatedS3Paths.length >= 1 && s3Paths !== '') {
      for (const path of s3.validatedS3Paths) {
        handleSelectFile(path);
      }
      swal.close!();
    }
    
  }, [s3.validatedS3Paths])

  const renderFiletray = (props: IFileTray, loading: boolean = false) => (
    <div className="filetray-wrapper">
      <div className="filetray-temporary">
        {loading && props.isCoordinatesFiles &&
          <div>
            <ul className="fa-ul">
              <li >
                <i className="fa-li fa fa-spinner fa-spin"></i> loading files ...
              </li>
            </ul>
          </div>
        }
        {filesBeingUploaded.map(file => (
          <div key={file.name}>
            <ul className="fa-ul">
              <li >
                <i className="fa-li fa fa-spinner fa-spin"></i> uploading {file.name} ...
              </li>
            </ul>
          </div>
        ))}
        {bulkFiles.map(file => (
          <div key={file.key}>
            <ul className="fa-ul">
              <li >
                <i className="fa-li fa fa-spinner fa-spin"></i> loading {file.key} ...
              </li>
            </ul>
          </div>
        ))}
      </div>
      <div className="filetray" onClick={() => {}} >
        {Array.isArray(props.files) && props.files.map(file => (
          <div key={file} id={file} className='file'>
            {formatFileName(file)}
            <i className='icon-close text-danger'
              onClick={() => handleRemoveFileFromTray(file)} />
          </div>
        ))}
      </div>
      {(!_.isEmpty(notIncludedFiles)) && (
        <div className="filetray mt-4" onClick={() => {}} >
          <span className='font-weight-bold'>Not Included Files:</span>
          {notIncludedFiles.map(file => (
            <div key={`key-${file.key}`} id={file.key} className='file text-secondary'>
              {formatFileName(file.key)}
              <span> (ERROR: {file.message})</span>
              <i className='icon-close text-danger'
                onClick={() => {
                  setNotIncludedFiles(_.remove(notIncludedFiles, (f) => f !== file));
                }} />
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderFolderAction = () => (
    <div className='folder-action'>
      Folder Name: <code>{selectedFolder}</code>
      <div className="actions">
        {isFolderSelected && !isFolderEmpty() && (
          <button className='btn btn-primary btn-labeled' onClick={handleBulkFilesToTray}>
            <span className='btn-label'>
              <i className='fa fa-files-o'></i>
            </span>
            Add Folder to Tray
          </button>
        )}
        {isFolderSelected && isInTray() && (
          <button
            className='btn btn-danger btn-labeled'
            onClick={handleBulkRemoveFilesToTray}
          >
            <span className='btn-label'>
              <i className='fa fa-times'></i>
            </span>
            Remove Folder from Tray
          </button>
        )}
      </div>
    </div>
    // handleBulkRemoveFilesToTray
  );

  // count file by extension
  const fileCount = () => {
    let extensions: Array<string> = [];
    props.tray.forEach(element => {
      extensions.push(getExtension(element));
    });
    const data = _.countBy(extensions, function(n) {
      return n;
    })
    return data;
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setS3Paths(e.target.value);
  }

  useEffect(() => {
    if (props.previousPipelineParams){
      dispatch(getPreviousPipelineOutputs(props.isPreviousPipelineFromPreProcessing, props.isOnlyPipelineFromPreProcessing, props.previousPipelineParams));
    } else {
      dispatch(getPreviousPipelineOutputs(props.isPreviousPipelineFromPreProcessing, props.isOnlyPipelineFromPreProcessing));
    }
  }, [isExtendWorkspace])

  useEffect(() => {
    if (props.isCoordinatesFiles && ! _.isEmpty(coordinatesFiles.files)) {
      coordinatesFiles.files.forEach(element => {
        dispatch(addFileToTray(props.name, element.s3Path));
      });
    }
  }, [coordinatesFiles])

  useEffect(() => {
    if (fileUploadType === UPLOAD_ALL_FILE){
      const onSuccess = (file) => {
        handleAddFileToTray(file);
        setFolderName(folderName);
      }
      const smallFiles: Array<File> = [];
      uploadAll(filesBeingUploaded, smallFiles, folderName, onSuccess);
    }
  }, [fileUploadType])

  // remove loading file from tray after upload success
  useEffect(() => {
    if (props.tray.length) {
      const filesBeingUpload = _.cloneDeep(filesBeingUploaded);
      for(let i = 0; i < props.tray.length; i++) {
        const filename = _.last(props.tray[i].split('/'));
        _.remove(filesBeingUpload, (f) => f.name === filename);
      }
      setFilesBeingUploaded(filesBeingUpload);
    }
    if (props.tray.length <= 0) {
      setFileUploadType(UPLOAD_QUEUE_FILE);
    }
  }, [props.tray])

  useEffect(() => {
    if (!_.isEmpty(bulkFiles)) {
      handleAddFileToTray(bulkFiles[0]);
    }
  }, [bulkFiles])

  useEffect(() => {
    if (!_.isNull(errorFile)) {
      const filesBeingUpload = _.cloneDeep(filesBeingUploaded);
      _.remove(filesBeingUpload, (f) => f.name === errorFile.name);
      setFilesBeingUploaded(filesBeingUpload);
      setErrorFile(null);
    }
  }, [errorFile])
  
  useEffect(() => {
    // filter files to only show the accepted format
    if (props.accept.length > 0 || props.isFolderOnly) {
      let currentFiles: Array<any> = [];
      let patternTest: any;
      if (props.pattern) {
        patternTest = new RegExp(props.pattern);
      }
      for (let i = 0; i < files.length; i++) {
        const file: { key: string } = files[i];
        const filename = file.key;
        const extension = getExtension(filename);

        // check previous
        if (!isShowPreviousPipeline) {
          if (filename.indexOf(constants.FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER) === 0) {
            continue;
          }
        }

        // check if pattern defined
        if (patternTest) {
          if (_.has(file, 'size') && !patternTest.test(filename)) {
            continue;
          }
        }

        // if it's a folder, push it to the files too
        // and continue the execution without going into 2nd loop
        if (filename.slice(-1) === '/') {
          currentFiles.push(file);
          continue;
        }

        if (!props.isFolderOnly) {
          for (let j = 0; j < props.accept.length; j++) {
            const acceptedExtension = props.accept[j];
            if (extension === acceptedExtension) {
              currentFiles.push(file);
            }
          }
        }
      }
      setLocalFiles(currentFiles);
    }
  }, [files]);

  return (
    <div className='file-selector'>
      {/* Show file workspace */}
      {isShowWorkspace &&
        <div className="workspace">
          {/* File Tray */}
          {renderFiletray({
            files: props.tray,
            isCoordinatesFiles: props.isCoordinatesFiles
          }, coordinatesFiles.isLoading)}

          <div className='row mr-2 ml-2 mb-2'>
            <div className='mr-auto'>
              {
                Object.keys(fileCount()).map((key, index) => {
                  return <span key={`count-${index}`}>{index > 0 ? ', ' : ''}{fileCount()[key]} .{key} files loaded</span>
                })
              }
            </div>

            {props.isShowS3Paths && 
              <a href="" onClick={handleS3Paths}>S3 Paths</a>
            }
            
            {props.isShowGetCoordinatesFiles && 
              <a href="" onClick={handleGetCoordinatesFiles}>Get Files from DB</a>
            }

            {/* {!props.isFolderOnly && ( */}
            {!props.isHideUpload && (
              <div className="show-dropzone">
                <Dropzone
                  accept={props.accept ? props.accept : ""}
                  onDrop={handleAddFilesToDropzone}
                  multiple={props.isMultiple}
                  style={dropzoneStyle}
                >
                  <span className='click'>Upload New Files</span>
                </Dropzone>
              </div>
            )}
            {/* )} */}

            {!props.isHideWorkspace && (
              <a href="" onClick={handleToggleExtendWorkspace}>
                {isExtendWorkspace ? 'Fold Workspace' : 'Extend Workspace'}
              </a>
            )}
          </div>

          {isShowS3PathsFields && (
            <div className='text-right'>
              <Input
                type='textarea'
                name="prefix"
                id="s3link_prefix"
                onChange={handleInput}
                value={s3Paths}
              />
              <div className='row mr-2 ml-2'>
                <span className='mr-auto'>Use comma (,) to separate between S3 Paths</span>
                <button
                  className="btn-primary mt-2 mr-0"
                  type="button"
                  onClick={handleValidate}
                >Validate</button>
              </div>
            </div>
          )}

          {isExtendWorkspace &&
            <div className={`select-zone ${props.isFolderOnly && 'folder-only'}`}>
              <FileBrowser
                files={localFiles}
                onSelectFolder={handleSelectFolder}
                onSelectFile={handleSelectFile}
                detailRenderer={() => renderDetail({
                  name: file.key,
                  canBeAdded: !isFileInTray,
                  isShow: isShowDetail,
                  onClick: () => {
                    if (isFileInTray) {
                      handleRemoveFileFromTray(file)
                    } else {
                      handleAddFileToTray();
                    }
                  },
                  onClose: onCloseDetail,
                })}

                icons={Icons.FontAwesome(4)}
                fileRenderer={(item) => {
                  const isAdded = _.includes(props.tray, item.s3Url);
                  return (
                    <FileSelectorDetail
                      {...item}
                      isAdded={isAdded}
                    />
                  );
                }}
              />
              {!props.isFolderOnly && renderFolderAction()}
              {isLoading && (<div className="file-loading"><span className="file-loading-text"> Loading...</span></div>)}
            </div>
          }
        </div>
      }
    </div>
  );
}

FileSelector.defaultProps = {
  files: [],
  projectId: 0,
  tray: [],
  isMultiple: false, // @todo: can the tray receive multiple files?
  accept: [],   // @todo: if not blank, show only file with this format
  checkFcsMetadata: false,  // need to check FCS metadata everytime an FCS is added to tray    
  getPreviousPipelineOutput: false,
  isShowWorkspace: true,
  isShowPreviousPipeline: false,
  isExtendWorkspace: false, // workspace is not extended
  clearMetadata: true, //clear metadata when tray empty
  mixedExtension: false,
  isFolderOnly: false,
  isConcatenateCsv: false,
  isPreviousPipelineFromPreProcessing: false,
  isOnlyPipelineFromPreProcessing: false,
  metadataName: 'protein',
  uploadPath: '',
  isHideWorkspace: false,
  isHideUpload: false,
  isCoordinatesFiles: false,
  isShowGetCoordinatesFiles: false,
  isShowS3Paths: false,
};

export default FileSelector;
