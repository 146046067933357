import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import RequestWrapperView from '../../components/Views/RequestWrapperView';
import { TDispatch } from '../../types/action';
import { IStore } from '../../types/store';
import { IProfile, TUser } from '../../types/type.user';
import { unsubscribe } from '../../actions/action.profile';

interface IProps {
    location: any,
}

const Subscription = ({ location }: IProps) => {
    const profile: IProfile = useSelector((state : IStore) => state.user.profile);
    const dispatch = useDispatch<TDispatch<TUser>>();

    const { isError, isLoading } = profile;

    useEffect(() => {
        dispatch(unsubscribe());
    }, []);
    return (
        <RequestWrapperView isError={isError} isLoading={isLoading}>
            <div id="login">
                <div className="container-login">
                    <div className="logo"></div>
                    <div className="content text-center col-md-6">
                        <h2 className="title">Subscription</h2>
                        <p>Unsubscribing successfuly.</p>
                        <p>You will not get any update from Cytogrtapher in the future. If you want to subscribe again, you can update subscription preferences on the profile page.</p>
                        <Link to="/">Back to Home</Link>
                    </div>
                </div>
            </div>
        </RequestWrapperView>
    );
}

export default Subscription;
