import React from 'react';

const SidebarFooter = () => {
  return null;
  // Uncomment following code lines to add Sidebar Footer
  // return (
  //   <div className="sidebar-footer"></div>
  // );
}

export default SidebarFooter;
