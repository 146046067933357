import * as types from '../reducers/actionTypes';
import { TNotification } from '../types/type.notification';
import { TAction } from '../types/action';
import { GET, PUT, POST, DELETE } from '../utils/httprequest';
import { INotificationResponse, IGeneralResponse, INotificationDetailResponse } from '../types/type.response';
import { getVersion } from './action.version-notification';

export const getNotification=(isFirstLoading : boolean = false) : TAction<TNotification> => {
    return (dispatch) => {
        dispatch({
            type: types.GET_NOTIFICATION_REQUEST,
            isFirstLoading
        });
        
        GET('notification')
        .then((response: INotificationResponse)=>{
            dispatch(getVersion(response.app_version));
            dispatch({
                type: types.GET_NOTIFICATION_SUCCESS,
                data: response
            });
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.GET_NOTIFICATION_FAILURE,
                message: error.response.statusText
            });
        });
    }
}

export const readNotification = (id : number) : TAction<TNotification> => {
    return (dispatch) => {
        dispatch({
            type: types.READ_NOTIFICATION_REQUEST,
        })

        PUT(`notification/${id}`, { read: 1 })
        .then((response: INotificationDetailResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.status == 'error') {
                dispatch({
                    type: types.READ_NOTIFICATION_FAILURE,
                    message: ''
                });
            } else {
                GET(`notification/${id}`)
                .then((notification: INotificationDetailResponse) => {
                    dispatch({
                        type: types.READ_NOTIFICATION_SUCCESS,
                        id,
                        data: notification.data,
                    });
                })
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.READ_NOTIFICATION_FAILURE,
                message: error.response.data.message
            });
        });
    }
}

export const readAllNotification = () : TAction<TNotification> => {
    return (dispatch) => {
        
        dispatch({
            type: types.READ_ALL_NOTIFICATION_REQUEST,
        });

        POST('notification/read-all')
        .then((response: IGeneralResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.status == 'error') {
                dispatch({
                    type: types.READ_ALL_NOTIFICATION_FAILURE,
                    message: ''
                });
            } else {
                GET('notification')
                .then((response: INotificationResponse)=>{
                    dispatch({ type: types.READ_ALL_NOTIFICATION_SUCCESS, data: response.data });
                })
                .catch((error) => {
                    dispatch({
                        type: types.GET_NOTIFICATION_FAILURE,
                        message: error.response.statusText
                    });
                });
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.READ_ALL_NOTIFICATION_FAILURE,
                message: error.response.statusText
            });
        });
    }
}

export const deleteNotification = (id : number) : TAction<TNotification> => {
    return (dispatch) => {
        dispatch({
            type: types.DELETE_NOTIFICATION_REQUEST,
        })

        DELETE(`notification/${id}`)
        .then((response: INotificationDetailResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.status == 'error') {
                dispatch({
                    type: types.DELETE_NOTIFICATION_FAILURE,
                    message: ''
                });
            } else {
                GET(`notification/${id}`)
                .then((notification: INotificationDetailResponse) => {
                    dispatch({
                        type: types.DELETE_NOTIFICATION_SUCCESS,
                        id,
                    });
                })
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.DELETE_NOTIFICATION_FAILURE,
                message: error.response.data.message
            });
        });
    }
}

export const deleteAllNotification = (data: Array<number>) : TAction<TNotification> => {
    return (dispatch) => {
        dispatch({
            type: types.DELETE_ALL_NOTIFICATION_REQUEST,
        })

        POST('notification/delete-all', { ids: data })
        .then((response: IGeneralResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.status == 'error') {
                dispatch({
                    type: types.DELETE_ALL_NOTIFICATION_FAILURE,
                    message: ''
                });
            } else {
                GET('notification')
                .then((response: INotificationResponse)=>{
                    dispatch({ type: types.DELETE_ALL_NOTIFICATION_SUCCESS, data: response.data });
                })
                .catch((error) => {
                    dispatch({
                        type: types.GET_NOTIFICATION_FAILURE,
                        message: error.response.statusText
                    });
                });
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.DELETE_ALL_NOTIFICATION_FAILURE,
                message: error.response.statusText
            });
        });
    }
}
