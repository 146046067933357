import React, { useEffect, useState } from 'react';
import { Input, InputProps, Button, Label, Row } from 'reactstrap';
import _ from 'lodash';
import Toggle from './Toggle';

interface IProps extends InputProps {
    name: string,
    defaultValue?: any,
    isDecimal?: boolean,
    isHideArrow?: boolean,
    fixedNumber?: number,
    isAuto?: boolean,
    autoValue?: string,
}

const InputCustom = (props : IProps) => {
    const [autoValue, setAutoValue] = useState<boolean>(props.value === props.autoValue);
    const [lastValue, setLastValue] = useState<string>(String(props.defaultValue || props.min || 0));

    const handleInputDecimal = (e: any) => {
        const { value } = e.target;
        if (value.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/gi) || _.isEmpty(value)) {
            props.onChange!(e);
        }
    }

    const checkLimit = (event: any) => {
        event.preventDefault();
        if (Number(event.target.value) < Number(props.min)) {
            event.target.value = props.min;
        }
        if (Number(event.target.value) > Number(props.max)) {
            event.target.value = props.max;
        }
        props.onChange!(event);
    }

    const onChangeNumber = (event: any) => {
        event.preventDefault();
        if (_.isNaN(Number(event.target.value))) {
            event.target.value = Number(props.defaultValue) || 0;
        } else {
            props.onChange!(event);
        }
    }

    // handle keyboard press
    const handleKeyDown = (e: any) => {
        const { fixedNumber } = props;
        const value = Number(e.target.value) || 0;
        const step = Number(props.step) || 1;
        const min = Number(props.min) || 0;
        let nextValue : number = value;

        switch (e.key) {
            case 'ArrowUp': {
                e.preventDefault();
                nextValue = +(value + Number(step)).toFixed(fixedNumber);
                break;
            }
            case 'ArrowDown': {
                e.preventDefault();
                nextValue = +(value - Number(step)).toFixed(fixedNumber);
                break;
            }
            default: return;
        }

        if (props.min && nextValue < props.min) nextValue = Number(props.min);
        if (props.max && nextValue > props.max) nextValue = Number(props.max);

        // fixed the trailing float number and then use toString to remove zero at the end 
        e.target.value = Number(nextValue.toFixed(fixedNumber)).toString();
        props.onChange!(e);
    }

    // handle when arrow clicked
    const onArrowPress = (e: any, key: string) => {
        e.preventDefault();
        // belum bisa trigger event, sementara pake cara bodoh
        const event = {
            key,
            target: { name: props.name, value: props.value },
            preventDefault: () => {}
        }
        handleKeyDown(event);
    }

    const onToggleAuto = (value: boolean) => {
        setAutoValue(!value);

        if (!value) {
            setLastValue(String(props.value));
        }

        props.onChange!({
            // @ts-ignore
            target: {
                name: props.name,
                value: value ? lastValue : props.autoValue!
            }
        });
    }

    const filteredProps = _.omit(props, ['defaultValue', 'isDecimal', 'isHideArrow', 'fixedNumber', 'isAuto', 'autoValue']);
    filteredProps.value = !_.isUndefined(props.value) ? props.value : props.defaultValue;

    if (props.type === 'number') {
        filteredProps.type = 'text';
        filteredProps.onChange = onChangeNumber;
        filteredProps.onKeyDown = handleKeyDown;
        filteredProps.onBlur = checkLimit;
    }

    const autoLabel = props.autoValue![0].toUpperCase() + props.autoValue!.slice(1);
    return (
        <div className="input-custom">
            {props.isAuto ? (
                <Row className="input-with-addon">
                    <div className="switch col-md-2">
                        <Label>{autoLabel}</Label>
                        <Toggle
                            value={autoValue}
                            onToggle={onToggleAuto}
                        />
                    </div>
                    {(!autoValue) && (
                        <Input {...filteredProps} className={`${filteredProps.className || ''} col-md-10`} />
                    )}
                </Row>
            ) : (
                <Input {...filteredProps} />
            )}

            {(props.type === 'number' && !props.isHideArrow && !autoValue) && (
                <div className="input-custom-arrow">
                    <button className="top" onClick={(e) => onArrowPress(e, 'ArrowUp')}>
                        <i className="icon-arrow-up"></i>
                    </button>
                    <button className="bottom" onClick={(e) => onArrowPress(e, 'ArrowDown')}>
                        <i className="icon-arrow-down"></i>
                    </button>
                </div>
            )}

            {(props.type === 'select') && (
                <div className="select-icon">
                    <i className="icon-arrow-down"></i>
                    {/* <i className="fa fa-angle-double-down"></i> */}
                </div>
            )}
        </div>

    );
}

InputCustom.defaultProps = {
    defaultValue: '',
    isHideArrow: false,
    isAuto: false,
    fixedNumber: 2,
    autoValue: "auto"
};

export default InputCustom;
