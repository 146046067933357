import swal from 'sweetalert';
import * as types from '../reducers/actionTypes';
import history from '../views/history';
import { TExecution } from '../types/type.execution';
import { TAction } from '../types/action';
import { GET, POST, DELETE } from '../utils/httprequest';
import { IPipelineResponse, IPipelineDetailResponse, IGeneralResponse } from '../types/type.response';
import { updateMetadata } from './action.pipeline-input';
import { getVersion } from './action.version-notification';

export const getExecution = (): TAction<TExecution> => {
    return (dispatch) => {
        dispatch({
            type: types.GET_EXECUTION_REQUEST,
        });
        
        GET('execution')
        .then((response: IPipelineResponse) => {
            dispatch(getVersion(response.app_version));
            dispatch({
                type: types.GET_EXECUTION_SUCCESS,
                data: response.data
            });
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.GET_EXECUTION_FAILURE,
                message: error.response.statusText,
            });
        });
    }
};

export const getOneExecution = (id: number, onSuccess?: Function): TAction<TExecution> => {
    return (dispatch) => {
        dispatch({
            type: types.GET_ONE_EXECUTION_REQUEST,
        });

        GET(`execution/${id}`)
        .then((response: IPipelineDetailResponse) => {
            dispatch(getVersion(response.app_version));
            dispatch({
                type: types.GET_ONE_EXECUTION_SUCCESS,
                data: response.data
            });
            if (onSuccess) {
                onSuccess();
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.GET_ONE_EXECUTION_FAILURE,
                message: error.response.data.message,
            });
        });
    }
};

export const runPipeline = (data: Object, onSuccess?: Function): TAction<TExecution> => {
    return (dispatch) => {
        dispatch({
            type: types.RUN_PIPELINE_REQUEST,
        });

        swal({
            title: 'Please wait',
            text: 'Creating Pipeline.....',
            closeOnClickOutside: false,
            buttons: { visible: false }
        });

        POST('execution', data)
        .then((response: IPipelineDetailResponse) => {
            dispatch(getVersion(response.app_version));
            dispatch({
                type: types.RUN_PIPELINE_SUCCESS,
                data: response.data
            });
            dispatch(getOneExecution(response.data.id));
            if (onSuccess) {
                if (swal.close) swal.close();
                onSuccess(response.data.id);
            } else {
                swal.close!();
                history.push({ pathname: '/pipeline/done' });
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            swal.close!();
            dispatch({
                type: types.RUN_PIPELINE_FAILURE,
                error_message: error.response.statusText,
            });
        });
    }
};

export const runShiny = (data: Object): TAction<TExecution> => {
    return (dispatch) => {
        dispatch({
            type: types.UPLOAD_FILE_REQUEST,
        });
        swal({
            title: 'Please wait',
            text: 'Creating Pipeline.....',
            closeOnClickOutside: false,
            buttons: { visible: false }
        });

        POST('execution', data)
        .then((response: IPipelineDetailResponse) => {
            dispatch(getVersion(response.app_version));
            const input = JSON.parse(response.data.input);
            // Object.keys(input).map((key) => dispatch(updateInput(key, input[key])));
            dispatch(updateMetadata('shinyInput', input));
            dispatch(getOneExecution(response.data.id));
            swal.close!();
            history.push({ pathname: '/pipeline/shiny' });
            dispatch({
                type: types.UPLOAD_FILE_SUCCESS,
            });
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));

            dispatch({
                type: types.UPLOAD_FILE_FAILURE,
            });

            swal.close!();

            const message = error.response.data.message;
            
            if (message.message && message.message == 'S3 Latency high') {
                swal({ 
                    buttons: ['Fail Run', 'Check again if files ready'],
                    title: "Warning",
                    text: message.message,
                    icon: "warning",
                    dangerMode: true
                }).then((tryAgain: boolean | null) => {
                    if (tryAgain) {
                        dispatch(latencyCheck({
                            files: message.files,
                            execution: message.execution,
                            s3Path: message.destination
                        }, () => {
                            history.push({ pathname: '/pipeline/shiny' });
                        }));
                    }
                });
            } else {
                swal('Error', message, 'error');
            }
        });
    }
};

export const resetPipeline = (): TAction<TExecution> => {
    return (dispatch) => {
        dispatch({ type: types.RUN_PIPELINE_SUCCESS });
    }
};

export const getOutput = (onProcess: Function, onSuccess?: Function): TAction<TExecution> => {
    return (dispatch, getState) => {
        const { execution, pipeline } = getState();
        const id = execution.selected_execution.id;

        const data = {
            reRun: pipeline.reRun
        }        

        const refreshPipeline = () => {
            GET('execution')
            .then((refresh: IPipelineResponse) => {
                dispatch(getVersion(refresh.app_version));
                dispatch({
                    type: types.GET_EXECUTION_SUCCESS,
                    data: refresh.data
                });
                if (onSuccess) {
                    onSuccess();
                }
            });
        }
        POST(`execution/get-output/${id}`, data)
        .then((response: IGeneralResponse) => {
            dispatch(getVersion(response.app_version));
            onProcess(response, refreshPipeline);
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            console.log(error);
        });
    }
};

export const archivePipeline = (id: number, onSuccess?: Function): TAction<TExecution> => {
    return (dispatch, getState) => {
        const { pipelineType } = getState().uiHistory;
        dispatch({
            type: types.ARCHIVE_REQUEST,
        });

        swal({
            title: 'Please wait',
            text: 'Archiving.....',
            closeOnClickOutside: false,
            buttons: { visible: false }
        });

        DELETE(`execution/${id}`)
        .then((response: IGeneralResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.success) {
                if ((pipelineType === 'preprocessing')) {
                    swal.close!();
                    swal('Success', response.message, 'success').then(() => {
                        if (onSuccess) {
                            onSuccess();
                        }
                    });
                } else {
                    GET('execution')
                    .then((refresh: IPipelineResponse) => {
                        dispatch(getVersion(refresh.app_version));
                        dispatch({
                            type: types.GET_EXECUTION_SUCCESS,
                            data: refresh.data
                        });
                        swal.close!();
                        swal('Success', response.message, 'success').then(() => {
                            if (onSuccess) {
                                onSuccess();
                            }
                        });
                    });
                }
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            swal.close!();
            swal('Failed', error.response.data.message, 'warning');
            dispatch({
                type: types.ARCHIVE_RESULT,
                message: error.response.statusText,
            });
        });
    }
};

export const requestBatchDownload = (id: number, fileName: string): TAction<TExecution> => {
    return (dispatch, getState) => {
        swal({
            title: 'Please wait',
            text: 'Archiving.....',
            closeOnClickOutside: false,
            buttons: { visible: false }
        });

        GET(`execution/batch-download/${id}`, { params: { fileName }})
        .then((response: IGeneralResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.status == 'success') {
                swal.close!();
                swal('Success', response.message, 'success');
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            swal.close!();
        });
    }
};

export const cancelPipeline = (id: number, onSuccess?: Function): TAction<TExecution> => {
    return (dispatch) => {
        swal({
            title: 'Please wait',
            text: 'Canceling Pipeline.....',
            closeOnClickOutside: false,
            buttons: { visible: false }
        });

        POST(`execution/cancel-pipeline/${id}`)
        .then((response: IGeneralResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.success) {
                GET('execution')
                .then((refresh: IPipelineResponse) => {
                    dispatch({
                        type: types.GET_EXECUTION_SUCCESS,
                        data: refresh.data
                    });
                    swal.close!();
                    swal('Success', response.message, 'success').then(() => {
                        if (onSuccess) {
                            onSuccess();
                        }
                    });
                });
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            swal.close!();
            swal('Failed', 'Canceling pipeline failed', 'warning');
        });
    }
};

export const downloadPipeline = (id: number, fileName: string): TAction<TExecution> => {
    return (dispatch, getState) => {
        POST(`execution/download-pipeline/${id}`, { fileName })
        .then((response: IGeneralResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.status == 'success') {
                swal('Success', response.message, 'success');
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            swal.close!();
        });
    }
};

export const latencyCheck = (data: Object, onSuccess: Function = () => {}): TAction<TExecution> => {
    return (dispatch) => {
        swal({
            title: 'Please wait',
            text: 'Checking Files.....',
            closeOnClickOutside: false,
            buttons: { visible: false }
        });

        POST(`execution/latency-check`, { data })
        .then((response: IPipelineDetailResponse) => {
            const input = JSON.parse(response.data.input);
            dispatch(updateMetadata('shinyInput', input));
            dispatch(getOneExecution(response.data.id));

            swal.close!();
            onSuccess();
        })
        .catch((error) => {
            swal.close!();
            const message = error.response.data.message;

            if (message.message && message.message == 'S3 Latency high') {
                swal({ 
                    buttons: ['Fail Run', 'Check again if files ready'],
                    title: "Warning",
                    text: message.message,
                    icon: "warning",
                    dangerMode: true
                }).then((tryAgain: boolean | null) => {
                    if (tryAgain) {
                        dispatch(latencyCheck({
                            files: message.files,
                            execution: message.execution,
                            s3Path: message.destination
                        }, () => {
                            history.push({ pathname: '/pipeline/shiny' });
                        }));
                    }
                });
            } else {
                swal('Error', message, 'error');
            }
        })
    }
}
