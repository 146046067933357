import React from 'react';
import ToggleButton from 'react-toggle-button';

interface IProps {
    value?: boolean,
    onToggle?: Function,
    disabled?: boolean
}

const mainColor = 'rgba(0, 46, 82, 1)';
const secondColor = 'rgba(91, 113, 129, 0.5)';
const mainColorDisabled = 'rgba(0, 46, 82, 0.65)';
const secondColorDisabled = 'rgba(91, 113, 129, 0.25)';
const defaultColors = {
    active: {
        base: mainColor,
        hover: mainColor,
    },
    inactive: {
        base: secondColor,
        hover: secondColor,
    }
};

const disabledColors = {
    active: {
        base: mainColorDisabled,
        hover: mainColorDisabled,
    },
    inactive: {
        base: secondColorDisabled,
        hover: secondColorDisabled,
    },
}

const Toggle = ({
    value,
    onToggle,
    disabled
}: IProps) => {

    const disabledAction = () => {}

    return (
        <ToggleButton
            colors={disabled ? disabledColors : defaultColors}
            value={value || false}
            onToggle={disabled ? disabledAction : onToggle!}
        />
    );
}

export default Toggle;
