import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { formatFileName } from '../../actions/action.s3';
import { isInputValid } from '../../actions/action.pipeline-input';
import _ from 'lodash';
import XMLViewer from 'react-xml-viewer';
import { convertToXml } from './helper';

interface IProps {
  input: any,
  exclude?: Array<string>,
  isPreview?: boolean
}

const InputSummary = (props: IProps) => {
  const [input] = useState<any>(props.input ? props.input : []);

  const formatValue = (value: any) => {
    let formattedValue = <span key={value}>{value}</span>;

    if (value.constructor === Boolean) {
      formattedValue = <span key={`options-${value}`} className={value ? 'text-success' : 'text-danger'}>{value.toString()}</span>;
    }

    if (typeof (value) === 'string') {
      formattedValue = <div key={value}>{formatFileName(value)}</div>
    }

    return formattedValue;
  }

  const renderInputItem = () => {
    return Object.keys(input).map((key) => {
      const title = key;
      const value = input[key];
      const values: Array<JSX.Element> = [];

      // An input is rendered if it's not "protein", 
      // or its value is not null, or its value is not undefined
      if (key === 'protein' || key === 'metaSampleEditor' || key === 'gatedProtein') {
        return;
      }
      if (!isInputValid(key, value)) {
        return;
      }
      // skip excluded input
      if (_.includes(props.exclude, key)) {
        return;
      }

      // if input's value is array, push each array item into values
      if (value.constructor === Array) {
        value.map((item: any) => values.push(formatValue(item)));
      } else {
        values.push(formatValue(value));
      }

      if (key === 'channels') {
        // render xml
        const xml = convertToXml(value);
        if (props.isPreview) {
          return (
            <div className="xml-preview">
              <XMLViewer xml={xml} />
            </div>
          );
        }
        return;
      }

      return (
        <Row key={key} className='summary-input'>
          <Col xs="3" className='key'>{title} : </Col>
          <Col xs="9" className='value'>
            {values}
          </Col>
        </Row>
      );
    });
  };

  return (
    <div>
      {renderInputItem()}
    </div>
  );
}

export default InputSummary;