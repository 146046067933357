import swal from 'sweetalert';
import * as types from '../reducers/actionTypes';
import { TAction } from '../types/action';
import { ICoordinatesFilesToUpload, TCoordinatesFiles } from '../types/type.coordinates-files';
import { POST } from '../utils/httprequest';
import { removeFileFromTrayWithoutClearingProtein } from './action.file-selector';
import { updateInput } from './action.pipeline-input';
import { getVersion } from './action.version-notification';

export const getCoordinatesFiles = (
    files: Array<ICoordinatesFilesToUpload> = [],
    projectId: number,
    prefix: string,
    onError?: Function
): TAction<TCoordinatesFiles> => (dispatch) => {
    let nullUuid: string = '';

    for(let i = 0; i<files.length; i++){
        if(files[i].uuid == ''){
            nullUuid = files[i].name;
            break;
        }
    }

    if(nullUuid != ''){
        if (onError) {
            onError();
        }
        swal('Failed', `Gating UUIDs not found. You can upload your own files.`, 'warning');
    }else{
        let data : {
            files: Array<ICoordinatesFilesToUpload>,
            prefix: string
        } = {
            files: files,
            prefix: prefix
        };
    
        dispatch({
            type: types.GET_COORDINATES_FILES_REQUEST
        });
    
        POST(`coordinates-files/${projectId}`, data)
        .then((response) => {
            dispatch(getVersion(response.app_version));
            dispatch({
                type: types.GET_COORDINATES_FILES_SUCCESS,
                data: response.data
            });
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.GET_COORDINATES_FILES_FAILURE,
                message: error.response.data.message
            });
            if (onError) {
                onError();
            }
            swal("Failed", error.response.data.message, "warning");
        });
    }
}

export const uploadCoordinatesFiles = (files: Array<ICoordinatesFilesToUpload>) : TAction<TCoordinatesFiles> => (dispatch) => {
    dispatch({
        type: types.UPLOAD_COORDINATES_FILES,
        filesToUpload: files
    });
}

export const removeCoordinatesFiles = (file: string) : TAction<TCoordinatesFiles> => (dispatch, getState) => {
    const { coordsFolder, coordsFile } = getState().pipeline.input;
    const fileName = file.substring(file.lastIndexOf('/')+1, file.lastIndexOf('.'));

    if (coordsFolder) {
        coordsFolder.forEach(element => {
            const coordsFilename: string = element.substring(element.lastIndexOf('/')+1, element.lastIndexOf('_coordinates.'))
            if(coordsFilename === fileName){
                dispatch(removeFileFromTrayWithoutClearingProtein('coordsFolder', element));
            }
        });
    }

    if (coordsFile) {
        coordsFile.forEach(element => {
            const coordsFilename: string = element.substring(element.lastIndexOf('/')+1, element.lastIndexOf('_coordinates.'))
            if(coordsFilename === fileName){
                dispatch(removeFileFromTrayWithoutClearingProtein('coordsFile', element));
            }
        });
    }

    dispatch({
        type: types.REMOVE_COORDINATES_FILES,
        value: fileName
    });
}

export const compareClonedCoordinatesFiles = (clonedCoordsFiles: Array<string>) => (dispatch, getState) => {
    const { coordsFolder, input } = getState().pipeline.input;
    const newCoordsFiles = coordsFolder;
    clonedCoordsFiles.forEach(element => {
        const coordsFilename: string = element.substring(element.lastIndexOf('/')+1, element.lastIndexOf('_coordinates.'));

        input.forEach((file: string) => {
            const fileName = file.substring(file.lastIndexOf('/')+1, file.lastIndexOf('.'));

            if ((fileName == coordsFilename) && (!coordsFolder.includes(element))) {
                newCoordsFiles.push(element);
            }
        });
    });
    dispatch(updateInput('coordsFolder', newCoordsFiles));
}