import initialState from './initialState';
import * as types from './actionTypes';
import { IReport, TReport } from '../types/type.report';

export default function(state: IReport = initialState.report, action: TReport) : IReport {
    switch (action.type){
        case types.ADD_REPORT_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.ADD_REPORT_SUCCESS:
            return{
                ...state,
                isLoading: false,
                isError: false,
            }
        case types.ADD_REPORT_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }
        default: return state;
    }
}
