export default (url: string, conf: any, convert: string | undefined = undefined) : any => {
    // default value
    let config: any = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
        body: [],
    }
    config = {
        ...conf,
    };
    return fetch(url, config).then((response) => {
        let headers = {};
        response.headers.forEach((v,k) => {
            headers[k] = v;
        });
        if (!response.ok) {
            throw {
                response: {
                    headers,
                    status: response.status,
                    statusText: response.statusText,
                }
            };
        }
        if (convert === 'text') {
            return response.text();
        }
        return response.json();
    });
};
