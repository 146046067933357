import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Row, Col, Nav, NavLink, NavItem, TabContent, TabPane
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';

import { getVisiblePipeline, changePipelineTab } from '../../actions/action.visible-pipeline';
import { updateMetadata } from '../../actions/action.pipeline-input';
import { IStore } from '../../types/store.d';
import { TDispatch } from '../../types/action';
import RequestWrapperView from '../../components/Views/RequestWrapperView';
import { TVisiblePipeline, IVisiblePipeline } from '../../types/type.visible-pipeline';
import { setSelectedPipelineTab } from '../../actions/action.ui-history';

interface IProps {
    projectId: number
}

const PipelineTab = ({ projectId }: IProps) => {
    const pipelines: IVisiblePipeline = useSelector((state : IStore) => state.visiblePipelines);
    const selectedPipelineTab: string = useSelector((state : IStore) => state.uiHistory.selectedPipelineTab);
    const dispatch = useDispatch<TDispatch<TVisiblePipeline>>();
    const { isLoading, isError } = pipelines;

    const [activeTab, setActiveTab] = useState<string>(selectedPipelineTab);
    const toggle = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            dispatch(setSelectedPipelineTab(tab));
        }
    }

    const onSelectPipeline = (tabId: number, pipelineId : number) => {
        dispatch(updateMetadata('projectId', projectId));
        dispatch(updateMetadata('tabId', tabId));
        dispatch(changePipelineTab(tabId));
        dispatch(updateMetadata('pipelineScript', 0));
        dispatch(updateMetadata('pipelineId', pipelineId));
        dispatch(updateMetadata('cloneSource', null));
    }

    useEffect(() => {
        dispatch(getVisiblePipeline());
    }, []);

    const filteredPipelines = _.filter(pipelines.data, (item) => !_.isEmpty(item.pipelines));
    return (
        <RequestWrapperView isLoading={isLoading} isError={isError}>
            <div className="pipeline-tab">
                <Row>
                    <Col xs="12" md="12" className="tabs">
                        <Nav tabs>
                            {_.map(filteredPipelines, (item) => (
                                <NavItem key={`tab-link-${item.id}`}>
                                    <NavLink
                                        className={classnames({ active: activeTab === `tab-${item.id}` })}
                                        onClick={() => { toggle(`tab-${item.id}`); }}>
                                        {item.name}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            {_.map(filteredPipelines, (item) => (
                                <TabPane key={`tab-${item.id}`} tabId={`tab-${item.id}`}>
                                    <Row className="pipeline-grid">
                                        {_.map(item.pipelines, (pipeline) => (
                                            <Col xs="6" md="3" key={`pipeline-${pipeline.id}`}>
                                                <Link to={{ pathname: '/pipeline/form' }}
                                                    onClick={() => onSelectPipeline(item.id, pipeline.id)}>
                                                    <div className="pipeline pipeline-icon">
                                                        <div className={`icon ${pipeline.shortName}`}></div>
                                                        <div>{pipeline.name.toUpperCase()}</div>
                                                    </div>
                                                </Link>
                                            </Col>
                                        ))}
                                    </Row>
                                </TabPane>
                            ))}
                        </TabContent>
                    </Col>
                </Row>
            </div>
        </RequestWrapperView>
    );
}

export default PipelineTab;
