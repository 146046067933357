import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { forgetPassword } from '../../actions/action.user';
import { TDispatch } from '../../types/action';
import { TUser } from '../../types/type.user';

const Login = () => {
    const dispatch = useDispatch<TDispatch<TUser>>();
    const [email, setEmail] = useState<string>('');

    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const onFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(forgetPassword(email));
    }

    return (
        <div id="login">
            <div className="container-login">
                <div className="logo"></div>

                <form onSubmit={onFormSubmit}>
                    <h1 className="title text-center">Forget Password</h1>
                    <div className="card border form-login">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email" id="email" name="email" className="form-control"
                                placeholder="Your Email"
                                onChange={handleChangeText} required />
                        </div>
                        <button className="btn-primary" type="submit">Reset Password</button>
                        <div className="text-center">
                            <Link to="/login">Login</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
