import initialState from './initialState';
import * as types from './actionTypes';
import { IBatchData, IBatch, TBatch } from '../types/type.batch';

export default function(state: IBatchData = initialState.batch, action: TBatch) : IBatchData {
    switch (action.type) {
        case types.GET_BATCH_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.GET_BATCH_SUCCESS:
            return{
                ...state,
                all_batch: action.data,
                isLoading: false,
                message: ''
            }
        case types.GET_BATCH_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }
        case types.GET_ONE_BATCH_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.GET_ONE_BATCH_SUCCESS:
            return{
                ...state,
                selected_batch: action.data,
                isLoading: false,
                message: ''
            }
        case types.GET_ONE_BATCH_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }
        case types.UPDATE_BATCH_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.UPDATE_BATCH_SUCCESS:
            return{
                ...state,
                isLoading: false,
                message: action.message
            }
        case types.UPDATE_BATCH_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }

        default: return state;
    }
}
