import React from 'react';
import { useSelector } from 'react-redux';
import { IUser } from '../../types/type.user';
import { IStore } from '../../types/store';

const SidebarHeader = () => {
    const user: IUser = useSelector((state : IStore) => state.user);
    const { profile } = user;
    return (
        <div className="sidebar-header">
            <img
                src={ profile != undefined && profile.avatarUrl ? profile.avatarUrl : "img/avatar.png" }
                className="img-avatar" alt="Avatar"
            />
            <div><strong>{profile.name}</strong></div>
            <div>{user.email}</div>
            <div><strong>{profile.organization}</strong></div>
        </div>
    );
}

export default SidebarHeader;
