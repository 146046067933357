import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';

import DocumentationItem from './DocumentationItem';
import { useSelector } from 'react-redux';
import { IStore } from '../../types/store';
import { docs } from '../../views/Documentation/Documents';

interface IProps {
	location: any
}

const DocumentationSidebar = ({ location }: IProps) => {
    const pipelineId: any = useSelector((state : IStore) => state.pipeline.pipelineId);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [data, setData] = useState<any>({ title: '', parameters: [] });

    const getDocsData = () => {
        const pipeline = _.find(docs, ['id', pipelineId]);
        if (pipeline) {
            setData(pipeline);
        }
    }

    const onModalToggle = (e: any) => {
        if (location.pathname === '/pipeline/form') {
            e.preventDefault();
            getDocsData();
            setIsModalOpen(!isModalOpen);
        }
    }

    return (
        <React.Fragment>
            <NavItem>
                <NavLink to={'/documentation'} className={'nav-link'} activeClassName="active" onClick={onModalToggle}>
                    <i className={'icon-book-open'}></i>Documentation
                </NavLink>
            </NavItem>

            <Modal isOpen={isModalOpen} toggle={onModalToggle} className="modal-lg modal-pipeline-tips">
                <ModalHeader toggle={onModalToggle}>{data.title}</ModalHeader>
                <ModalBody>
                    <div className="modal-body-inner">
                        <div className="section" key={data.key}>
                            {
                                data.parameters.map((p: any) => {
                                    if (p.isHidden) return null;
                                    return (
                                        <div className="section-params" key={p.key}>
                                            <h5>{p.title}</h5>
                                            <DocumentationItem
                                                key={p.key}
                                                data={p}
                                                isImageScrollable
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default DocumentationSidebar;
