import React, { Component } from 'react';
import { Link, Route, Redirect } from 'react-router-dom';
import PipelineTable from './PipelineTable';
import PipelineDetail from './Detail';
import PipelineForm from './Form';
import PipelineSummary from './Summary';
import PipelineDone from './Done';
import PipelineShiny from './Shiny';
import PreprocessingBatch from './PreprocessingBatch';
import PipelineUpload from './PipelineUpload';

interface IProps {
  match: any
}

const Pipeline = ({
  match
} : IProps) => (
  <div className="animated fadeIn">
    <div className="container-pipeline">
      {/* <Route exact path={match.path} component={PipelineTable} /> */}
      <Route exact path={`${match.url}/form/`} name="PipelineForm" component={PipelineForm}/>
      <Route exact path={`${match.url}/preprocessing/`} name="PipelineShiny" component={PreprocessingBatch}/>
      <Route path={`${match.url}/detail/:id`} name="PipelineDetail" component={PipelineDetail}/>
      <Route path={`${match.url}/summary/`} name="PipelineSummary" component={PipelineSummary}/>
      <Route path={`${match.url}/done/`} name="PipelineDone" component={PipelineDone}/>
      <Route path={`${match.url}/shiny/`} name="PipelineShiny" component={PipelineShiny}/>
      <Route path={`${match.url}/upload/`} name="PipelineUpload" component={PipelineUpload}/>
      <Route path={`${match.url}/cluster-code-upload/`} name="PipelineClusterCodeUpload" component={PipelineUpload}/>
    </div>
  </div>
)

export default Pipeline;
