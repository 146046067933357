import initialState from './initialState';
import * as types from './actionTypes';
import { IUser, TUser } from '../types/type.user';
import { removeState } from '../utils/localStorage';

export default function(state: IUser = initialState.user, action: TUser) : IUser {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                email: action.data.email,
                id: action.data.id,
                token: action.data.token,
                username: action.data.username,
                profile: action.data,
                message: '',
                isWelcome: true,
            }
        case types.LOGIN_FAILURE:
            return {
                ...state,
                message: action.message,
                isLoading: false,
                isError: true,
            }

        case types.GET_PROFILE_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.GET_PROFILE_SUCCESS:
            return {
                ...state,
                message: '',
                profile: action.data,
            }
        case types.GET_PROFILE_FAILURE:
            return {
                ...state,
                message: action.message,
                isLoading: false,
                isError: true,
            }

        case types.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                message: '',
                isLoading: false,
                isError: false,
            }
        case types.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                message: action.message,
                isLoading: false,
                isError: true,
            }

        case types.FORGET_PASS_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.FORGET_PASS_SUCCESS:
            return {
                ...state,
                message: '',
                isLoading: false,
                isError: false,
            }
        case types.FORGET_PASS_FAILURE:
            return {
                ...state,
                message: action.message,
                isLoading: false,
                isError: true,
            }

        case types.FORGET_PASS_RESET_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.FORGET_PASS_RESET_SUCCESS:
            return {
                ...state,
                message: '',
                isLoading: false,
                isError: false,
            }
        case types.FORGET_PASS_RESET_FAILURE:
            return {
                ...state,
                message: action.message,
                isLoading: false,
                isError: true,
            }

        case types.RESET_WELCOME:
            return {
                ...state,
                isWelcome: action.value,
            }

        case types.LOGOUT_REQUEST:
            removeState();
            return {
                ...state,
                email: '',
                id: null,
                token: '',
                username: '',
                isLoading: false,
                isWelcome: false,
            }

        default: return state;
    }
}
