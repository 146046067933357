import initialState from './initialState';
import * as types from './actionTypes';
import { IS3, TS3 } from '../types/type.s3';
import _ from 'lodash';

export default function (state : IS3 = initialState.s3, action : TS3) : IS3 {
  switch (action.type) {
    case types.S3_FILES_RESET:
      return {
        ...state,
        files: [],
      };
    case types.S3_GET_FILES_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.S3_GET_FILES_SUCCESS:
      return {
        ...state,
        files: action.data,
        isLoading: false,
        isError: false
      };

    case types.S3_GET_FILES_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true
      };

    case types.S3_CHANGE_BUCKET:
      return {
        ...state,
        bucket: action.data,
        files: [],
      };

    case types.S3_SELECT_FOLDER:
      const { isSelectedFolderFcsOnly } = initialState.s3;
      return {
        ...state,
        selectedFolder: action.data,
        isSelectedFolderFcsOnly: action.isFcsOnly || isSelectedFolderFcsOnly,
      };

    case types.S3_SELECT_FILE: {
      const initS3 = initialState.s3;

      return {
        ...state,
        selectedFolder: initS3.selectedFolder,
        isSelectedFolderFcsOnly: initS3.isSelectedFolderFcsOnly,
      };
    }

    case types.S3_ADD_FILE: {
      const { filesToUpload } = state;
      filesToUpload.push(action.data);

      return {
        ...state,
        filesToUpload,
      };
    }

    case types.S3_UPLOAD_START: {
      const total = state.toBeUploadedCount + action.total;
      return {
        ...state,
        uploadDone: false,
        toBeUploadedCount: total,
      };
    }

    case types.S3_UPLOAD_PROGRESSING: {
      let { progress } = action;
      let { toBeUploadedCount } = state;

      // if progress is 100%, remove 1 item count
      if (progress >= 100) {
        toBeUploadedCount -= 1;
        progress = 0;
      }

      // finish uploading
      if (toBeUploadedCount === 0) {
        return {
          ...state,
          uploadDone: true,
          uploadingPercentage: 0,
          toBeUploadedCount,
          uploadedFiles: action.uploadedFiles,
        };
      }

      return {
        ...state,
        uploadingPercentage: progress,
        toBeUploadedCount,
      };
    }

    case types.S3_UPLOAD_END: {
      return {
        ...state,
        uploadDone: true,
      };
    }

    case types.SELECT_PROJECT_FILES_SUCCESS:
      return {
        ...state,
        selectedUrl: action.data.selectedUrl,
      };

    case types.S3_DELETE_FILE: {
      const files = _.cloneDeep(state.files);
      const { file, isFolder } = action;

      if (isFolder) {
        _.remove(files, function(element) {
          return new RegExp(`^${file}`).test(element.key)
        });
      } else {
        // const index = files.indexOf(file);
        const index = files.findIndex(element => element.key === file);
        if (index > 1) {
          files.splice(index, 1);
        }
      }
      return {
        ...state,
        files,
      };
    }

    case types.S3_RENAME_FILE: {
      const files = _.cloneDeep(state.files);
      const { file, newName } = action;
      const index = files.findIndex(element => element.key === file);
      if (index > 1) {
        files[index].s3Url = files[index].s3Url!.replace(files[index].key, newName);
        files[index].key = newName;
        files[index].relativeKey = newName;
      }
      return {
        ...state,
        files,
      };
    }

    case types.S3_VALIDATE_PATH: {
      return {
        ...state,
        validatedS3Paths: action.data,
      };
    }

    default: return state;
  }
}
