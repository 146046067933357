import React, { useState, useEffect } from 'react';
import {
    Col, Input, Label
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import ColorPalette from '../../../components/ColorPalette/ColorPalette';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { changeExcludedParams, updateInput } from '../../../actions/action.pipeline-input';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import InputCustom from '../../../components/Form/InputCustom';
import Toggle from '../../../components/Form/Toggle';
import { GATING_SUBSETTING_STEP, INPUT_SMALL, SYMBOL_PARAMETERS } from '../../../utils/constants';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';
import MetasampleEditor from '../../../components/Views/MetasampleEditor';
import { defaultFileUpload, getExtension } from '../helper';
import { ICoordinatesFiles } from '../../../types/type.coordinates-files';
import { compareClonedCoordinatesFiles, getCoordinatesFiles } from '../../../actions/action.coordinates-files';
import { IProfile } from '../../../types/type.user';
import { removeFileFromTrayWithoutClearingProtein } from '../../../actions/action.file-selector';

const HighDimensionalAnalysis = () => {
    const profile: IProfile = useSelector((state : IStore) => state.user.profile);
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const coordinatesFiles: ICoordinatesFiles = useSelector((state: IStore) => state.coordinatesFiles);
    const projectId: number = useSelector((state : IStore) => state.projects.selected_project.id)!;
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced } = pipeline;
    const [isSingleCsv, setIsSingleCsv] = useState<boolean>(false);
    const [calcFreq, setCalcFreq] = useState<boolean>(false);
    const [clonedCoordinates, setClonedCoordinates] = useState<Array<string>>([]);

    const dispatchInput = (key: string, value: any) => dispatch(updateInput(key, value));

    const handleToggle = (value: boolean, key: string) => {
        const toggledValue = !value;
        if (key !== 'doFreq'){
            dispatchInput(key, toggledValue ? true : null);
        }

        // if toggled value is true,
        // then, activate other inputs
        switch (key) {
            case 'doFreq':
                setCalcFreq(toggledValue);
                if (toggledValue) {
                    const prefix = defaultFileUpload(profile);
                    dispatch(getCoordinatesFiles(coordinatesFiles.filesToUpload, projectId, prefix));
                } else {
                    input.coordsFolder = [];
                }
                break;
            case 'doTsne':
                dispatchInput('perplexityTsne', toggledValue ? "auto" : null);
                break;
            case 'doPhate':
                dispatchInput('kValuePhate', toggledValue ? "auto" : null);
                break;
            case 'doUmap':
                dispatchInput('kValueUmap', toggledValue ? "auto" : null);
                dispatchInput('spreadUmap', toggledValue ? 1 : null);
                dispatchInput('minDistUmap', toggledValue ? 0.1 : null);
                break;
            case 'doPhenograph':
                dispatchInput('kValuePheno', toggledValue ? "auto" : null);
                break;
            // case 'doFlowsom':
            //     dispatchInput('kValueFlow', toggledValue ? "auto" : null);
            //     dispatchInput('xDim', toggledValue ? "auto" : null);
            //     dispatchInput('yDim', toggledValue ? "auto" : null);
            //     break;
            case 'plot':
                dispatchInput('plotSample', toggledValue ? false : null);
                dispatchInput('topPercentile', toggledValue ? 1 : null);
                dispatchInput('bottomPercentile', toggledValue ? 0 : null);
                dispatchInput('pch', toggledValue ? 'circle' : null);
                dispatchInput('plotCeiling', toggledValue ? 20000 : null);
                dispatchInput('svg', toggledValue ? false : null);
                dispatchInput('palette', toggledValue ? 1 : null);
                break;
        }
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        let value = e.target.value;

        dispatchInput(name, value);
    }

    const handleInputCustom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const symbol = ['*', '+', 'o', 'x'];
        // +value => convert string to number
        if (_.includes(symbol, value) || _.inRange(+value, 0, 26) || _.isEmpty(value)) {
            dispatchInput(name, value);
        }
    }

    const isFcsFile = () : boolean => {
        const fileExtension = getExtension(input.input.length>0 ? input.input[0] : '');

        return fileExtension === 'fcs';
    }

    useEffect(() => {
        const findCsv = _.find(input.input, (i) => i.split('.').pop() === 'csv');
        setIsSingleCsv(!!findCsv);
        if (!!findCsv) {
            dispatch(changeExcludedParams(['transformation', 'plotSample']));
        } else {
            dispatch(changeExcludedParams([]));
        }
    }, [input.input]);

    useEffect(() => {
        if(_.isEmpty(input.input)){
            coordinatesFiles.filesToUpload = [];
            coordinatesFiles.files = [];
        }
        if (!_.isEmpty(input.coordsFolder)) {
            setClonedCoordinates([...input.coordsFolder]);
            setCalcFreq(true);
        }
    }, []);

    useEffect(() => {
        if (calcFreq) {
            dispatch(compareClonedCoordinatesFiles(clonedCoordinates));
        }
    }, [input.coordsFolder, calcFreq]);

    return (
        <TabbedMetaContainer
            name="High Dimensional Analysis"
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.input)}
            containerClass="tsne"
            metamarker={(!_.isEmpty(input.input)) && (
                <FormGroupCustom
                    pipeline="highdim"
                    id="metamarkerhighdim"
                    input={input.metaMarker}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        {(input.protein && input.protein.length > 0) ? (
                            <ProteinSelector
                                columns={['keep']}
                                protein={input.protein}
                                tray={input.metaMarker}
                                trayName="metaMarker"
                            />
                        ) : (
                            <FileSelector
                                name="metaMarker"
                                tray={input.metaMarker}
                                isMultiple={false}
                                accept={['csv', '.csv']}
                                checkFcsMetadata={false}
                                getPreviousPipelineOutput={false}
                            />
                        )}
                    </Col>
                </FormGroupCustom>
            )}
            metasample={
                <FormGroupCustom
                    pipeline="highdim"
                    id="metasamplehighdim"
                    input={input.metaSample}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <MetasampleEditor input='input' />
                    </Col>
                </FormGroupCustom>
            }
        >
            <div className="pipeline-form">
                {/* sample */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="inputhighdim"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            isMultiple={true}
                            accept={['fcs', '.fcs', 'csv', '.csv']}
                            checkFcsMetadata={true}
                            getPreviousPipelineOutput={true}
                            mixedExtension={true}
                            isShowPreviousPipeline
                            isConcatenateCsv
                            isPreviousPipelineFromPreProcessing
                            previousPipelineParams = {{
                                preprocessingStep: GATING_SUBSETTING_STEP.toString()
                            }}
                            isShowS3Paths
                        />
                    </Col>
                </FormGroupCustom>

                {/* calculate freq */}
                { isFcsFile() &&
                    <FormGroupCustom
                        pipeline="highdim"
                        id="calculatefreqhighdim"
                        input={`${calcFreq}`}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <Toggle
                                value={calcFreq}
                                onToggle={(v: boolean) => handleToggle(v, 'doFreq')}
                            />
                            {calcFreq && (
                                <div className='mt-4'>
                                    <FileSelector
                                        name='coordsFolder'
                                        tray={input.coordsFolder}
                                        isMultiple={true}
                                        accept={['csv', '.csv']}
                                        isCoordinatesFiles={true}
                                        isShowGetCoordinatesFiles={calcFreq}
                                        isHideWorkspace={!calcFreq}
                                        isHideUpload={!calcFreq}
                                    />
                                </div>
                            )}
                        </Col>
                    </FormGroupCustom>
                }

                {/* prefix */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="prefixhighdim"
                    input={input.prefix}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Input
                            name="prefix"
                            id="tsne_prefix"
                            onChange={handleInput}
                            value={input.prefix}
                        />
                    </Col>
                </FormGroupCustom>

                {/* transformation */}
                {(!_.isEmpty(input.input) && !isSingleCsv) && (
                    <FormGroupCustom
                        pipeline="highdim"
                        id="transformationhighdim"
                        input={input.transformation}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <InputCustom
                                type="select" name="transformation" id="tsne_type"
                                onChange={handleInput} value={input.transformation}>
                                <option value="" disabled>Select Transformation</option>
                                <option value="cytof">cytof</option>
                                <option value="fluor">fluor</option>
                                <option value="linear1">linear1</option>
                                <option value="linear2">linear2</option>
                                <option value="arcsinh5">arcsinh5</option>
                                <option value="arcsinh150">arcsinh150</option>
                                <option value="auto">auto</option>
                                <option value="none">none</option>
                            </InputCustom>
                        </Col>
                    </FormGroupCustom>
                )}

                {/* ceiling */}
                {(!_.isEmpty(input.input) && !isSingleCsv) && (
                    <FormGroupCustom
                        pipeline="highdim"
                        id="ceilinghighdim"
                        input={input.ceiling}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <InputCustom
                                name="ceiling" id="tsne_ceiling" placeholder=" Number of events to take per sample (unless sample has less)"
                                type="number" step="100" min="100" max="50000"
                                onChange={handleInput} value={input.ceiling}
                                isAuto
                                autoValue="all"
                            />
                        </Col>
                    </FormGroupCustom>
                )}

                {/* tSNE */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="tsnehighdim"
                    input={input.doTsne}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doTsne}
                            onToggle={(v: boolean) => handleToggle(v, 'doTsne')}
                        />

                        {/* perplexityTsne */}
                        {input.doTsne && (
                            <Col sm={12} className="sub-params">
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="perplexitytsnehighdim"
                                    input={input.perplexityTsne}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="perplexityTsne" placeholder=" Increased perplexity => increased spread"
                                            type="number" step="any" min="5" max="50" defaultValue="25"
                                            onChange={handleInput} value={input.perplexityTsne}
                                            isAuto
                                        />
                                    </Col>
                                </FormGroupCustom>
                            </Col>
                        )}
                    </Col>
                </FormGroupCustom>

                {/* doPhate */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="phatehighdim"
                    input={input.doPhate}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doPhate}
                            onToggle={(v: boolean) => handleToggle(v, 'doPhate')}
                        />

                        {/* kvaluephate */}
                        {input.doPhate && (
                            <Col sm={12} className="sub-params">
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="kvaluephatehighdim"
                                    input={input.kValuePhate}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="kValuePhate" id="kValuePhate" placeholder=""
                                            type="number" step="any" min="1" max="100" defaultValue="25"
                                            onChange={handleInput} value={input.kValuePhate}
                                            isAuto
                                        />
                                    </Col>
                                </FormGroupCustom>
                            </Col>
                        )}
                    </Col>
                </FormGroupCustom>

                {/* Isomap */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="isomaphighdim"
                    input={input.doIsomap}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doIsomap}
                            onToggle={(v: boolean) => handleToggle(v, 'doIsomap')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* DiffMap */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="diffmaphighdim"
                    input={input.doDiffmap}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doDiffmap}
                            onToggle={(v: boolean) => handleToggle(v, 'doDiffmap')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* PCA */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="pcahighdim"
                    input={input.doPca}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doPca}
                            onToggle={(v: boolean) => handleToggle(v, 'doPca')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* UMAP */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="umaphighdim"
                    input={input.doUmap}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doUmap}
                            onToggle={(v: boolean) => handleToggle(v, 'doUmap')}
                        />
                        {input.doUmap && (
                            <Col sm={12} className="sub-params">
                                {/* kValueUmap */}
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="kvalueumaphighdim"
                                    input={input.kValueUmap}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="kValueUmap" id="kValueUmap" placeholder=""
                                            type="number" step="any" min="1" max="100" defaultValue="25"
                                            onChange={handleInput} value={input.kValueUmap}
                                            isAuto
                                        />
                                    </Col>
                                </FormGroupCustom>

                                {/* spreadUmap */}
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="spreadumaphighdim"
                                    input={input.spreadUmap}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="spreadUmap" placeholder=""
                                            type="number" step="0.1" min="0.1" max="20"
                                            onChange={handleInput} value={input.spreadUmap}
                                        />
                                    </Col>
                                </FormGroupCustom>

                                {/* minDistUmap */}
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="mindistumaphighdim"
                                    input={input.minDistUmap}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="minDistUmap" id="minDistUmap" placeholder=""
                                            type="number" step="0.01" min={0.0001} max={input.spreadUmap - 0.001}
                                            fixedNumber={4}
                                            onChange={handleInput} value={input.minDistUmap}
                                        />
                                    </Col>
                                </FormGroupCustom>
                            </Col>
                        )}
                    </Col>
                </FormGroupCustom>

                {/* oneSense */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="onesensehighdim"
                    input={input.doOneSense}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doOneSense}
                            disabled
                        // onToggle={(v: boolean) => handleToggle(v, 'doOneSense')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* Phenograph */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="phenographhighdim"
                    input={input.doPhenograph}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doPhenograph}
                            onToggle={(v: boolean) => handleToggle(v, 'doPhenograph')}
                        />
                        {input.doPhenograph &&
                            <Col sm={12} className="sub-params">
                                {/* kValuePheno */}
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="kvaluephenohighdim"
                                    input={input.kValuePheno}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="kValuePheno" id="kValuePheno" placeholder="k is low => more clusters and inversely"
                                            type="number" step="any" min="1" max="100" defaultValue="25"
                                            onChange={handleInput} value={input.kValuePheno}
                                            isAuto
                                        />
                                    </Col>
                                </FormGroupCustom>
                            </Col>}
                    </Col>
                </FormGroupCustom>

                {/* FlowSOM */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="flowsomhighdim"
                    input={input.doFlowsom}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doFlowsom}
                            onToggle={(v: boolean) => handleToggle(v, 'doFlowsom')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* Graphical Output/plot */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="meaningplotshighdim"
                    input={input.plot}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.plot}
                            onToggle={(v: boolean) => handleToggle(v, 'plot')}
                        />
                        {input.plot &&
                            <Col sm={12} className="sub-params">
                                {(!_.isEmpty(input.input) && !isSingleCsv) && (
                                    <FormGroupCustom
                                        pipeline="highdim"
                                        id="foreachhighdim"
                                        input={input.plotSample}
                                        isAdvanced={isAdvanced}
                                        row>
                                        <Col sm={INPUT_SMALL}>
                                            <Toggle
                                                value={input.plotSample}
                                                onToggle={(v: boolean) => handleToggle(v, 'plotSample')}
                                            />
                                        </Col>
                                    </FormGroupCustom>
                                )}
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="toppercentilehighdim"
                                    input={input.topPercentile}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="topPercentile" id="hda-topPercentile" placeholder=" 0.99"
                                            type="number" step="0.01" min="0.51" max="1"
                                            onChange={handleInput} value={input.topPercentile}
                                            isDecimal
                                        />

                                    </Col>
                                </FormGroupCustom>
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="bottompercentilehighdim"
                                    input={input.bottomPercentile}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="bottomPercentile" id="hda-BottomPercentile" placeholder=" 0.01"
                                            type="number" step="0.01" min="0" max="0.49"
                                            onChange={handleInput} value={input.bottomPercentile}
                                            isDecimal
                                        />
                                    </Col>
                                </FormGroupCustom>
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="pchhighdim"
                                    input={input.pch}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            type="select" 
                                            name="pch" 
                                            id="hda-pch"
                                            onChange={handleInput} 
                                            value={input.pch}
                                        >
                                            <option value="" disabled>Select Symbol</option>
                                            {SYMBOL_PARAMETERS.map((element) => {
                                                return (
                                                    <option value={element} key={element}>{element}</option>
                                                );
                                            })}
                                        </InputCustom>
                                    </Col>
                                </FormGroupCustom>

                                {/* plotCeiling */}
                                {(!_.isEmpty(input.input) && !isSingleCsv) && (
                                    <FormGroupCustom
                                        pipeline="highdim"
                                        id="plotceilinghighdim"
                                        input={input.plotCeiling}
                                        isAdvanced={isAdvanced}
                                        row>
                                        <Col sm={INPUT_SMALL}>
                                            <InputCustom
                                                name="plotCeiling" id="tsne_plotCeiling" placeholder=" Number of points to plot"
                                                type="number" step="100" min="100" max="50000"
                                                onChange={handleInput} value={input.plotCeiling}
                                                isAuto
                                                autoValue="all"
                                            />
                                        </Col>
                                    </FormGroupCustom>
                                )}

                                {/* doSVG */}
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="svghighdim"
                                    input={input.svg}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <Toggle
                                            value={input.svg}
                                            onToggle={(v: boolean) => handleToggle(v, 'svg')}
                                        />
                                    </Col>
                                </FormGroupCustom>

                                {/* Pallete */}
                                <FormGroupCustom
                                    pipeline="highdim"
                                    id="palettehighdim"
                                    input={input.palette}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <ColorPalette
                                            name="palette"
                                            value={input.palette}
                                            onDataChange={handleInput}
                                            reverse={input.revPalette}
                                            addonComponent={() => (
                                                <div className="switch">
                                                    <Label>Reverse Color</Label>
                                                    <Toggle
                                                        value={input.revPalette}
                                                        onToggle={(v: boolean) => handleToggle(v, 'revPalette')}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Col>
                                </FormGroupCustom>
                            </Col>}
                    </Col>
                </FormGroupCustom>

                {/* seed */}
                <FormGroupCustom
                    pipeline="highdim"
                    id="seedhighdim"
                    input={input.seed}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="seed" id="DoFlowSOM_seed" type="number" step="any"
                            onChange={handleInput} value={input.seed}
                        />
                    </Col>
                </FormGroupCustom>

            </div>
        </TabbedMetaContainer>
    );
}

export default HighDimensionalAnalysis;
