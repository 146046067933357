import React from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';

interface IProps {
    logs: Array<{
        timestamp: number,
        message: string,
        ingestionTime: number
    }>
} 
const LogView = ({
    logs
} : IProps) => {
    return (
        <div className="log-table">
            <div className="log-table-inner">
                <Table bordered>
                    <thead>
                        <tr>
                            <th style={{ width: "20%" }}>Time</th>
                            <th style={{ width: "80%" }}>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.map((item, index) => (
                            <tr key={`log-${item.timestamp}-${index}`}>
                                <td style={{ width: "20%" }}>{moment.unix(item.timestamp/1000).format('LLL')}</td>
                                <td style={{ width: "80%" }}>{item.message}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default LogView;
