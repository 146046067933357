import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

interface IProps {
    data: {
        text: string,
        images?: Array<any>,
        list?: any
    },
    isImageScrollable: boolean,
    onImageClicked: Function,
    className?: string
}

const DocumentationItem = ({ data, isImageScrollable, onImageClicked, className } : IProps) => {
    return (
        <div className={classNames('documentation-item', className)}>
            <p dangerouslySetInnerHTML={{ __html: data.text }} />
            {data.list && (
                <div>
                    <span dangerouslySetInnerHTML={{ __html: data.list.text }} />
                    <ul>
                        {
                            data.list.items.map((listText: string, i: number) => {
                                return (
                                    <li key={i} dangerouslySetInnerHTML={{ __html: listText }}></li>
                                );
                            })
                        }
                    </ul>
                </div>
            )}
            {!_.isEmpty(data.images) && (
                <div>
                    {_.map(data.images, (item, index) => (
                        <div key={`images-item-${index}`}>
                            {(item.text) && (<p dangerouslySetInnerHTML={{ __html: item.text }} />)}
                            {(item.url) && (
                                <div
                                    className={classNames('docs-image', { scrollable: isImageScrollable })}
                                    onClick={() => onImageClicked(item.url)}>
                                    <img src={item.url} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

DocumentationItem.defaultProps = {
    isImageScrollable: false,
    onImageClicked: () => {}
};

export default DocumentationItem;
