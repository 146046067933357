import { IDocument } from "../../types/type.template";

export const docs: Array<IDocument> = [
  {
    id: 1,
    key: "robot",
    isHidden: false,
    title: "ROBOT",
    description:
      "The Robot pipeline clusters and processes a list of peptides of interest. It outputs config files needed for the pipetting robot.",
    parameters: [
      {
        key: "descriptionrobot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Description",
        tooltip: "robottooltipdesc",
        text: "The Robot pipeline clusters and processes a list of peptides of interest. It outputs config files needed for the pipetting robot.",
      },
      {
        key: "inputrobot",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Peptide Sequences",
        tooltip:
          "File (.csv) containing a list of peptide names and sequences to be studied",
        text: "File in CSV format, containing peptide names and sequences. Column headers of the .csv file are expected to be <i>peptide_name</i> and <i>peptide_sequence</i>.",
        images: [
          {
            text: "Example:",
            url: "img/docs/robot_peptide.png",
          },
        ],
      },
      {
        key: "tablerobot",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "SAV Channels",
        tooltip: "File (.csv) with the SAV code translation table",
        text: "File in CSV format, containing streptavidin (SAV) metal channels for coding. Column headers of the .csv file are expected to be <i>order</i> and <i>channel</i>.",
        images: [
          {
            text: "Example:",
            url: "img/docs/robot_sav.png",
          },
        ],
      },
      {
        key: "prefixrobot",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Prefix",
        tooltip: "Prefix for the output files",
        text: 'Introduce a string that would be used as prefix in your output files. Default of "robot" for this pipeline.',
      },
      {
        key: "cutoffrobot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Clustering Cut-Off (% Sequence Identity)",
        tooltip: "Cut-off value for cluster similarity",
        text: "Cut-off value for cluster similarity (85 by default).",
      },
      {
        key: "codingrobot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Coding Strategy",
        tooltip: "Multiplex tetramer coding strategy used",
        text: "Multiplex tetramer coding strategy used. Currently Cytographer&trade; supports double, triple (default) and quadruple coding strategy.",
      },
      {
        key: "volumerobot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Pipet Volume",
        tooltip:
          "Pipetting volume (ul) for individual SAV metals according to coding scheme.",
        text: "Pipetting volume for individual SAV metals according to coding scheme, e.g. for triple coding strategy: the final volume is 60ul (3 x 20ul). Set to 20ul by default",
      },
      {
        key: "samplesrobot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Number of Samples",
        tooltip: "Number of samples to calculate dilution factor",
        text: "Number of samples to calculate dilution factor. Dilution factor = (coding * volume) / (0.75 * samples). Set to 3 by default. New: twice the dilution is used at the moment. An extra 10% of volume is added for the recommended total volume.",
      },
      {
        key: "configrobot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Configuration Setup",
        tooltip: "Number of configurations",
        text: "Number of configurations used. Currently Cytographer&trade; supports 1 (default) or 2 configuration setups.",
      },
      {
        key: "wellrobot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Well Type",
        tooltip: "Well type",
        text: "Well type, either standard (default) or deep",
      },
      {
        key: "eppendorfrobot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Eppendorf Volume",
        tooltip: "Eppendorf volume",
        text: "Volume of the eppendorf tubes used in the experiment. They come in either 1.5ml (default) or 2ml size.",
      },
      {
        key: "flatrobot",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Flat Eppendorf",
        tooltip: "Flat eppendorf",
        text: "Define whether the eppendorf tubes used are of the flat type or not (default).",
      },
      {
        key: "outputrobot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of Robot Pipeline",
        tooltip: "robottooltipout",
        text: "The Robot pipeline produces clustered peptides and their respective metal/fluorochrome configurations to run the robot arm, and a file containing detailed information of the experiment for the user.<br>For single configuration (config = 1), the Robot pipeline produces a single configuration file in GWL format (and in CSV format), and for double configuration (config = 2), it produces two .gwl (and .csv) configuration files.",
        list: {
          text: "List of output files:",
          items: [
            "ClusterCodes.csv : Clustered peptides and their respective metal/fluorochrome configurations in CSV format.",
            "Config.csv : Metal/fluorochrome configuration robot input in CSV format.",
            "Config.gwl : Metal/fluorochrome configuration for robot use.",
            "Config.pdf : Visualization of metal/fluorochrome configuration on the actual plate.",
            "info file : Detailed information inclusive of dilution info for the user.",
          ],
        },
        images: [
          {
            text: "Example of Info output:",
            url: "img/docs/robot_info.png",
          },
          {
            text: "Example of ClusterCodes output (2 configurations):",
            url: "img/docs/robot_clusters.png",
          },
          {
            text: "Example of Config.pdf:",
            url: "img/docs/robot_plate.png",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    key: "prepro",
    isHidden: false,
    title: "PRE-PROCESSING",
    description:
      "The Pre-processing pipeline currently comprises 3 functionalities. <br><b>Randomization</b> takes values that are 0 in the input .fcs files, and converts them into random values between 0 and -1, outputting new .fcs files.<br><b>Cytospill</b> The cytospill method is an ab initio spillover compensation algorithm published by Miao et al (2020). The algorithm analysis per file the signal distributions in each channel, constructs a spillover matrix thereof and applies it to the respective file. Output are the corrected .fcs files. <br><b>Bead Normalization</b> Cytographer Bead normalization uses the algorithm published by Fink et al. (2013), in an R implementation by Ch. Bligaard Pedersen [Biosurf](https://github.com/biosurf). The output are a set of bead normalized .fcs files, another set of .fcs files with the used bead events and diagnostic plots. ",
    parameters: [
      {
        key: "descriptionprepro",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Description",
        tooltip: "preprotooltipdesc",
        text: "The Pre-processing pipeline currently comprises 3 functionalities. <b>Randomization</b> takes values that are 0 in the input .fcs files, and converts them into random values between 0 and -1, outputting new .fcs files.<br><b>Cytospill</b> The cytospill method is an ab initio spillover compensation algorithm published by Miao et al (2020). The algorithm analysis per file the signal distributions in each channel, constructs a spillover matrix thereof and applies it to the respective file. Output are the corrected .fcs files.<br><b>Bead Normalization</b> Cytographer Bead normalization uses the algorithm published by Fink et al. (2013), in an R implementation by Ch. Bligaard Pedersen [Biosurf](https://github.com/biosurf). The output are a set of bead normalized .fcs files, another set of .fcs files with the used bead events and diagnostic plots.",
      },
      {
        key: "sampleprepro",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs)",
        tooltip: "Input files in FCS format",
        text: "Multiple input files in FCS format.<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.",
      },
      {
        key: "prefixprepro",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Prefix",
        tooltip: "Prefix for the output files",
        text: 'Introduce a string that would be used as prefix in your output files. Default of "random" for <b>Randomization</b> and "spill" for <b>Cytospill</b>.',
      },
      {
        key: "seedprepro",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Seed",
        tooltip: "Make reproducible results",
        text: "Set a seed to make reproducible results; in case you want to run the same data with the same parameters again, setting the seed to the same value would ensure that procedures that rely on randomness to a certain extent yield identical results. Set to 42 by default.",
      },
      {
        key: "normalizedprepro",
        isRequired: false,
        isAdvanced: false,
        isHidden: true,
        title: "Normalized (multiple .fcs)",
        tooltip: "Normalized",
        text: "Normalized",
      },
      {
        key: "outputprepro",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of Pre-processing Pipeline",
        tooltip: "preprotooltipout",
        text: "<b>Randomization</b> produces an output folder containing the new .fcs files with randomized values between 0 and -1 (instead of 0).<br><b>Cytospill</b> Output are the corrected .fcs files.<br><b>Bead Normalization</b> The output are a set of bead normalized .fcs files, another set of .fcs files with the used bead events and diagnostic plots.",
      },
    ],
  },
  {
    id: 4,
    key: "tetdecon",
    isHidden: false,
    title: "TETRAMER DECONVOLUTION",
    description:
      "The Tetramer Deconvolution pipeline deconvolutes .fcs files and outputs automated hit validations.",
    parameters: [
      {
        key: "descriptiontetdecon",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Description of Tetramer Deconvolution Pipeline",
        tooltip: "tetdecontooltipdesc",
        text: "The Tetramer Deconvolution pipeline deconvolutes .fcs files and outputs automated hit validations.",
      },
      {
        key: "inputcasetetdecon",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Target (.fcs files, e.g. CD8 T cells)",
        tooltip: "Case .fcs file(s)",
        text: 'Files in FCS format, containing CD8 T cells (or your own case cells).<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.<br>Please use the following naming convention: <i>sample_comparison_config.fcs</i><br>Make sure the <i>sample</i> field is identical for the same sample across case/control folders and for all configs.<br>Make sure the <i>comparison</i> field is identical across configs in the same folder.<br>Make sure the <i>config</i> field is identical across case/control folders for the same config - Please do not add additional "_" as it is the delimiting character used to determine the fields. Note that the <i>config</i> field can be ommitted if there is only one config.',
        list: {
          text: "Example:",
          items: [
            "For Donor 1, Configuration 1 : Donor1_CD8_C1.fcs",
            "For Donor 1, Configuration 2 : Donor1_CD8_C2.fcs",
          ],
        },
      },
      {
        key: "inputcontroltetdecon",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Background (.fcs files, e.g. CD4 T cells)",
        tooltip: "Control FCS file(s)",
        text: 'Files in FCS format, containing CD4 T cells (or your own control cells).<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.<br>Please use the following naming convention: <i>sample_comparison_config.fcs</i><br>Make sure the <i>sample</i> field is identical for the same sample across case/control folders and for all configs.<br>Make sure the <i>comparison</i> field is identical across configs in the same folder.<br>Make sure the <i>config</i> field is identical across case/control folders for the same config - Please do not add additional "_" as it is the delimiting character used to determine the fields. Note that the <i>config</i> field can be ommitted if there is only one config.',
        list: {
          text: "Examples:",
          items: [
            "For Donor 1, Configuration 1 : Donor1_CD4_C1.fcs",
            "For Donor 1, Configuration 2 : Donor1_CD4_C2.fcs",
          ],
        },
      },
      {
        key: "metamarkertetdecon",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Selection/Information",
        tooltip: "Marker metadata information, to identify SAVs",
        text: "Click in the marker list that appears, or upload your own file in CSV format, containing marker grouping information.<br>The first column should be the unique marker IDs used in the input .fcs files. Include an <i>SAV</i> column to identify streptavidins (SAVs) with <i>y</i> or <i>TRUE</i>. Other marker grouping columns will be ignored in this script.",
        images: [
          {
            text: "Example:",
            url: "img/docs/tetdecon_metamarker.png",
          },
        ],
      },
      {
        key: "badsavtetdecon",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Bad SAV",
        tooltip: "Exclusion of a certain SAV",
        text: 'Exclusion of a certain SAV metal from the deconvolution algorithm. "none" by default.',
      },
      {
        key: "clustercodestetdecon",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Cluster Codes",
        tooltip: "ClusterCodes output file from the Robot pipeline",
        text: "ClusterCodes output file from the Robot pipeline. File in CSV format with clustered peptides and their respective metal configurations.",
        images: [
          {
            text: "Example (1 configuration):",
            url: "img/docs/tetdecon_clusters.png",
          },
        ],
      },
      {
        key: "prefixtetdecon",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Prefix",
        tooltip: "Prefix",
        text: "Prefix",
      },
      {
        key: "transformationtetdecon",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Transformation",
        tooltip: "Data transformation to be applied",
        text: 'Enable data transformation of .fcs files derived from either mass or flow cytometry to be able to use them for downstream analyses.<br>Note that this option is not available for a .csv data matrix input.<br>Files in FCS format derived from mass cytometry (CyTOF) data should undergo a modified <i>arc sin</i> transformation (logical transformation) before their analysis. The <i>arc sin</i> transformation is the inverse of taking the <i>sin</i> function of a data point. The important aspects of this function are: (1) It is linear in nature around zero, and allows for low end data to be visualized. (2) Beyond a control point, the function becomes logarithmic in nature, allowing for increasing compression of "positive" populations.<br>Because fluorescent intensity increases logarithmically, data derived from flow cytometry requires a different transformation than data derived from CyTOF to be displayed correctly. The <i>hyperbolic arcsine</i> (<i>arcsinh</i>) is a function for transforming data that serves a similar purpose as other transformation functions such as biexponential, logicle, hyperlog, etc. The biexponential scale is a combination of linear and log scaling on a single axis using an <i>arcsine</i> function as its backbone. The "logicle" implementation of biexponential has been implemented in many popular software packages like FACSDiva and FlowJo.',
        list: {
          text: "List of available transformation methods:",
          items: [
            "cytof : 'flowcore'-based logicle transform using w = 0.25, t = 16409, m = 4.5, a = 0",
            "fluor : 'flowcore'-based logicle transform using w = 0.1, t = 500'000, m = 4.5, a = 0",
            "linear1 : linear transform using f(x) = x / max(x) * 4.5",
            "linear2 : linear transform using f(x) = ((x - min(x)) / (max(x) - min(x))) * 4.5",
            "arcsinh5 : 'flowcore'-based arcsinh transform using a = 0, b = 1/5",
            "arcsinh150 : 'flowcore'-based arcsinh transform using a = 0 b = 1/150",
            "auto : automatic parameter determination, 'flowcore'-based logicle transform using w = auto, t = auto, m = 4.5, a = 0",
            "none : no transformation will be applied.",
          ],
        },
      },
      {
        key: "configtetdecon",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Configuration Setup",
        tooltip: "Number of configurations",
        text: "Number of configurations used. Currently Cytographer&trade; supports 1 (default) or 2 configuration setups.",
      },
      {
        key: "outputtetdecon",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of Tetramer Deconvolution Pipeline",
        tooltip: "tetdecontooltipout",
        text: "The Tetramer Deconvolution pipeline produces an output folder containing the following files and folders per sample, including hits, deconvoluted input .fcs files, and a stats file in CSV format.",
        list: {
          text: "List of outputs:",
          items: [
            "hitlist folder : Output folder containing the hits. Inside you can find a <i>bootstrap_hitlist</i> with those hits that are deemed significant by phenotype skewing, and a <i>significant_hitlist</i> folder with those hits that are deemed significant by 2-configuration correspondence (for 2 configurations only)",
            "concatenated folder : Output folder containing the final concatenated .fcs file for the 2 configurations (if applicable).",
            "Deconvoluted .fcs files : Individual deconvoluted input .fcs files.",
            "Stats file : File in CSV format, containing the HitEC scoring system results.",
          ],
        },
        images: [
          {
            text: "Example of a sample Stats output:",
            url: "img/docs/tetdecon_stats.png",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    key: "highdim",
    isHidden: false,
    title: "HIGH DIMENSIONAL ANALYSIS",
    description:
      "The High Dimensional Analysis pipeline helps to visualize high dimensional data derived from either mass or flow cytometry, using diverse dimensionality reduction techniques.",
    parameters: [
      {
        key: "coordsfolderhighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title:
          "Load Coordinates Files to Calculate Freqs (one or more .csv files)",
        tooltip:
          "Load Coordinates Files to Calculate Freqs (one or more .csv files)",
        text: "Load Coordinates Files to Calculate Freqs (one or more .csv files).",
      },
      {
        key: "calculatefreqhighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Calculate Frequencies",
        tooltip: "Calculate Freq (results based on this)",
        text: "Calculate Freq (results based on this).",
      },
      {
        key: "descriptionhighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Description of High Dimensional Script",
        tooltip: "highdimtooltipdesc",
        text: "The High Dimensional Analysis pipeline helps to visualize high dimensional data derived from either mass or flow cytometry.<br>It runs several dimensionality reduction algorithms, including tSNE, PCA, and UMAP, as well as clustering methods like Phenograph and FlowSOM. Results are plotted in a comprehensible way showing marker intensities, clustering results, and meta grouping data side by side, to aid the gating process.",
      },
      {
        key: "inputhighdim",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs or one .csv data matrix)",
        tooltip: "Input Files",
        text: 'Multiple input files in FCS format to study; alternatively, you can upload a .csv data file.<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.<br>In case a .csv file is uploaded instead, note that, if you do not upload <i>Metamarker</i> or <i>Metasample</i> files to indicate otherwise, the program expects that the Markers are in rows and the Samples are in columns (unless you specify "Sample" as the first column name). It should be a numerical data matrix (do not add non-numerical characters in the data cells; decimal positions are determined by ".", not ","), with <b>unique identifiers</b> for markers and samples (the marker IDs should be in the first column, and sample IDs as remaining column names, or vice-versa). No data transformation will be performed if the input is a .csv data matrix.',
        images: [
          {
            text: "Example of a .csv data matrix:",
            url: "img/docs/highdim_input.png",
          },
        ],
      },
      {
        key: "metamarkerhighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Selection/Information",
        tooltip:
          "Marker metadata information, to choose which markers to include in the analysis",
        text: "Click in the marker list that appears (only when you upload .fcs files), or upload your own file in CSV format.<br>The first column should be the unique marker IDs used in the input .fcs files or in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the markers that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>No additional marker grouping columns would be considered in this pipeline.",
        images: [
          {
            text: "Example:",
            url: "img/docs/highdim_metamarker.png",
          },
        ],
      },
      {
        key: "metasamplehighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Selection/Information",
        tooltip:
          "Sample metadata information, to choose which samples to include and group them with colors",
        text: "If you want your samples classified by groups (or you want to filter out undesired samples), upload your own file in CSV format, containing sample grouping information.<br>The first column should be the unique sample IDs, <i>i.e.</i> the input .fcs file names, with or without the <i>.fcs</i> extension, or the sample IDs used in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the samples that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional grouping columns with factor levels can be added, resulting in color grouping of the samples in the relevant output heat maps and (dimensionality reduced) scatter plots.<br>Furthermore, an optional <i>Label</i> column indicates Sample IDs to label in the output plots, in case you need to track certain ones (note labels are only added when the input is a .csv data matrix; a column named <i>Label</i>, same as <i>Keep</i>, will not be considering for grouping).<br>Both discrete and continuous variables are accepted here, resulting in either discrete or continuous coloring of the relevant plots.",
        images: [
          {
            text: "Example:",
            url: "img/docs/highdim_metasample.png",
          },
        ],
      },
      {
        key: "prefixhighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Prefix",
        tooltip: "Prefix for the output files",
        text: 'Introduce a string that would be used as prefix in your output files. Default of "highdim" for this pipeline.',
      },
      {
        key: "transformationhighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Transformation",
        tooltip: "Data transformation to be applied",
        text: 'Enable data transformation of .fcs files derived from either mass or flow cytometry to be able to use them for downstream analyses.<br>Note that this option is not available for a .csv data matrix input.<br>Files in FCS format derived from mass cytometry (CyTOF) data should undergo a modified <i>arc sin</i> transformation (logical transformation) before their analysis. The <i>arc sin</i> transformation is the inverse of taking the <i>sin</i> function of a data point. The important aspects of this function are: (1) It is linear in nature around zero, and allows for low end data to be visualized. (2) Beyond a control point, the function becomes logarithmic in nature, allowing for increasing compression of "positive" populations.<br>Because fluorescent intensity increases logarithmically, data derived from flow cytometry requires a different transformation than data derived from CyTOF to be displayed correctly. The <i>hyperbolic arcsine</i> (<i>arcsinh</i>) is a function for transforming data that serves a similar purpose as other transformation functions such as biexponential, logicle, hyperlog, etc. The biexponential scale is a combination of linear and log scaling on a single axis using an <i>arcsine</i> function as its backbone. The "logicle" implementation of biexponential has been implemented in many popular software packages like FACSDiva and FlowJo.',
        list: {
          text: "List of available transformation methods:",
          items: [
            "cytof : 'flowcore'-based logicle transform using w = 0.25, t = 16409, m = 4.5, a = 0",
            "fluor : 'flowcore'-based logicle transform using w = 0.1, t = 500'000, m = 4.5, a = 0",
            "linear1 : linear transform using f(x) = x / max(x) * 4.5",
            "linear2 : linear transform using f(x) = ((x - min(x)) / (max(x) - min(x))) * 4.5",
            "arcsinh5 : 'flowcore'-based arcsinh transform using a = 0, b = 1/5",
            "arcsinh150 : 'flowcore'-based arcsinh transform using a = 0 b = 1/150",
            "auto : automatic parameter determination, 'flowcore'-based logicle transform using w = auto, t = auto, m = 4.5, a = 0",
            "none : no transformation will be applied.",
          ],
        },
      },
      {
        key: "ceilinghighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Number of Events (per input file)",
        tooltip:
          "Number of events to take per sample (unless sample has fewer)",
        text: "Number of events to take for sample (unless sample has fewer). A default of 20,000 is used.",
      },
      {
        key: "tsnehighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "tSNE",
        tooltip: "Runs tSNE reduction method",
        text: "Runs t-Distributed Stochastic Neighbor Embedding (tSNE), a technique for dimensionality reduction that is particularly well suited for the visualization of high-dimensional datasets. Set to TRUE by default.",
      },
      {
        key: "perplexitytsnehighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "tSNE Perplexity",
        tooltip: "Increasing the perplexity will increase the tSNE spread",
        text: "Parameter for tSNE. Increasing the perplexity will increase the tSNE spread; a good perplexity value should be between 5 and 50 (default: 25, automatically adjusted inside the pipeline depending on input size in case of input .csv data matrix).",
      },
      {
        key: "phatehighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Phate",
        tooltip: "Runs Phate reduction method",
        text: "Runs PHATE (Potential of Heat-diffusion for Affinity-based Trajectory Embedding), a tool for visualizing high dimensional data. PHATE uses a novel conceptual framework for learning and visualizing the manifold to preserve both local and global distances. Set to FALSE by default.",
      },
      {
        key: "kvaluephatehighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Phate K Nearest Neighbours",
        tooltip: "Number of nearest neighbors for Phate calculations",
        text: "Number of nearest neighbors for Phate calculations. This kValue must be lower than the number of samples in case your input is a .csv file (default: 25, automatically adjusted inside the pipeline depending on input size in case of input .csv data matrix).",
      },
      {
        key: "isomaphighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Isomap",
        tooltip: "Runs Isomap reduction method.",
        text: "Runs Isomap, which provides a simple method for estimating the intrinsic geometry of a data manifold based on a rough estimate of each data point’s neighbors on the manifold. Isomap is highly efficient and generally applicable to a broad range of data sources and dimensionalities. Set to FALSE by default.",
      },
      {
        key: "diffmaphighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "DiffMap",
        tooltip: "Runs Diffusion maps reduction method",
        text: "Runs Diffusion maps (DiffMap), which computes difference maps starting from two density maps, and also allows to perform resolution scaling. The program can perform its analysis by using aligned maps with identical overall dimensions. Set to FALSE by default.",
      },
      {
        key: "pcahighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "PCA",
        tooltip: "Runs Principal Component Analysis (PCA)",
        text: "Runs Principal Component Analysis (PCA), a common dimensionality reduction procedure that uses an orthogonal transformation to convert a set of observations of possibly correlated variables into a set of values of linearly uncorrelated variables called principal components. Set to FALSE by default.",
      },
      {
        key: "umaphighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "UMAP",
        tooltip: "Runs the Umap reduction method",
        text: "Runs the Uniform Manifold Approximation and Projection (UMAP) method, a dimension reduction technique that can be used for visualisation similarly to tSNE, but also for general non-linear dimension reduction. Set to FALSE by default.",
      },
      {
        key: "kvalueumaphighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "UMAP K Nearest Neighbours",
        tooltip: "Number of nearest neighbors for Umap calculations",
        text: "Number of nearest neighbors for Umap calculations. This kValue must be lower than the number of samples in case your input is a .csv file (default: 25, automatically adjusted inside the pipeline depending on input size in case of input .csv data matrix).",
      },
      {
        key: "spreadumaphighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "UMAP Spread",
        tooltip: "UMAP Spread",
        text: "UMAP Spread set to 1 by default.",
      },
      {
        key: "mindistumaphighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "UMAP Minimum Distance",
        tooltip: "UMAP Minimum Distance",
        text: "UMAP Minimum Distance, set to 0.2 by default.",
      },
      {
        key: "onesensehighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "OneSENSE",
        tooltip: "Runs OneSENSE analysis",
        text: "Runs One-Dimensional Soli-Expression by Nonlinear Stochastic Embedding (OneSENSE) analysis. Currently disabled.",
      },
      {
        key: "phenographhighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Phenograph",
        tooltip: "Runs Phenograph clustering method",
        text: "Runs Phenograph clustering method, which identifies subpopulations in high-dimensional single-cell data, aiding in the gating process. It works by creating a graph representing phenotypic similarities between cells by calculating the Jaccard coefficient between nearest-neighbor sets, and then identifying communities using the well-known Louvain method in this graph. Set to FALSE by default.",
      },
      {
        key: "kvaluephenohighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Phenograph K Nearest Neighbours",
        tooltip: "Number of nearest neighbors",
        text: "Parameter for Phenograph that indicates number of nearest neighbors. The lower kValue is, the more clusters you would obtain. This kValue must be lower than the number of samples in case your input is a .csv file (default: 25, automatically adjusted inside the pipeline depending on input size in case of input .csv data matrix).",
      },
      {
        key: "flowsomhighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "FlowSOM",
        tooltip: "Runs FlowSOM clustering method",
        text: "Run FlowSOM clustering method, which offers visualization options for cytometry data, by using Self-Organizing Map clustering and Minimal Spanning Trees. Set to FALSE by default.",
      },
      // {
      //   key: 'kvalueflowhighdim',
      //   isRequired: false,
      //   isAdvanced: true,
      //   isHidden: false,
      //   title: 'Number of Clusters FlowSOM',
      //   tooltip: 'Number of FlowSOM clusters to produce',
      //   text: 'Number of clusters to produce with FlowSOM. This number must be lower than the number of samples in case your input is a .csv file (default: 25, automatically adjusted inside the pipeline depending on input size in case of input .csv data matrix).',
      // },
      // {
      //   key: 'xdimhighdim',
      //   isRequired: false,
      //   isAdvanced: true,
      //   isHidden: false,
      //   title: 'x dimension',
      //   tooltip: 'input x dimension for FlowSOM',
      //   text: 'Input <i>x</i> dimension for FlowSOM; <i>x</i> dimension of the grid used. Note that the FlowSOM grid is equal to <i>x-dimension</i> * <i>y-dimension</i>; this grid must be smaller than the number of samples in case your input is a .csv file (default: 5, automatically adjusted inside the pipeline depending on input size in case of input .csv data matrix).',
      // },
      // {
      //   key: 'ydimhighdim',
      //   isRequired: false,
      //   isAdvanced: true,
      //   isHidden: false,
      //   title: 'y dimension',
      //   tooltip: 'input y dimension for FlowSOM',
      //   text: 'Input <i>y</i> dimension for FlowSOM; <i>y</i> dimension of the grid used. Note that the FlowSOM grid is equal to <i>x-dimension</i> * <i>y-dimension</i>; this grid must be smaller than the number of samples in case your input is a .csv file (default: 5, automatically adjusted inside the pipeline depending on input size in case of input .csv data matrix).',
      // },
      {
        key: "meaningplotshighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Graphical Output",
        tooltip: "Makes Graphical Output",
        text: "Choose this option for a graphical output. Please note that only selecting dimensionality reduction or clustering methods will produce output .fcs files or a .csv file (depending on the input) with extra columns with the selected methods results, but <b>will not</b> produce a graphical output.<br>This option will create a folder for each of the dimensionality reduction methods selected with a collection of graphical files inside, showing scatter plots for each given method, colored by marker intensities and clusters obtained (if applicable), as well as by any grouping variables in your input <i>Sample information</i> file, and Sample ID labels if applicable (for input .csv data matrix only). Set to TRUE by default.",
      },
      {
        key: "foreachhighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Graphical Output per Sample",
        tooltip:
          "Make additional graphical output folders for each input .fcs file (sample).",
        text: "If you select this option, apart from the main graphical output folder generated with the whole data set from all input .fcs files (samples), it will create a similar output folder for each of the .fcs files (samples) studied individually. This option is not available for input .csv data files. Set to FALSE by default.",
      },
      {
        key: "toppercentilehighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Top Percentile",
        tooltip: "Top percentile limit included in the scatter plots",
        text: "Top percentile limit for outlier removal in graphical output scatter plots. The value range is from 0 to 1 (0.51 to 1 for top percentile). Set to 0.99 by default.",
      },
      {
        key: "bottompercentilehighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Bottom Percentile",
        tooltip: "Bottom percentile limit included in the scatter plots",
        text: "Bottom percentile limit for outlier removal in graphical output scatter plots. The value range is from 0 to 1 (0 to 0.49 for bottom percentile). Set to 0.01 by default.",
      },
      {
        key: "pchhighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Symbol",
        tooltip: "Point symbol used in the scatter plots",
        text: "Data point symbol used in the graphical output scatter plots. Choose a symbols (circle, square, diamond, up-triangle, down-triangle).",
        images: [
          {
            text: "Number values correspond to the following symbols:",
            url: "img/docs/highdim_pch.png",
          },
        ],
      },
      {
        key: "plotceilinghighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Number of Points to Plot",
        tooltip: "Number of data points to plot in the scatter plots",
        text: "Number of data points to plot in the graphical output scatter plots; if the <i>Number of Events</i> chosen was really high, you might want a random subset of them to be actually plotted, so your output files are not as big in size and the plots are not as cluttered. A default of 20,000 is used.",
      },
      {
        key: "svghighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Individual .svg plots",
        tooltip: "Save individual plots in SVG format",
        text: "Save scatter plots in the graphical output folder individually, in SVG format. Set to FALSE by default.",
      },
      {
        key: "palettehighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output Plot Colors",
        tooltip: "Color Palette",
        text: "Comprehensible color gradient schemes available for the ouput plots. Set to the ImmunoScape color scheme by default. There is the option to reverse the palette.",
        images: [
          {
            text: "",
            url: "img/docs/heatplot_palette.png",
          },
        ],
      },
      {
        key: "seedhighdim",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Seed",
        tooltip: "Make reproducible results",
        text: "Set a seed to make reproducible results; in case you want to run the same data with the same parameters again, setting the seed to the same value would ensure that procedures that rely on randomness to a certain extent yield identical results. Set to 42 by default.",
      },
      {
        key: "outputhighdim",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of High Dimensional Pipeline",
        tooltip: "highdimtooltipout",
        text: "The High Dimensional pipeline produces modified .fcs files with extra columns with the dimensionality reduction and cluster method results, a concatenated .fcs file joining all of these modified files, gated .fcs files according to Phenograph and FlowSOM clusters (when applicable), as well as graphical outputs, namely scatter plots for each selected dimensionality reduction method, colored by marker intensities and clusters obtained (if applicable), as well as by any grouping variables in your input <i>Sample information</i> file.",
        list: {
          text: "List of outputs:",
          items: [
            "NEW_fcs folder : Output folder containing modified .fcs files with extra parameters showing dimensionality reduction and cluster method results.",
            "output_concatenated folder : Output folder containing the final concatenated .fcs file joining NEW_fcs files.",
            "clusters_phenograph : Output folder containing stacked barplots with sample/clusters distributions, and heat maps showing marker intensities across clusters, along with a <i>for_analysis_pipeline</i> folder with the gated .fcs files based on Phenograph clustering algorithm, and frequency tables for use in the Analysis pipeline as well.",
            "clusters_flowsom : Similar to <i>clusters_phenograph</i>, containing FlowSOM results.",
            "main_plots folder : Graphical output folder containing a subfolder for each of the dimensionality reduction methods selected with a collection of graphical files inside, showing scatter plots for each given method, colored by marker intensities and clusters obtained (if applicable), as well as by any grouping variables in your input <i>Sample information</i> file. The plots are saved individually in a .pdf book, and in an all-in-one summary .png file. Additional <i>FOCUS</i> plots are generated, showing higher intensities (above the 90<sup>th</sup> percentile) in red. If the <i>Individual .svg plots</i> option was selected, individual plots would be saved in SVG format inside a folder named <i>individual_SVGs</i>.",
            "main_plots_per_sample folder : If the <i>Graphical Output per Sample</i> option was selected, this output folder would be created with a subfolder for each of the input .fcs files (samples) studied individually. Inside each one, a similar structure to that of the <i>main_plots</i> output folder can be found.",
          ],
        },
        images: [
          {
            text: "Example of a tSNE all-in-one .png output (in the <i>main_plots</i> folder):",
            url: "img/docs/highdim_plot1.png",
          },
          {
            text: "Example of a tSNE output plot colored by a marker intensity (in the <i>main_plots</i> folder):",
            url: "img/docs/highdim_plot2.png",
          },
          {
            text: "Example of a tSNE FOCUS plot for the same marker (in the <i>main_plots</i> folder):",
            url: "img/docs/highdim_plot3.png",
          },
          {
            text: "Example of a tSNE output plot colored by Phenograph clusters (in the <i>main_plots</i> folder):",
            url: "img/docs/highdim_plot4.png",
          },
          {
            text: "Example of grouping variable distribution across Phenograph clusters (in the <i>clusters_phenograph</i> folder):",
            url: "img/docs/highdim_clusters1.png",
          },
          {
            text: "Example of heat map of marker intensities across Phenograph clusters (in the <i>clusters_phenograph</i> folder):",
            url: "img/docs/highdim_clusters2.png",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    key: "heatplot",
    isHidden: false,
    title: "HEAT PLOT",
    description:
      "The Heat Plot pipeline outputs customized heat maps based on intensities data from input .fcs files or from a single .csv data matrix.",
    parameters: [
      {
        key: "coordsfolderheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title:
          "Load Coordinates Files to Calculate Freqs (one or more .csv files)",
        tooltip:
          "Load Coordinates Files to Calculate Freqs (one or more .csv files)",
        text: "Load Coordinates Files to Calculate Freqs (one or more .csv files).",
      },
      {
        key: "calculatefreqheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Calculate Frequencies",
        tooltip: "Calculate Freq (results based on this)",
        text: "Calculate Freq (results based on this).",
      },
      {
        key: "descriptionheat map",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Description of Heat Plot Pipeline",
        tooltip: "heatplottooltipdesc",
        text: "The Heat Plot pipeline outputs customized heat maps based on intensities data from input .fcs files or from a single .csv data matrix. You have the option to run the pipeline in the Normal tab, and download the results, or work on the Interactive tab and make modifications on the fly before exporting the heat map.",
      },
      {
        key: "inputheatplot",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs or one .csv data matrix)",
        tooltip: "Input Files",
        text: 'Multiple input files in FCS format to study; alternatively, you can upload a .csv data file.<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.<br>In case a .csv file is uploaded instead, note that, if you do not upload <i>Metamarker</i> or <i>Metasample</i> files to indicate otherwise, the program expects that the Markers are in rows and the Samples are in columns (unless you specify "Sample" as the first column name). It should be a numerical data matrix (do not add non-numerical characters in the data cells; decimal positions are determined by ".", not ","), with <b>unique identifiers</b> for markers and samples (the marker IDs should be in the first column, and sample IDs as remaining column names, or vice-versa). No data transformation will be performed if the input is a .csv data matrix.',
        images: [
          {
            text: "Example of a .csv data matrix:",
            url: "img/docs/heatplot_input.png",
          },
        ],
      },
      {
        key: "metamarkerheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Selection/Information",
        tooltip:
          "Marker metadata information, to choose which markers are included in the analysis and group the markers with colors",
        text: "Click in the marker list that appears (only when you upload .fcs files), or upload your own file in CSV format.<br>The first column should be the unique marker IDs used in the input .fcs files or in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the markers that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional grouping columns with factor levels can be added, resulting in a multi-level color grouping of the markers in the output heat map.",
        images: [
          {
            text: "Example:",
            url: "img/docs/heatplot_metamarker.png",
          },
        ],
      },
      {
        key: "metasampleheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Selection/Information",
        tooltip: "Sample metadata information, to group samples with colors",
        text: "If you want your samples classified by groups (or you want to filter out undesired samples), upload your own file in CSV format, containing sample grouping information.<br>The first column should be the unique sample IDs, <i>i.e.</i> the input .fcs file names, with or without the <i>.fcs</i> extension, or the sample IDs used in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the samples that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional grouping columns with factor levels can be added, resulting in a multi-level color grouping of the samples in the output heat map. Both discrete and continuous variables are accepted here.",
        images: [
          {
            text: "Example:",
            url: "img/docs/heatplot_metasample.png",
          },
        ],
      },
      {
        key: "prefixheatplot",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Prefix",
        tooltip: "Prefix for the output files",
        text: 'Introduce a string that would be used as prefix in your output files. Default of "heatplot" for this pipeline.',
      },
      {
        key: "transformationheatplot",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Transformation",
        tooltip: "Data transformation to be applied",
        text: 'Enable data transformation of .fcs files derived from either mass or flow cytometry to be able to use them for downstream analyses.<br>Note that this option is not available for a .csv data matrix input.<br>Files in FCS format derived from mass cytometry (CyTOF) data should undergo a modified <i>arc sin</i> transformation (logical transformation) before their analysis. The <i>arc sin</i> transformation is the inverse of taking the <i>sin</i> function of a data point. The important aspects of this function are: (1) It is linear in nature around zero, and allows for low end data to be visualized. (2) Beyond a control point, the function becomes logarithmic in nature, allowing for increasing compression of "positive" populations.<br>Because fluorescent intensity increases logarithmically, data derived from flow cytometry requires a different transformation than data derived from CyTOF to be displayed correctly. The <i>hyperbolic arcsine</i> (<i>arcsinh</i>) is a function for transforming data that serves a similar purpose as other transformation functions such as biexponential, logicle, hyperlog, etc. The biexponential scale is a combination of linear and log scaling on a single axis using an <i>arcsine</i> function as its backbone. The "logicle" implementation of biexponential has been implemented in many popular software packages like FACSDiva and FlowJo.',
        list: {
          text: "List of available transformation methods:",
          items: [
            "cytof : 'flowcore'-based logicle transform using w = 0.25, t = 16409, m = 4.5, a = 0",
            "fluor : 'flowcore'-based logicle transform using w = 0.1, t = 500'000, m = 4.5, a = 0",
            "linear1 : linear transform using f(x) = x / max(x) * 4.5",
            "linear2 : linear transform using f(x) = ((x - min(x)) / (max(x) - min(x))) * 4.5",
            "arcsinh5 : 'flowcore'-based arcsinh transform using a = 0, b = 1/5",
            "arcsinh150 : 'flowcore'-based arcsinh transform using a = 0 b = 1/150",
            "auto : automatic parameter determination, 'flowcore'-based logicle transform using w = auto, t = auto, m = 4.5, a = 0",
            "none : no transformation will be applied.",
          ],
        },
      },
      {
        key: "ceilingheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Number of Events (per input file)",
        tooltip:
          "Number of events to take per sample (unless sample has fewer)",
        text: "Number of events to take for sample (unless sample has fewer). A default of 20,000 is used.",
      },
      {
        key: "seedheatplot",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Seed",
        tooltip: "Make reproducible results",
        text: "Set a seed to make reproducible results; in case you want to run the same data with the same parameters again, setting the seed to the same value would ensure that procedures that rely on randomness to a certain extent yield identical results. Set to 42 by default.",
      },
      {
        key: "paletteheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output Plot Colors",
        tooltip: "Color Palette",
        text: "Comprehensible color gradient schemes available for the ouput plots. Set to the ImmunoScape color scheme by default. There is the option to reverse the palette.",
        images: [
          {
            text: "",
            url: "img/docs/heatplot_palette.png",
          },
        ],
      },
      {
        key: "groupcolorsheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Information Colors (requires sample information)",
        tooltip:
          "R color palettes used for group coloring of markers and samples",
        text: "R color palettes used for group coloring of markers and samples. Default: Set1.",
        images: [
          {
            text: "",
            url: "img/docs/heatplot_groupcolors.png",
          },
        ],
      },
      {
        key: "scaleheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Scaling",
        tooltip:
          "Optional scaling of primary data, either by <i>marker</i> or <i>sample</i> (or <i>none</i>)",
        text: "Optional scaling/normalization of data that can be done either accross <i>markers</i> or <i>samples</i> (or <i>none</i>). Set to markers z-scores (each value minus the mean, then divided by the standard deviation) by default.",
      },
      {
        key: "clustmarkerheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Cluster Markers",
        tooltip: "Distance measures for clustering the markers",
        text: "Choose one of the available distance measures for clustering the markers; if <i>none</i> is chosen (default), no marker clustering would be done.",
        list: {
          text: "",
          items: [
            "none",
            "Pearson Correlation",
            "Euclidean Distance",
            "Manhattan Distance",
          ],
        },
      },
      {
        key: "cutmarkerheatplot",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Cut Markers Tree",
        tooltip: "Option to cut the marker cluster tree",
        text: "Option to cut the marker cluster tree. The selected number is the target amount of marker groups. This option is only available when <i>Cluster Markers</i> is chosen. Values between 1 and the number of markers are valid. Groups of markers are visualized by dividing lines across the heat map.",
      },
      {
        key: "clustsampleheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Cluster Samples",
        tooltip: "Distance measures for clustering the samples",
        text: "Choose one of the available distance measures for clustering the samples; if <i>none</i> is chosen (default), no sample clustering would be done.",
        list: {
          text: "",
          items: [
            "none",
            "Pearson Correlation",
            "Euclidean Distance",
            "Manhattan Distance",
          ],
        },
      },
      {
        key: "cutsampleheatplot",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Cut Samples Tree",
        tooltip: "Option to cut the sample cluster tree",
        text: "Option to cut the sample cluster tree. The selected number is the target amount of sample groups. This option is only available when <i>Cluster Samples</i> is chosen. Values between 1 and the number of samples are valid. Groups of samples are visualized by dividing lines across the heat map.",
      },
      {
        key: "percentilerangeheatplot",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Percentile Range",
        tooltip:
          "Display range of the color gradient from the 10th to 90th percentile value in the heat map",
        text: "If selected, displays range of the color gradient from the 10th to 90th percentile value in the heat map. Otherwise, displays range of the color gradient from minimum value to maximum value. Set to FALSE by default.",
      },
      {
        key: "transposeheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Transpose",
        tooltip: "Option to transpose the heat map",
        text: "Option to transpose the heat map. By default (no transpose), markers are shown in rows and samples in columns.",
      },
      {
        key: "allcellsheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title:
          "Show All Events (set Number of Events to <200 if your files are bigger)",
        tooltip:
          "Show All Events (set Number of Events to <200 if your files are bigger) (up to 100 events)(average per FCS sample instead)",
        text: "Show All Events (set Number of Events to <200 if your files are bigger) (up to 100 events)(average per FCS sample instead)",
      },
      {
        key: "outputheatplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of Heat Plot Pipeline",
        tooltip: "heatplottooltip",
        text: "The Heat Plot pipeline produces a customized heat map based on intensities data from input .fcs files or from a single .csv data matrix, and all the selections made above, along with a frequency table with the data plotted.",
        images: [
          {
            text: "Example of an output heat map:",
            url: "img/docs/heatplot_output.png",
          },
        ],
      },
    ],
  },
  {
    id: 8,
    key: "analysis",
    isHidden: false,
    title: "STATISTICAL ANALYSIS",
    description:
      "The Statistical Analysis pipeline performs tests to compare intensities data from input .fcs files, or from a single .csv data matrix, across the different levels in grouping variables specified in an accompanying <i>Sample information</i> file. Significant comparisons are highlighted in the relevant output plots according to the p-value cut-offs passed.<br>Please note: each level in a grouping variable should have at least 3 data points per Marker (if your input is a CSV data matrix, this means a variable level should comprise at least 3 samples) for it to be studied; plots with significance values will only be done for grouping variables (meta_sample_file columns) with 2 to 10 levels (5 in the case of volcano plots).",
    parameters: [
      {
        key: "descriptionanalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Description of Statistical Analysis Pipeline",
        tooltip: "analysistooltipdesc",
        text: "The Statistical Analysis pipeline performs tests to compare intensities data from input .fcs files, or from a single .csv data matrix, across the different levels in grouping variables specified in an accompanying <i>Sample information</i> file. Significant comparisons are highlighted in the relevant output plots according to the p-value cut-offs passed.<br>Please note: each level in a grouping variable should have at least 3 data points per Marker (if your input is a CSV data matrix, this means a variable level should comprise at least 3 samples) for it to be studied; plots with significance values will only be done for grouping variables (meta_sample_file columns) with 2 to 10 levels (5 in the case of volcano plots).<br>Parametric or non-parametric tests are applied where appropriate (after testing for normality and homoscedasticity), across the board ANOVA or Kruskal-Wallis, and t-tests or Mann-Whitney tests pairwise.<br>Significant comparisons are highlighted in the relevant output plots according to the p-value cut-offs passed. These plots include box plots, bar plots, violin plots, volcano plots, as well as upstream data inspection plots such as heat maps and PCA biplots. Furthermore, correlation analysis are performed to observe how marker intensities correlate (an additional file with non-marker continuous data can be passed to include in the correlation analysis).",
      },
      {
        key: "inputanalysis",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs or one .csv data matrix)",
        tooltip: "Input Files",
        text: 'Multiple input files in FCS format to study; alternatively, you can upload a .csv data file.<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.<br>In case a .csv file is uploaded instead, note that, if you do not upload <i>Metamarker</i> or <i>Metasample</i> files to indicate otherwise, the program expects that the Markers are in rows and the Samples are in columns (unless you specify "Sample" as the first column name). It should be a numerical data matrix (do not add non-numerical characters in the data cells; decimal positions are determined by ".", not ","), with <b>unique identifiers</b> for markers and samples (the marker IDs should be in the first column, and sample IDs as remaining column names, or vice-versa). No data transformation will be performed if the input is a .csv data matrix.',
        images: [
          {
            text: "Example of a .csv data matrix:",
            url: "img/docs/analysis_input.png",
          },
        ],
      },
      {
        key: "metamarkeranalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Selection/Information",
        tooltip:
          "Marker metadata information, to choose which markers are included in the analysis and group the markers with colors",
        text: "Click in the marker list that appears (only when you upload .fcs files), or upload your own file in CSV format.<br>The first column should be the unique marker IDs used in the input .fcs files or in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the markers that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional grouping columns with factor levels can be added, resulting in a multi-level color grouping of the markers in the output heat map.",
        images: [
          {
            text: "Example:",
            url: "img/docs/analysis_metamarker.png",
          },
        ],
      },
      {
        key: "metasampleanalysis",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Selection/Information",
        tooltip: "Sample metadata information, to group samples with colors",
        text: "This file is mandatory in this pipeline. Upload your own file in CSV format, containing sample grouping information.<br>The first column should be the unique sample IDs, <i>i.e.</i> the input .fcs file names, with or without the <i>.fcs</i> extension, or the sample IDs used in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the samples that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional columns containing sample grouping information are recommended/mandatory for this pipeline (otherwise tests and most plots would not be done); these grouping variables would be used to perform statistical tests to detect significant markers comparing the variable levels, and group the data in the output plots.<br>You need at least 3 data values per grouping level to perform the tests (this become relevant when your input is a .csv file); grouping variables with more than 10 levels will not be plotted, as the resulting plots would be too crowded (but you would still find tables with tests results).<br>Furthermore, an optional <i>Label</i> column indicates Sample IDs to label in the output plots, in case you need to track certain ones (note labels are only added when the input is a .csv data matrix; a column named <i>Label</i>, same as <i>Keep</i>, will not be considering for grouping).<br>Note the flexibility of this file; if you want to study the interaction of two grouping variables, just add an additional interaction column. While both discrete and continuous variables are accepted here, only discrete ones would be used for testing.",
        images: [
          {
            text: "Example:",
            url: "img/docs/analysis_metasample.png",
          },
        ],
      },
      {
        key: "pairedfileanalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Paired/Unpaired Information per Grouping Variable",
        tooltip: "Paired/Unpaired information per grouping variable",
        text: "Include a simple optional .csv file that specifies whether the pairwise tests performed should be paired or unpaired for each grouping variable under study. By default, if no file with paired/unpaired information is uploaded (or the file does not contain information about a given grouping variable), all tests performed are unpaired.",
        images: [
          {
            text: "Example:",
            url: "img/docs/analysis_paired.png",
          },
        ],
      },
      {
        key: "correlationfileanalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Extra Correlation File",
        tooltip:
          "File with continuous non-marker data to include in correlation analysis",
        text: "Include an optional extra .csv file with continuous non-marker data that would be used as well in the correlation analysis.",
        images: [
          {
            text: "Example:",
            url: "img/docs/analysis_corrfile.png",
          },
        ],
      },
      {
        key: "coordsfolderanalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title:
          "Load Coordinates Files to Calculate Freqs (one or more .csv files)",
        tooltip:
          "Load Coordinates Files to Calculate Freqs (one or more .csv files)",
        text: "Load Coordinates Files to Calculate Freqs (one or more .csv files).",
      },
      {
        key: "calculatefreqanalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Calculate Frequencies",
        tooltip: "Calculate Freq (results based on this)",
        text: "Calculate Freq (results based on this).",
      },
      {
        key: "ceilinganalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Number of Events (per input file)",
        tooltip:
          "Number of events to take per sample (unless sample has fewer)",
        text: "Number of events to take for sample (unless sample has fewer). A default of 20,000 is used.",
      },
      {
        key: "prefixanalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Prefix",
        tooltip: "Prefix for the output files",
        text: 'Introduce a string that would be used as prefix in your output files. Default of "stats" for this pipeline.',
      },
      {
        key: "transformationanalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Transformation",
        tooltip: "Data transformation to be applied",
        text: 'Enable data transformation of .fcs files derived from either mass or flow cytometry to be able to use them for downstream analyses.<br>Note that this option is not available for a .csv data matrix input.<br>Files in FCS format derived from mass cytometry (CyTOF) data should undergo a modified <i>arc sin</i> transformation (logical transformation) before their analysis. The <i>arc sin</i> transformation is the inverse of taking the <i>sin</i> function of a data point. The important aspects of this function are: (1) It is linear in nature around zero, and allows for low end data to be visualized. (2) Beyond a control point, the function becomes logarithmic in nature, allowing for increasing compression of "positive" populations.<br>Because fluorescent intensity increases logarithmically, data derived from flow cytometry requires a different transformation than data derived from CyTOF to be displayed correctly. The <i>hyperbolic arcsine</i> (<i>arcsinh</i>) is a function for transforming data that serves a similar purpose as other transformation functions such as biexponential, logicle, hyperlog, etc. The biexponential scale is a combination of linear and log scaling on a single axis using an <i>arcsine</i> function as its backbone. The "logicle" implementation of biexponential has been implemented in many popular software packages like FACSDiva and FlowJo.',
        list: {
          text: "List of available transformation methods:",
          items: [
            "cytof : 'flowcore'-based logicle transform using w = 0.25, t = 16409, m = 4.5, a = 0",
            "fluor : 'flowcore'-based logicle transform using w = 0.1, t = 500'000, m = 4.5, a = 0",
            "linear1 : linear transform using f(x) = x / max(x) * 4.5",
            "linear2 : linear transform using f(x) = ((x - min(x)) / (max(x) - min(x))) * 4.5",
            "arcsinh5 : 'flowcore'-based arcsinh transform using a = 0, b = 1/5",
            "arcsinh150 : 'flowcore'-based arcsinh transform using a = 0 b = 1/150",
            "auto : automatic parameter determination, 'flowcore'-based logicle transform using w = auto, t = auto, m = 4.5, a = 0",
            "none : no transformation will be applied.",
          ],
        },
      },
      {
        key: "pvcut1analysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Lower p-value Cut-off",
        tooltip: "Lower p-value cut-off",
        text: 'Lower p-value cut-off, set to 0.01 by default. P-values lower than 0.01 would be considered significant and labelled with "**" in the output plots.',
      },
      {
        key: "pvcut2analysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Higher p-value Cut-off",
        tooltip: "Higher p-value cut-off",
        text: 'Higher p-value cut-off, set to 0.05 by default. P-values lower than 0.01 would be considered significant and labelled with "*" in the output plots.',
      },
      {
        key: "fdranalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "False Discovery Rate Correction",
        tooltip: "FDR Correction",
        text: "Correct p-values for multiple testing using the False Discovery Rate (Benjamini-Hochberg) method, in order to reduce the number of type-I errors. Set to TRUE by default.",
      },
      {
        key: "paletteanalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output Plot Colors",
        tooltip: "Color Palette",
        text: "Comprehensible color gradient schemes available for the ouput plots. Set to the ImmunoScape color scheme by default. There is the option to reverse the palette.",
        images: [
          {
            text: "",
            url: "img/docs/heatplot_palette.png",
          },
        ],
      },
      {
        key: "groupcolorsanalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Information Colors (requires sample information)",
        tooltip:
          "R color palettes used for group coloring of markers and samples",
        text: "R color palettes used for group coloring of markers and samples. Default: Set1.",
        images: [
          {
            text: "",
            url: "img/docs/heatplot_groupcolors.png",
          },
        ],
      },
      {
        key: "outliersanalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Show Outliers",
        tooltip: "Show Outliers in box and violin plots",
        text: "Show Outliers in box and violin plots.",
      },
      {
        key: "pchanalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Symbol",
        tooltip: "Point symbol used in the scatter plots",
        text: "Data point symbol used in the graphical output scatter plots. Choose a symbols (circle, square, diamond, up-triangle, down-triangle).",
        images: [
          {
            text: "Number values correspond to the following symbols:",
            url: "img/docs/highdim_pch.png",
          },
        ],
      },
      {
        key: "plotceilinganalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: true,
        title: "Number of Points to Plot",
        tooltip: "Number of data points to plot in the scatter plots",
        text: "Number of data points to plot in the graphical output scatter plots; if the <i>Number of Events</i> chosen was really high, you might want a random subset of them to be actually plotted, so your output files are not as big in size and the plots are not as cluttered. A default of 20,000 is used.",
      },
      {
        key: "seedanalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Seed",
        tooltip: "Make reproducible results",
        text: "Set a seed to make reproducible results; in case you want to run the same data with the same parameters again, setting the seed to the same value would ensure that procedures that rely on randomness to a certain extent yield identical results. Set to 42 by default.",
      },
      {
        key: "outputanalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of Statistical Analysis Pipeline",
        tooltip: "analysistooltip",
        text: "The Statistical Analysis pipeline performs produces a collection of mainly graphical outputs organized in a series of folders. It performs tests to compare intensities data from input .fcs files, or from a single .csv data matrix, across the different levels in grouping variables specified in an accompanying sample information file, and highlights significant comparisons in the relevant output plots.",
        list: {
          text: "List of output folders:",
          items: [
            "Heatplots : Default heat maps produced by the Heat Plot pipeline, with marker intensities per sample group.",
            "PCA : Exploratory PCA biplots for each sample grouping variable, colored by the different levels, with arrows corresponding to the underlying markers driving the variability of the data.",
            "Tests : Test results for normality and homoscedasticity, and parametric or non-parametric tests results (across the board ANOVA or Kruskal-Wallis, and t-tests or Mann-Whitney tests pairwise), with actual adjusted and non-adjusted p-values. Tests are perfomed on a marker basis comparing the levels of each sample grouping variable.",
            "Boxplots and Boxplots_significance : Boxplots per grouping variable on a marker basis, with and without significant comparisons highlighted, according to the p-value cut-offs passed.",
            "Barplots and Barplots_significance : Barplots per grouping variable on a marker basis, with and without significant comparisons highlighted, according to the p-value cut-offs passed.",
            "Violin and Violin_significance : Violin plots per grouping variable on a marker basis, with and without significant comparisons highlighted, according to the p-value cut-offs passed.",
            "Volcano_plots : Volcano plots per sample grouping variable with significant markers highlighted.",
            "Correlation : Pearson's correlation tests are performed, and correlation coefficients along with accompanying p-values are plotted in correlation matrices, for all data, and for each level in each sample grouping variable (these data - coefficients and p-values - can be found in output numeric simetric matrices in .csv files as well). If the number of significant markers is no more than 30, and the number of levels for a sample grouping variable is no more than 3 (with at least 3 data points per level), additional <i>detail</i> plots are created to visualize marker distribution in each sample group level.",
          ],
        },
        images: [
          {
            text: "Example of an output heat map:",
            url: "img/docs/analysis_heatmap.png",
          },
          {
            text: "Example of an output PCA biplot:",
            url: "img/docs/analysis_pca.png",
          },
          {
            text: "Example of output box plots with significant markers highlighted:",
            url: "img/docs/analysis_boxplots.png",
          },
          {
            text: "Example of an output volcano plot:",
            url: "img/docs/analysis_volcano.png",
          },
          {
            text: "Example of a correlation plot:",
            url: "img/docs/analysis_correlation1.png",
          },
          {
            text: "Example of a detail correlation plot (portion):",
            url: "img/docs/analysis_correlation1.png",
          },
          {
            text: "Example of output ANOVA/Kruskal-Wallis test results:",
            url: "img/docs/analysis_anova.png",
          },
          {
            text: "Example of output pairwise t-test/Mann-Whitney test results:",
            url: "img/docs/analysis_ttest.png",
          },
        ],
      },
    ],
  },
  {
    id: 9,
    key: "manualgating",
    isHidden: true,
    title: "MANUAL GATING",
    description:
      'The Manual Gating pipeline consists of two independent modules: "Define Gates" is the actual interactive gating app that is used to generate csv text files with lower and upper coordinates that define the gate. The coordinates relate to non-transormed values. "Apply Gates" is a batch pipeline where csv coordinates files from the "Define Gates" function can be used to apply the gating to additional .fcs files. The result will be a text csv cell count frequency table, based on the given gating. ',
    parameters: [
      {
        key: "inputmanualgating",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs)",
        tooltip: "Input files in FCS format",
        text: "Multiple input files in FCS format.<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.",
      },
      {
        key: "metamarkermanualgating",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Selection/Information",
        tooltip:
          "Marker metadata information, to identify streptavidins (SAVs)",
        text: "Marker Selection/Information",
      },
      {
        key: "metasamplemanualgating",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Selection/Information",
        tooltip: "Sample metadata information, to group samples with colors",
        text: "Sample Selection/Information",
      },
      {
        key: "coordinatesfilemanualgating",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Coordinates files",
        tooltip: 'Coordinates file, as produced by the "Define Gates" function',
        text: 'Coordinates files, text csv format as produced bye the "Define Gates" function',
      },
    ],
  },
  {
    id: 12,
    key: "batch",
    isHidden: true,
    title: "BATCH INDEPENDENT ANALYSIS",
    description: "Batch Independent Analysis.",
    parameters: [
      {
        key: "inputbatch",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs)",
        tooltip: "Input",
        text: "Input",
      },
      {
        key: "metamarkerbatch",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Selection/Information",
        tooltip: "Marker Selection/Information",
        text: "Marker Selection/Information",
      },
      {
        key: "metasamplebatch",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Selection/Information",
        tooltip: "Sample Selection/Information",
        text: "Sample Selection/Information",
      },
      {
        key: "patientcolbatch",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Patient Column",
        tooltip: "Patient Column",
        text: "Patient Column",
      },
      {
        key: "templatebatch",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Template Files for Markers",
        tooltip: "Template File for Markers",
        text: "Template"
      },
      {
        key: "zipfile",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Zip File",
        tooltip: "Zip File upload",
        text: "Zip File upload",
      },
      {
        key: "annotationbatch",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Do Annotation",
        tooltip: "Do Annotation",
        text: "Do Annotation.",
      },
      {
        key: "discoverybatch",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Do Discovery",
        tooltip: "Do Discovery",
        text: "Do Discovery.",
      },
      {
        key: "depthscorecutoffbatch",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Depth Score Cut-off",
        tooltip: "Depth Score Cut-off",
        text: "Depth Score Cut-off",
      },
      {
        key: "selectionquantilebatch",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Selection Quantile",
        tooltip: "Selection Quantile",
        text: "Selection Quantile",
      },
      {
        key: "transformationbatch",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Transformation",
        tooltip: "Data transformation to be applied",
        text: 'Enable data transformation of .fcs files derived from either mass or flow cytometry to be able to use them for downstream analyses.<br>Note that this option is not available for a .csv data matrix input.<br>Files in FCS format derived from mass cytometry (CyTOF) data should undergo a modified <i>arc sin</i> transformation (logical transformation) before their analysis. The <i>arc sin</i> transformation is the inverse of taking the <i>sin</i> function of a data point. The important aspects of this function are: (1) It is linear in nature around zero, and allows for low end data to be visualized. (2) Beyond a control point, the function becomes logarithmic in nature, allowing for increasing compression of "positive" populations.<br>Because fluorescent intensity increases logarithmically, data derived from flow cytometry requires a different transformation than data derived from CyTOF to be displayed correctly. The <i>hyperbolic arcsine</i> (<i>arcsinh</i>) is a function for transforming data that serves a similar purpose as other transformation functions such as biexponential, logicle, hyperlog, etc. The biexponential scale is a combination of linear and log scaling on a single axis using an <i>arcsine</i> function as its backbone. The "logicle" implementation of biexponential has been implemented in many popular software packages like FACSDiva and FlowJo.',
        list: {
          text: "List of available transformation methods:",
          items: [
            "cytof : 'flowcore'-based logicle transform using w = 0.25, t = 16409, m = 4.5, a = 0",
            "fluor : 'flowcore'-based logicle transform using w = 0.1, t = 500'000, m = 4.5, a = 0",
            "linear1 : linear transform using f(x) = x / max(x) * 4.5",
            "linear2 : linear transform using f(x) = ((x - min(x)) / (max(x) - min(x))) * 4.5",
            "arcsinh5 : 'flowcore'-based arcsinh transform using a = 0, b = 1/5",
            "arcsinh150 : 'flowcore'-based arcsinh transform using a = 0 b = 1/150",
            "auto : automatic parameter determination, 'flowcore'-based logicle transform using w = auto, t = auto, m = 4.5, a = 0",
            "none : no transformation will be applied.",
          ],
        },
      },
      {
        key: "prefixbatch",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Prefix",
        tooltip: "Prefix",
        text: "Prefix",
      },
      {
        key: "seedbatch",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Seed",
        tooltip: "Make reproducible results",
        text: "Set a seed to make reproducible results; in case you want to run the same data with the same parameters again, setting the seed to the same value would ensure that procedures that rely on randomness to a certain extent yield identical results. Set to 42 by default.",
      },
    ],
  },
  {
    id: 13,
    key: "generatemetafiles",
    isHidden: true,
    title: "GENERATE META FILES",
    description: "Generate Meta Files",
    parameters: [
      {
        key: "inputgeneratemetafiles",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs)",
        tooltip: "Input",
        text: "Input",
      },
    ],
  },
  {
    id: 14,
    key: "tcranalysis",
    isHidden: true,
    title: "TCR ANALYSIS",
    description: "TCR Analysis",
    parameters: [
      {
        key: "h5adtcranalysis",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Input 10x File (in .h5ad format)",
        tooltip: "Input",
        text: "Input",
      },
      {
        key: "bulktcranalysis",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Input Bulk File (in .fcs format)",
        tooltip: "Input",
        text: "Input",
      },
      {
        key: "metamarkertcranalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Selection/Information",
        tooltip:
          "Marker metadata information, to choose which markers to include in the analysis",
        text: "Click in the marker list that appears (only when you upload .fcs files), or upload your own file in CSV format.<br>The first column should be the unique marker IDs used in the input .fcs files or in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the markers that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>No additional marker grouping columns would be considered in this pipeline.",
        images: [
          {
            text: "Example:",
            url: "img/docs/highdim_metamarker.png",
          },
        ],
      },
      {
        key: "metasampletcranalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Selection/Information",
        tooltip:
          "Sample metadata information, to choose which samples to include and group them with colors",
        text: "If you want your samples classified by groups (or you want to filter out undesired samples), upload your own file in CSV format, containing sample grouping information.<br>The first column should be the unique sample IDs, <i>i.e.</i> the input .fcs file names, with or without the <i>.fcs</i> extension, or the sample IDs used in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the samples that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional grouping columns with factor levels can be added, resulting in color grouping of the samples in the relevant output heat maps and (dimensionality reduced) scatter plots.<br>Furthermore, an optional <i>Label</i> column indicates Sample IDs to label in the output plots, in case you need to track certain ones (note labels are only added when the input is a .csv data matrix; a column named <i>Label</i>, same as <i>Keep</i>, will not be considering for grouping).<br>Both discrete and continuous variables are accepted here, resulting in either discrete or continuous coloring of the relevant plots.",
        images: [
          {
            text: "Example:",
            url: "img/docs/highdim_metasample.png",
          },
        ],
      },
      {
        key: "hitstcranalysis",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Gated FCS file(s)",
        tooltip: "Gated FCS file(s)",
        text: "Gated FCS file(s)",
      },
      {
        key: "transformationtcranalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Transformation",
        tooltip: "Data transformation to be applied",
        text: 'Enable data transformation of .fcs files derived from either mass or flow cytometry to be able to use them for downstream analyses.<br>Note that this option is not available for a .csv data matrix input.<br>Files in FCS format derived from mass cytometry (CyTOF) data should undergo a modified <i>arc sin</i> transformation (logical transformation) before their analysis. The <i>arc sin</i> transformation is the inverse of taking the <i>sin</i> function of a data point. The important aspects of this function are: (1) It is linear in nature around zero, and allows for low end data to be visualized. (2) Beyond a control point, the function becomes logarithmic in nature, allowing for increasing compression of "positive" populations.<br>Because fluorescent intensity increases logarithmically, data derived from flow cytometry requires a different transformation than data derived from CyTOF to be displayed correctly. The <i>hyperbolic arcsine</i> (<i>arcsinh</i>) is a function for transforming data that serves a similar purpose as other transformation functions such as biexponential, logicle, hyperlog, etc. The biexponential scale is a combination of linear and log scaling on a single axis using an <i>arcsine</i> function as its backbone. The "logicle" implementation of biexponential has been implemented in many popular software packages like FACSDiva and FlowJo.',
        list: {
          text: "List of available transformation methods:",
          items: [
            "cytof : 'flowcore'-based logicle transform using w = 0.25, t = 16409, m = 4.5, a = 0",
            "fluor : 'flowcore'-based logicle transform using w = 0.1, t = 500'000, m = 4.5, a = 0",
            "linear1 : linear transform using f(x) = x / max(x) * 4.5",
            "linear2 : linear transform using f(x) = ((x - min(x)) / (max(x) - min(x))) * 4.5",
            "arcsinh5 : 'flowcore'-based arcsinh transform using a = 0, b = 1/5",
            "arcsinh150 : 'flowcore'-based arcsinh transform using a = 0 b = 1/150",
            "auto : automatic parameter determination, 'flowcore'-based logicle transform using w = auto, t = auto, m = 4.5, a = 0",
            "none : no transformation will be applied.",
          ],
        },
      },
      {
        key: "ceilingtcranalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Number of Events (bulk file)",
        tooltip:
          "Number of events to take per sample (unless sample has fewer)",
        text: "Number of events to take for sample (unless sample has fewer). A default of 20,000 is used.",
      },
      {
        key: "umaptcranalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "UMAP",
        tooltip: "Runs the Umap reduction method",
        text: "Runs the Uniform Manifold Approximation and Projection (UMAP) method, a dimension reduction technique that can be used for visualisation similarly to tSNE, but also for general non-linear dimension reduction. Set to FALSE by default.",
      },
      {
        key: "kvalueumaptcranalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "UMAP K Nearest Neighbours",
        tooltip: "Number of nearest neighbors for Umap calculations",
        text: "Number of nearest neighbors for Umap calculations. This kValue must be lower than the number of samples in case your input is a .csv file (default: 25, automatically adjusted inside the pipeline depending on input size in case of input .csv data matrix).",
      },
      {
        key: "spreadumaptcranalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "UMAP Spread",
        tooltip: "UMAP Spread",
        text: "UMAP Spread set to 1 by default.",
      },
      {
        key: "mindistumaptcranalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "UMAP Minimum Distance",
        tooltip: "UMAP Minimum Distance",
        text: "UMAP Minimum Distance, set to 0.2 by default.",
      },
      {
        key: "toppercentiletcranalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Top Percentile",
        tooltip: "Top percentile limit included in the scatter plots",
        text: "Top percentile limit for outlier removal in graphical output scatter plots. The value range is from 0 to 1 (0.51 to 1 for top percentile). Set to 0.99 by default.",
      },
      {
        key: "bottompercentiletcranalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Bottom Percentile",
        tooltip: "Bottom percentile limit included in the scatter plots",
        text: "Bottom percentile limit for outlier removal in graphical output scatter plots. The value range is from 0 to 1 (0 to 0.49 for bottom percentile). Set to 0.01 by default.",
      },
      {
        key: "pchtcranalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Symbol",
        tooltip: "Point symbol used in the scatter plots",
        text: "Data point symbol used in the graphical output scatter plots. Choose a symbols (circle, square, diamond, up-triangle, down-triangle).",
        images: [
          {
            text: "Number values correspond to the following symbols:",
            url: "img/docs/highdim_pch.png",
          },
        ],
      },
      {
        key: "plotceilingtcranalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Number of Points to Plot",
        tooltip: "Number of data points to plot in the scatter plots",
        text: "Number of data points to plot in the graphical output scatter plots; if the <i>Number of Events</i> chosen was really high, you might want a random subset of them to be actually plotted, so your output files are not as big in size and the plots are not as cluttered. A default of 20,000 is used.",
      },
      {
        key: "palettetcranalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output Plot Colors",
        tooltip: "Color Palette",
        text: "Comprehensible color gradient schemes available for the ouput plots. Set to the ImmunoScape color scheme by default. There is the option to reverse the palette.",
        images: [
          {
            text: "",
            url: "img/docs/heatplot_palette.png",
          },
        ],
      },
      {
        key: "groupcolorstcranalysis",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Grouping Colors (requires sample information)",
        tooltip:
          "R color palettes used for group coloring of markers and samples",
        text: "R color palettes used for group coloring of markers and samples. Default: Set1.",
        images: [
          {
            text: "",
            url: "img/docs/heatplot_groupcolors.png",
          },
        ],
      },
      {
        key: "prefixtcranalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Prefix",
        tooltip: "Prefix for the output files",
        text: 'Introduce a string that would be used as prefix in your output files. Default of "tcr" for this pipeline.',
      },
      {
        key: "seedtcranalysis",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Seed",
        tooltip: "Make reproducible results",
        text: "Set a seed to make reproducible results; in case you want to run the same data with the same parameters again, setting the seed to the same value would ensure that procedures that rely on randomness to a certain extent yield identical results. Set to 42 by default.",
      },
      {
        key: "tcrtcranalysis",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "TCR Sequences File (in .csv format)",
        tooltip: "TCR Sequences File (in .csv format)",
        text: "TCR Sequences File (in .csv format)",
      },
    ],
  },
  {
    id: 16,
    key: "beadnormalization",
    isHidden: true,
    title: "BEAD NORMALIZATION",
    description: "Bead Normalization",
    parameters: [
      {
        key: "inputbeadnormalization",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs)",
        tooltip: "Input files in FCS format",
        text: "Multiple input files in FCS format.<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.",
      },
      {
        key: "windowsizebeadnormalization",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Window Size",
        tooltip: "Window Size",
        text: "Window Size",
      },
      {
        key: "normalizedbeadnormalization",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Normalized Beads",
        tooltip: "Normalized Beads",
        text: "Normalized Beads",
      },
      {
        key: "beadchannelsbeadnormalization",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Bead Channels",
        tooltip: "Bead Channels",
        text: "Bead Channels",
      },
    ],
  },
  {
    id: 17,
    key: "tetdecon2021",
    isHidden: true,
    title: "TETDECON 2021",
    description: "Tetdecon 2021",
    parameters: [
      {
        key: "inputtetdecon2021",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Target (.fcs file, e.g. CD8 T cells)",
        tooltip: "Case .fcs file(s)",
        text: 'Files in FCS format, containing CD8 T cells (or your own case cells).<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.<br>Please use the following naming convention: <i>sample_comparison_config.fcs</i><br>Make sure the <i>sample</i> field is identical for the same sample across case/control folders and for all configs.<br>Make sure the <i>comparison</i> field is identical across configs in the same folder.<br>Make sure the <i>config</i> field is identical across case/control folders for the same config - Please do not add additional "_" as it is the delimiting character used to determine the fields. Note that the <i>config</i> field can be ommitted if there is only one config.',
        list: {
          text: "Example:",
          items: [
            "For Donor 1, Configuration 1 : Donor1_CD8_C1.fcs",
            "For Donor 1, Configuration 2 : Donor1_CD8_C2.fcs",
          ],
        },
      },
      {
        key: "clustercodestetdecon2021",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Cluster Codes",
        tooltip: "ClusterCodes output file from the Robot pipeline",
        text: "ClusterCodes output file from the Robot pipeline. File in CSV format with clustered peptides and their respective metal configurations.",
        images: [
          {
            text: "Example (1 configuration):",
            url: "img/docs/tetdecon_clusters.png",
          },
        ],
      },
      {
        key: "configtetdecon2021",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Configuration Setup",
        tooltip: "Number of configurations",
        text: "Number of configurations used. Currently Cytographer&trade; supports 1 (default) or 2 configuration setups.",
      },
      {
        key: "alphatetdecon2021",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Alpha",
        tooltip: "Alpha",
        text: "Alpha",
      },
      {
        key: "markercsvtetdecon2021",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Selection/Information",
        tooltip: "Marker Selection/Information",
        text: "Marker Selection/Information",
      },
      {
        key: "cutofftetdecon2021",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Cutoff files",
        tooltip: "Cutoff files",
        text: "Cutoff files",
      },
      {
        key: "alphatetdecon2021",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Alpha",
        tooltip: "Alpha",
        text: "Alpha",
      },
    ],
  },
  {
    id: 19,
    key: "debarcode",
    isHidden: true,
    title: "DEBARCODE",
    description: "Debarcode",
    parameters: [
      {
        key: "inputdebarcode",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs)",
        tooltip: "Input files in FCS format",
        text: "Multiple input files in FCS format.<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.",
      },
    ],
  },
  {
    id: 20,
    key: "scatterplot",
    isHidden: true,
    title: "SCATTER PLOT",
    description: "Scatter Plot",
    parameters: [
      {
        key: "inputscatterplot",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Input Data to Plot (in .csv format)",
        tooltip: "Input Data to Plot (in .csv format)",
        text: "Input Data to Plot (in .csv format)",
      },
      {
        key: "bulkscatterplot",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Intensities for Coloring (in .csv format)",
        tooltip: "Marker Intensities for Coloring (in .csv format)",
        text: "Marker Intensities for Coloring (in .csv format)",
      },
      {
        key: "metasamplescatterplot",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Selection/Information",
        tooltip: "Sample metadata information, to group samples with colors",
        text: "If you want your samples classified by groups (or you want to filter out undesired samples), upload your own file in CSV format, containing sample grouping information.<br>The first column should be the unique sample IDs, <i>i.e.</i> the input .fcs file names, with or without the <i>.fcs</i> extension, or the sample IDs used in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the samples that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional grouping columns with factor levels can be added, resulting in a multi-level color grouping of the samples in the output heat map. Both discrete and continuous variables are accepted here.",
        images: [
          {
            text: "Example:",
            url: "img/docs/heatplot_metasample.png",
          },
        ],
      },
    ],
  },
  {
    id: 21,
    key: "resultupload",
    isHidden: true,
    title: "RESULT UPLOAD",
    description: "Result upload",
    parameters: [
      {
        key: "resulttyperesultupload",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Result Type",
        tooltip: "Result Type",
        text: "Result Type",
      },
      {
        key: "filesresultupload",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Result Files",
        tooltip: "Result Files",
        text: "Result Files",
      },
    ],
  },
  {
    id: 22,
    key: "pyir",
    isHidden: true,
    title: "PYIR",
    description: "PyIR ",
    parameters: [
      {
        key: "inputpyir",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Sequences (one or more .fasta or .seq file)",
        tooltip: "Sequences would accept any number of .fasta, .fa, .seq files",
        text: "Sequences would accept any number of .fasta, .fa, .seq files",
      },
      {
        key: "prefixpyir",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Prefix",
        tooltip: "Prefix for the output files",
        text: 'Introduce a string that would be used as prefix in your output files. Default of "pyir" for this pipeline.',
      },
    ],
  },
  {
    id: 23,
    key: "highdiminteractive",
    isHidden: true,
    title: "HIGH DIMENSIONAL INTERACTIVE",
    description: "High Dimensional Interactive",
    parameters: [
      {
        key: "coordsfolderhighdiminteractive",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title:
          "Load Coordinates Files to Calculate Freqs (one or more .csv files)",
        tooltip:
          "Load Coordinates Files to Calculate Freqs (one or more .csv files)",
        text: "Load Coordinates Files to Calculate Freqs (one or more .csv files).",
      },
      {
        key: "calculatefreqhighdiminteractive",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Calculate Frequencies",
        tooltip: "Calculate Freq (results based on this)",
        text: "Calculate Freq (results based on this).",
      },
      {
        key: "inputhighdiminteractive",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs or one .csv data matrix)",
        tooltip: "Input Files",
        text: 'Multiple input files in FCS format to study; alternatively, you can upload a .csv data file.<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.<br>In case a .csv file is uploaded instead, note that, if you do not upload <i>Metamarker</i> or <i>Metasample</i> files to indicate otherwise, the program expects that the Markers are in rows and the Samples are in columns (unless you specify "Sample" as the first column name). It should be a numerical data matrix (do not add non-numerical characters in the data cells; decimal positions are determined by ".", not ","), with <b>unique identifiers</b> for markers and samples (the marker IDs should be in the first column, and sample IDs as remaining column names, or vice-versa). No data transformation will be performed if the input is a .csv data matrix.',
        images: [
          {
            text: "Example of a .csv data matrix:",
            url: "img/docs/highdim_input.png",
          },
        ],
      },
      {
        key: "metamarkerhighdiminteractive",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Selection/Information",
        tooltip:
          "Marker metadata information, to choose which markers are included in the analysis and group the markers with colors",
        text: "Click in the marker list that appears (only when you upload .fcs files), or upload your own file in CSV format.<br>The first column should be the unique marker IDs used in the input .fcs files or in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the markers that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional grouping columns with factor levels can be added, resulting in a multi-level color grouping of the markers in the output High Dimensional Interactive.",
        images: [
          {
            text: "Example:",
            url: "img/docs/highdim_metamarker.png",
          },
        ],
      },
      {
        key: "metasamplehighdiminteractive",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Selection/Information",
        tooltip: "Sample metadata information, to group samples with colors",
        text: "If you want your samples classified by groups (or you want to filter out undesired samples), upload your own file in CSV format, containing sample grouping information.<br>The first column should be the unique sample IDs, <i>i.e.</i> the input .fcs file names, with or without the <i>.fcs</i> extension, or the sample IDs used in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the samples that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional grouping columns with factor levels can be added, resulting in a multi-level color grouping of the samples in the output High Dimensional Interactive. Both discrete and continuous variables are accepted here.",
        images: [
          {
            text: "Example:",
            url: "img/docs/highdim_metasample.png",
          },
        ],
      },
      {
        key: "outputhighdiminteractive",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of High Dimensional Interactive Pipeline",
        tooltip: "Output of High Dimensional Interactive Pipeline",
        text: "The High Dimensional Interactive pipeline produces a customized High Dimensional Interactive based on intensities data from input .fcs files or from a single .csv data matrix, and all the selections made above, along with a frequency table with the data plotted.",
        images: [
          {
            text: "Example of an output High Dimensional Interactive:",
            url: "img/docs/highdim_output.png",
          },
        ],
      },
    ],
  },
  {
    id: 24,
    key: "url2s3",
    isHidden: true,
    title: "URL2S3",
    description: "url2s3",
    parameters: [
      {
        key: "inputurl2s3",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Input (one .csv or .txt file)",
        tooltip: "Input (one .csv or .txt file)",
        text: "Input (one .csv or .txt file)",
      },
    ],
  },
  {
    id: 25,
    key: "sequencinggating",
    isHidden: true,
    title: "SEQUENCING GATING",
    description: "Sequencing Gating",
    parameters: [
      {
        key: "inputsequencinggating",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Sample (one .fcs only)",
        tooltip: "Input Files",
        text: 'One input file in FCS format to study; alternatively, you can upload a .csv data file.<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.<br>In case a .csv file is uploaded instead, note that, if you do not upload <i>Metamarker</i> or <i>Metasample</i> files to indicate otherwise, the program expects that the Markers are in rows and the Samples are in columns (unless you specify "Sample" as the first column name). It should be a numerical data matrix (do not add non-numerical characters in the data cells; decimal positions are determined by ".", not ","), with <b>unique identifiers</b> for markers and samples (the marker IDs should be in the first column, and sample IDs as remaining column names, or vice-versa). No data transformation will be performed if the input is a .csv data matrix.',
      },
      {
        key: "outputsequencinggating",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of Sequencing Gating Pipeline",
        tooltip: "Output of Sequencing Gating Pipeline",
        text: "Output of Sequencing Gating Pipeline",
      },
    ],
  },
  {
    id: 26,
    key: "histogram",
    isHidden: true,
    title: "HISTOGRAM",
    description: "Histogram",
    parameters: [
      {
        key: "coordsfolderhistogram",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title:
          "Load Coordinates Files to Calculate Freqs (one or more .csv files)",
        tooltip:
          "Load Coordinates Files to Calculate Freqs (one or more .csv files)",
        text: "Load Coordinates Files to Calculate Freqs (one or more .csv files).",
      },
      {
        key: "calculatefreqhistogram",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Calculate Frequencies",
        tooltip: "Calculate Freq (results based on this)",
        text: "Calculate Freq (results based on this).",
      },
      {
        key: "inputhistogram",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Samples (multiple .fcs or one .csv data matrix)",
        tooltip: "Input Files",
        text: 'Multiple input files in FCS format to study; alternatively, you can upload a .csv data file.<br>Note that we should be able to read the .fcs files in as a set, so make sure the same observables (markers) are listed in all the files.<br>In case a .csv file is uploaded instead, note that, if you do not upload <i>Metamarker</i> or <i>Metasample</i> files to indicate otherwise, the program expects that the Markers are in rows and the Samples are in columns (unless you specify "Sample" as the first column name). It should be a numerical data matrix (do not add non-numerical characters in the data cells; decimal positions are determined by ".", not ","), with <b>unique identifiers</b> for markers and samples (the marker IDs should be in the first column, and sample IDs as remaining column names, or vice-versa). No data transformation will be performed if the input is a .csv data matrix.',
      },
      {
        key: "metamarkerhistogram",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Marker Selection/Information",
        tooltip:
          "Marker metadata information, to choose which markers are included in the analysis and group the markers with colors",
        text: "Click in the marker list that appears (only when you upload .fcs files), or upload your own file in CSV format.<br>The first column should be the unique marker IDs used in the input .fcs files or in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the markers that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional grouping columns with factor levels can be added, resulting in a multi-level color grouping of the markers in the output High Dimensional Interactive.",
      },
      {
        key: "metasamplehistogram",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Selection/Information",
        tooltip: "Sample metadata information, to group samples with colors",
        text: "If you want your samples classified by groups (or you want to filter out undesired samples), upload your own file in CSV format, containing sample grouping information.<br>The first column should be the unique sample IDs, <i>i.e.</i> the input .fcs file names, with or without the <i>.fcs</i> extension, or the sample IDs used in the input .csv data matrix.<br>Include an optional <i>Keep</i> column to indicate the samples that should be used for the analysis, with <i>y</i> or <i>TRUE</i>.<br>Additional grouping columns with factor levels can be added, resulting in a multi-level color grouping of the samples in the output High Dimensional Interactive. Both discrete and continuous variables are accepted here.",
      },
      {
        key: "outputhistogram",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of Histogram Pipeline",
        tooltip: "Output of Histogram Pipeline",
        text: "The Histogram pipeline produces histogram plots that were saved during the interactive session.",
      },
    ],
  },
  {
    id: 27,
    key: "tcrquery",
    isHidden: true,
    title: "TCRQUERY",
    description: "TCR Query",
    parameters: [
      {
        key: "outputtcrquery",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of TCR Query Pipeline",
        tooltip: "Output of TCR Query Pipeline",
        text: "The TCR Query pipeline allows to fuzzy search for matching sequences in the TCR Database in an interactive session.",
      },
    ],
  },
  {
    id: 28,
    key: "stitchr",
    isHidden: true,
    title: "STITCHR",
    description: "StiTChR",
    parameters: [
      {
        key: "outputstitchr",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of StiTChR Pipeline",
        tooltip: "Output of StiTChR Pipeline",
        text: "Output of StiTChR Pipeline",
      },
    ],
  },
  {
    id: 29,
    key: "tcrsearch",
  isHidden: true,
    title: "TCRSEARCH",
    description: "TCR Search",
    parameters: [
      {
        key: "queryclonotype",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Query Clonotype",
        tooltip: "Example input query_TRAV38-2/DV8.TRAJ43.CAWAYNNNDMRF..TRBV4-3.TRBJ2-7.CASSQGLSSGASYEQYF",
        text: "Example input for the query clonotype field: query_TRAV38-2/DV8.TRAJ43.CAWAYNNNDMRF..TRBV4-3.TRBJ2-7.CASSQGLSSGASYEQYF",
      },
      {
        key: "mode",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Mode",
        tooltip: "Mode of TCR Search Pipeline",
        text: "Mode of TCR Search Pipeline",
      },
      {
        key: "outputtcrsearch",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Output of TCR Search Pipeline",
        tooltip: "Output of TCR Search Pipeline",
        text: "Output of TCR Search Pipeline",
      },
    ],
  },
  {
    id: 30,
    key: "onttcrconsensus",
    isHidden: true,
    title: "ONT TCR CONSENSUS",
    description: "ONT TCR Consensus",
    parameters: [
      {
        key: "inputonttcrconsensus",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Input (one .zip file)",
        tooltip: "Zip file of the 'fast_pass' folder",
        text: "One zip file of the 'fast_pass' folder.",
      },
    ],
  },
  {
    id: 31,
    key: "pfda",
    isHidden: false,
    title: "Faust Pfda",
    description: "Faust Pfda",
    parameters: [
      {
        key: "inputpfda",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "RDS or Zip file (output from POPS)",
        tooltip: "Input",
        text: "Input",
      },
      {
        key: "fcspfda",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "FCS file to use as marker template (eg. the same as used in POPS)",
        tooltip: "Template FCS",
        text: "Template FCS",
      },
      {
        key: "patientcolpfda",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Patient Column, column name in meta sample",
        tooltip: "Patient Column, colum name in meta sample",
        text: "Patient Column, colum name in meta sample",
      },
      {
        key: "predictor1pfda",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Predictor 1",
        tooltip: "Predictor 1, colum name in meta sample",
        text: "Predictor 1, column name in meta sample",
      },
      {
        key: "predictor2pfda",
        isRequired: false,
        isAdvanced: false,
        isHidden: false,
        title: "Predictor 2",
        tooltip: "Predictor 2, colum name in meta sample",
        text: "Predictor 2, column name in meta sample",
      },
      {
        key: "pvcutpfda",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "P-value cut-off",
        tooltip: "P-value cut-off",
        text: "P-value cut-off",
      },
      {
        key: "fdrpfda",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "False Discovery Rate Correction",
        tooltip: "False Discovery Rate Correction",
        text: "False Discovery Rate Correction",
      },
      {
        key: "palettepfda",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Output Plot Colors",
        tooltip: "Output Plot Colors",
        text: "Output Plot Colors",
      },
      {
        key: "umappfda",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Do UMAP",
        tooltip: "Do UMAP",
        text: "Do UMAP. This option needs ZIP as main input - the script takes the expression matrix, with the markers selectred in POPS, from the ZIP file instead of FCS files.",
      },
      {
        key: "savepopulationspfda",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Save Populations to FCS",
        tooltip: "Save Populations to FCS",
        text: "Save Populations to FCS, this will need a Template file, ideally the same as used in POPS",
      },
      {
        key: "seedpfda",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Seed",
        tooltip: "Make reproducible results",
        text: "Set a seed to make reproducible results; in case you want to run the same data with the same parameters again, setting the seed to the same value would ensure that procedures that rely on randomness to a certain extent yield identical results. Set to 42 by default.",
      },
      {
        key: "metasamplepfda",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Sample Selection/Information",
        tooltip: "Sample Selection/Information",
        text: "Sample Selection/Information, text file with samples as rows and meta data as columns",
      }
    ],
  },
  {
    id: 32,
    key: "igblast",
    isHidden: true,
    title: "IGBLAST",
    description: "IgBlast  ",
    parameters: [
      {
        key: "typeigblast",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Input Type Nucelotide or Amino Acid",
        tooltip: "Input Type Nucelotide or Amino Acid",
        text: "Input Type Nucelotide or Amino Acid",
      },
      {
        key: "inputigblast",
        isRequired: true,
        isAdvanced: false,
        isHidden: false,
        title: "Sequences (one or more .fasta or .seq file)",
        tooltip: "Sequences would accept any number of .fasta, .fa, .seq files",
        text: "Sequences would accept any number of .fasta, .fa, .seq files",
      },
      {
        key: "prefixigblast",
        isRequired: false,
        isAdvanced: true,
        isHidden: false,
        title: "Prefix",
        tooltip: "Prefix for the output files",
        text: 'Introduce a string that would be used as prefix in your output files. Default of "igblast" for this pipeline.',
      },
    ],
  }
];
