import React, { useState, useEffect } from 'react';
import {
    Col, Input, Label
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { changeExcludedParams, updateInput } from '../../../actions/action.pipeline-input';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import InputCustom from '../../../components/Form/InputCustom';
import ColorPalette from '../../../components/ColorPalette/ColorPalette';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { GATING_SUBSETTING_STEP, INPUT_SMALL, SYMBOL_PARAMETERS } from '../../../utils/constants';
import Toggle from '../../../components/Form/Toggle';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';
import MetasampleEditor from '../../../components/Views/MetasampleEditor';
import { defaultFileUpload, getExtension } from '../helper';
import { IProfile } from '../../../types/type.user';
import { ICoordinatesFiles } from '../../../types/type.coordinates-files';
import { compareClonedCoordinatesFiles, getCoordinatesFiles } from '../../../actions/action.coordinates-files';
import { removeFileFromTrayWithoutClearingProtein } from '../../../actions/action.file-selector';

const Analysis = () => {
    const profile: IProfile = useSelector((state : IStore) => state.user.profile);
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const coordinatesFiles: ICoordinatesFiles = useSelector((state: IStore) => state.coordinatesFiles);
    const projectId: number = useSelector((state : IStore) => state.projects.selected_project.id)!;
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced } = pipeline;
    const [isSingleCsv, setIsSingleCsv] = useState<boolean>(false);
    const [calcFreq, setCalcFreq] = useState<boolean>(false);
    const [clonedCoordinates, setClonedCoordinates] = useState<Array<string>>([]);

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        dispatch(updateInput(name, value));
    }

    const handleToggle = (value: boolean, key: string) => {
        const toggledValue = !value;
        if (key !== 'doFreq'){
            dispatch(updateInput(key, toggledValue ? true : null));
        }

        switch (key) {
            case 'doFreq':
                setCalcFreq(toggledValue);
                if (toggledValue) {
                    const prefix = defaultFileUpload(profile);
                    dispatch(getCoordinatesFiles(coordinatesFiles.filesToUpload, projectId, prefix));
                } else {
                    input.coordsFolder = [];
                }
                break;
        }
    }

    const handleInputCustom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const symbol = ['*', '+', 'o', 'x'];
        // +value => convert string to number
        if (_.includes(symbol, value) || _.inRange(+value, 0, 26) || _.isEmpty(value)) {
            dispatch(updateInput(name, value));
        }
    }

    const isFcsFile = () : boolean => {
        const fileExtension = getExtension(input.input.length>0 ? input.input[0] : '');

        return fileExtension === 'fcs';
    }

    useEffect(() => {
        const findCsv = _.find(input.input, (i) => i.split('.').pop() === 'csv');
        const exclude = (!!findCsv) ? ['transformation'] : [];
        setIsSingleCsv(!!findCsv);

        dispatch(changeExcludedParams(exclude));
    }, [input.input]);

    useEffect(() => {
        if(_.isEmpty(input.input)){
            coordinatesFiles.filesToUpload = [];
            coordinatesFiles.files = [];
        }

        if (!_.isEmpty(input.coordsFolder)) {
            setClonedCoordinates([...input.coordsFolder]);
            setCalcFreq(true);
        }
    }, []);

    useEffect(() => {
        if (calcFreq) {
            dispatch(compareClonedCoordinatesFiles(clonedCoordinates));
        }
    }, [input.coordsFolder, calcFreq]);

    return (
        <TabbedMetaContainer
            name="Statistical Analysis"
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.input)}
            containerClass="analysis"
            metamarker={(!_.isEmpty(input.input)) && (
                <FormGroupCustom
                    pipeline="analysis"
                    id="metamarkeranalysis"
                    input={input.metaMarker}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        {(input.protein && input.protein.length > 0) ? (
                            <ProteinSelector
                                columns={['keep']}
                                protein={input.protein}
                                tray={input.metaMarker}
                                trayName='metaMarker'
                            />
                        ) : (
                            <FileSelector
                                name="metaMarker"
                                tray={input.metaMarker}
                                isMultiple={false}
                                accept={['csv', '.csv']}
                                checkFcsMetadata={false}
                                getPreviousPipelineOutput={false}
                            />
                        )}
                    </Col>
                </FormGroupCustom>
            )}
            metasample={
                <FormGroupCustom
                    pipeline="analysis"
                    id="metasampleanalysis"
                    input={input.metaSample}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <MetasampleEditor input='input' />
                    </Col>
                </FormGroupCustom>
            }
        >
            <div className="pipeline-form">
                <FormGroupCustom
                    pipeline="analysis"
                    id="inputanalysis"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            isMultiple={true}
                            accept={['fcs', '.fcs', 'csv', '.csv']}
                            checkFcsMetadata={true}
                            getPreviousPipelineOutput={true}
                            mixedExtension={true}
                            isShowPreviousPipeline
                            isPreviousPipelineFromPreProcessing
                            previousPipelineParams = {{
                                preprocessingStep: GATING_SUBSETTING_STEP.toString()
                            }}
                            isShowS3Paths
                        />
                    </Col>
                </FormGroupCustom>

                {/* correlationFile */}
                <FormGroupCustom
                    pipeline="analysis"
                    id="correlationfileanalysis"
                    input={input.correlationFile}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='correlationFile'
                            tray={input.correlationFile}
                            isMultiple={false}
                            accept={['csv', '.csv']}
                        />
                    </Col>
                </FormGroupCustom>

                {/* calculate freq */}
                { isFcsFile() &&
                    <FormGroupCustom
                        pipeline="analysis"
                        id="calculatefreqanalysis"
                        input={`${calcFreq}`}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <Toggle
                                value={calcFreq}
                                onToggle={(v: boolean) => handleToggle(v, 'doFreq')}
                            />
                            {calcFreq && (
                                <div className='mt-4'>
                                    <FileSelector
                                        name='coordsFolder'
                                        tray={input.coordsFolder}
                                        isMultiple={true}
                                        accept={['csv', '.csv']}
                                        isCoordinatesFiles={true}
                                        isShowGetCoordinatesFiles={calcFreq}
                                        isHideWorkspace={!calcFreq}
                                        isHideUpload={!calcFreq}
                                    />
                                </div>
                            )}
                        </Col>
                    </FormGroupCustom>
                }

                {/* prefix */}
                <FormGroupCustom
                    pipeline="analysis"
                    id="prefixanalysis"
                    input={input.prefix}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Input
                            name="prefix"
                            id="analysis_prefix"
                            onChange={handleInput}
                            value={input.prefix}
                        />
                    </Col>
                </FormGroupCustom>

                {/* transformation */}
                {(!_.isEmpty(input.input) && !isSingleCsv) && (
                    <FormGroupCustom
                        pipeline="analysis"
                        id="transformationanalysis"
                        input={input.transformation}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <InputCustom
                                type="select" name="transformation" id="analysis_transformation"
                                onChange={handleInput} value={input.transformation}>
                                <option value="" disabled>Select Transformation</option>
                                <option value="cytof">cytof</option>
                                <option value="fluor">fluor</option>
                                <option value="linear1">linear1</option>
                                <option value="linear2">linear2</option>
                                <option value="arcsinh5">arcsinh5</option>
                                <option value="arcsinh150">arcsinh150</option>
                                <option value="auto">auto</option>
                                <option value="none">none</option>
                            </InputCustom>
                        </Col>
                    </FormGroupCustom>
                )}

                {/* ceiling */}
                {(!_.isEmpty(input.input) && !isSingleCsv) && (
                    <FormGroupCustom
                        pipeline="analysis"
                        id="ceilinganalysis"
                        input={input.ceiling}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <InputCustom
                                name="ceiling" id="tsne_ceiling" placeholder=" Number of events to take per sample (unless sample has less)"
                                type="number" step="100" min="100" max="50000"
                                onChange={handleInput} value={input.ceiling}
                                isAuto
                                autoValue="all"
                            />
                        </Col>
                    </FormGroupCustom>
                )}

                {/* pvcut1 */}
                <FormGroupCustom
                    pipeline="analysis"
                    id="pvcut1analysis"
                    input={input.pvCut1}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="pvCut1" id="analysis_pvcut1"
                            type="number" step="0.01" min={0.001} max={0.09}
                            fixedNumber={3}
                            onChange={handleInput} value={input.pvCut1}
                        />
                    </Col>
                </FormGroupCustom>

                {/* pvcut2 */}
                <FormGroupCustom
                    pipeline="analysis"
                    id="pvcut2analysis"
                    input={input.pvCut2}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="pvCut2" id="analysis_pvcut2"
                            type="number" step="0.01" min={Number(input.pvCut1) + 0.001} max={0.1}
                            fixedNumber={3}
                            onChange={handleInput} value={input.pvCut2}
                        />
                    </Col>
                </FormGroupCustom>

                {/* Fdr */}
                <FormGroupCustom
                    pipeline="analysis"
                    id="fdranalysis"
                    input={input.fdr}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.fdr}
                            onToggle={(v: boolean) => handleToggle(v, 'fdr')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* palette */}
                <FormGroupCustom
                    pipeline="analysis"
                    id="paletteanalysis"
                    input={input.palette}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <ColorPalette
                            name="palette"
                            value={input.palette}
                            onDataChange={handleInput}
                            reverse={input.revPalette}
                            addonComponent={() => (
                                <div className="switch">
                                    <Label>Reverse Color</Label>
                                    <Toggle
                                        value={input.revPalette}
                                        onToggle={(v: boolean) => handleToggle(v, 'revPalette')}
                                    />
                                </div>
                            )}
                        />
                    </Col>
                </FormGroupCustom>

                {/* groupColors */}
                <FormGroupCustom
                    pipeline="analysis"
                    id="groupcolorsanalysis"
                    input={input.groupColors}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <ColorPalette
                            name="groupColors"
                            value={input.groupColors}
                            onDataChange={handleInput}
                            addonComponent={() => (<div />)}
                        />
                    </Col>
                </FormGroupCustom>

                {/* outliers */}
                <FormGroupCustom
                    pipeline="analysis"
                    id="outliersanalysis"
                    input={input.outliers}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.outliers}
                            onToggle={(v: boolean) => handleToggle(v, 'outliers')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* pch */}
                <FormGroupCustom
                    pipeline="analysis"
                    id="pchanalysis"
                    input={input.pch}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select" 
                            name="pch" 
                            id="analysis_pch"
                            onChange={handleInput} 
                            value={input.pch}
                        >
                            <option value="" disabled>Select Symbol</option>
                            {SYMBOL_PARAMETERS.map((element) => {
                                return (
                                    <option value={element} key={element}>{element}</option>
                                );
                            })}
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                {/* plotCeiling */}
                {(!_.isEmpty(input.input) && !isSingleCsv) && (
                    <FormGroupCustom
                        pipeline="analysis"
                        id="plotceilinganalysis"
                        input={input.plotCeiling}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <InputCustom
                                name="plotCeiling" id="tsne_plotCeiling" placeholder=" Number of points to plot"
                                type="number" step="100" min="100" max="50000"
                                onChange={handleInput} value={input.plotCeiling}
                                isAuto
                                autoValue="all"
                            />
                        </Col>
                    </FormGroupCustom>
                )}

                {/* seed */}
                <FormGroupCustom
                    pipeline="analysis"
                    id="seedanalysis"
                    input={input.seed}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="seed" id="analysis_seed" type="number" step="any"
                            onChange={handleInput} value={input.seed}
                        />
                    </Col>
                </FormGroupCustom>

            </div>
        </TabbedMetaContainer>
    );
}

export default Analysis;
