import React from 'react';
import {
    NavbarToggler,
    NavbarBrand,
} from 'reactstrap';

const Header = () => {
    const sidebarToggle = (e: any) => {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    }

    const sidebarMinimize = (e: any) => {
        e.preventDefault();
        document.body.classList.toggle('sidebar-minimized');
    }

    const mobileSidebarToggle = (e: any) => {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    }

    const asideToggle = (e: any) => {
        e.preventDefault();
        document.body.classList.toggle('aside-menu-hidden');
    }

    return (
        <header className="app-header navbar">
            <NavbarToggler className="d-lg-none" onClick={mobileSidebarToggle}>
                <span className="navbar-toggler-icon"></span>
            </NavbarToggler>
            <NavbarBrand href="#"></NavbarBrand>
        </header>
    );
}

export default Header;
