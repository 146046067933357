import _ from 'lodash';
import initialState from './initialState';
import * as types from './actionTypes';
import { INotification, TNotification } from '../types/type.notification';

export default function(state: INotification = initialState.notification, action: TNotification) : INotification {
    switch (action.type){
        case types.GET_NOTIFICATION_REQUEST:
            return{
                ...state,
                isLoading: action.isFirstLoading,
                isError: false,
            }
        case types.GET_NOTIFICATION_SUCCESS:
            return{
                ...state,
                data: action.data.data,
                unread: action.data.data.filter(n => n.read == 0).length,
                isLoading: false
            }
        case types.GET_NOTIFICATION_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }
        case types.READ_NOTIFICATION_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.READ_NOTIFICATION_SUCCESS:
            const notification = state.data;
            const index = _.findIndex(notification, ['id', action.id]);
            // @ts-ignore
            if (index != -1) {
                notification[index].read = 1;
            }
            return{
                ...state,
                data: notification,
                unread: notification.filter(n => n.read == 0).length,
                isLoading: false,
                selected: action.data
            }
        case types.READ_NOTIFICATION_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }

        case types.READ_ALL_NOTIFICATION_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.READ_ALL_NOTIFICATION_SUCCESS:
            let notifs = action.data;
            return{
                ...state,
                data: notifs,
                unread: notifs.filter(n => n.read == 0).length,
                isLoading: false
            }
        case types.READ_ALL_NOTIFICATION_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }

        case types.DELETE_NOTIFICATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case types.DELETE_NOTIFICATION_SUCCESS:
            let notifications = _.cloneDeep(state.data);
            _.remove(notifications, ["id", action.id])
            return {
                ...state,
                isLoading: false,
                data: notifications
            }
        case types.DELETE_NOTIFICATION_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }

        case types.DELETE_ALL_NOTIFICATION_REQUEST:
            return{
                ...state,
                isLoading: true,
            }
        case types.DELETE_ALL_NOTIFICATION_SUCCESS:
            return{
                ...state,
                isLoading: false,
                data: action.data
            }
        case types.DELETE_ALL_NOTIFICATION_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }
                    

        default: return state;
    }
}
