import axios, { AxiosRequestConfig, Method } from 'axios';

import * as types from '../reducers/actionTypes';
import config from '../config';
import store from '../store';

export const GET = async (endpoint: string, options?: AxiosRequestConfig) => {
    const { user } = store.getState();
    const url = `${config.API_URL}/${endpoint}`;
    try {
        const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${user.token}` },
            ...options
        });
        const { data, status } = response;
        return data;
    } catch (error) {
        if (error.response.status == 401) {
            store.dispatch({ type: types.LOGOUT_REQUEST });
        }

        throw error;
    }
}

export const POST = async (endpoint: string, body?: any, options?: AxiosRequestConfig) => {
    const { user } = store.getState();
    const url = `${config.API_URL}/${endpoint}`;
    try {
        const response = await axios.post(url, body, {
            headers: { Authorization: `Bearer ${user.token}` },
            ...options
        });
        const { data } = response;
        return data;
    } catch (error) {
        if (error.response.status == 401) {
            store.dispatch({ type: types.LOGOUT_REQUEST });
        }

        throw error;
    }
}

export const PUT = async (endpoint: string, body?: any, options?: AxiosRequestConfig) => {
    const { user } = store.getState();
    const url = `${config.API_URL}/${endpoint}`;
    try {
        const response = await axios.put(url, body, {
            headers: { Authorization: `Bearer ${user.token}` },
            ...options
        });
        const { data } = response;
        return data;
    } catch (error) {
        if (error.response.status == 401) {
            store.dispatch({ type: types.LOGOUT_REQUEST });
        }

        throw error;
    }
}

export const DELETE = async (endpoint: string, options?: AxiosRequestConfig) => {
    const { user } = store.getState();
    const url = `${config.API_URL}/${endpoint}`;
    try {
        const response = await axios.delete(url, {
            headers: { Authorization: `Bearer ${user.token}` },
            ...options
        });
        const { data, status } = response;
        return data;
    } catch (error) {
        if (error.response.status == 401) {
            store.dispatch({ type: types.LOGOUT_REQUEST });
        }

        throw error;
    }
}

export const REQUEST = async (url: string, method: Method, options?: any) => {
    try {
        const requestOptions: AxiosRequestConfig = {
            method,
            url,
            ...options
        }

        const response = await axios(requestOptions);
        const { data, status } = response;
        return data;
    } catch (error) {
        throw error;
    }
}