import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import swal from 'sweetalert';
import { runPipeline, runShiny } from '../../actions/action.pipeline';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import InputSummary from './InputSummary';
import { isInputValid, updateMetadata } from '../../actions/action.pipeline-input';
import { TDispatch } from '../../types/action';
import { TExecution } from '../../types/type.execution';
import { IStore } from '../../types/store';
import { IProjectList } from '../../types/type.project';
import { IPipeline } from '../../types/type.pipeline';
import { IVisiblePipeline } from '../../types/type.visible-pipeline';
import history from '../../views/history';
import { createBatch, setSelectedBatch } from '../../actions/action.batch';
import batchSteps from '../../reducers/batch-steps';
import { MANUAL_GATING } from './Form';


interface IProps {
  history: any
}

const Summary = (props: IProps) => {
  const pipelineType: string = useSelector((state : IStore) => state.uiHistory.pipelineType);
  const project: IProjectList = useSelector((state : IStore) => state.projects);
  const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
  const visiblePipelines: IVisiblePipeline = useSelector((state : IStore) => state.visiblePipelines);
  const batchName: string = useSelector((state : IStore) => state.batch.selected_batch.name);
  const dispatch = useDispatch<TDispatch<TExecution>>();
  const { all_projects } = project;
  const {
    projectId, input, tabId, pipelineId, isShiny, pipelineScript, isLifeDeath,
    name, description, random, excludeInputs, cloneSource, batchId, reRun
  } = pipeline;

  const [disabled, setDisabled] = useState<boolean>(false);

  const handleBack = () => {
    props.history.replace({
      pathname: `${'/pipeline/form'}`
    });
  }

  const onRunPipeline = () => {
    const inputClone = _.cloneDeep(input);

    // remove 'sav' column if not tetramer
    // if (pipelineId != 4) {
    //   inputClone.protein = _.filter(inputClone.protein, ['sav', false])
    // }

    // analyse each input item
    for (const key in inputClone) {
      const value = inputClone[key];
      if (!isInputValid(key, value, (pipelineId as number))) {
        delete inputClone[key];
      }

      // remove excluded inputs
      if (_.includes(excludeInputs, key)) {
        delete inputClone[key];
      }
    }

    const data: any = {
      projectId: projectId,
      pipelineId: pipelineId,
      name,
      description,
      metadata: {
        random: random,
        input: inputClone,  // to store the real input data for pipeline cloning,
        isShiny: false,
        tabId: +tabId,
        pipelineScript,
        batchId,
      },
      input: inputClone,
      cloneSource,
    };

    if (!batchId && pipelineType === 'preprocessing') {
      dispatch(runPipeline(data, async (executionId) => {
        await createBatch({
          name: batchName, 
          currentStep: 1,
          totalStep: _.keys(batchSteps).length,
          status: 'waiting_for_user',
          projectId: projectId!,
        }, {
          executionId: executionId,
          status: 'success',
          step: 1
        }).then((res: any) => {
          swal.close!();
          // @ts-ignore
          swal({
            title: 'Success',
            text: 'Success creating new Batch',
            icon: 'success',
            buttons: {
              ok: {
                text: 'Go to Batch',
                closeModal: true
            }}
          }).then(() => {
              dispatch(setSelectedBatch(res.id, res.projectId));
              history.push({ pathname: '/pipeline/preprocessing' });
          });
        });
      }));
    } else {
      dispatch(runPipeline(data));
    }
    setDisabled(true);
  }

  const onRunShiny = () => {
    const standalone = pipelineType === 'analysis' && pipelineId === MANUAL_GATING;
    if (standalone) {
      dispatch(updateMetadata('isStandalone', true));
    }
    if (reRun) {
      dispatch(updateMetadata('reRun', true));
    }
    const inputClone = _.cloneDeep(input);
    const data: any = {
      projectId: projectId,
      pipelineId: pipelineId,
      name,
      description,
      input: inputClone,
      metadata: {
        input: inputClone,  // to store the real input data for pipeline cloning
        isShiny: true,
        tabId: +tabId,
        pipelineScript,
        batchId,
        isLifeDeath,
        isStandalone: standalone ? true : false
      },
      isShiny: true,
      cloneSource,
    };

    dispatch(runShiny(data));
    setDisabled(true);
  }

  const getProjectName = () : string => {
    const project = all_projects.find((element) => element.id == projectId);
    return project ? project.name : '';
  }

  const getPipelineName = () : string => {
    const { data } = visiblePipelines;
    const selectedTab = data.find((element) => element.id == tabId);
    const allPipeline = selectedTab ? selectedTab.pipelines : [];
    const pipeline = allPipeline.find((element) => element.id == pipelineId);
    return pipeline ? pipeline.name : '';
  }

  const projectName = getProjectName();
  const pipelines = getPipelineName();

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href={`#/projects/${pipelineType}/${projectId}`}>Projects</BreadcrumbItem>
        <BreadcrumbItem tag="a" href="#/pipeline/form">Run Pipeline</BreadcrumbItem>
        <BreadcrumbItem active tag="span">Summary</BreadcrumbItem>
      </Breadcrumb>
      <h1 className="title">Please Confirm the Session</h1>
      <div className="card">
        <h4 className="subtitle">Session Summary</h4>
        <table className="table table-striped">
          <tbody>
            <tr>
              <th>Project Name</th>
              <td>{projectName}</td>
            </tr>
            <tr>
              <th>Pipeline Type</th>
              <td>{pipelines}</td>
            </tr>
            <tr>
              <th>Session Name</th>
              <td>{name}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td className="description-text">{description}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="card">
        <h4>Parameters</h4>
        <InputSummary input={input} exclude={excludeInputs} isPreview />
      </div>
      <div className="text-center">
        <button
          className="btn-secondary btn-form-summary"
          type="button"
          style={{ width: '150px', borderRadius: '3px' }}
          onClick={handleBack}>
          Edit
        </button>
        <button
          className="btn-primary btn-form-summary"
          type="button"
          style={{ width: '150px', marginLeft: '20px' }}
          onClick={isShiny ? onRunShiny : onRunPipeline}
          disabled={disabled}>
          {isShiny ? 'Analysis' : 'Submit'}
        </button>
      </div>
    </div>
  )
}

export default Summary;
