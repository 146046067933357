import initialState from './initialState';
import * as types from './actionTypes';
import { IExecution, TExecution } from '../types/type.execution';

export default function(state : IExecution = initialState.execution, action: TExecution) : IExecution {
    switch (action.type) {
        case types.GET_EXECUTION_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.GET_EXECUTION_SUCCESS:
            return {
                ...state,
                all_execution: action.data,
                isLoading: false
            }
        case types.GET_EXECUTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }

        case types.GET_ONE_EXECUTION_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.GET_ONE_EXECUTION_SUCCESS:
            return {
                ...state,
                selected_execution: action.data,
                isLoading: false
            }
        case types.GET_ONE_EXECUTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }

        case types.UPLOAD_FILE_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case types.UPLOAD_FILE_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }

        default: return state;
    }
}
