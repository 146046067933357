import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { updateMetadata } from '../../../actions/action.pipeline-input';
import FileSelector from '../../../components/FileSelector/FileSelector';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { INPUT_SMALL } from '../../../utils/constants';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';
import MetasampleEditor from '../../../components/Views/MetasampleEditor';

const ScatterPlot = () => {
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced, isShiny } = pipeline;

    useEffect(() => {
        if (!isShiny) {
            dispatch(updateMetadata('isShiny', true));
        }
    }, [isShiny])

    return (
        <TabbedMetaContainer
            name="Scatter Plot"
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.bulk)}
            containerClass="scatterplot"
            metamarker={(!_.isEmpty(input.bulk)) && (
                <FormGroupCustom
                    pipeline="scatterplot"
                    id="metamarkerscatterplot"
                    input={input.metaMarker}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        {(input.protein && input.protein.length > 0) ? (
                            <ProteinSelector
                                columns={['keep']}
                                protein={input.protein}
                                tray={input.metaMarker}
                                trayName='metaMarker'
                            />
                        ) : (
                            <FileSelector
                                name="metaMarker"
                                tray={input.metaMarker}
                                isMultiple={false}
                                accept={['csv', '.csv']}
                                checkFcsMetadata={false}
                                getPreviousPipelineOutput={false}
                            />
                        )}
                    </Col>
                </FormGroupCustom>
            )}
            metasample={
                <FormGroupCustom
                    pipeline="scatterplot"
                    id="metasamplescatterplot"
                    input={input.metaSample}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <MetasampleEditor input='input' />
                    </Col>
                </FormGroupCustom>
            }
        >
            <div className="pipeline-form">
                {/* input scatter */}
                <FormGroupCustom
                    pipeline="scatterplot"
                    id="inputscatterplot"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            accept={['csv', '.csv']}
                            getPreviousPipelineOutput={true}
                            isShowPreviousPipeline={true}
                        />
                    </Col>
                </FormGroupCustom>

                {/* input bulk */}
                <FormGroupCustom
                    pipeline="scatterplot"
                    id="bulkscatterplot"
                    input={input.bulk}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='bulk'
                            tray={input.bulk}
                            accept={['csv', '.csv']}
                            checkFcsMetadata={true}
                            getPreviousPipelineOutput={true}
                            isShowPreviousPipeline={true}
                        />
                    </Col>
                </FormGroupCustom>
            </div>
        </TabbedMetaContainer>
    );
}

export default ScatterPlot;
