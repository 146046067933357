import React, { useState } from "react";
import { Link, Redirect, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GoogleLogin, { GoogleLoginResponse } from "react-google-login";
import _ from "lodash";
import { getLogin, getLoginOauth } from "../../actions/action.user";
import Full from "../Full/Full";
import Loading from "../../components/Loading/Loading";
import swal from "sweetalert";
import { IUser, TUser } from "../../types/type.user";
import { IStore } from "../../types/store";
import { TDispatch } from "../../types/action";
import RequestWrapperView from "../../components/Views/RequestWrapperView";

interface IProps {
  history: any;
}

const Login = (props: IProps) => {
  const user: IUser = useSelector((state: IStore) => state.user);
  const dispatch = useDispatch<TDispatch<TUser>>();
  let { isLoading, isError, message } = user;

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [remember, setRemember] = useState<boolean>(false);

  const _handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const _handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const _handleRemember = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemember(e.target.checked);
  };

  const loginSuccessCallback = () => {
    const pathname = _.get(
      props,
      "location.state.from.pathname",
      "/projects/analysis"
    );
    props.history.push({ pathname });
  };

  const _handleLogin = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      getLogin(
        email,
        password,
        remember,
        loginSuccessCallback,
        (message: string, url: string) => {
          swal("Warning", message, "warning").then(() => {
            props.history.push({ pathname: `/reset-password/${url}` });
          });
        }
      )
    );
  };

  const successCallback = ({ tokenObj }: any) => {
    // profileObj:{
    //     email: "suci.sukawana@spinroam.com"
    //     familyName: "Sukawana"
    //     givenName: "Suci"
    //     googleId: "105047090433784277945"
    //     imageUrl: "https://lh4.googleusercontent.com/-7RUUgFcbv6w/AAAAAAAAAAI/AAAAAAAAAAA/AA6ZPT5Lr3OLbbK6qkzm1Wk-JN3GQ2VKoA/s96-c/photo.jpg"
    //     name: "Suci Sukawana"
    // }
    // ya29.GltFBUgiRc9j0HUOtadtwBtvgXq8U1mikM3v3MGLii7HvSVL_1KMPHkcODrHMmNym-sRoA0Zn7mStmjvrS56nlGtzCoYadiOApPOJ5qjmb9qK1ZzEzndDUylr4KJ
    dispatch(getLoginOauth(tokenObj.access_token));
    return true;
  };

  const failureCallback = (response: any) => {
    // swal("Error", "Please refresh your browser \n" + "Details: \n" + JSON.stringify(response), "warning");
  };

  const isLogin = user.id != null;
  if (isLogin) return <Redirect to="/" />;
  return (
    <RequestWrapperView
      isLoading={isLoading}
      isError={false}
      loadigMessage={`Logging in - Please wait...`}
    >
      <div id="login">
        <div className="container-login">
          <div className="logo"></div>

          <form onSubmit={_handleLogin}>
            <h1 className="title text-center">Login</h1>
            <p className="login-error text-center">{message}</p>
            <div className="card border form-login">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  onChange={_handleEmail}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  onChange={_handlePassword}
                  required
                  minLength={8}
                />
              </div>
              <div className="row">
                <div className="col-sm">
                  <label>
                    <input
                      type="checkbox"
                      onChange={_handleRemember}
                      checked={remember}
                    />{" "}
                    Remember me
                  </label>
                  <br />
                  <Link to="/forget-password">Forgot Password?</Link>
                </div>
                <div className="col-sm">
                  <div className="text-right">
                    <button className="btn-primary btn-login" type="submit">
                      Log in
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <GoogleLogin
              clientId="921292605155-br9mhj4jt3or8q73ghuk38v8e2sima8h.apps.googleusercontent.com"
              buttonText="Login with Google"
              className="btn google-button"
              onSuccess={successCallback}
              onFailure={failureCallback}
            />
          </form>
        </div>
      </div>
    </RequestWrapperView>
  );
};

export default Login;
