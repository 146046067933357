import React from 'react';
import Loading from '../Loading/Loading';
import { removeState } from '../../utils/localStorage';

interface IProps<B = boolean> {
    isLoading: B,
    isError: B,
    children: any,
    message?: string,
    loadigMessage?: string
}

const RequestWrapperView = ({
    isLoading, isError,
    children, message, loadigMessage
} : IProps) => {
    if (isLoading === true) return (<Loading message={loadigMessage || `Loading...`}/>);
    if (isError === true) return (
        <div className="error-message-enclosure">
            <h4>
                {message ? message : 
                    'Due to inactivity, you have been logged out automatically for your protection'
                }
            </h4>
            <button className="btn-warning" onClick={location.reload}>Retry</button>
        </div>
    );
    return children;
}

export default RequestWrapperView;
