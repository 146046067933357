import React, { useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap';

interface IProps {
    name: string,
    children: any,
    metamarker: any,
    metasample?: any,
    isDisabled?: (tab: any) => boolean,
    containerClass?: string
}

const TabbedMetaContainer = ({
    name,
    children, metamarker, metasample,
    isDisabled,
    containerClass = ''
} : IProps) => {
    const [activeTab, setActiveTab] = useState<string>('params');

    const tabItems = [
        { id: 'params', name },
        metamarker ? { id: 'metamarker', name: 'Marker Information' } : null,
        metasample ? { id: 'metasample', name: 'Sample Information' } : null,
    ];

    return (
        <div className={`${containerClass} styled-tab-blue`}>
            <div className="detail">
                <Nav tabs>
                    {_.map(tabItems, (tab, index) => {
                    if (tab == null) return null;
                    const isDisable = isDisabled ? isDisabled(tab) : false;
                    return (
                        <NavItem key={`tab-with-id${index}`} className={classnames({ disabled: isDisable })}>
                        <NavLink
                            className={classnames({ active: tab.id === activeTab })}
                            onClick={() => { setActiveTab(tab.id); }}
                            hidden={isDisable}
                        >
                            {tab.name}
                        </NavLink>
                        </NavItem>
                    );
                    })}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId='params'>
                        {children}
                    </TabPane>
                    {metamarker && (
                        <TabPane tabId='metamarker'>
                            {metamarker}
                        </TabPane>
                    )}
                    {metasample && (
                        <TabPane tabId='metasample'>
                            {metasample}
                        </TabPane>
                    )}
                </TabContent>
            </div>
        </div>
    );
}

export default TabbedMetaContainer;
