import matchSorter from "match-sorter";
import swal from "sweetalert";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable, { Filter } from "react-table";
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { getOneProject } from "../../actions";
import {
  archiveBatch,
  getBatch,
  resetSelectedBatch,
  setSelectedBatch,
} from "../../actions/action.batch";
import { resetPipeline } from "../../actions/action.pipeline";
import {
  changeBatchSetting,
  setPipelineType,
} from "../../actions/action.ui-history";
import RequestWrapperView from "../../components/Views/RequestWrapperView";
import { TDispatch } from "../../types/action";
import { IStore } from "../../types/store";
import { IBatch } from "../../types/type.batch";
import { TExecution } from "../../types/type.execution";
import { IProjectList } from "../../types/type.project";
import { IUiHistory } from "../../types/type.ui-history";
import { IUser } from "../../types/type.user";
import {
  batchPreprocDocs,
  DefaultBatchPreprocDocs,
  IBatchPreprocDocs,
} from "../Documentation/BatchPreprocDocuments";
import { PREPROCESSING_ACTION_COLUMN } from "../../utils/constants";
import DocumentationItem from "../../components/Views/DocumentationItem";

interface IProps {
  match: any;
  history: any;
}

const ProjectPreprocessing = ({ match, history }: IProps) => {
  const uiHistory: IUiHistory = useSelector((state: IStore) => state.uiHistory);
  const projects: IProjectList = useSelector((state: IStore) => state.projects);
  const allBatch: IBatch[] = useSelector(
    (state: IStore) => state.batch.all_batch
  );
  const { isLoading, isError, message, selected_project } = projects;
  const { batchSetting } = uiHistory;
  const dispatch = useDispatch<TDispatch<TExecution>>();
  const [reload, setReload] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const newBatch = () => {
    dispatch(resetSelectedBatch());
    dispatch(setSelectedBatch(null, +match.params.id));
    history.push({
      pathname: "/pipeline/preprocessing",
    });
  };

  const viewBatchDetail = (batchId: number) => {
    dispatch(setSelectedBatch(batchId, +match.params.id));
    history.push({
      pathname: "/pipeline/preprocessing",
    });
  };

  const onBatchFilterApplied = (filter: Array<any>) => {
    dispatch(changeBatchSetting(filter, batchSetting.page));
  };

  const onBatchPageChanged = (page: number) => {
    dispatch(changeBatchSetting(batchSetting.filter, page));
  };

  const handleArchive = (batchId: number) => {
    swal({
      buttons: [true, true],
      title: "Are you sure?",
      text: "This process is permanent and cannot be undone",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete: boolean | null) => {
      if (willDelete) {
        dispatch(
          archiveBatch(batchId, match.params.id, () => {
            setReload(!reload);
          })
        );
      }
    });
  };

  const onModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const tooltipText = (): IBatchPreprocDocs => {
    const batchDocs = batchPreprocDocs.find(
      (key) => key.key === PREPROCESSING_ACTION_COLUMN
    );
    return batchDocs ? batchDocs : DefaultBatchPreprocDocs;
  };

  useEffect(() => {
    // reset pipeline every user visit project page
    dispatch(resetPipeline());

    dispatch(getOneProject(match.params.id, true));
    dispatch(setPipelineType("preprocessing"));
    dispatch(getBatch(match.params.id));
  }, [reload]);

  return (
    <RequestWrapperView
      isError={isError}
      isLoading={isLoading}
      message={message}
    >
      <div className="container-project styled-tab-blue">
        <Breadcrumb tag="nav">
          <BreadcrumbItem tag="a" href="#/projects/preprocessing">
            Projects
          </BreadcrumbItem>
          <BreadcrumbItem active tag="span">
            {selected_project.name}
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="long-card-detail">
          <div className="right">
            <h4>{selected_project.name}</h4>
            <p>{selected_project.description}</p>
            <p>
              Start Date:{" "}
              {moment
                .unix(selected_project.createdAt as number)
                .format("DD MMMM YYYY h:mm:ss")}
            </p>
          </div>
        </div>
        {/* <Link to={`/pipeline/preprocessing?project=${match.params.id}`}> */}
        {/* </Link> */}
        <Row style={{ marginBottom: "16px" }}>
          <Col sm={12}>
            <button className="pull-right btn-primary" onClick={newBatch}>
              Create new batch
            </button>
          </Col>
        </Row>
        <div className="card">
          <ReactTable
            data={allBatch}
            filterable
            onPageChange={onBatchPageChanged}
            page={batchSetting.page}
            onFilteredChange={onBatchFilterApplied}
            defaultFiltered={batchSetting.filter}
            columns={[
              {
                Header: "Batch Name",
                accessor: "name",
                minWidth: 120,
                filterMethod: (filter: Filter, rows: any) =>
                  matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true,
              },
              {
                Header: "Progress",
                accessor: "currentStep",
                minWidth: 120,
                Cell: (row) => (
                  <span>
                    {row.row.currentStep} / {row.row.totalStep}
                  </span>
                ),
                filterMethod: (filter: Filter, rows: any) =>
                  matchSorter(rows, filter.value, { keys: ["currentStep"] }),
                filterAll: true,
              },
              {
                Header: "Total Step",
                accessor: "totalStep",
                show: false,
              },
              {
                Header: "Status",
                accessor: "status",
                minWidth: 100,
                Cell: (row) => (
                  <p className="text-left text-primary-immuno">{row.value}</p>
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  }
                  return row[filter.id] == filter.value;
                },
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: "-2px",
                    }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="all">Show All</option>
                    <option value="in_progress">In Progress</option>
                    <option value="waiting_for_user">Waiting for User</option>
                    <option value="completed">Completed</option>
                  </select>
                ),
              },
              {
                Header: (
                  <div>
                    <UncontrolledTooltip
                      autohide={false}
                      placement="top"
                      target="action"
                    >
                      {tooltipText().tooltip}
                    </UncontrolledTooltip>
                    <span className="steps-header-item number">
                      <i
                        id="action"
                        className="fa fa-question-circle form-tooltip mr-2"
                        onClick={onModalToggle}
                      ></i>
                    </span>
                    <span>Action</span>
                  </div>
                ),
                accessor: "isActive",
                minWidth: 120,
                Cell: (row) => {
                  return (
                    <div className="action-wrapper">
                      {row.row.isActive ? (
                        <button
                          className="btn-danger m-0 p-0 pl-2 pr-2 delete-action"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleArchive(row.original.id);
                          }}
                        >
                          <i className="fa fa-archive read-action" /> Archive
                        </button>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  );
                },
                Filter: () => <span></span>,
              },
            ]}
            defaultPageSize={10}
            defaultSorted={[{ id: "createdAt", desc: true }]}
            className="-striped -highlight table-immuno table-pipeline"
            getTheadFilterThProps={() => {
              return { style: { position: "inherit", overflow: "inherit" } };
            }}
            getTrProps={(state: any, rowInfo: any) => {
              return {
                className: rowInfo !== undefined ? "" : " none",
                onClick: () => {
                  if (rowInfo !== undefined) {
                    viewBatchDetail(rowInfo.original.id);
                  }
                },
              };
            }}
          />
        </div>
        <Modal
          isOpen={isModalOpen}
          toggle={onModalToggle}
          className="modal-lg modal-pipeline-tips"
        >
          <ModalHeader toggle={onModalToggle}>Action</ModalHeader>
          <ModalBody>
            <DocumentationItem
              className="modal-body-inner"
              data={{
                text: tooltipText().description,
                images: tooltipText().images,
                list: tooltipText().list,
              }}
              isImageScrollable
            />
          </ModalBody>
        </Modal>
      </div>
    </RequestWrapperView>
  );
};

export default ProjectPreprocessing;
