import React from 'react';
import { Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { updateInput } from '../../../actions/action.pipeline-input';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { INPUT_SMALL } from '../../../utils/constants';
import InputCustom from '../../../components/Form/InputCustom';

const gating = [
    { name: 'Define Gates', isShiny: true },
    { name: 'Apply Gates', isShiny: false },
];

const ManualGating = () => {
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced } = pipeline;

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateInput(e.target.name, e.target.value));
    }

    return (
        <div className="bead-normalization">
            <h4 className="subtitle">Bead Normalization</h4>
            <div className="pipeline-form">
                <FormGroupCustom
                    pipeline="beadnormalization"
                    id="inputbeadnormalization"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            accept={['fcs', '.fcs']}
                            isMultiple
                        />
                    </Col>
                </FormGroupCustom>

                {/* windowSize */}
                <FormGroupCustom
                    pipeline="beadnormalization"
                    id="windowsizebeadnormalization"
                    input={input.windowSize}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="windowSize"
                            type="number" step="any" min="10" max="400"
                            onChange={handleInput} value={input.windowSize}
                        />
                    </Col>
                </FormGroupCustom>

                {/* normalized */}
                <FormGroupCustom
                    pipeline="beadnormalization"
                    id="normalizedbeadnormalization"
                    input={input.normalized}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='normalized'
                            tray={input.normalized}
                            accept={['fcs', '.fcs']}
                            isMultiple
                        />
                    </Col>
                </FormGroupCustom>

                {/* beadChannels */}
                <FormGroupCustom
                    pipeline="beadnormalization"
                    id="beadchannelsbeadnormalization"
                    input={input.beadChannels}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select" name="beadChannels"
                            onChange={handleInput} value={input.beadChannels} >
                            <option value="" disabled>Select Bead Channels</option>
                            <option value="89,115,140,159,175,209">89, 115, 140, 159, 175, 209 (EQ6)</option>
                            <option value="140,151,153,165,175">140, 151, 153, 165, 175</option>
                        </InputCustom>
                    </Col>
                </FormGroupCustom>
            </div>
        </div>
    );
}

export default ManualGating;
