import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Col, Input } from "reactstrap";
import { updateInput } from '../../../actions/action.pipeline-input';
import FileSelector from "../../../components/FileSelector/FileSelector";
import FormGroupCustom from "../../../components/Form/FormGroupCustom";
import { TDispatch } from '../../../types/action';
import { IStore } from "../../../types/store";
import { IPipeline, TPipeline } from "../../../types/type.pipeline";
import { INPUT_SMALL } from "../../../utils/constants";

const Pyir = () => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const { input, isAdvanced } = pipeline;
    const extensions = ['fasta', '.fasta', 'fa', '.fa', 'seq', '.seq'];
    const dispatch = useDispatch<TDispatch<TPipeline>>();

    const dispatchInput = (key: string, value: any) => dispatch(updateInput(key, value));

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        let value = e.target.value;

        dispatchInput(name, value);
    }

    return (
        <div className="pyir">
            <h4 className="subtitle">PyIR</h4>
            <div className="pipeline-form">
                {/* sequences */}
                <FormGroupCustom
                    pipeline="pyir"
                    id="inputpyir"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name="input"
                            tray={input.input}
                            accept={extensions}
                            isMultiple={true}
                            mixedExtension
                            // validateFilename={filterPattern}
                        />
                    </Col>
                </FormGroupCustom>

                {/* prefix */}
                <FormGroupCustom
                    pipeline="pyir"
                    id="prefixpyir"
                    input={input.prefix}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Input
                            name="prefix"
                            id="pyir_prefix"
                            onChange={handleInput}
                            value={input.prefix}
                        />
                    </Col>
                </FormGroupCustom>
            </div>
        </div>
    )
}

export default Pyir;