import React from 'react';
import { Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import FileSelector from '../../../components/FileSelector/FileSelector';
import { IPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { INPUT_SMALL } from '../../../utils/constants';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';

const ManualGating = () => {
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const { input, isAdvanced } = pipeline;

    return (
        <TabbedMetaContainer
            name="Manual Gating"
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.input)}
            containerClass="manual-gating"
            metamarker={!_.isEmpty(input.input) ? (
                <FormGroupCustom
                    pipeline="manualgating"
                    id="metamarkermanualgating"
                    input={input.metaMarker}
                    isAdvanced={isAdvanced}
                    row
                >
                    <Col sm={INPUT_SMALL}>
                        {(input.protein && input.protein.length > 0) ? (
                            <ProteinSelector
                                columns={['keep']}
                                protein={input.protein}
                                tray={input.metaMarker}
                                trayName="metaMarker"
                            />
                        ) : (
                            <FileSelector
                                name="metaMarker"
                                tray={input.metaMarker}
                                accept={['csv', '.csv']}
                            />
                        )}
                    </Col>
                </FormGroupCustom>
            ) : undefined}
        >
            <div className="pipeline-form">
                <FormGroupCustom
                    pipeline="manualgating"
                    id="inputmanualgating"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            accept={['fcs', '.fcs']}
                            checkFcsMetadata
                            isMultiple
                            getPreviousPipelineOutput={true}
                            isShowPreviousPipeline
                        />
                    </Col>
                </FormGroupCustom>
            </div>
        </TabbedMetaContainer>
    );
}

export default ManualGating;
