import { PREPROCESSING_ACTION_COLUMN } from "../../utils/constants";

export interface IBatchPreprocDocs {
    key: string,
    tooltip: string,
    description: string,
    images?: Array<{
        text: string,
        url: string,
    }>,
    list?: {
        text: string,
        items: Array<string>
    },
}

export const DefaultBatchPreprocDocs = {
    key: '',
    tooltip: '',
    description: '',
}

export const batchPreprocDocs: Array<IBatchPreprocDocs> = [
    {
        key: PREPROCESSING_ACTION_COLUMN,
        tooltip: 'Archive a preprocessing Batch',
        description: 'Archiving of a pre-processing batch. This action can not be undone by the user. Please contact a cytographer admin in such a case. Also all executions of a archived pre-processing batch are still visible in the usual result table.',
    },
]