// version check
export const GET_VERSION = 'GET_VERSION';

// reset-password
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const FORGET_PASS_REQUEST = 'FORGET_PASS_REQUEST';
export const FORGET_PASS_SUCCESS = 'FORGET_PASS_SUCCESS';
export const FORGET_PASS_FAILURE = 'FORGET_PASS_FAILURE';

export const FORGET_PASS_RESET_REQUEST = 'FORGET_PASS_RESET_REQUEST';
export const FORGET_PASS_RESET_SUCCESS = 'FORGET_PASS_RESET_SUCCESS';
export const FORGET_PASS_RESET_FAILURE = 'FORGET_PASS_RESET_FAILURE';

// LOGIN
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// DASHBOARD
export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

// USER PROFILE
export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

export const RESET_WELCOME = 'RESET_WELCOME';

// PROJECTS
export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';

export const GET_ONE_PROJECT_REQUEST = 'GET_ONE_PROJECT_REQUEST';
export const GET_ONE_PROJECT_SUCCESS = 'GET_ONE_PROJECT_SUCCESS';
export const GET_ONE_PROJECT_FAILURE = 'GET_ONE_PROJECT_FAILURE';


export const UPDATE_PROJECTS_REQUEST = 'UPDATE_PROJECTS_REQUEST';
export const UPDATE_PROJECTS_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECTS_FAILURE = 'UPDATE_PROJECTS_FAILURE';

// File explorer in the project
export const GET_PROJECT_FILES_SUCCESS = 'GET_PROJECT_FILES_SUCCESS';
export const GET_PROJECT_FILES_FAILED = 'GET_PROJECT_FILES_FAILED';
export const UPLOAD_PROJECT_FILE_SUCCESS = 'UPLOAD_PROJECT_FILE_SUCCESS';
export const UPLOAD_PROJECT_FILE_FAILED = 'UPLOAD_PROJECT_FILE_FAILED';
export const CREATE_PROJECT_FILE_FOLDER_SUCCESS = 'CREATE_PROJECT_FILE_FOLDER_SUCCESS';
export const CREATE_PROJECT_FILE_FOLDER_FAILED = 'CREATE_PROJECT_FILE_FOLDER_FAILED';
export const CHANGE_PROJECT_FILE_S3PREFIX_SUCCESS = 'CHANGE_PROJECT_FILE_S3PREFIX';
export const RESET_FILE_BROWSER = 'RESET_FILE_BROWSER';
export const SELECT_PROJECT_FILES_SUCCESS = 'SELECT_PROJECT_FILES_SUCCESS';

// File explorer in the pipeline
export const SELECT_ONE_FILE = 'SELECT_ONE_FILE';

// File tray
export const TRAY_RESET = 'TRAY_RESET';
export const TRAY_PUT_FILE = 'TRAY_PUT_FILE';
export const TRAY_REMOVE_FILE = 'TRAY_REMOVE_FILE';

// s3 operations
export const S3_GET_FILES_REQUEST = 'S3_GET_FILES_REQUEST';
export const S3_GET_FILES_SUCCESS = 'S3_GET_FILES_SUCCESS';
export const S3_FILES_RESET = 'S3_FILES_RESET';
export const S3_GET_FILES_FAILED = 'S3_GET_FILES_FAILED';
export const S3_CHANGE_BUCKET = 'S3_CHANGE_BUCKET';
export const S3_SELECT_FOLDER = 'S3_SELECT_FOLDER';
export const S3_DELETE_FOLDER = 'S3_DELETE_FOLDER';
export const S3_SELECT_FILE = 'S3_SELECT_FILE';
export const S3_DELETE_FILE = 'S3_DELETE_FILE';
export const S3_ADD_FILE = 'S3_ADD_FILE';
export const S3_UPLOAD_START = 'S3_UPLOAD_START';
export const S3_UPLOAD_PROGRESSING = 'S3_UPLOAD_PROGRESSING';
export const S3_UPLOAD_END = 'S3_UPLOAD_END';
export const S3_RENAME_FILE = 'S3_RENAME_FILE';
export const S3_VALIDATE_PATH = 'S3_VALIDATE_PATH';

// ARCHIVE
export const ARCHIVE_REQUEST = 'ARCHIVE_REQUEST';
export const ARCHIVE_RESULT = 'ARCHIVE_RESULT';

// SAMPLES
export const GET_SAMPLES_REQUEST = 'GET_SAMPLES_REQUEST';
export const GET_SAMPLES_SUCCESS = 'GET_SAMPLES_SUCCESS';
export const GET_SAMPLES_FAILURE = 'GET_SAMPLES_FAILURE';

export const ADD_SAMPLES_REQUEST = 'ADD_SAMPLES_REQUEST';
export const ADD_SAMPLES_SUCCESS = 'ADD_SAMPLES_SUCCESS';
export const ADD_SAMPLES_FAILURE = 'ADD_SAMPLES_FAILURE';

// SAMPLES SETS
export const GET_SAMPLESETS_REQUEST = 'GET_SAMPLESETS_REQUEST';
export const GET_SAMPLESETS_SUCCESS = 'GET_SAMPLESETS_SUCCESS';
export const GET_SAMPLESETS_FAILURE = 'GET_SAMPLESETS_FAILURE';

export const CREATE_SAMPLESETS_REQUEST = 'CREATE_SAMPLESETS_REQUEST';
export const CREATE_SAMPLESETS_SUCCESS = 'CREATE_SAMPLESETS_SUCCESS';
export const CREATE_SAMPLESETS_FAILURE = 'CREATE_SAMPLESETS_FAILURE';

// FCS
export const CREATE_FCS_REQUEST = 'CREATE_FCS_REQUEST';
export const CREATE_FCS_SUCCESS = 'CREATE_FCS_SUCCESS';
export const CREATE_FCS_FAILURE = 'CREATE_FCS_FAILURE';

export const GET_FCS_REQUEST = 'GET_FCS_REQUEST';
export const GET_FCS_SUCCESS = 'GET_FCS_SUCCESS';
export const GET_FCS_FAILURE = 'GET_FCS_FAILURE';

// PIPELINE
export const GET_PIPELINE_REQUEST = 'GET_PIPELINE_REQUEST';
export const GET_PIPELINE_SUCCESS = 'GET_PIPELINE_SUCCESS';
export const GET_PIPELINE_FAILURE = 'GET_PIPELINE_FAILURE';

export const RUN_PIPELINE_REQUEST = 'RUN_PIPELINE_REQUEST';
export const RUN_PIPELINE_SUCCESS = 'RUN_PIPELINE_SUCCESS';
export const RUN_PIPELINE_FAILURE = 'RUN_PIPELINE_FAILURE';

export const PIPELINE_SELECT_PROJECT = 'SELECT_PIPELINE_PROJECT';
export const PIPELINE_CHANGE_INPUT = 'CHANGE_PIPELINE_INPUT';
export const PIPELINE_CHANGE_METADATA = 'CHANGE_PIPELINE_METADATA';
export const PIPELINE_RESET_INPUT = 'RESET_PIPELINE_INPUT';
export const PIPELINE_ADD_FILE = 'PIPELINE_ADD_FILE';
export const PIPELINE_REMOVE_FILE = 'PIPELINE_REMOVE_FILE';
export const PIPELINE_CLONE = 'PIPELINE_CLONE';
export const PIPELINE_CHANGE_ALL_METADATA = 'PIPELINE_CHANGE_ALL_METADATA';

export const PIPELINE_TETRAMER_SELECT_SAV = 'PIPELINE_TETRAMER_SELECT_SAV';
export const PIPELINE_SELECT_NAMEFILE_ITEM = 'PIPELINE_SELECT_NAMEFILE_ITEM';
export const PIPELINE_GET_PREVIOUS_OUTPUTS = 'PIPELINE_GET_PREVIOUS_OUTPUTSSS';
export const PIPELINE_ADD_OUTPUT_FILES_TO_TRAY = 'PIPELINE_ADD_OUTPUT_FILES_TO_TRAY';
export const PIPELINE_SELECT_NAMEFILE_BULK = 'PIPELINE_SELECT_NAMEFILE_BULK';

// EXECUTION
export const GET_EXECUTION_REQUEST = 'GET_EXECUTION_REQUEST';
export const GET_EXECUTION_SUCCESS = 'GET_EXECUTION_SUCCESS';
export const GET_EXECUTION_FAILURE = 'GET_EXECUTION_FAILURE';

export const GET_ONE_EXECUTION_REQUEST = 'GET_ONE_EXECUTION_REQUEST';
export const GET_ONE_EXECUTION_SUCCESS = 'GET_ONE_EXECUTION_SUCCESS';
export const GET_ONE_EXECUTION_FAILURE = 'GET_ONE_EXECUTION_FAILURE';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';


// REPORT
export const ADD_REPORT_REQUEST = 'ADD_REPORT_REQUEST';
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS';
export const ADD_REPORT_FAILURE = 'ADD_REPORT_FAILURE';


// HELP
export const CREATE_HELP_REQUEST = 'CREATE_HELP_REQUEST';
export const CREATE_HELP_SUCCESS = 'CREATE_HELP_SUCCESS';
export const CREATE_HELP_FAILURE = 'CREATE_HELP_FAILURE';

// NOTIFICATION
export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

export const READ_NOTIFICATION_REQUEST = 'READ_NOTIFICATION_REQUEST';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAILURE = 'READ_NOTIFICATION_FAILURE';

export const READ_ALL_NOTIFICATION_REQUEST = 'READ_ALL_NOTIFICATION_REQUEST';
export const READ_ALL_NOTIFICATION_SUCCESS = 'READ_ALL_NOTIFICATION_SUCCESS';
export const READ_ALL_NOTIFICATION_FAILURE = 'READ_ALL_NOTIFICATION_FAILURE';

export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

export const DELETE_ALL_NOTIFICATION_REQUEST = 'DELETE_ALL_NOTIFICATION_REQUEST';
export const DELETE_ALL_NOTIFICATION_SUCCESS = 'DELETE_ALL_NOTIFICATION_SUCCESS';
export const DELETE_ALL_NOTIFICATION_FAILURE = 'DELETE_ALL_NOTIFICATION_FAILURE';

// PROFILE
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const ERROR = 'ERROR';

// VISIBLE PIPELINE
export const GET_VISIBLE_PIPELINE_REQUEST = 'GET_VISIBLE_PIPELINE_REQUEST';
export const GET_VISIBLE_PIPELINE_SUCCESS = 'GET_VISIBLE_PIPELINE_SUCCESS';
export const GET_VISIBLE_PIPELINE_FAILURE = 'GET_VISIBLE_PIPELINE_FAILURE';
export const PIPELINE_TAB_CHANGED = 'PIPELINE_TAB_CHANGED';

// TOGGLE ADVANCED PARAMS
export const TOGGLE_ADVANCED_PARAMS = 'TOGGLE_ADVANCED_PARAMS';

// EXCLUDE PIPELINE
export const PIPELINE_CHANGE_EXCLUDE = 'PIPELINE_CHANGE_EXCLUDE';

// UI HISTORY 
export const SET_SELECTED_PIPELINE_TAB = 'SET_SELECTED_PIPELINE_TAB';
export const SET_SELECTED_MENU_TAB = 'SET_SELECTED_MENU_TAB';
export const SET_PIPELINE_SETTING = 'SET_PIPELINE_SETTING';
export const SET_REPORT_SETTING = 'SET_REPORT_SETTING';
export const RESET_UI_SETTING = 'RESET_UI_SETTING';
export const SET_PIPELINE_TYPE = 'SET_PIPELINE_TYPE';
export const SET_BATCH_SETTING = 'SET_BATCH_SETTING';

// BATCH
export const GET_BATCH_REQUEST = 'GET_BATCH_REQUEST';
export const GET_BATCH_SUCCESS = 'GET_BATCH_SUCCESS';
export const GET_BATCH_FAILURE = 'GET_BATCH_FAILURE';

export const GET_ONE_BATCH_REQUEST = 'GET_ONE_BATCH_REQUEST';
export const GET_ONE_BATCH_SUCCESS = 'GET_ONE_BATCH_SUCCESS';
export const GET_ONE_BATCH_FAILURE = 'GET_ONE_BATCH_FAILURE';

export const UPDATE_BATCH_REQUEST = 'UPDATE_BATCH_REQUEST';
export const UPDATE_BATCH_SUCCESS = 'UPDATE_BATCH_SUCCESS';
export const UPDATE_BATCH_FAILURE = 'UPDATE_BATCH_FAILURE';

// COORDINATES FILES
export const GET_COORDINATES_FILES_REQUEST = 'GET_COORDINATES_FILES_REQUEST';
export const GET_COORDINATES_FILES_SUCCESS = 'GET_COORDINATES_FILES_SUCCESS';
export const GET_COORDINATES_FILES_FAILURE = 'GET_COORDINATES_FILES_FAILURE';

export const SET_COORDINATES_FILES_UUID = 'SET_COORDINATES_FILES_UUID'
export const UPLOAD_COORDINATES_FILES = 'UPLOAD_COORDINATES_FILES'
export const REMOVE_COORDINATES_FILES = 'REMOVE_COORDINATES_FILES'

// PEPTIDES ANNOTATION
export const GET_PEPTIDES_ANNOTATION_SEQUENCE_REQUEST = 'GET_PEPTIDES_ANNOTATION_SEQUENCE_REQUEST';
export const GET_PEPTIDES_ANNOTATION_SEQUENCE_SUCCESS = 'GET_PEPTIDES_ANNOTATION_SEQUENCE_SUCCESS';
export const GET_PEPTIDES_ANNOTATION_SEQUENCE_FAILURE = 'GET_PEPTIDES_ANNOTATION_SEQUENCE_FAILURE';