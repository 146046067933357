import React, { createRef, useEffect, useRef, useState } from 'react';
import ReactTable from 'react-table';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import matchSorter from 'match-sorter';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import _ from 'lodash';

import { getNotification, readNotification, readAllNotification, deleteAllNotification, deleteNotification } from '../../actions/action.notification';
import { Link } from 'react-router-dom';
import { INotification, INotificationDetail, TNotification } from '../../types/type.notification';
import { TDispatch } from '../../types/action';
import { IStore } from '../../types/store';
import RequestWrapperView from '../../components/Views/RequestWrapperView';
import { IProject } from '../../types/type.project';

interface IProps {
    history: any
}

const NotificationTable = ({ history } : IProps) => {
    const pipelineType: string = useSelector((state : IStore) => state.uiHistory.pipelineType);
    const [visibleData, setVisibleData] = useState<INotificationDetail[]>([]);

    const notification: INotification = useSelector((state : IStore) => state.notification);
    const project: IProject = useSelector((state : IStore) => state.projects.selected_project);
    const dispatch = useDispatch<TDispatch<TNotification>>();
    const { isLoading, isError, data } = notification;
    const projectId = !_.isNull(project.id) ? project.id : '';

    const markRead = (id : number) =>  {
        dispatch(readNotification(id));
    }

    const readAllNotif = () => {
        if (visibleData.length > 0) {
            dispatch(readAllNotification());
        }
    }

    const deleteOneNotification = (id: number) => {
        dispatch(deleteNotification(id));
    }

    const deleteAllNotifications = () => {
		// @ts-ignore
		swal({
			title: "Are you sure?",
			text: "This process is permanent and cannot be undone",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
		.then((willDelete: boolean | null) => {
			if (willDelete) {
                if (visibleData.length > 0) {
                    const ids = _.map(visibleData, 'id');
                    dispatch(deleteAllNotification(ids));
                }
			}
		});
    }

    useEffect(() => {
        dispatch(getNotification(true));
    }, []);

    const tableRef = createRef<ReactTable<any>>();

    const onTableChanged = () => {
        const current: any = tableRef.current;
        if (!current && !_.isNull(current)) {
            return;
        }
        if (_.isNull(current)) {
            const sortedData = _.sortBy(notification.data, ['createdAt']).reverse();
            const data = sortedData.slice(0, 10);
            setVisibleData(data);
            return;
        }

        const page = current.state.page;
        const pageSize = current.state.pageSize;
        const allData = current.getResolvedState().sortedData;
        const startIdx = page * pageSize;
        const currentData = allData.slice(startIdx, startIdx + pageSize).map((item) => item._original);
        setVisibleData(currentData);
    }

    return (
        <RequestWrapperView isLoading={isLoading} isError={isError}>
            <div className="animated fadeIn">
                <div className="container-notification table-responsive">
                    <Breadcrumb tag="nav">
                        <BreadcrumbItem active tag="span">Notifications</BreadcrumbItem>
                    </Breadcrumb>

                    <div className="buttons">
                        <Link to={`/projects/${pipelineType}/${projectId}`}>
                            <button className="btn-primary" type="button">
                                <i className="fa fa-arrow-left"></i> Return to Dashboard
                            </button>
                        </Link>
                    </div>

                    <ReactTable
                        ref={tableRef}
                        resolveData={(data) => {
                            onTableChanged();
                            return data;
                        }}
                        onPageChange={onTableChanged}
                        onPageSizeChange={onTableChanged}
                        onSortedChange={onTableChanged}
                        onExpandedChange={onTableChanged} 
                        onFilteredChange={onTableChanged}
                        onResizedChange={onTableChanged}
                        data={notification.data}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        filterable
                        columns={[
                            {
                                Header: "Timestamp",
                                accessor: "createdAt",
                                minWidth: 70,
                                Filter: ({filter, onChange}) => (
                                    <DatePicker
                                        selected={filter ? moment.unix(filter.value / 1000).toDate() : moment().toDate()}
                                        onChange={event => onChange(event)}
                                        dateFormat="dd MMM yyyy"
                                    />
                                ),
                                filterMethod: (filter, row) => {
                                    if (filter.value === null) { return true }
                                    return moment.unix(row[filter.id]).format('DD MMM YYYY') === moment(filter.value).format('DD MMM YYYY');
                                },
                                Cell: row => <div>{moment.unix(row.value).format('DD MMM YYYY')}</div>,
                            },{
                                Header: "Message",
                                accessor: "message",
                                minWidth: 200,
                                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["message"] }),
                                filterAll: true
                            }, {
                                Header: "Action",
                                accessor: "read",
                                minWidth: 100,
                                // filterable: false,
                                Cell: (row) => {
                                    return(
                                        <div className="action-wrapper">
                                            {/* <Link to={`notification/detail/${row.original.id}`}>
                                                <span className="view-action">View detail</span>
                                            </Link>
                                            <div className="action-spacer" /> */}
                                            {
                                                !row.value ? (
                                                    <span className="read-action" onClick={()=>markRead(row.original.id)}>Mark as read</span>
                                                ):(
                                                    <span className="read-placeholder" />
                                                )
                                            }
                                            {
                                                <span
                                                    className="delete-action"
                                                    onClick={()=>deleteOneNotification(row.original.id)}>
                                                    Delete
                                                </span>
                                            }
                                        </div>
                                    );
                                },
                                Filter: ({filter, onChange}) => {
                                    const items = !_.isEmpty(notification.data) ? (
                                        <div className="read-all">
                                            <span className="read-action" onClick={readAllNotif}>Mark all as read</span>
                                            <span className="delete-action" onClick={deleteAllNotifications}>Delete all</span>
                                        </div>
                                    ) : (
                                        <div></div>
                                    );
                                    return items;
                                },
                            }
                        ]}
                        defaultPageSize={10}
                        defaultSorted={[{ id: "createdAt", desc: true }]}
                        className="card table-immuno -striped -highlight table-notif"
                        getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                        getTrProps={(state, rowInfo, column) => {
                            let read = rowInfo != undefined && rowInfo.row.read ? 'read':'no-read';
                            return {
                                className: rowInfo !== undefined ? read:read + ' none'
                            }
                        }} 
                        getTdProps={(state, rowInfo, column) => {
                            if (rowInfo) {
                                if (column.id == 'read') {
                                    return {};
                                }
                                return {
                                    className: 'clickable',
                                    onClick: () => {
                                        if (rowInfo !== undefined) {
                                            history.push(`/notification/detail/${rowInfo.original.id}`);
                                        }
                                    }
                                };
                            }
                            return {
                                title: rowInfo !== undefined ? rowInfo.row[column.id]:' none'
                            }
                        }}
                        NoDataComponent={() => (
                            <div className="table-data-empty">
                                <span>No notification right now.</span>
                            </div>
                        )}
                        showPagination={!_.isEmpty(notification.data)}
                    />
                </div>
            </div>
        </RequestWrapperView>
    );
}

export default NotificationTable;
