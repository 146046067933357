import initialState from "./initialState";
import * as types from './actionTypes';

export default function (state = initialState.peptideAnnotation, action) {
  switch (action.type) {
    case types.GET_PEPTIDES_ANNOTATION_SEQUENCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      }

    case types.GET_PEPTIDES_ANNOTATION_SEQUENCE_SUCCESS:
      return {
        ...state,
        sequence: action.data,
        isLoading: false
      }

    case types.GET_PEPTIDES_ANNOTATION_SEQUENCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      }

    default:
      return state;
  }
}