import { TAction } from "../types/action";
import { TPeptideAnnotation } from "../types/type.peptide-annotation";
import { GET } from "../utils/httprequest";
import { getVersion } from "./action.version-notification";

export const getSequence = (): TAction<TPeptideAnnotation> => {
  return (dispatch) => {
    dispatch({
      type: 'GET_PEPTIDES_ANNOTATION_SEQUENCE_REQUEST'
    });

    GET(`peptide-annotations/sequence`)
      .then((response) => {
        dispatch(getVersion(response.app_version));
        dispatch({
          type: 'GET_PEPTIDES_ANNOTATION_SEQUENCE_SUCCESS',
          data: response.data
        });
      })
      .catch((error) => {
        dispatch(getVersion(error.response.data.app_version));
        dispatch({
          type: 'GET_PEPTIDES_ANNOTATION_SEQUENCE_FAILURE',
          message: error.response.data.message
        });
      })
  }
}