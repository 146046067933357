
import * as types from '../reducers/actionTypes';
import { TAction } from '../types/action';
import { TUiHistory } from '../types/type.ui-history';

export const setSelectedPipelineTab = (id: string) : TAction<TUiHistory> => {
    return (dispatch) => {
        dispatch({
            type: types.SET_SELECTED_PIPELINE_TAB,
            selectedPipelineTab: id
        });
    }
}

export const setSelectedMenuTab = (id: string) : TAction<TUiHistory> => {
    return (dispatch) => {
        dispatch({
            type: types.SET_SELECTED_MENU_TAB,
            selectedMenuTab: id
        });
    }
}

export const changePipelineSetting = (filter: any, page: number) : TAction<TUiHistory> => {
    return (dispatch) => {
        dispatch({
            type: types.SET_PIPELINE_SETTING,
            pipelineSetting: { filter, page }
        });
    }
}

export const changeReportSetting = (filter: any, page: number) : TAction<TUiHistory> => {
    return (dispatch) => {
        dispatch({
            type: types.SET_REPORT_SETTING,
            reportSetting: { filter, page }
        });
    }
}

export const setPipelineType = (name: string) : TAction<TUiHistory> => {
    return (dispatch) => {
        dispatch({
            type: types.SET_PIPELINE_TYPE,
            pipelineType: name
        });
    }
}

export const resetUiSetting = () : TAction<TUiHistory> => {
    return (dispatch) => {
        dispatch({  type: types.RESET_UI_SETTING });
    }
}

export const changeBatchSetting = (filter: any, page: number) : TAction<TUiHistory> => {
    return (dispatch) => {
        dispatch({
            type: types.SET_BATCH_SETTING,
            batchSetting: { filter, page }
        });
    }
}