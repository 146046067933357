import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { useSelector } from 'react-redux';
import { IUser } from '../../types/type.user';
import { IStore } from '../../types/store';
import { IPipeline } from '../../types/type.pipeline';
import { RESULT_UPLOAD, URL2S3 } from './Form';

const Done = (props: any) => {
    const pipelineType: string = useSelector((state : IStore) => state.uiHistory.pipelineType);
    const user: IUser = useSelector((state : IStore) => state.user);
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const selectedExcution = useSelector((state : IStore) => state.execution.selected_execution);
    const projectId = pipeline.projectId || '';
    const { id, pipelineId } = selectedExcution;
    const showResultButton = [RESULT_UPLOAD, URL2S3]

    return (
        <div className="pipeline done">
            <Breadcrumb tag="nav">
                <BreadcrumbItem tag="a" href={`#/projects/${pipelineType}/${projectId}`}>Projects</BreadcrumbItem>
                <BreadcrumbItem tag="a" href="#/pipeline/form">Run Pipeline</BreadcrumbItem>
                <BreadcrumbItem active tag="span">Done</BreadcrumbItem>
            </Breadcrumb>
            <h1>Done <i className="fa fa-thumbs-o-up"></i></h1>
            <div className="card">
                <p>The pipeline session has been submitted. <br/>
                We will notify you at <span className="text-primary">{user.email}</span> once
                the session has finished running.</p>

                {showResultButton.includes(pipelineId) ? (
                    <Link to={`/pipeline/detail/${id}`} style={{marginTop:'20px'}}>
                        <button className="btn-primary" type="button">
                            <i className="fa fa-arrow-left"></i> Go to Results
                        </button>
                    </Link>
                ) : (
                    <Link to={`/projects/${pipelineType}/${projectId}`} style={{marginTop:'20px'}}>
                        <button className="btn-primary" type="button">
                            <i className="fa fa-arrow-left"></i> Return to Dashboard
                        </button>
                    </Link>
                )}
                <br/><br/><br/>
            </div>
        </div>
    )
}

export default Done;
