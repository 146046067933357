import _ from "lodash";
import swal from "sweetalert";
import * as types from "../reducers/actionTypes";
import { s3GetFiles, s3GetSequencingFiles } from "./action.s3";
import * as constants from "../utils/constants";
import { TAction } from "../types/action";
import { TS3 } from "../types/type.s3";
import { TPipeline, IMarker } from "../types/type.pipeline";
import { GET, POST } from "../utils/httprequest";
import { changePipelineTab } from "./action.visible-pipeline";
import { FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER } from "../utils/constants";
import { getVersion } from "./action.version-notification";

/**
 * When user select a folder in the FileSelector,
 * query S3 files
 *
 * @param {*} path
 * @returns
 */
export const selectFolderFileSelector =
  (path: string): TAction<TS3> =>
  (dispatch, getState) => {
    const projectId = getState().pipeline.selected_project_id;
    dispatch(s3GetFiles(projectId, path));
  };

/**
 * Get previous pipeline outputs
 * 1. Get from server
 * 2. Massage the data, and insert it into file workspace's reducer store
 */
export const getPreviousPipelineOutputs =
  (
    includePreprocessingResult: boolean = false,
    onlyPreprocessingResult: boolean = false,
    params: {
      pipelineId?: string;
      projectId?: string;
      preprocessingStep?: string;
    } = {}
  ): TAction<TS3> =>
  async (dispatch, getState) => {
    const { user, pipeline } = getState();
    // const { pipelineId, projectId } = pipeline;
    const pipelineId = params.pipelineId || pipeline.pipelineId;
    const projectId = params.projectId || pipeline.projectId;
    const tabId = pipeline.tabId ? pipeline.tabId : "0";
    const preprocessingStep = params.preprocessingStep;

    if (!pipelineId || !projectId) {
      console.log(
        "getPreviousPipelineOutputs: pipelineId, projectId or tabId is not defined"
      );

      return;
    }

    const getPreviousFileKey = (url: string): string => {
      let newUrl = url.replace("s3://", "");
      // remove bucket name and project name
      newUrl = newUrl.substring(
        _.indexOf(newUrl, "/", _.indexOf(newUrl, "/") + 1) + 1
      );
      return newUrl;
    };

    let url = `execution/previous-simple/${projectId}/${pipelineId}`;

    if (!onlyPreprocessingResult) {
      await GET(url, { params: { tabId, preprocessingStep } })
        .then((response) => {
          dispatch(getVersion(response.app_version));
          // for each result, store in the file workspace
          const data = response.data;
          const newFiles: Array<{
            key: string;
            s3Url: string;
            size: number;
            originalKey: string;
          }> = [];

          data.map((output) => {
            // foreach new files in the item
            output.files.map((file) => {
              const displayKey = output.batchName
                ? `${FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER}/Batch Sequence/${output.batchName}/${output.name} - ${output.id}/${file.name}`
                : `${FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER}/${output.type}/${output.name} - ${output.id}/${file.name}`;
              newFiles.push({
                key: displayKey,
                s3Url: file.s3Url,
                size: 1,
                originalKey: getPreviousFileKey(file.s3Url),
              });
            });
          });

          // remove previous pipeline outputs from the file list
          const { files = [] } = getState().s3;
          _.remove(files, (file) => {
            return (
              file.key.indexOf(
                constants.FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER
              ) === 0
            );
          });

          const allFiles = _.unionBy(files, newFiles, "key");
          dispatch({
            type: types.S3_GET_FILES_SUCCESS,
            data: allFiles,
          });
        })
        .catch((error) => {
          dispatch(getVersion(error.response.data.app_version));
          swal("Error", "Cannot fetch previous pipeline results.", "error");
        });
    }

    if (includePreprocessingResult) {
      await GET(
        `execution/previous-batch-preprocessing/${projectId}/${pipelineId}`,
        { params: { preprocessingStep } }
      )
        .then((response) => {
          dispatch(getVersion(response.app_version));
          // for each result, store in the file workspace
          const data = response.data;
          const newFiles: Array<{
            key: string;
            s3Url: string;
            size: number;
            originalKey: string;
          }> = [];

          data.map((output) => {
            // foreach new files in the item
            output.files.map((file) => {
              let displayKey = `${FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER}/${output.batchName}/${output.name} - ${output.id}/${file.name}`;

              if (output.executionId) {
                displayKey = `${FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER}/${output.batchName}/${output.name} - ${output.id}/${output.executionName} - ${output.executionId}/${file.name}`;
              }

              newFiles.push({
                key: displayKey,
                s3Url: file.s3Url,
                size: 1,
                originalKey: getPreviousFileKey(file.s3Url),
              });
            });
          });

          // remove previous pipeline outputs from the file list
          const { files } = getState().s3;
          if (onlyPreprocessingResult) {
            _.remove(files, (file) => {
              return (
                file.key.indexOf(
                  constants.FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER
                ) === 0
              );
            });
          }

          const allFiles = _.unionBy(files, newFiles, "key");
          dispatch({
            type: types.S3_GET_FILES_SUCCESS,
            data: allFiles,
          });
        })
        .catch((error) => {
          dispatch(getVersion(error.response.data.app_version));
          swal("Error", "Cannot fetch previous pipeline results.", "error");
        });
    }
  };

/**
 * The pipeline metadata is something like: session name,
 * pipelineId, projectId, comment, etc.
 *
 * @param {*} name
 * @param {*} value
 * @returns
 */
export const updateMetadata =
  (name: string, value: any): TAction<TPipeline> =>
  (dispatch) => {
    dispatch({
      type: types.PIPELINE_CHANGE_METADATA,
      name,
      value,
    });

    // reset pipeline input
    const metaParams = ["pipelineId"];
    if (_.includes(metaParams, name)) {
      dispatch({
        type: types.PIPELINE_RESET_INPUT,
        keep: [],
      });
      dispatch(getPreviousPipelineOutputs());
      dispatch(s3GetSequencingFiles());
    }
  };

/**
 * This function will handle input and write it to redux store
 * in state.pipeline.input
 *
 * @param {*} name
 * @param {*} value
 * @returns
 */
export const updateInput =
  (name: string, value: any): TAction<TPipeline> =>
  (dispatch) => {
    dispatch({
      type: types.PIPELINE_CHANGE_INPUT,
      name,
      value,
    });
  };

/**
 * Handle a sav selection in tetramer deconvolution
 */
export const tetramerSelectSav =
  (cd4: string, cd8: string): TAction<TPipeline> =>
  (dispatch) => {
    dispatch({
      type: types.PIPELINE_TETRAMER_SELECT_SAV,
      cd4,
      cd8,
    });
  };

export const selectProtein =
  (index: number, name: string): TAction<TPipeline> =>
  (dispatch) => {
    dispatch({
      type: types.PIPELINE_SELECT_NAMEFILE_ITEM,
      index,
      name,
      value: true,
    });
  };

export const deselectProtein =
  (index: number, name: string): TAction<TPipeline> =>
  (dispatch) => {
    dispatch({
      type: types.PIPELINE_SELECT_NAMEFILE_ITEM,
      index,
      name,
      value: false,
    });
  };

export const handleBulkProtein =
  (name: string, value: boolean): TAction<TPipeline> =>
  (dispatch) => {
    dispatch({
      type: types.PIPELINE_SELECT_NAMEFILE_BULK,
      name,
      value,
    });
  };

export const copyPreviousPipelineOutputToFileTray =
  (
    trayName: string,
    filePath: string,
    metadata: Array<IMarker>
  ): TAction<TPipeline> =>
  (dispatch) => {
    // reset the tray
    dispatch({
      type: types.PIPELINE_CHANGE_INPUT,
      name: trayName,
      value: [],
    });

    // add folder to the tray
    dispatch({
      type: types.PIPELINE_ADD_FILE,
      name: trayName,
      value: filePath,
    });

    // add metadata / names file
    dispatch({
      type: types.PIPELINE_CHANGE_INPUT,
      name: "protein",
      value: metadata,
    });
  };

export const clone = (): TAction<TPipeline> => (dispatch, getState) => {
  const {
    pipelineId,
    projectId,
    metaInput: input,
    isShiny,
    pipelineScript,
    tabId,
    id,
  } = getState().execution.selected_execution;
  dispatch(changePipelineTab(tabId || 1));
  dispatch(updateMetadata("pipelineId", pipelineId));

  dispatch(updateMetadata("isShiny", isShiny));
  dispatch(updateMetadata("cloneSource", id));

  // force pipelinescript it Manual gating (apply gates)
  if (pipelineId === 9 && !isShiny) {
    dispatch(updateMetadata("pipelineScript", 1));
  } else {
    dispatch(updateMetadata("pipelineScript", pipelineScript));
  }

  dispatch({
    type: types.PIPELINE_RESET_INPUT,
    keep: ["isShiny", "pipelineScript"],
  });

  dispatch({
    type: types.PIPELINE_CLONE,
    tabId: tabId,
    pipelineId: pipelineId!,
    projectId: projectId!,
    input,
  });

  dispatch(s3GetFiles(projectId!, ""));
  dispatch(getPreviousPipelineOutputs());
};

export const changeExcludedParams =
  (exclude: Array<string>): TAction<TPipeline> =>
  (dispatch) => {
    dispatch({
      type: types.PIPELINE_CHANGE_EXCLUDE,
      data: exclude,
    });
  };

/**
 * A helper function that judge whether the input should be submitted to API
 * return true or false
 */
export const isInputValid = (
  key: string,
  value: any,
  pipelineId: number | null = null
): boolean => {
  // remove input items that are null or undefined
  if (value === null || value === undefined || value === "") {
    return false;
  }
  // remove tray array that has 0 item
  if (Array.isArray(value) && value.length === 0) {
    return false;
  }

  return true;
};

export const toggleAdvancedParams = (): TAction<TPipeline> => (dispatch) => {
  dispatch({
    type: types.TOGGLE_ADVANCED_PARAMS,
  });
};

export const resetPipelineParams =
  (keep: Array<string> = []): TAction<TPipeline> =>
  (dispatch) => {
    dispatch({
      type: types.PIPELINE_RESET_INPUT,
      keep,
    });
  };

export const getGeneratedMetamarker =
  (onSuccess?: Function): TAction<TPipeline> =>
  (dispatch, getState) => {
    swal({
      title: "Please wait",
      text: "Ganerating metaMarker file.....",
      closeOnClickOutside: false,
      buttons: { visible: false },
    });
    const { pipeline, s3 } = getState();
    const { pipelineId, projectId, input } = pipeline;
    POST(`project/${projectId}/generate-namesfile/${pipelineId}`, {
      marker: input.protein,
    })
      .then((response) => {
        dispatch({
          type: types.PIPELINE_CHANGE_INPUT,
          name: "metaMarker",
          value: [response.s3Url],
        });
        swal.close!();
        onSuccess!();
      })
      .catch(() => {
        swal.close!();
        swal("Error", "Failed to generate metaMarker.", "error");
      });
  };

export const getGeneratedMetamsample =
  (onSuccess?: Function): TAction<TPipeline> =>
  (dispatch, getState) => {
    swal({
      title: "Please wait",
      text: "Ganerating MetaSample file.....",
      closeOnClickOutside: false,
      buttons: { visible: false },
    });
    const { pipeline } = getState();
    const { projectId, input } = pipeline;
    POST(`execution/generate-metasample-template/${projectId}`, {
      files: input.input,
    })
      .then((response) => {
        dispatch(getVersion(response.app_version));
        onSuccess!(response);
        swal.close!();
      })
      .catch((error) => {
        dispatch(getVersion(error.response.data.app_version));
        swal("Error", "Failed to generate MetaSample.", "error");
      });
  };

export const getMetasampleColNames =
  (onSuccess?: Function): TAction<TPipeline> =>
  (dispatch, getState) => {
    const { pipeline } = getState();
    const { projectId, input } = pipeline;
    GET(`project/get-metasample-col-names/${projectId}`, {
      params: { metasample: input.metaSample[0] },
    })
      .then((response) => {
        dispatch(getVersion(response.app_version));
        onSuccess!(response.data);
      })
      .catch((error) => {
        dispatch(getVersion(error.response.data.app_version));
        swal("Error", "Failed to Get Response.", "error");
      });
  };

export const getMetasampleRowNames = 
  (onSuccess?: Function): TAction<TPipeline> => 
    (dispatch, getState) => {
      const { pipeline } = getState();
      const { projectId, input } = pipeline;
      GET(`project/get-metasample-row-names/${projectId}`, {
        params:{ metasample: input.metaSample[0]},
    })
      .then((response) => {
        dispatch(getVersion(response.app_version));
        onSuccess!(response.data);
      })
      .catch((error) => {
        dispatch(getVersion(error.response.data.app_version));
        swal("Error", "Failed to Get Response.", "error");
      })
    };

export const getResultTypes = () => {
  return GET("result-types")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      swal("Error", "Failed to Get Response.", "error");
    });
};

export const updateAllMetadata =
  (metadata: any): TAction<TPipeline> =>
  (dispatch) => {
    dispatch({
      type: types.PIPELINE_CHANGE_ALL_METADATA,
      metadata,
    });
  };
