import React, { useEffect, useState, createRef } from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import {
    Input, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody
} from 'reactstrap';
// import moment from 'moment';
import _ from 'lodash';

import { IDocument } from '../../types/type.template';
import { IStore } from '../../types/store';
import { IProfile } from '../../types/type.user';
import DocumentationItem from '../../components/Views/DocumentationItem';
import { docs } from './Documents';

const filteredDocs = _.cloneDeep(docs);
_.remove(filteredDocs, (pipeline) => pipeline.isHidden);
_.map(filteredDocs, (pipeline) => _.remove(pipeline.parameters, (params) => params.isHidden))

const Documentation = () => {
    const profile: IProfile = useSelector((state : IStore) => state.user.profile);

    const [isFilter, setIsFilter] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const [filteredData, setFilteredData] = useState<Array<IDocument>>([]);
    const [data] = useState<Array<IDocument>>(filteredDocs);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalImage, setModalImage] = useState<string>('');

    const typeRefs: Array<any> = [];

    const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value == '') {
            setIsFilter(false);
            setFilter('');
            setFilteredData([]);
        } else {
            setIsFilter(true);
            setFilter(e.target.value);
            filterData(e.target.value);
        }
    }

    const filterData = (keyword: string) => {
        keyword = keyword.replace(/\\/g, "\\\\");
        let filteredData : Array<IDocument> = [];
        let regex = new RegExp((keyword), "gi");

        for (let i in data) {
            // check pipeline title/key contain keyword. if not, check the params
            let parent = _.cloneDeep(data[i]);
            if (regex.test(parent.key) || regex.test(parent.title)) {
                filteredData.push(parent);
            } else {
                parent.parameters = [];
                for (let p of data[i].parameters) {
                    if (regex.test(p.key) || regex.test(p.title) || regex.test(p.text)) {
                        // check title/description(the property is text)
                        parent.parameters.push(p);
                        filteredData = _.unionBy(filteredData, [parent], 'key');
                    } else if (!_.isEmpty(p.images)) {
                        // check image
                        for (let i of p.images!) {
                            if (regex.test(i.text)) {
                                parent.parameters.push(p);
                                filteredData = _.unionBy(filteredData, [parent], 'key');
                                break;
                            }
                        }
                    } else if (p.list) {
                        // check list
                        if (regex.test(p.list.text)) {
                            parent.parameters.push(p);
                            filteredData = _.unionBy(filteredData, [parent], 'key');
                        } else {
                            // check list items
                            for (let l of p.list.items) {
                                if (regex.test(l)) {
                                    parent.parameters.push(p);
                                    filteredData = _.unionBy(filteredData, [parent], 'key');
                                    continue;
                                }
                            }
                        }
                    }
                }
            }
        }
        setFilteredData(filteredData);
    }

    const onImageClick = (url: string) => {
        setModalImage(url);
        onModalToggle();
    }

    const onModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    }

    useEffect(() => {
        let hash = window.location.hash.replace('#/documentation', '');
        if (hash !== '') {
            let refs = hash.substring(1);
            const refObject = typeRefs[refs];
            if (!refObject) return;
            const node = refObject.current;
            if (node) {
                // window.scrollTo(-50, node.offsetTop);
                // node.scrollIntoView({ 
                //     behavior: 'smooth' ,
                //     block: 'start',
                //     inline: 'nearest'
                // });
                node.scrollIntoView(true);
                window.scrollBy(0, -50)
            }
        }
    }, []);

    const docsData = isFilter ? filteredData : data;

    return (
        <div className="animated fadeIn">
            <div className="container-documentation container-project">
                <Breadcrumb tag="nav">
                    <BreadcrumbItem active tag="span">Documentation</BreadcrumbItem>
                </Breadcrumb>
                <div className="card">
                    <Input type="text" className="search" placeholder="Filter" onChange={handleFilter} value={filter} />
                    {
                        docsData.map((d: any) => {
                            typeRefs[d.key] = createRef();
                            const isPipelineAllowed = _.includes(profile.visiblePipelines, d.id);
                            if (!isPipelineAllowed) {
                                return null;
                            }
                            return (
                                <div className="section" key={d.key}>
                                    <h2 ref={typeRefs[d.key]}>{d.title}</h2>
                                    {
                                        d.parameters.map((p: any) => {
                                            typeRefs[`${d.key}_${p.key}`] = createRef();
                                            return (
                                                <div className="section-params" key={p.key} ref={typeRefs[`${d.key}_${p.key}`]}>
                                                    <h5>{p.title}</h5>
                                                    <DocumentationItem
                                                        key={p.key}
                                                        data={p}
                                                        onImageClicked={onImageClick}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            {/* <a href="#/documentation#preprocessing">sa</a>
            <NavLink
            to="#preprocessing"
            >HMMM</NavLink> */}

            <Modal isOpen={isModalOpen} toggle={onModalToggle} className="image-modal-container modal-lg">
                {/* <ModalHeader toggle={onModalToggle} /> */}
                <button type="button" className="close image-modal" onClick={onModalToggle}>
                    <span aria-hidden="true">×</span>
                </button>
                <ModalBody>
                    <div className="image-modal-body">
                        <img src={modalImage} />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Documentation;
