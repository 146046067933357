import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';

import { updateInput } from '../../../actions/action.pipeline-input';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import { IChannel, ITag, ITarget } from '../../../types/type.cytof-template';
import { getTarget, getTag } from '../../../actions/action.cytof-template';

const CytofTemplate = () => {
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input } = pipeline;
    const [targets, setTargets] = useState<Array<ITarget>>([]);
    const [tags, setTags] = useState<Array<ITag>>([]);
    const [availableTags, setAvailableTags] = useState<Array<Array<ITag>>>([]);

    const updateChannels = (input: Array<{}>) => {
        dispatch(updateInput('channels', input));
    }

    const addRow = () => {
        const currentInput = _.cloneDeep(input.channels);
        const newRow: IChannel = {
            target: "",
            tag: "",
            mass: "",
            element: "",
            readonly: false
        };
        currentInput.push(newRow);
        updateChannels(currentInput);
    }

    const deleteRow = (event, index: number) => {
        event.preventDefault();
        let currentInput = _.cloneDeep(input.channels);
        const currentRow = _.get(currentInput, index);
        _.each(currentInput, (item, idx: number) => {
            if (idx > index) {
                currentInput[idx - 1] = item;
            }
        });
        currentInput = _.dropRight(currentInput);

        updateChannels(currentInput);

        // update tags
        const tagClone = _.clone(tags);
        const oldIndex = _.findIndex(tags, ["name", currentRow.tag]);
        _.set(tagClone, `${oldIndex}.isSelected`, false);
    }

    const changeTarget = (index: number, target: string) => {
        const currentInput = _.cloneDeep(input.channels);
        const currentRow = _.get(currentInput, index);
        const updatedRow = {
            ...currentRow,
            target,
        };
        _.set(currentInput, index, updatedRow);
        updateChannels(currentInput);
    }

    const changeTag = (index: number, tag: string) => {
        const currentInput = _.cloneDeep(input.channels);
        const currentRow = _.get(currentInput, index);
        const tagIndex = _.findIndex(tags, ["name", tag]);
        const tagData = tags[tagIndex];
        if (!tagData) return;
        const updatedRow = {
            ...currentRow,
            tag: tagData.name,
            mass: tagData.mass,
            element: tagData.element,
            // set default value
            target: tagData.defaultValue ? tagData.defaultValue.name : ""
        };

        _.set(currentInput, index, updatedRow);
        updateChannels(currentInput);

        // update tags
        const tagClone = _.clone(tags);
        const oldIndex = _.findIndex(tags, ["name", currentRow.tag]);
        _.set(tagClone, `${oldIndex}.isSelected`, false);
        _.set(tagClone, `${tagIndex}.isSelected`, true);
        setTags(tagClone);
    }

    useEffect(() => {
        (async () => {
            const targets = await getTarget();
            setTargets(targets);
            const tags = await getTag();
            const mappedTags = _.map(tags, (tag) => {
                return {
                    ...tag,
                    isSelected: true,
                };
            });
            setTags(mappedTags);

            const defaultTags: IChannel[] = _.map(mappedTags, (tag) => {
                return {
                    readonly: false,
                    tag: tag.name,
                    mass: tag.mass,
                    element: tag.element,
                    // set default value
                    target: tag.defaultValue ? tag.defaultValue.name : ""
                }
            });
            updateChannels(defaultTags);
        })();
    }, []);

    const tagData = _.map(tags, (tag) => {
        return {
            value: tag.name,
            label: tag.name,
            isDisabled: tag.isSelected
        }
    });

    const targetData = targets.map((target) => ({
        value: target.name,
        label: target.label
    }));

    return (
        <div className="cytof-template">
            <h4 className="subtitle">Cytof Run Template</h4>
            <div className="pull-right" style={{ padding: '24px 15px' }}>
                <button
                    type="button"
                    onClick={addRow}
                    className="btn-primary btn-form-summary"
                    style={{ width: '150px' }} disabled={_.isEmpty(targets) || _.isEmpty(tags)}>Add Row</button>
            </div>
            <div className="pipeline-form">
                <Table bordered style={{ marginBottom: '0px' }}>
                    <thead>
                        <tr>
                            <th style={{ width: "10%" }}>No</th>
                            <th style={{ width: "30%" }}>Tag/Metal</th>
                            <th style={{ width: "30%" }}>Target/Marker</th>
                            <th style={{ width: "10%" }}></th>
                        </tr>
                    </thead>
                </Table>
                <div className="scrollable-table">
                    <Table bordered style={{ marginBottom: '0px' }}>
                        <tbody>
                            {input.channels.map((channel: IChannel, index: number) => (
                                <tr key={`channel-${index}`}>
                                    <td style={{ width: "10%" }}>{ index + 1 }</td>
                                    <td style={{ width: "30%" }}>
                                        {tagData.length && (
                                            <Select
                                                options={tagData}
                                                value={_.find(tagData, (x: any) => x.value === channel.tag) || ''}
                                                onChange={(selected: any) => {
                                                    changeTag(index, _.isNull(selected) ? '' : selected.value);
                                                }}
                                                placeholder="Select Tag/Metal"
                                                classNamePrefix="cytof-select"
                                                isSearchable
                                                isClearable
                                            />
                                        )}
                                    </td>
                                    <td style={{ width: "30%" }}>
                                        {targetData.length && (
                                            <Select
                                                options={targetData}
                                                value={_.find(targetData, (x: any) => x.value === channel.target) || ''}
                                                onChange={(selected: any) => {
                                                    changeTarget(index, _.isNull(selected) ? '' : selected.value);
                                                }}
                                                placeholder="Select Target/Marker"
                                                classNamePrefix="cytof-select"
                                                isSearchable
                                                isClearable
                                            />
                                        )}
                                    </td>
                                    <td style={{ width: "10%" }}>
                                        <button className="btn-danger button-rounded" onClick={(e) => deleteRow(e, index)}>
                                            <i className="fa fa-remove"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            {input.channels.length > 0 && (
                <span
                    onClick={addRow}
                    className="text-primary add-more">
                    Add More...
                </span>
            )}
        </div>
    );
}

export default CytofTemplate;
