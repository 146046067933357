import initialState from './initialState';
import * as types from './actionTypes';
import { IProjectList, TProject } from '../types/type.project';

export default function (state : IProjectList = initialState.projects.data, action: TProject) : IProjectList {
  switch (action.type) {
    case types.GET_PROJECTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        all_projects: action.data,
        isLoading: false,
      };
    case types.GET_PROJECTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };

    case types.GET_ONE_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
        // selected_project: action.data,
      };
    case types.GET_ONE_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected_project: action.data,
      };
    case types.GET_ONE_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };

    case types.ARCHIVE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    case types.ARCHIVE_RESULT:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        success: action.success,
        selected_project: action.data,
      };

    case types.ADD_REPORT_SUCCESS:
      return {
        ...state,
        selected_project: {
          ...state.selected_project,
          reports: action.data,
        },
      };
    case types.CREATE_FCS_SUCCESS:
      return {
        ...state,
        selected_project: {
          ...state.selected_project,
          samples: action.data,
        },
      };

    // for file explorer
    case types.GET_PROJECT_FILES_SUCCESS:
      return {
        ...state,
        s3FileBrowser: {
          ...state.s3FileBrowser,
          files: action.data,
        },
      };

    case types.RESET_FILE_BROWSER:
      return {
        ...state,
        s3FileBrowser: initialState.projects.data.s3FileBrowser,
      };

    case types.CHANGE_PROJECT_FILE_S3PREFIX_SUCCESS:
      return {
        ...state,
        s3FileBrowser: {
          ...state.s3FileBrowser,
          prefix: action.data,
        },
      };

    default: return state;
  }
}
