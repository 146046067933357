export const FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER = 'previous-result';
export const FILE_EXPLORER_WORKSPACE_FOLDER = 'workspace';
export const FILE_EXPLORER_ADMIN_FOLDER = 'origin';
export const FILE_EXPLORER_DISCOVERY = 'discovery';
export const FILE_PROJECT_PREFIX = 'project-';

// EXTENSION EXPLORER THAT IS SHOWED IN FILE EXPLORER
export const EXTENSION_EXPLORER_DISCOVERY = ['cloupe', 'vloupe', 'html', 'png'];

export const LABEL_SMALL = 3;
export const INPUT_SMALL = 12 - LABEL_SMALL;
export const MAX_FILE_SIZE = 100 * 1024 * 1024; //100MB

export const NO_FILES = 'No files to display.';
export const WORKSPACE_RETRY_COUNT = 0;

export const SEQUENCING = 3;

export const UPLOAD_STEP = 1; // TODO: make dynamic later
export const CLUSTER_CODE_UPLOAD_STEP = 2; // TODO: make dynamic later
export const BEADNORM_STEP = 3; // TODO: make dynamic later
export const DEBARCODE_STEP = 4; // TODO: make dynamic later
export const GATING_STEP = 5; // TODO: make dynamic later
export const GATING_SUBSETTING_STEP = 6; // TODO: make dynamic later

// BATCH STEP TITLE
// TODO: make dynamic later
export const FILE_UPLOAD = "File Upload";
export const CLUSTER_CODE_UPLOAD  = "Cluster Code Upload";
export const BEAD_NORMALIZATION = "Bead Normalization";
export const DEBARCODE = "Debarcode";
export const LIFE_DEATH_GATING = "Life/Death Gating";
export const MARKER_GATING_SUBSETTING = "Marker Gating and Subsetting";

export const PIPELINE_NAME_MAX_LENGTH = 50;
export const PIPELINE_NAME_MAX_LENGTH_ERROR_MESSAGE = "Session name was longer than the configured maximum length";


// BATCH STEP BUTTON TEXT
// TODO: make dynamic later
export const BUTTON_TEXT_RUN = "Run";
export const BUTTON_TEXT_RE_RUN = "Re-Run";
export const BUTTON_TEXT_GOTO_ANALYSIS = "Goto Analysis";
export const BUTTON_TEXT_SUBSET = "Subset";

// PREVIOUS RESULT FILE SOURCE BASED ON TABLE NAME
export const EXECUTION = 'EXECUTION';
export const BATCH_EXECUTION = 'BATCH_EXECUTION';

// BATCH PREPROCESSING DOCUMENTS
export const PREPROCESSING_FILE_UPLOAD = 'preprocessingFileUpload';
export const PREPROCESSING_CLUSTER_CODE_UPLOAD = 'preprocessingClusterCodeUpload';
export const PREPROCESSING_BEAD_NORMALIZATION = 'preprocessingBeadNormalization';
export const PREPROCESSING_DEBARCODE = 'preprocessingDebarcode';
export const PREPROCESSING_LIFE_DEATH_GATING = 'preprocessingLifeDeathGating';
export const PREPROCESSING_MARKER_GATING_SUBSETTING = 'preprocessingMarkerGatingSubsetting';
export const PREPROCESSING_ACTION_COLUMN = 'preprocessingActionColumn';

//PIPELINE PARAMETERS
export const SYMBOL_PARAMETERS = ['circle','square','diamond','up-triangle','down-triangle'];