import React, { Component, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert';
import classNames from 'classnames';

import { createBatch, createBatchStep, getLastInput, setSelectedBatch, unskipBatchStep } from "../../actions/action.batch";
import { clone, updateAllMetadata, updateInput, updateMetadata } from "../../actions/action.pipeline-input";
import { TDispatch } from "../../types/action";
import { IPipelineTab } from "../../types/type.visible-pipeline";
import { Link } from "react-router-dom";
import { Card, CardHeader, Collapse, CardBody, UncontrolledTooltip, Modal, ModalHeader, ModalBody } from "reactstrap";
import { IBatch, IBatchStep, IBatchStepItem } from "../../types/type.batch";
import history from '../../views/history';
import { IStore } from "../../types/store";
import batchSteps from '../../reducers/batch-steps';
import { BEADNORM_STEP, BUTTON_TEXT_GOTO_ANALYSIS, BUTTON_TEXT_RE_RUN, BUTTON_TEXT_RUN, BUTTON_TEXT_SUBSET, DEBARCODE_STEP, GATING_STEP, GATING_SUBSETTING_STEP, UPLOAD_STEP } from "../../utils/constants";
import { readFcsMetadataFromS3 } from "../../actions/action.file-selector";
import { TPipeline } from "../../types/type.pipeline";
import { trimString } from "../../views/Pipeline/helper";
import { getOneExecution } from "../../actions/action.pipeline";
import DocumentationItem from "./DocumentationItem";

interface IProps {
    data: IBatchStep,
    currentStep: number,
    totalStep: number,
    actionButtons?: Array<Component>,
}

interface DataProps {
    item: IBatchStepItem[]
}

const StepData = ({ item }: DataProps) => {
    if (item.length === 0) return null;
    const firstItem = item[0];
    if (firstItem.executionId) {
        // pipeline
        return (
            <React.Fragment>
                {item.map((pipeline) => (
                    <div className="steps-body" key={pipeline.id}>
                        <span className="steps-body-item">{pipeline.executionId || '-'}</span>
                        <span className="steps-body-item">{pipeline.status}</span>
                        <span className="steps-body-item actions">
                            {pipeline.executionId && (
                                <Link to={`/pipeline/detail/${pipeline.executionId}`}>
                                    <span>View detail</span>
                                </Link>
                            )}
                        </span>
                    </div>
                ))}
            </React.Fragment>
        );
    } else {
        // non pipeline
        // return null;
        if (firstItem.files) {
            const parsed = JSON.parse(firstItem.files);
            return (
                <React.Fragment>
                    {parsed.files.map((file, index) => (
                        <div className="steps-body" key={index}>
                            <span className="steps-body-item">{index + 1}</span>
                            <span className="steps-body-item">{file.name}</span>
                            <span className="steps-body-item actions" onClick={() => {}}>
                                {/* <span>Download</span> */}
                            </span>
                        </div>
                    ))}
                </React.Fragment>
            );
        }
        return (
            <div className="steps-body">
                <span className="steps-body-item">{firstItem.status}</span>
            </div>
        );
    }
}

const StepDetailView = ({ data, currentStep, totalStep }: IProps) => {
    const batch: IBatch = useSelector((state : IStore) => state.batch.selected_batch);
    const visiblePipelines: IPipelineTab[] = useSelector((state : IStore) => state.visiblePipelines.data);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const [isOpen, setIsOpen] = useState<boolean>(data.step === currentStep);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const onActionClick = async (item) => {
        const { type, metadata } = item;
        const sessionName = (): string => {
            const selectedTab = visiblePipelines.find((element) => element.id == metadata.tabId);
            const allPipeline = selectedTab ? selectedTab.pipelines : [];
            const selectedPipeline = allPipeline.find((element) => element.id === metadata.pipelineId);
            const pipelineName = selectedPipeline ? selectedPipeline.name : metadata.pipelineId;
            return trimString(`${batch.name}_${pipelineName}_${data.items.length + 1}`);
        }

        switch(type) {
            case 'runPipeline': {
                // set pipeline metadata
                const allMetadata = {
                    ...metadata,
                    batchId: batch.id,
                    projectId: batch.projectId
                };

                // set name
                if (metadata.pipelineId) {
                    allMetadata.name = sessionName();
                }
                dispatch(updateAllMetadata(allMetadata));
                dispatch(updateInput('protein', [])); //reset input protein

                // set input
                if (batch.id) {
                    const getLast = await getLastInput(batch.id);
                    const lastStepOutput = getLast.data;

                    // logic to filter bead norm output
                    const BEAD_NORM_PATTERN = /(\/output\/normed\/).*.fcs/;
                    if (
                        (data.step === DEBARCODE_STEP) && 
                        (batch.steps[BEADNORM_STEP - 1].status === 'success')
                    ) {
                        lastStepOutput.files = _.filter(lastStepOutput.files,
                            (file) => new RegExp(BEAD_NORM_PATTERN).test(file.s3Url.toLowerCase()))
                    } else if (
                        (data.step === GATING_STEP || data.step === GATING_SUBSETTING_STEP) &&
                        (batch.steps[BEADNORM_STEP - 1].status === 'success') &&
                        (batch.steps[DEBARCODE_STEP - 1].status === 'skipped')
                    ) {
                        lastStepOutput.files = _.filter(lastStepOutput.files,
                            (file) => new RegExp(BEAD_NORM_PATTERN).test(file.s3Url.toLowerCase()))
                    } else {
                        lastStepOutput.files = _.filter(lastStepOutput.files,
                            (file) => new RegExp(/.*.fcs/).test(file.s3Url.toLowerCase()))
                    }
                    if (!_.isEmpty(lastStepOutput.files)) {
                        const inputs = _.map(lastStepOutput.files, 's3Url');
                        if (data.step >= GATING_STEP) {
                            const firstFile: any = _.first(lastStepOutput.files);
                            dispatch(readFcsMetadataFromS3('protein', firstFile, () => {
                                dispatch(updateInput('input', inputs));
                                history.push({ pathname: item.url });
                            }, () => {}));
                        } else {
                            dispatch(updateInput('input', inputs));
                            history.push({ pathname: item.url });
                        }
                    } else {
                        history.push({ pathname: item.url });
                    }
                } else {
                    history.push({ pathname: item.url });
                }
                return;
            }
            case 'skipPipeline': {
                // create batch step
                if (batch.id) {
                    swal({
                        title: 'Please wait',
                        text: 'Skipping.....',
                        closeOnClickOutside: false,
                        buttons: { visible: false }
                    });
                    await createBatchStep({
                        batchId: batch.id,
                        executionId: null,
                        status: 'skipped',
                        step: data.step,
                    }).then(() => {
                        swal('Success', 'Success', 'success').then(() => {
                            // reload page
                            history.go(0);
                        });
                    });
                } else {
                    await createBatch({
                        name: batch.name, 
                        currentStep: 1,
                        totalStep: _.keys(batchSteps).length,
                        status: 'waiting_for_user',
                        projectId: batch.projectId,
                    }, {
                        executionId: null,
                        status: 'skipped',
                        step: 1
                    }).then((res: any) => {
                        swal('Success', 'Success creating new Batch', 'success').then(() => {
                            dispatch(setSelectedBatch(res.id, res.projectId));
                        });
                    })
                }
                return;
            }
            case 'unskipPipeline': {
                if (metadata.batchStepId) {
                    await unskipBatchStep(metadata.batchStepId)
                    .then(() => {
                        swal('Success', 'Success', 'success').then(() => {
                            // reload page
                            history.go(0);
                        });
                    })
                }
                return;
            }
            case 'gotoAnalysis': {
                const url = item.url + '/' + batch.projectId;
                history.push({ pathname: url });
                return;
            }
            case 'reRunPipeline': {
                // set pipeline metadata
                const allMetadata = {
                    ...metadata,
                    batchId: batch.id,
                    projectId: batch.projectId,
                };

                // set name
                if (metadata.pipelineId) {
                    allMetadata.name = sessionName();
                }
                dispatch(updateAllMetadata(allMetadata));
                dispatch(updateInput('protein', [])); //reset input protein
                dispatch(updateMetadata('reRun', true));
                // get first success execution
                const successExecution = _.find(data.items, ['status', 'success']);
                const onSuccess = () => {
                    dispatch(clone());
                    history.push('/pipeline/form');
                }

                if (successExecution && successExecution.executionId) {
                    dispatch(getOneExecution(successExecution.executionId, onSuccess));
                } else {
                    swal('Warning', 'Execution not found', 'warning');
                }
            }
            default: return;
        }
    }

    const onModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <Card className="step-card">
            <CardHeader className={classNames("steps-header", { show: isOpen })} onClick={() => setIsOpen(!isOpen)}>
                <UncontrolledTooltip autohide={false} placement="top" target={batchSteps[data.step].key}>
                    {batchSteps[data.step].tooltip}
                </UncontrolledTooltip>
                <span className="steps-header-item number">
                    <i id={batchSteps[data.step].key} className="fa fa-question-circle form-tooltip" onClick={onModalToggle}></i>
                </span>
                <span className="steps-header-item number">{data.step} / {totalStep}</span>
                <span className="pipeline-icon">
                    <div className={batchSteps[data.step].icon} />
                </span>
                <span className="steps-header-item title">{batchSteps[data.step].title}</span>
                <div className="steps-header-item actions">
                    {data.actions && data.actions.map((action) => (
                        <button
                            key={action.text}
                            className={[BUTTON_TEXT_GOTO_ANALYSIS, BUTTON_TEXT_SUBSET, BUTTON_TEXT_RUN, BUTTON_TEXT_RE_RUN].includes(action.text) && action.metadata.hidden
                                ? "btn-primary display-none" 
                                : "btn-primary"
                            }
                            style={{ marginLeft: '14px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onActionClick(action);
                            }}
                            disabled={_.isEmpty(batch.name)}>
                            {action.text}
                        </button>
                    ))}
                </div>
                <span className="steps-header-item arrow">
                    {isOpen ? (
                        <i className="icon icon-arrow-up"></i>
                    ) : (
                        <i className="icon icon-arrow-down"></i>
                    )}
                </span>
            </CardHeader>
            <Modal isOpen={isModalOpen} toggle={onModalToggle} className="modal-lg modal-pipeline-tips">
                <ModalHeader toggle={onModalToggle}>{batchSteps[data.step].title}</ModalHeader>
                <ModalBody>
                    <DocumentationItem
                        className="modal-body-inner"
                        data={{
                            text: batchSteps[data.step].description,
                            images: batchSteps[data.step].images,
                            list: batchSteps[data.step].list
                        }}
                        isImageScrollable
                    />
                </ModalBody>
            </Modal>
            <Collapse isOpen={isOpen}>
            <CardBody>
                <StepData item={data.items} />
            </CardBody>
            </Collapse>
        </Card>
    )
}

export default StepDetailView;