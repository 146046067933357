import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import swal from 'sweetalert';
import { Breadcrumb, BreadcrumbItem, Col, FormGroup, Label } from 'reactstrap';
import { TDispatch } from '../../types/action';
import { IStore } from '../../types/store';
import { IBatch, IBatchStepItem, TBatch, TBatchCreate } from '../../types/type.batch';
import { setPipelineType } from '../../actions/action.ui-history';
import StepDetailView from '../../components/Views/StepDetailView';
import BatchPreprocessingWrapper from '../../components/Views/BatchPreprocessingWrapper';
import batchSteps from '../../reducers/batch-steps';
import { archiveBatch, changeSelectedBatchData, createBatch, setSelectedBatch, updateBatch } from '../../actions/action.batch';
import { BEADNORM_STEP, BUTTON_TEXT_GOTO_ANALYSIS, BUTTON_TEXT_RE_RUN, BUTTON_TEXT_RUN, BUTTON_TEXT_SUBSET, GATING_SUBSETTING_STEP, INPUT_SMALL, LABEL_SMALL, PIPELINE_NAME_MAX_LENGTH, UPLOAD_STEP } from '../../utils/constants';
import InputCustom from '../../components/Form/InputCustom';

interface IProps {
    history: any,
    location: any
}

interface INextStepProps {
    data: IBatch,
}

const emptyStep = {
    step: 0,
    items: [],
    status: '',
    actions: undefined,
};

const StepsView = ({ data }: INextStepProps) => {
    const [newData, setNewData] = useState<IBatch>(data);
    const batchStepData = _.cloneDeep(batchSteps);

    useEffect(() => {
        // if (data.currentStep === data.totalStep && data.status === "completed") return;
        if (data.steps) {
            const newDataSet = _.cloneDeep(data.steps);
            const lastStep = _.last(newDataSet) || emptyStep;

            if (_.includes(['', 'skipped', 'success'], lastStep.status)) {
                if (data.currentStep < data.totalStep) {
                    const currentStep = batchStepData[data.currentStep + 1];
                    newDataSet.push({
                        ...emptyStep,
                        step: data.currentStep + 1,
                        actions: currentStep ? currentStep.actions : [],
                    });
                } else {
                    // // if uploads already, done show 'download'. Hardcode for now. TODO: make dynamic later
                    // newDataSet[UPLOAD_STEP - 1].actions = [{
                    //     type: 'downloadFiles',
                    //     url: '',
                    //     metadata: {
                    //     },
                    //     text: 'Download Files',
                    // }];
                }
                if (lastStep.status === 'skipped' && lastStep.step >= BEADNORM_STEP) {
                    const stepItems = _.get(data, `steps.${data.currentStep - 1}.items`, []);
                    const skippedStep = _.find(stepItems, ['status', 'skipped']);
                    if (skippedStep) {
                        newDataSet[data.currentStep - 1].actions = [{
                            type: 'unskipPipeline',
                            text: 'Unskip',
                            metadata: {
                                batchStepId: skippedStep.id,
                            }
                        }];
                    }
                }
            } else {
                const currentStep = batchStepData[data.currentStep];
                if (currentStep) {
                    newDataSet[data.currentStep - 1].actions = currentStep.actions;
                }
            }

            if (data.currentStep === GATING_SUBSETTING_STEP) {
                const currentStep = batchStepData[data.currentStep];
                const items = _.get(data, `steps.${GATING_SUBSETTING_STEP-1}.items`, []);
                const hasSuccessRun = _.filter(items, ['status', 'success']);
                
                if (!_.isEmpty(hasSuccessRun)) {
                    currentStep.actions.forEach(element => {
                        if (element.text === BUTTON_TEXT_RUN) {
                            if (element.metadata) {
                                element.metadata.hidden = true;
                            }
                        } else if (element.text === BUTTON_TEXT_SUBSET) {
                            if (element.metadata) {
                                // showing subset button
                                element.metadata.hidden = false;
                                const lastStepItems: IBatchStepItem[] = _.get(data, `steps.${data.currentStep-1}.items`, []);
                                const firstSuccessRun = _.find(lastStepItems, ['status', 'success']);
                                if (firstSuccessRun && firstSuccessRun.executionId) {
                                    element.metadata.subsetting = true;
                                    element.metadata.cloneSource = firstSuccessRun.executionId; // make functionality like gating cloning
                                }
                            }
                        } else if (element.text === BUTTON_TEXT_GOTO_ANALYSIS && element.metadata) {
                            // showing goto analysis button
                            element.metadata.hidden = false;
                        } else if (element.text == BUTTON_TEXT_RE_RUN && hasSuccessRun.length == 1 && element.metadata) {
                            element.metadata.hidden = false;
                        }
                    });
                }
                newDataSet[data.currentStep - 1].actions = currentStep.actions;
            }

            setNewData({
                ...data,
                steps: newDataSet
            });
        }
    }, [data]);

    return (
        <React.Fragment>
            {newData.steps.map((item) => (
                <StepDetailView key={item.step} data={item} currentStep={data.currentStep} totalStep={data.totalStep} />
            ))}
        </React.Fragment>
    )
}

const PreprocessingBatch = ({ location, history }: IProps) => {
    const selectedBatchData: IBatch = useSelector((state: IStore) => state.batch.selected_batch);
    const projectId = useSelector((state : IStore) => state.batch.selected_batch.projectId);
    const dispatch = useDispatch<TDispatch<TBatch>>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [batchName, setBatchName] = useState<string>(selectedBatchData.name);

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(changeSelectedBatchData(name, value));
    }

    const onClickAction = async() => {
        await createBatch({
            name: selectedBatchData.name, 
            currentStep: 0,
            totalStep: _.keys(batchSteps).length,
            status: 'waiting_for_user',
            projectId: projectId!,
        }).then((response: any) => {
            const res = response.data;
            
            // @ts-ignore
            swal('Success', 'Success creating new Batch', 'success').then(() => {
                dispatch(setSelectedBatch(res.id, res.projectId));
            });
        });
    }

    const handleArchive = (batchId: number) => {
        if (batchId <= 0) {
            swal("Failed", "Preprocessing batch not found.", "warning")
            return;
        }

        swal({ 
            buttons: [true, true],
			title: "Are you sure?",
			text: "This process is permanent and cannot be undone",
			icon: "warning",
			dangerMode: true,
		}).then((willDelete: boolean | null) => {
            if (willDelete) {
                dispatch(archiveBatch(
                    batchId,
                    projectId,
                    () => {
                        history.push({
                            pathname: `/projects/preprocessing/${projectId}`
                        });
                    }
                ));
			}
        });
    }

    const handleEdit = (batchId: number, data: TBatchCreate) => {
        if (batchId <= 0) {
            swal("Failed", "Preprocessing batch not found.", "warning")
            return;
        }
        dispatch(updateBatch(batchId, data, () => {
            setIsEdit(false);
        }));
    }

    useEffect(() => {
        dispatch(setPipelineType('preprocessing'));
    }, []);

    return (
        <BatchPreprocessingWrapper location={location}>
            <Breadcrumb tag="nav">
                <BreadcrumbItem tag="a" href={`#/projects/preprocessing/${projectId}`}>Projects</BreadcrumbItem>
                <BreadcrumbItem active tag="span">{selectedBatchData.id ? selectedBatchData.name : 'Create New'}</BreadcrumbItem>
            </Breadcrumb>
            <div className="card basic">
                <div className='row mr-2'>
                    <p className="text-danger col-auto mr-auto"><i>* denotes a mandatory field</i></p>
                    {(selectedBatchData.id && selectedBatchData.isActive && !isEdit) && (
                        <div>
                            <button
                                className="btn-danger col-auto"
                                type="button"
                                onClick={()=>handleArchive(selectedBatchData.id ? selectedBatchData.id : 0)}
                            >
                                <i className="fa fa-archive read-action" /> Archive
                            </button>
                            <button
                                className="btn-primary col-auto ml-2"
                                type="button"
                                onClick={() => {
                                    setBatchName(selectedBatchData.name);
                                    setIsEdit(true);
                                }}
                            >
                                <i className="fa fa-pencil" /> Edit
                            </button>
                        </div>
                    )}
                </div>
                <FormGroup row>
                    <Label for="batch" sm={LABEL_SMALL} className="label-custom required">Batch Name</Label>
                    <Col sm={INPUT_SMALL}>
                        {(selectedBatchData.id && !isEdit) ? (
                            <span className="option-label">{selectedBatchData.name}</span>
                        ) : (
                            <div>
                                <InputCustom type="text"
                                    name="name"
                                    placeholder="Batch name"
                                    onChange={onNameChange}
                                    value={selectedBatchData.name}
                                    maxLength={PIPELINE_NAME_MAX_LENGTH}
                                />
                                <button
                                    type="submit"
                                    className="btn-primary"
                                    style={{ marginTop: '14px' }}
                                    onClick={() => {
                                        isEdit ? handleEdit(selectedBatchData.id ? selectedBatchData.id : 0, selectedBatchData) : onClickAction()
                                    }}
                                    disabled={_.isEmpty(selectedBatchData.name)}>
                                    { isEdit ? 'Update Batch' : 'Save Batch' }
                                </button>
                                {
                                    isEdit && (
                                        <button
                                            type="submit"
                                            className="btn-danger ml-2"
                                            style={{ marginTop: '14px' }}
                                            onClick={() => {
                                                selectedBatchData.name = batchName;
                                                setIsEdit(false);
                                            }}
                                            disabled={_.isEmpty(selectedBatchData.name)}>
                                            Cancel
                                        </button>
                                    )
                                }
                            </div>
                        )}
                    </Col>
                </FormGroup>
            </div>
            {selectedBatchData.id && <StepsView data={selectedBatchData} />}
        </BatchPreprocessingWrapper>
    );
}

export default PreprocessingBatch;
