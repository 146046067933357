import React, { useState, useEffect } from 'react';
import {  Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { IStore } from '../../types/store';

interface IProps {
    name: string,
    value: string,
    onDataChange: Function,
    reverse?: boolean,
    addonComponent?: any
}
interface ColorItem {
    id: string,
    caption: string,
    colorSet: Array<string>
}

const palette = [
    { id: '1', caption: 'ImmunoScape', colorSet: ['#008843', '#11A664', '#23C486', '#47D0A2', '#7EC9B8', '#B6C2CE', '#719AD3', '#2C72D9', '#0E57C3', '#184891', '#223A60']},
    { id: '2', caption: 'green,black,red', colorSet: ['#00FF00', '#00CC00', '#009900', '#006500', '#003200', '#000000', '#330000', '#660000', '#990000', '#CC0000', '#FF0000']},
    { id: '3', caption: 'black,blue,yellow,orange,red', colorSet: ['#000000', '#000099', '#0033CB', '#00CC32', '#66FF00', '#FFFF00', '#FFC800', '#FF8300', '#FF2000', '#D00000', '#8B0000']},
    { id: '4', caption: 'red,yellow,blue', colorSet: ['#A50026', '#D73027', '#F46D43', '#FDAE61', '#FEE090', '#FFFFBF', '#E0F3F8', '#ABD9E9', '#74ADD1', '#4575B4', '#313695']},
    { id: '5', caption: 'red,yellow,green', colorSet: ['#A50026', '#D73027', '#F46D43', '#FDAE61', '#FEE08B', '#FFFFBF', '#D9EF8B', '#A6D96A', '#66BD63', '#1A9850', '#006837']},
    { id: '6', caption: 'spectral', colorSet: ['#9E0142', '#D53E4F', '#F46D43', '#FDAE61', '#FEE08B', '#FFFFBF', '#E6F598', '#ABDDA4', '#66C2A5', '#3288BD', '#5E4FA2']},
    { id: '7', caption: 'viridis', colorSet: ['#FDE725FF', '#BBDF27FF', '#7AD151FF', '#43BF71FF', '#22A884FF', '#21908CFF', '#2A788EFF', '#35608DFF', '#414487FF', '#482576FF', '#440154FF']},
    { id: '8', caption: 'plasma', colorSet: ['#F0F921FF', '#FCCE25FF', '#FCA636FF', '#F1844BFF', '#E16462FF', '#CC4678FF', '#B12A90FF', '#900DA4FF', '#6A00A8FF', '#42049EFF', '#0D0887FF']},
    { id: '9', caption: 'inferno', colorSet: ['#FCFFA4FF', '#F6D645FF', '#FCA50AFF', '#F3771AFF', '#DD513AFF', '#BB3754FF', '#932667FF', '#6B186EFF', '#420A68FF', '#170C3AFF', '#000004FF']},
    { id: '10', caption: 'MATLAB', colorSet: ['#0000AA', '#0040FF', '#0080FF', '#40BFFF', '#80FFFF', '#BFFFBF', '#FFFF80', '#FFBF40', '#FF8000', '#FF4000', '#AA0000']},
    { id: '11', caption: 'MATLAB2', colorSet: ['#0000BF', '#0000FF', '#0080FF', '#00FFFF', '#40FFBF', '#80FF80', '#BFFF40', '#FFFF00', '#FF8000', '#FF0000', '#BF0000']},
    { id: '12', caption: 'magenta2green', colorSet: ['#FF00FF', '#E61AE6', '#CC33CC', '#B34DB3', '#996699', '#808080', '#669966', '#4CB34C', '#33CC33', '#19E619', '#00FF00']}
];

const groupColors = [
    { id: 'Set1', caption: 'Set1', colorSet: ['#e4211d', '#377db8', '#4daf4a', '#984ea3', '#ff8000', '#fefb31', '#a75629', '#f880bd', '#999999'] },
    { id: 'Set2', caption: 'Set2', colorSet: ['#65c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d853', '#ffd930', '#e4c493', '#b3b3b3'] },
    { id: 'Set3', caption: 'Set3', colorSet: ['#8dd3c8', '#fffdb3', '#bebbda', '#fb8071', '#80b1d2', '#fcb462', '#b2df6a', '#fdcde5', '#d9d9d9', '#bc80bc', '#cbebc4', '#ffed6e'] },
    { id: 'Pastel1', caption: 'Pastel1', colorSet: ['#fbb4ae', '#b3cde4', '#cdeac4', '#decce5', '#fed8a6', '#fffecd', '#e7d7bd', '#fddbec', '#f2f2f2'] },
    { id: 'Pastel2', caption: 'Pastel2', colorSet: ['#b3e3cd', '#ffcdac', '#c9d6e7', '#f5cae5', '#e5f4c9', '#fff2ae', '#f1e2cb', '#cccccc'] },
    { id: 'Accent', caption: 'Accent', colorSet: ['#7fc97e', '#beaed3', '#fdc087', '#fffc99', '#376cb1', '#f02a7f', '#c05b17', '#666666'] },
    { id: 'Dark2', caption: 'Dark2', colorSet: ['#1b9f77', '#da5f02', '#7570b3', '#e82989', '#67a71f', '#e7aa02', '#a5761c', '#666666'] },
];

const scheme = {
    palette,
    groupColors
}

const ColorPalette = (props: IProps) => {
    const input: any = useSelector((state : IStore) => state.pipeline.input);
    const [selectedColor, setSelectedColor] = useState<ColorItem>({ id: '0', caption: 'Select Color', colorSet: []});
    const [dropdownOpen, setDropdownOpen] = useState(false);
  
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const changeColor = (value: string) => {
        const e = {
            target: {
                name: props.name,
                value
            }
        }
        props.onDataChange(e);
    }

    const getColor = (color: ColorItem, isFull: boolean = true) : Object => {
        const colorSetClone = _.cloneDeep(color.colorSet);
        const colorSet = props.reverse? _.reverse(colorSetClone) : colorSetClone;
        const length = colorSet.length;

        const max = 12;
        const width = 100 / (isFull ? length : max);
        let colors = '';
        if (props.name === 'palette') {
            colors = colorSet.join(',');
        } else if (props.name === 'groupColors') {
            const colorRanges: Array<string> = [];
            for (let i=0; i < length; i++) {
                if (i === 0) colorRanges.push(`${colorSet[i]} ${width}%`);
                colorRanges.push(`${colorSet[i]} ${width * i}% ${width * (i + 1)}%`);
                if (i === length) colorRanges.push(`${colorSet[i]} ${width * i}%`);
            }
            if (!isFull && length < max) {
                colorRanges.push(`#ECEEF1 ${width * length}%`);
            }
            colors = colorRanges.join(',');
        }
        return { backgroundImage: `linear-gradient(to right, ${colors})` };
    }

    const renderColorItem = (color: ColorItem, index: number) => {
        return (
            <DropdownItem
                key={`color-${input[props.name]}-${index}`}
                className="gradient-image"
                onClick={() => changeColor(color.id)}
            >
                <div className="gradient-image-group" style={getColor(color, true)}>
                </div>
                <span className="gradient-image-text">{color.caption}</span>
            </DropdownItem>
        );
    }

    useEffect(() => {
        const selected = input[props.name];
        if (!selected) return;
        const selectedColors = _.find(scheme[props.name], ['id', selected.toString()]);
        if (selectedColors) {
            setSelectedColor(selectedColors);
        }
    }, [input[props.name]]);

    const colors: Array<ColorItem> = scheme[props.name];

    return (
        <Row className="palette">
            <Dropdown
                isOpen={dropdownOpen} toggle={toggle}
                className={classNames({
                    'col-md-10': props.addonComponent,
                    'has-addon': props.addonComponent
                })}>
                <DropdownToggle style={getColor(selectedColor)}>
                    {selectedColor.caption}
                </DropdownToggle>
                <DropdownMenu>
                    {colors.map(renderColorItem)}
                </DropdownMenu>
                <div className="select-icon">
                    <i className="icon-arrow-down"></i>
                </div>
            </Dropdown>
            {props.addonComponent && (
                <div className={classNames('addon col-md-2', { 'has-addon': props.addonComponent })}>
                    {props.addonComponent()}
                </div>
            )}
        </Row>
    );
}

export default ColorPalette;
