import swal from 'sweetalert';
import * as types from '../reducers/actionTypes';
import { TAction } from '../types/action';
import { TUser } from '../types/type.user';
import { GET, POST } from '../utils/httprequest';
import { IProfileResponse, IGeneralResponse } from '../types/type.response';
import { getVersion } from './action.version-notification';

export const getProfile = (): TAction<TUser> => {
    return (dispatch) => {
        dispatch({
            type: types.GET_PROFILE_REQUEST
        });

        GET('user')
        .then((response: IProfileResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.status == 'error') {
                dispatch({
                    type: types.GET_PROFILE_FAILURE,
                    message: response.message,
                });
            } else {
                dispatch({
                    type: types.GET_PROFILE_SUCCESS,
                    data: response.data
                });
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.GET_PROFILE_FAILURE,
                message: error.response.statusText,
            });
        });
    }
};

export const updateProfile = (
    data: { name?: string, avatar?: any, emailSubscription?: boolean },
    cb: Function
): TAction<TUser> => {
    return (dispatch) => {
        const formData = new FormData();
        Object.keys(data).map((key) => {
            formData.append(key, data[key]);
        });

        swal({
            title: 'Please wait',
            text: 'Updating Profile.....',
            closeOnClickOutside: false,
            buttons: { visible: false }
        });

        POST('user/update-profile', formData)
        .then((response: IProfileResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.status == 'error') {
                dispatch({
                    type: types.GET_PROFILE_FAILURE,
                    message: response.message,
                });
                swal('Failed', response.message, 'warning');
            } else {
                dispatch({
                    type: types.GET_PROFILE_SUCCESS,
                    data: response.data
                });
                swal('Success', 'Profile updated', 'success');
                cb();
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            swal.close!();
            dispatch({
                type: types.GET_PROFILE_FAILURE,
                message: error.response.statusText,
            });
        });
    }
};

export const unsubscribe = (): TAction<TUser> => {
    return (dispatch) => {
        POST('user/update-profile', { emailSubscription: false })
        .then((response: IProfileResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.status == 'error') {
                dispatch({
                    type: types.GET_PROFILE_FAILURE,
                    message: response.message,
                });
            } else {
                dispatch({
                    type: types.GET_PROFILE_SUCCESS,
                    data: response.data
                });
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.GET_PROFILE_FAILURE,
                message: error.response.statusText,
            });
        });
    }
};

export const resetPassword = (
    old_password: string,
    new_password: string
): TAction<TUser> => {
    return (dispatch) => {
        dispatch({
            type: types.RESET_PASSWORD_REQUEST
        });

        POST('user/reset-password', { old_password, new_password })
        .then((response: IGeneralResponse) => {
            dispatch(getVersion(response.app_version));
            if (response.status == 'error') {
                dispatch({
                    type: types.RESET_PASSWORD_FAILURE,
                    message: response.message,
                });
                swal('Failed', response.message, 'warning');
            } else {
                dispatch({
                    type: types.RESET_PASSWORD_SUCCESS,
                    data: response,
                });
                swal('Success', response.message, 'success');
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.RESET_PASSWORD_FAILURE,
                message: error.response.statusText,
            });
        });
    }
};
