import React from 'react';
import {
  Col, Input
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { updateInput } from '../../../actions/action.pipeline-input';
import LabelView from '../../../components/Views/LabelView';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import _ from 'lodash';
import InputCustom from '../../../components/Form/InputCustom';
import Toggle from '../../../components/Form/Toggle';
import { INPUT_SMALL } from '../../../utils/constants';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';

const Robot = () => {
  const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
  const dispatch = useDispatch<TDispatch<TPipeline>>();
  const { input, isAdvanced } = pipeline;

  const handleToggle = (value: boolean, key: string) => {
    const toggledValue = !value;
    dispatch(updateInput(key, toggledValue ? true : null));
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateInput(name, value));

    if (name === 'samples' && value) {
      dispatch(updateInput('samples', Number(value).toFixed(0)));
    }
  }

  const handleInputDecimal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(value, typeof value);
    if (value.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/gi) || _.isEmpty(value)) {
      dispatch(updateInput(name, value));
    }
  }

  return (
    <div className="robot">
      <h4 className="subtitle">Robot</h4>
      <div className="pipeline-form">
        <FormGroupCustom
          pipeline="robot"
          id="inputrobot"
          input={input.input}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <FileSelector
              name='input'
              tray={input.input}
              isMultiple={false}
              accept={['csv', '.csv']}
            />
          </Col>
        </FormGroupCustom>

        <FormGroupCustom
          pipeline="robot"
          id="tablerobot"
          input={input.table}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <FileSelector
              name='table'
              tray={input.table}
              isMultiple={false}
              accept={['csv', '.csv']}
            />
          </Col>
        </FormGroupCustom>

        {/* prefix */}
        <FormGroupCustom
          pipeline="robot"
          id="prefixrobot"
          input={input.prefix}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <Input
              name="prefix" id="robot_prefix" type="text" placeholder="Prefix for the output files"
              onChange={handleInput} value={input.prefix ? input.prefix : ''}
            />
          </Col>
        </FormGroupCustom>

        <FormGroupCustom
          pipeline="robot"
          id="cutoffrobot"
          input={input.cutoff}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <InputCustom
              name="cutoff" id="robot_cutoff" placeholder="e.g. 85"
              type="number" step="1" min="50" max="100"
              onChange={handleInput} value={input.cutoff}
            />
          </Col>
        </FormGroupCustom>

        <FormGroupCustom
          pipeline="robot"
          id="codingrobot"
          input={input.coding}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <InputCustom
              type="select" name="coding" id="robot_coding"
              placeholder="Coding strategy used (e.g. 3 for triple coding)"
              onChange={handleInput} value={input.coding}>
              <option value="" disabled>Select Coding Strategy</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </InputCustom>
          </Col>
        </FormGroupCustom>

        <FormGroupCustom
          pipeline="robot"
          id="volumerobot"
          input={input.volume}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <InputCustom
              name="volume" id="robot_volume" placeholder="Pipet volume (uL)"
              type="number" step="any" min="1"
              onChange={handleInput} value={input.volume}
            />
          </Col>
        </FormGroupCustom>

        <FormGroupCustom
          pipeline="robot"
          id="samplesrobot"
          input={input.samples}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <InputCustom
              name="samples" id="robot_samples" placeholder="Number of samples to calculate dilution factor"
              type="number" step="any"
              onChange={handleInput} value={input.samples}
            />
          </Col>
        </FormGroupCustom>

        <FormGroupCustom
          pipeline="robot"
          id="configrobot"
          input={input.config}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <InputCustom
              type="select" name="config" id="robot_config"
              placeholder="Config"
              onChange={handleInput} value={input.config}>
              <option value="" disabled>Select Configuration Setup</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </InputCustom>
          </Col>
        </FormGroupCustom>

        <FormGroupCustom
          pipeline="robot"
          id="wellrobot"
          input={input.well}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <InputCustom
              type="select" name="well" id="robot_well"
              placeholder="well"
              onChange={handleInput} value={input.well}>
              <option value="1">Standard</option>
              <option value="2">Deep</option>
            </InputCustom>
          </Col>
        </FormGroupCustom>

        <FormGroupCustom
          pipeline="robot"
          id="eppendorfrobot"
          input={input.eppendorf}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <InputCustom
              type="select" name="eppendorf" id="robot_eppendorf"
              onChange={handleInput} value={input.eppendorf} defaultValue={'1.5'}>
              <option value="1.5">1.5 ml</option>
              <option value="2.0">2.0 ml</option>
            </InputCustom>
          </Col>
        </FormGroupCustom>

        <FormGroupCustom
          pipeline="robot"
          id="flatrobot"
          input={input.flat}
          isAdvanced={isAdvanced}
          row>
          <Col sm={INPUT_SMALL}>
            <Toggle
              value={input.flat}
              onToggle={(v: boolean) => handleToggle(v, 'flat')}
            />

          </Col>
        </FormGroupCustom>

      </div>
    </div>
  );
}

export default Robot;
