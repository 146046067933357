
import * as types from '../reducers/actionTypes';
import { TAction } from '../types/action';
import { TVisiblePipeline } from '../types/type.visible-pipeline';
import { GET } from '../utils/httprequest';
import { IVisiblePipelineResponse } from '../types/type.response';
import { getVersion } from './action.version-notification';

export const getVisiblePipeline = () : TAction<TVisiblePipeline> => {
    return(dispatch) => {
        dispatch({
            type: types.GET_VISIBLE_PIPELINE_REQUEST,
        });

        GET('pipeline')
        .then((response: IVisiblePipelineResponse) => {
            dispatch(getVersion(response.app_version));
            dispatch({
                type: types.GET_VISIBLE_PIPELINE_SUCCESS,
                data: response.data
            });
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.GET_VISIBLE_PIPELINE_FAILURE,
                message: error.response.statusText
            });
        });
    }
}

export const changePipelineTab = (tabId: number) : TAction<TVisiblePipeline> => {
    return (dispatch) => {
        dispatch({
            type: types.PIPELINE_TAB_CHANGED,
            tabId
        });
    }
}