import swal from 'sweetalert';
import * as types from '../reducers/actionTypes';
import { TUser } from '../types/type.user';
import { TAction } from '../types/action';
import { POST, GET } from '../utils/httprequest';
import { ILoginResponse, IProfileResponse, IGeneralResponse } from '../types/type.response';
import { getVersion } from './action.version-notification';

export const getLogin = (
    email : string,
    password : string | undefined = undefined,
    remember : boolean = false,
    successCallback: Function,
    redirectResetPassword: Function
) : TAction<TUser>  => {
    return (dispatch) => {
        let data : {
            login: string,
            password: string,
            rememberMe?: boolean
        } = {
            login: email,
            password: password!,
        };
        if (remember) {
            data.rememberMe = true;
        }

        dispatch({
            type: types.LOGIN_REQUEST,
        });

        POST('user/login', data)
        .then((response: ILoginResponse) => {
            getVersion(response.app_version);
            if (response.status == 'error') {
                dispatch({
                    type: types.LOGIN_FAILURE,
                    message: response.message
                });
            } else if (response.status == 'reset_password') {
                // redirect to reset password
                redirectResetPassword(response.message, response.data.data);
            } else {
                GET('user', { headers: { Authorization: `Bearer ${response.data.token}` }})
                .then((profile: IProfileResponse)=>{
                    profile.data.token = response.data.token;
                    dispatch({
                        type: types.LOGIN_SUCCESS,
                        data: profile.data
                    });
                    successCallback();
                })
                .catch((error)=>{
                    dispatch({
                        type: types.LOGIN_FAILURE,
                        message: error.message
                    });
                });
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.LOGIN_FAILURE,
                message: error.response.data.message
            });
        });
    }
}

export const getLoginOauth = (token: string) : TAction<TUser> => {
    return (dispatch) => {
        dispatch({
            type: types.LOGIN_REQUEST
        });

        POST('user/login?type=google', { token })
        .then((response: ILoginResponse) => {
            getVersion(response.app_version);
            if (response.status == 'error') {
                dispatch({
                    type: types.LOGIN_FAILURE,
                    message: response.message
                });
            } else {
                GET('user', { headers: { Authorization: `Bearer ${response.data.token}` }})
                .then((profile: IProfileResponse) => {
                    profile.data.token = response.data.token;
                    dispatch({
                        type: types.LOGIN_SUCCESS,
                        data: profile.data
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: types.LOGIN_FAILURE,
                        message: error.message
                    });
                });
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            dispatch({
                type: types.LOGIN_FAILURE,
                message: error.message
            });
        });
    }
}

export const forgetPassword = (email: string) : TAction<TUser> => {
    return (dispatch) => {
        dispatch({
            type: types.FORGET_PASS_REQUEST
        });

        swal({
            title: "Please wait",
            text: "Sending Message to Your Email..",
            closeOnClickOutside: false,
            buttons: { visible: false }
        });

        POST('user/forget-password-request', { email })
        .then((response: IGeneralResponse) => {
            getVersion(response.app_version);
            if (response.status == 'error') {
                dispatch({
                    type: types.FORGET_PASS_FAILURE,
                    message: response.message
                });
                swal.close!();
                swal("Failed", response.message, "warning");
            } else {
                dispatch({
                    type: types.FORGET_PASS_SUCCESS,
                    message: response.message
                });
                swal.close!();
                swal("Success", response.message, "success");
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            swal.close!();
            dispatch({
                type: types.FORGET_PASS_FAILURE,
                message: error.message
            });
        });
    }
}

export const resetPassword = (data: {
    password: string,
    confirm_password: string,
    userId: string,
    code: string
}, redirect: Function) : TAction<TUser> => {
    return (dispatch) => {
        dispatch({
            type: types.FORGET_PASS_RESET_REQUEST,
        });

        swal({
            title: "Please wait",
            text: "Reseting Your Password..",
            closeOnClickOutside: false,
            buttons: { visible: false }
        });

        POST('user/forget-password-reset', data)
        .then((response: IGeneralResponse) => {
            getVersion(response.app_version);
            if (response.status == 'error') {
                dispatch({
                    type: types.FORGET_PASS_RESET_FAILURE,
                    message: response.message
                });
                swal.close!();
                swal("Failed", response.message, "warning");
            } else {
                dispatch({
                    type: types.FORGET_PASS_RESET_SUCCESS,
                    message: response.message
                });
                swal.close!();
                swal("Success", response.message, "success");

                redirect()
            }
        })
        .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
            swal.close!();
            dispatch({
                type: types.FORGET_PASS_RESET_FAILURE,
                message: error.message
            });
        });
    }
}

export const getLogout = () : TAction<TUser> => {
    return (dispatch) => {
        dispatch({
            type: types.LOGOUT_REQUEST,
        });
    };

}

export const resetWelcome = (value: boolean) : TAction<TUser> => {
    return (dispatch) => {
        dispatch({
            type: types.RESET_WELCOME,
            value,
        });
    };

}