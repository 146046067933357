import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Col, Label, Row, Table } from 'reactstrap';
import swal from 'sweetalert';
import { selectProtein, deselectProtein, updateInput, handleBulkProtein } from '../../actions/action.pipeline-input';
import FileSelector from '../../components/FileSelector/FileSelector';
import { TDispatch } from '../../types/action';
import { TPipeline, IPipeline, IMarker } from '../../types/type.pipeline';
import { IStore } from '../../types/store';
import InputCustom from '../Form/InputCustom';
import { readCsvData } from '../../actions/action.file-selector';
import { TSNE, ANALYSIS, HEATPLOT, TCR_ANALYSIS, PFDA, BATCH } from '../../views/Pipeline/Form';
import { IPeptideAnnotation, TPeptideAnnotation } from "../../types/type.peptide-annotation";
import { getSequence } from "../../actions/action.peptide-annotation";

interface IProps {
    input: string;
}

type TRow = {
    name: string;
    keep: boolean;
    [x: string]: any;
}

export const DISPLAY_ID = 'Display_ID';
export const SEQUENCE_COLUMN = 'sequence';

const MetasampleEditor = (props: IProps) => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input: pipelineInput, pipelineId } = pipeline;

    const peptideAnnotation: IPeptideAnnotation = useSelector((state: IStore) => state.peptideAnnotation);
    const dispatchPeptide = useDispatch<TDispatch<TPeptideAnnotation>>();
    const { sequence: peptideSequence } = peptideAnnotation;

    const [columns, setColumns] = useState<string[]>([]);
    const [newColumn, setNewColumn] = useState<string>('');
    const [columnPrefill, setColumnPrefill] = useState<string>('');
    const [input, setInput] = useState<Pick<TRow, string>[]>(pipelineInput.metaSampleEditor || []);
    const [isDefaultMarker, setIsDefaultMarker] = useState<boolean>(_.isEmpty(pipelineInput.metaSample));
    const defaultColumn = ['name', 'keep'];
    const includesDisplayId = [TSNE, HEATPLOT, ANALYSIS, TCR_ANALYSIS];
    const canGenerateSequence = [HEATPLOT];
    const noEditor = [PFDA, BATCH];
    const [isAsc, setIsAsc] = useState<boolean>(false);
    const [isSorted, setIsSorted] = useState<boolean>(false);
    const [sortedColumn, setSortedColumn] = useState<string>('');

    const handleToggleDefaultmarker = (event: any) => {
        event.preventDefault();
        setIsDefaultMarker(!isDefaultMarker);
    }

    const formatName = (name: string) => {
        if (new RegExp(/^s3:\/\/.(.*)$/).test(name)) {
            return name.substring(name.lastIndexOf('/') + 1, name.lastIndexOf('.'));
        }
        return name;
    }

    const handleChangeNewColumn = (e) => {
        const name = e.target.value.replace(/[^a-z0-9]/gi, '');
        setNewColumn(name);
    }

    const addColumn = () => {
        if (_.isEmpty(newColumn)) {
            return;
        }

        if (_.includes(columns, newColumn)) {
            // column already exists
        } else {
            const columnClone = _.clone(columns);
            columnClone.push(newColumn);
            setColumns(columnClone);

            changeColumnValueBulk(input, columnClone);

            setNewColumn('');
            setColumnPrefill('');
        }
    }

    const lookupSeq = (event: any) => {
        event.preventDefault();

        dispatchPeptide(getSequence());
    }

    const changeColumnValue = (column: string, value: string | boolean, index: number) => {
        const inputClone = _.cloneDeep(input);
        _.set(inputClone, `${index}.${column}`, value);
        setInput(inputClone);
        saveMetaSample(inputClone, columns);
    }

    const deleteColumn = (event, columnName: string) => {
        event.preventDefault();
        const columnClone = _.clone(columns);
        _.remove(columnClone, (x) => x === columnName);
        setColumns(columnClone);
        changeColumnValueBulk(input, columnClone, columnName);
    }

    const changeColumnValueBulk = (data: Pick<TRow, string>[], customColumn: string[], columnToDelete?: string) => {
        _.each(input, (item, index) => {
            if (!columnToDelete) {
                _.set(data, `${index}.${newColumn}`, columnPrefill);
            } else {
                _.set(data, index, _.omit(item, [columnToDelete]));
            }
        });
        setInput(data);
        saveMetaSample(data, customColumn);
    }

    const saveMetaSample = (data: Pick<TRow, string>[], customColumn: string[]) => {
        const result = data.map((item) => {
            if (_.includes(includesDisplayId, pipelineId) && _.includes(customColumn, DISPLAY_ID)) {
                return _.pick(item, ['name', DISPLAY_ID, 'keep', ...customColumn]);
            } else {
                return _.pick(item, ['name', 'keep', ...customColumn]);
            }
        });
        setInput(result);
        dispatch(updateInput('metaSampleEditor', result));
    }

    const processData = async (data: string[], isFormatData: boolean = false) => {
        let items: TRow[] = _.cloneDeep(pipelineInput.metaSampleEditor);
        if (_.isEmpty(items)) {
            // sync metasample from input
            items = data.map((item: string) => {
                return {
                    name: isFormatData ? formatName(item) : item,
                    keep: true
                };
            });
            saveMetaSample(items, []);
        } else {
            // sync metasample from current metaSampleEditor
            const dataToCompare = isFormatData ? (_.map(data, (x) => formatName(x))) : data;
            _.each(pipelineInput.metaSampleEditor, (sample) => {
                if (!_.includes(dataToCompare, sample.name)) {
                    _.remove(items, (item) => item.name === sample.name);
                }
            });

            _.each(data, (input) => {
                const formattedName = formatName(input);
                const nameFound = _.find(items, (item) => item.name === formattedName);
                if (!nameFound) {
                    items.push({
                        name: isFormatData ? formatName(input) : input,
                        keep: true
                    });
                }
            });

            // sync custom columns
            const additionalColumns = _.keys(_.omit(pipelineInput.metaSampleEditor[0], defaultColumn));
            const newColumns = _.union(columns, additionalColumns);

            setColumns(newColumns);
            saveMetaSample(items, additionalColumns);
        }
        setInput(items);
    }

    const handleAddDisplayId = (event: any) => {
        event.preventDefault();
        const newColumns = _.union(columns, DISPLAY_ID);
        if (_.includes(includesDisplayId, pipelineId)) {
            const customColumn = DISPLAY_ID;
            if (!_.includes(newColumns, customColumn)) {
                newColumns.push(customColumn);
            }

            _.each(input, (item, index) => {
                _.set(input, `${index}.${customColumn}`, item.name);
            });
        }

        setColumns(newColumns);
        saveMetaSample(input, newColumns);
    }

    const sortTable = (sortBy: string) => {
        const sorting = !isAsc;
        setSortedColumn(sortBy);
        setIsSorted(true);
        setIsAsc(!isAsc);
        setInput(_.orderBy(input,[data => data[sortBy].toLowerCase()],[sorting ? 'asc' : 'desc']));
    }

    useEffect(() => {
        // Don't show Editor for certain pipelines
        if(_.includes(noEditor, pipelineId)){
            setIsDefaultMarker(false);
        }
        if (isDefaultMarker) {
            dispatch(updateInput('metaSample', []));
        } else {
            // dispatch(updateInput('metaSampleEditor', []));
        }
    }, [isDefaultMarker]);

    useEffect(() => {
        const findCsv = _.find(pipelineInput[props.input], (i) => i.split('.').pop() === 'csv');
        if ((!!findCsv)) {
            // if the input is csv file, then fetch the data from s3
            dispatch(readCsvData(
                pipelineInput[props.input],
                (data: any) => processData(data),
                (message: string) => swal('failed', message, 'error')
            ));
        } else {
            processData(pipelineInput[props.input], true);
        }
    }, [pipelineInput[props.input]]);

    useEffect(() => {
        if (input.length > 0) {
            const allKeys = Object.keys(input[0]);
            const inputKeys = Object.keys(input);

            inputKeys.forEach(inputKey => {
                const keys = Object.keys(input[inputKey])
                if (allKeys.length != keys.length) {
                    allKeys.forEach(allKey => {
                        if (!keys.includes(allKey)) {
                            if (allKey == DISPLAY_ID) {
                                _.set(input, `${inputKey}.${allKey}`, input[inputKey].name);
                            } else {
                                _.set(input, `${inputKey}.${allKey}`, '');
                            }

                            saveMetaSample(input, allKeys);
                        }
                    })
                }
            });
        }
    }, [input])

    useEffect(() => {
        if (!_.isEmpty(peptideSequence)) {
            const newColumn = columns;
            const regex = new RegExp(/[ARNDBCEQZGHILKMFPSTWYV]+/);
            let firstKey = '';

            Object.keys(peptideSequence[0]).forEach((key, index) => {
                // ignore first column
                if (index == 0) {
                    firstKey = key;
                    newColumn.push(SEQUENCE_COLUMN);
                } else {
                    if (!_.includes(newColumn, key)) {
                        newColumn.push(key);
                    }
                }
            })

            if (_.includes(canGenerateSequence, pipelineId)) {
                const customColumn = SEQUENCE_COLUMN;
                if (!_.includes(newColumn, customColumn)) {
                    newColumn.push(customColumn);
                }

                _.each(input, (item, index) => {
                    // get item.name text after last underscore (_)
                    const lastName = item.name.substring(item.name.lastIndexOf('_') + 1);

                    // find matches string
                    // if match, set value
                    // else set empty string
                    if (regex.test(lastName)) {
                        _.set(input, `${index}.${customColumn}`, lastName);
                    } else {
                        _.set(input, `${index}.${customColumn}`, '');
                    }

                    // find matches peptide annotation
                    const foundSequence = _.find(peptideSequence, (sequence) => {
                        return sequence[firstKey] == lastName
                    });
                
                    // if match, set value
                    if (foundSequence) {
                        Object.keys(foundSequence).forEach((key, i) => {
                            // ignore first column
                            if (i > 0) {
                                _.set(input, `${index}.${key}`, foundSequence[key]);
                            }
                        })
                    }
                })

                setColumns(newColumn);
                saveMetaSample(input, newColumn);
            }
        }
    }, [peptideAnnotation.sequence])

    return (
        <div className="fcs-config">
            <div className="title">
                {isDefaultMarker &&
                    <span>
                        Select samples from default list, 
                        or <a href="#" onClick={handleToggleDefaultmarker}>use your own meta sample file (.csv)</a>.
                    </span>
                }
                {!isDefaultMarker && !_.includes(noEditor, pipelineId) &&
                    <span>
                        Select your samples list (csv),
                        or <a href="#" onClick={handleToggleDefaultmarker}>select samples from default list</a>.
                    </span>
                }
            </div>

            <div className="body">
                {!isDefaultMarker && (
                    <FileSelector
                        name='metaSample'
                        tray={pipelineInput.metaSample}
                        isMultiple={false}
                        accept={['csv', '.csv']}
                        isShowPreviousPipeline
                    />
                )}

                {isDefaultMarker && (
                    <div className="metasample-editor">
                        <Row className="ml-auto w-100">
                            {!_.includes(columns, DISPLAY_ID) && _.includes(includesDisplayId, pipelineId) && (
                                <Label>
                                    <a href="#" onClick={handleAddDisplayId}>Add {DISPLAY_ID}</a>
                                </Label>
                            )}
                            <Label className="ml-4">Custom Column</Label>
                            <div className="custom-column-input">
                                <InputCustom
                                    type="text"
                                    name="new-column"
                                    placeholder="Custom column..."
                                    value={newColumn}
                                    onChange={handleChangeNewColumn}
                                    disabled={(input.length === 0)}
                                />
                                <InputCustom
                                    type="text"
                                    name="new-column-value"
                                    placeholder="Prefill value..."
                                    value={columnPrefill}
                                    onChange={(e) => setColumnPrefill(e.target.value)}
                                    disabled={(input.length === 0)}
                                />
                                <button
                                    type="button"
                                    onClick={addColumn}
                                    className="btn-primary btn-form-summary"
                                    disabled={(input.length === 0)}>
                                    Add Column
                                </button>
                                <button
                                    type="button"
                                    onClick={lookupSeq}
                                    className="btn-primary btn-form-summary"
                                    disabled={(input.length === 0)}>
                                    Lookup Seq
                                </button>
                            </div>
                        </Row>
                        <Table bordered style={{ marginBottom: '0px' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "5%" }}>No</th>
                                    <th style={{ width: "30%" }}>
                                        <a href="#" className='text-secondary' onClick={(event) => {
                                            event.preventDefault()
                                            sortTable('name');
                                        }}>
                                            <i className={`fa fa-sort-desc` + (!isAsc && isSorted && sortedColumn === 'name' ? ' text-primary' : '')}/>
                                            <i className={`fa fa-sort-asc ml-min-8` + (isAsc && isSorted && sortedColumn === 'name' ? ' text-primary' : '')}/>
                                        </a>
                                        &nbsp;ID
                                    </th>
                                    {_.includes(columns, DISPLAY_ID) && _.includes(includesDisplayId, pipelineId) && (
                                        <th style={{ width: "30%" }}>
                                            <a href="#" className='text-secondary' onClick={(event) => {
                                                event.preventDefault()
                                                sortTable(DISPLAY_ID);
                                            }}>
                                                <i className={`fa fa-sort-desc` + (!isAsc && isSorted && sortedColumn === DISPLAY_ID ? ' text-primary' : '')}/>
                                                <i className={`fa fa-sort-asc ml-min-8` + (isAsc && isSorted && sortedColumn === DISPLAY_ID ? ' text-primary' : '')}/>
                                            </a>
                                            &nbsp;{DISPLAY_ID}
                                            <button
                                                className="btn-danger button-rounded ml-2"
                                                onClick={(e) => deleteColumn(e, DISPLAY_ID)}>
                                                <i className="fa fa-remove"></i>
                                            </button>
                                        </th>
                                    )}
                                    <th style={{ width: "5%" }}>Keep</th>
                                    {columns.map((column, index) => {
                                        if (column !== DISPLAY_ID) {
                                            return(
                                                <th key={`head-${index}`}>
                                                    <a href="#" className='text-secondary' onClick={(event) => {
                                                        event.preventDefault()
                                                        sortTable(column);
                                                    }}>
                                                        <i className={`fa fa-sort-desc` + (!isAsc && isSorted && sortedColumn === column ? ' text-primary' : '')}/>
                                                        <i className={`fa fa-sort-asc ml-min-8` + (isAsc && isSorted && sortedColumn === column ? ' text-primary' : '')}/>
                                                    </a> 
                                                    &nbsp;{column}
        
                                                    <button
                                                        className="btn-danger button-rounded ml-2"
                                                        onClick={(e) => deleteColumn(e, column)}>
                                                        <i className="fa fa-remove"></i>
                                                    </button>
                                                </th>
                                            )
                                        }
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {input.map((item, index: number) => (
                                    <tr key={`channel-${index}`}>
                                        <td>{index + 1}</td>
                                        {Object.keys(item).map((key, i) => {
                                            if (key === 'name') {
                                                return(
                                                    <td key={`key-${i}`}>{item[key]}</td>
                                                )
                                            } else if (key === 'keep') {
                                                return (
                                                    <td key={`key-${i}`}>
                                                        <input
                                                            type="checkbox"
                                                            name="keep"
                                                            checked={item.keep}
                                                            onChange={(e) => {
                                                                changeColumnValue('keep', !item.keep, index);
                                                            }}
                                                        />
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td key={`key-${i}`}>
                                                        <InputCustom
                                                            type="text"
                                                            name={item[key]}
                                                            value={_.get(item, key, '')}
                                                            onChange={(e) => {
                                                                changeColumnValue(key, e.target.value, index);
                                                            }}
                                                        />
                                                    </td>
                                                )
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MetasampleEditor;
