import * as types from '../reducers/actionTypes';
import { TAction } from "../types/action";
import { TVersionNotif } from "../types/type.version-notification";
import package_json from '../../package.json';

export const getVersion = (
    version : string
) : TAction<TVersionNotif> => {
    return (dispatch) => {
        if (version) {
            dispatch({
                type: types.GET_VERSION,
                data: !(version == package_json.version) // set false if version is equal
            });
        }
    }
}