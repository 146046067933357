import _ from 'lodash';
import { orderBy } from 'natural-orderby'
import * as types from '../reducers/actionTypes';
import { s3UploadFiles } from './action.s3';
import { TAction } from '../types/action';
import { TPipeline, IMarker } from '../types/type.pipeline';
import { GET, POST } from '../utils/httprequest';
import { IMetadataResponse } from '../types/type.response';
import { DEBARCODE_STEP, GATING_STEP } from '../utils/constants';
import { updateInput } from './action.pipeline-input';
import { uploadCoordinatesFiles } from './action.coordinates-files';
import { getVersion } from './action.version-notification';

const TETRAMER = 4;
const TETDECON_2021 = 17;
const MANUAL_TETDECON_2021 = 18;
const MANUAL_GATING = 9;

/**
 * This function will add selected file from the FileSelector component
 * to pipeline's tray, e.g state.input.trayInput, state.input.trayTable, etc.
 *
 * @param {*} trayName
 * @param {*} filePath
 */
export const addFileToTray = (
  trayName: string,
  filePath: string
) : TAction<TPipeline> => (dispatch) => {
  dispatch({
    type: types.PIPELINE_ADD_FILE,
    name: trayName,
    value: filePath,
  });
};

/**
 * This function will remove a selected file from tray.
 * This works like addFileToTray(), just in the opposite way.
 *
 * @param {*} trayName
 * @param {*} filePath
 */
export const removeFileFromTray = (
  trayName: string,
  metadataName: string,
  filePath: string
) : TAction<TPipeline> => (dispatch, getState) => {
  // check if the tray will be empty after removal of the file
  // i.e. there's only one item in the tray.
  // If the tray will be empty, then clear the names file / metadata / protein.
  const { input } = getState().pipeline;
  if (input[trayName].length <= 1 && input[metadataName]) {
    dispatch({
      type: types.PIPELINE_CHANGE_INPUT,
      name: metadataName,
      value: [],
    });
  }

  dispatch({
    type: types.PIPELINE_REMOVE_FILE,
    name: trayName,
    value: filePath,
  });
};

export const removeFileFromTrayWithoutClearingProtein = (
  trayName: string,
  filePath: string
) : TAction<TPipeline> => (dispatch) => {
  dispatch({
    type: types.PIPELINE_REMOVE_FILE,
    name: trayName,
    value: filePath,
  });
};

const filterMarker = (data: Array<IMarker>): Array<IMarker> => {
  const filtered = _.map(data, (n) => {
    n.tag = n.original.tag;
    return n;
  });
  return filtered;
}

const selectMarker = (data: Array<IMarker>, pipelineId: number, isLifeDeathGating: boolean) => {
  const multiColumnPipeline = [TETDECON_2021, MANUAL_TETDECON_2021];
  const filteredData = _.includes(multiColumnPipeline, pipelineId) ? filterMarker(data) : data;
  let columns: string[] = [];
  if (pipelineId === TETRAMER) {
    columns = ['sav'];
  } else if (_.includes(multiColumnPipeline, pipelineId)) {
    columns = ['keep', 'sav'];
  } else {
    columns = ['keep'];
  }

  const SAV_TEST = /SAV|TT/gi;
  const NOT_TETRAMER_TEST = /pmhc$|sav|tt|bc|dna|cisp|ig/i;
  const OTHER_TEST = /intercalator|anti[-_]?pe/i;
  const NOT_GATING_TEST = /cd3$|cd4$|cd8$|cd14$|cd19$|cd45$|cd3[_-].*$|cd4[_-].*$|cd8[_-].*$|cd14[_-].*$|cd19[_-].*$|cd45[_-].*$/i;
  const NUMBER_ONLY = pipelineId === TETDECON_2021 || pipelineId === MANUAL_TETDECON_2021 ? /^\d+$/ : /^\d+[^_-]+$/;
  const TIGIT_TEST = /tigit/i;

  // custom logics for life gating preprocessing
  const GATING_PREPROCESSING_TEST = /(CD3|CD4|CD8|CD19|CD14|DNA|Cisplatin)$/gi;
  if (isLifeDeathGating) {
    return _.map(filteredData, (protein) => {
      if (new RegExp(GATING_PREPROCESSING_TEST).test(protein.name)) {
        protein.keep = true;
      }
      return protein;
    });
  }

  const savData = _.cloneDeep(filteredData);
  const selectSav = _.map(savData, (protein) => {
    if (new RegExp(SAV_TEST).test(protein.name) || new RegExp(NUMBER_ONLY).test(protein.name)) {
      protein.sav = true;
    }
    return protein;
  });
  
  // sav only
  if (columns.length === 1 && columns[0] === 'sav') {
    return selectSav;
  }

  // if contains 'sav' that mean select both 'keep' and 'sav'. Otherwise only select 'keep'
  const initialData = _.includes(columns, 'sav') ? selectSav : filteredData;
  return _.map(initialData, (protein) => {
    if (
      !new RegExp(NOT_TETRAMER_TEST).test(protein.name) &&
      !new RegExp(OTHER_TEST).test(protein.name) &&
      !new RegExp(NOT_GATING_TEST).test(protein.name) &&
      !new RegExp(NUMBER_ONLY).test(protein.name)
    ) {
      protein.keep = true;
    }

    if (pipelineId === MANUAL_GATING && new RegExp(NOT_GATING_TEST).test(protein.name)) {
      protein.keep = true;
    }

    if (new RegExp(TIGIT_TEST).test(protein.name)) {
      protein.keep = true;
    }

    return protein;
  });
};

const readMeta = (text: string): IMarker[] => {
  let hasTap = false;
  const NUMBER_ONLY = /^\d+[^_-]+$/;
  let SKIP_TEST = '^VIR|^TAA|beadDist|Amplitude|Center|Offset|Residual|Sample|Width|excoord|tetstain|^hit|originalHitlist|tSNE|Phate|OneSENSE|Isomap|DiffMap|^PC|^PV|UMAP|Phenograph|FlowSOM|Infile';
  SKIP_TEST += '|Event|Time';
  const TAP_TEST = '^TAP';

  const cleanUnknownCharacter = (word: string) => {
    let newWord = word.replace(/\s+/g, '_');
    newWord = newWord.replace(/™/g, '');
    newWord = newWord.replace(/<e2><84><a2>/g, '');
    return newWord;
  }

  const trimClean = (word: string) => {
    let newWord = _.clone(word);
    newWord = newWord.replace(/[^\w._+-]+/g, ' ');
    newWord = newWord.trim();
    newWord = newWord.replace(/\s+/g, '_');
    newWord = newWord.replace(/_+/g, '_');
    return newWord;
  }

  let delimiterCount = 0;
  let delimiterText = '';
  const options = [
    { count: 0, test: '\\|', text: '|' },
    { count: 0, test: '\\\\', text: '\\'},
    { count: 0, test: '', text: ''},
    { count: 0, test: '\\/', text: '/'}
  ];
  _.map(options, (item) => {
    if (text.match(new RegExp(item.test, 'gi'))) {
      item.count = text.match(new RegExp(item.test, 'gi'))!.length;
    }
    if (item.count > delimiterCount) {
      delimiterCount = item.count;
      delimiterText = item.text;
    }
    return item;
  });

  const final = {};
  const output: Array<IMarker> = [];
  const result: Array<string> = text.split(delimiterText);
  result.shift();
  result.forEach((val, i) => {
    if (i % 2 === 1) return;
    final[val] = result[i + 1];
  });
  
  // grouping by KeyboardEvent. example $P23N and $P23S
  const grouped: any = {};
  const markerTest = new RegExp('^\\$P+[1-9]', 'i');
  const nameTest = new RegExp('N$', 'i') // N = name
  const descTest = new RegExp('S$', 'i') // S = description

  Object.keys(final).forEach((val, i) => {
    if (markerTest.test(val) && String(final[val]).trim() !== '') {

      // remove entries if contain 'TAP'
      if (new RegExp(TAP_TEST).test(final[val])) {
        if (!hasTap) hasTap = true;
        return;
      }

      // remove entries that match skip pattern
      if (new RegExp(SKIP_TEST).test(final[val])) {
        return;
      }
  
      // initialize object
      const key = val.replace(/[A-Z]+$/i, '');
      if (_.isUndefined(grouped[key])) {
        grouped[key] = {};
      }

      if (nameTest.test(val)) {
        grouped[key].name = final[val];
      } else if (descTest.test(val)) {
        grouped[key].desc = final[val];
        grouped[key].tag = final[val];
      }
    }
  });

  _.each(grouped, (item) => {
    if (item.desc) {
      // 1. Remove some spurious stuff from name
      let name = '';
      if (item.name) {
        name = _.clone(item.name);
        name = name.replace(/FJComp-/, '');
        name = trimClean(name);
      }

      // 2. Remove some spurious stuff from desc
      let desc = _.clone(item.desc);
      desc = cleanUnknownCharacter(desc);
      desc = trimClean(desc);

      // 3. Retain only what is after the first _ for each marker (now, skip this step completely)
      // let descNew = desc;
      // let descModified = desc.replace(/^[^_]*_/, '');
      // if (new RegExp(NUMBER_ONLY).test(descModified) || descModified.length < 3 || hasTap) {
      //   descNew = desc;
      // } else {
      //   descNew = descModified
      // }

      output.push({
        name: desc,
        keep: false,
        sav: false,
        original: { name, desc, tag: item.tag }
      });
    }
  });

  // 4. check duplicate 1st step
  let names: Array<any> = [];
  const outputClone = _.cloneDeep(output);
  _.each(outputClone, (o) => {
    if(_.includes(names, o.name)){
      _.each(_.filter(outputClone, (f) => f.name === o.name), (d) => {
        // replace duplicate
        d.name = d.original.desc;
      })
    } else {
      names.push(o.name);
    }
  });

  // 5. check duplicate 2nd step
  names = [];
  _.map(outputClone, (o) => {
    if(_.includes(names, o.name)){
      // handle duplicate
      _.each(_.filter(outputClone, (f) => f.name === o.name), (d) => {
        // replace duplicate
        d.name = `${d.original.desc}_${d.original.name}`;
      });
    } else {
      names.push(o.name);
    }
  });

  // 6. remove TotalC at the end
  // _.map(outputClone, (protein) => {
  //   protein.name = protein.name.replace(/_TotalC$/, '');
  //   // protein.original.tag = protein.original.tag.replace(/_TotalC$/, '');
  //   return protein;
  // });


  return outputClone;
};

const readMetaFromCsv = (text: string): IMarker[] => {
  const firstLine = (text.split('\n').shift() as string)
  const output: Array<IMarker> = [];
  const markers = firstLine.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).slice(1);
  _.each(markers, (marker) => {
    if (markers.length === 0) return;
    const name = marker.trim().replace(/(^"|"$)/g, '');
    output.push({
      name,
      keep: false,
      sav: false,
      original: {
        name,
        desc: name,
        tag: name,
      }
    });
  })
  return output;
}

/**
 * Update input protein. Ideally, this is run as the
 * next action after reading namesfile from FCS
*/
export const updateInputProtein = (
  name: string, 
  metadata: Array<IMarker>,
  onSuccess: Function,
  onFail: Function,
) : TAction<TPipeline> => (dispatch, getState) => {
  const { input } = getState().pipeline;
  const protein = input[name];

  // dispatch if protein/metadata is empty. No need to do comparison
  // straight away add metadata to redux and continue with
  // onSuccess, then finished!
  if (!protein || protein.length === 0) {
    dispatch({
      type: types.PIPELINE_CHANGE_INPUT,
      name: name,
      value: metadata,
    });
    onSuccess();
    return;
  }

  // compare with input.protein in redux
  // we just need to compare the 'name' key
  if (metadata.length !== protein.length) {
    onFail();
    return;
  }

  const arr1 = metadata.map((element => element.name));
  const arr2 = protein.map((element => element.name));
  const equal = _.isEqual(arr1.sort, arr2.sort);
  if (!equal) {
    onFail();
    return;
  }

  onSuccess();
};

/**
 * Read FCS metadata from blob file
 * @param {File} file
*/
export const readFcsMetadata = (
  name: string,
  file: File,
  onSuccess: Function,
  onFail: Function, 
) : TAction<TPipeline> => (dispatch, getState) => {
  const { pipeline } = getState();
  const { coordinatesFiles } = getState();
  const { pipelineId } = pipeline;
  // make sure that file is FCS.
  // we ensure this simply by comparing the extension
  const filename = file.name;
  const extension = filename.split('.').pop() || '';

  const readers = {
    'csv': readMetaFromCsv,
    'fcs': readMeta,
  }
  const metadataReader = readers[extension.toLocaleLowerCase()];
  if (!metadataReader) {
    return;
  }

  const reader = new FileReader();

  reader.onload = () => {
    const text: string = (reader.result as string);
    const firstLine: string = (text.split('\n').shift() as string);
    const result = metadataReader(firstLine);
    const findText = '$BEGINANALYSIS';
    const findIndex = text.indexOf(findText);

    const metadata = selectMarker(result, Number(pipelineId), false);
    
    const splitText = (text.toLowerCase().split(firstLine[findIndex+findText.length]));
    const indexUuid: number = splitText.indexOf('gating_uuid');

    let filesToUpload = coordinatesFiles.filesToUpload;

    const duplicatedFiles = filesToUpload.findIndex(x => x.name == filename);
    if (duplicatedFiles < 0) {
      if(indexUuid > 0) {
        filesToUpload.push({
          name: filename,
          uuid: splitText[indexUuid+1]
        });
      } else {
        filesToUpload.push({
          name: filename,
          uuid: ''
        });
      }
    }
      
    dispatch(uploadCoordinatesFiles(filesToUpload));

    const sortedMetadata = orderBy(
      metadata,
      [(v : { name: any }) => v.name],
      ['asc']
    );

    dispatch(updateInputProtein(name, sortedMetadata, onSuccess, onFail));
  };

  reader.onerror = () => {
    onFail('Error when loading metadata file. Please contact administrator');
  }
  // read file 50kb only
  reader.readAsText(file.slice(0, 50 * 1024), 'UTF-8');
};

/**
 * Upload FCS Metadata (names file) to s3 for future usage
 * If the FCS file is stored in workspace as: {bucket}/workspace/abcd.fcs
 * Then, the metadata/names file will be stored in: {bucket}/fcs-metadata/abcd.json
*/
export const uploadFcsMetadata = (
  fcsFilename: string,
  jsonSerialisedString: string,
  projectId: any = null,
  folder: string = 'fcs-metadata/'
) : TAction<TPipeline> => (dispatch, getState) => {
  if (!projectId) {
    projectId = getState().pipeline;
  }
  const lastIndex = fcsFilename.lastIndexOf('.');
  let filename = '';
  if (lastIndex > 0) {
    filename = `${fcsFilename.substring(0, fcsFilename.lastIndexOf('.'))}.json`;
  } else {
    filename = `${fcsFilename}.json`;
  }

  const files: Array<File> = [];
  const file = new File([jsonSerialisedString], filename, {
    type: 'text/plain',
  });
  files.push(file);

  dispatch(s3UploadFiles(files, projectId, folder));
};


export const readFcsMetadataFromS3 = (
  name: string,
  fcsFile: { key: string, s3Url: string },
  onSuccess: Function,
  onFail: Function
) : TAction<TPipeline> => (dispatch, getState) => {
  // if need to check FCS metadata before adding file to tray then:
  // 1. get the FCS metadata from S3
  // 3. Compare with input.protein in redux
  // 4. If same, then add the file to tray and update redux input.protein
  // 5. Else, throw a sweetalert error screen
  const { pipeline, uiHistory, batch } = getState();
  const { projectId, pipelineId } = pipeline;
  const { selected_batch } = batch;
  const { key, s3Url } = fcsFile;

  GET(`project/${projectId}/namesfile`, { params: { key, s3Url } })
  .then((response: IMetadataResponse) => {
    const { coordinatesFiles } = getState();
    dispatch(getVersion(response.app_version));
    let isFailedLifeGating = selected_batch.currentStep === GATING_STEP;

    if (isFailedLifeGating) {
      const stepIndex = selected_batch.steps.length-1;
      if (selected_batch.steps[stepIndex].step === GATING_STEP && selected_batch.steps[stepIndex].status === 'failed') {
        isFailedLifeGating = true;
      } else {
        isFailedLifeGating = false;
      }
    }

    const isLifeDeathGating = uiHistory.pipelineType === 'preprocessing' && (selected_batch.currentStep == DEBARCODE_STEP || isFailedLifeGating);
    const metadata = selectMarker(response.data.namesfile, Number(pipelineId), isLifeDeathGating);
    const sortedMetadata = orderBy(
      metadata,
      [(v : { name: any }) => v.name],
      ['asc']
    );

    const filesToUpload = coordinatesFiles.filesToUpload;

    const duplicatedFiles = filesToUpload.findIndex(x => x.name == response.data.fileName);
    
    if (duplicatedFiles < 0) {
      filesToUpload.push({
        name: response.data.fileName,
        uuid: response.data.uuid
      });
    }

    dispatch(uploadCoordinatesFiles(filesToUpload));

    dispatch(updateInputProtein(name, sortedMetadata, onSuccess, onFail));
    // dispatch(updateInput('protein', sortedMetadata))
  })
  .catch((error) => {
    if (error.response) {
      dispatch(getVersion(error.response.data.app_version));
      const response = error.response.data;
      if (response.status === 'error') {
        onFail(response.message);
        return;
      }
    }
    onFail('Error when loading metadata file. Please contact administrator');
  });
};

export const readCsvData = (
  files: string[],
  onSuccess: Function,
  onFail: Function
) : TAction<TPipeline> => (dispatch, getState) => {
  const { pipeline } = getState();
  const { projectId } = pipeline;

  POST(`project/${projectId}/concatenate-csv`, { files })
  .then((response) => {
    dispatch(getVersion(response.app_version));
    onSuccess(response.data);
  })
  .catch((error) => {
    if (error.response) {
      dispatch(getVersion(error.response.data.app_version));
      const response = error.response.data;
      if (response.status === 'error') {
        onFail(response.message);
      }
    }
  });

};
