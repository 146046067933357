import initialState from './initialState';
import * as types from './actionTypes';
import { IHelp, THelp } from '../types/type.help';

export default function(state : IHelp = initialState.help, action: THelp) : IHelp {
    switch (action.type){
        case types.CREATE_HELP_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.CREATE_HELP_SUCCESS:
            return{
                ...state,
                message: action.data,
                isLoading: false,
            }
        case types.CREATE_HELP_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message,
            }
        default: return state;
    }   
}