import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { isError } from 'lodash';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalFooter, ModalBody, Progress, FormGroup, Input } from 'reactstrap';
import FileBrowser, { Icons } from 'react-keyed-file-browser';
import Dropzone from 'react-dropzone';
import {
  s3GetFiles,
  s3CreateFolder,
  s3UploadFiles,
  s3GetDownloadUrl,
  deleteObject,
  renameObject,
  workspaceDownload,
} from '../../actions/action.s3';
import { readFcsMetadata } from '../../actions/action.file-selector';
import * as constants from '../../utils/constants';
import { TS3, IS3 } from '../../types/type.s3';
import { TDispatch } from '../../types/action';
import { IStore } from '../../types/store';
import { IProps, IFolder, IUpload, IUploadProgress, IDetail } from './FileBrowser.types';
import moment from 'moment';

const CytoFileBrowser = ({ error } : IProps) => {
  const projectId: number = useSelector((state : IStore) => state.projects.selected_project.id)!;
  const s3: IS3 = useSelector((state : IStore) => state.s3);
  const dispatch = useDispatch<TDispatch<TS3>>();
  const {
    files, toBeUploadedCount, uploadingPercentage, uploadDone,
    isLoading, isError
  } = s3;

  const [localFiles, setLocalFiles] = useState<Array<any>>(files);
  const [filesToUpload, setFilesToUpload] = useState<Array<File>>([]);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [selectedFolder, setSelectedFolder] = useState<string>(`${constants.FILE_EXPLORER_WORKSPACE_FOLDER}/`);

  const [selectedFile, setSelectedFile] = useState<string>('');
  const [selectedUrl, setSelectedUrl] = useState<string>('');

  const [showFolderDetail, setShowFolderDetail] = useState<boolean>(false);
  const [showFileDetail, setShowFileDetail] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState<boolean>(false);
  const [newFolderName, setNewFolderName] = useState<string>('');

  const [showRenameFileModal, setShowRenameFileModal] = useState<boolean>(false);
  const [newFileName, setNewFileName] = useState<string>('');

  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [zipFileName, setZipFileName] = useState<string>('');

  const [retryCount, setRetryCount] = useState<number>(0);

  const rootFolders = [
    constants.FILE_EXPLORER_ADMIN_FOLDER,
    constants.FILE_EXPLORER_WORKSPACE_FOLDER,
    constants.FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER,
    constants.FILE_EXPLORER_DISCOVERY,
  ];

  const writeProtectedFolders = [ // user cannot write this folder
    constants.FILE_EXPLORER_ADMIN_FOLDER,
  ]

  const isFolderDeletable = (): boolean => {
    // const { selectedFolder: folder, localFiles } = this.state;

    // cannot delete if it's a root folder
    if (rootFolders.find((element: string) => element === selectedFolder) !== undefined) {
      return false;
    }

    // cannot delete if the folder is not empty
    let count = 0;
    for (let i = 0; i < localFiles.length; i++) {
      const element = localFiles[i];
      if (element.key.substring(0, selectedFolder.length) === selectedFolder) {
        count++;
      }
      if (count > 1) {
        return false;
      }
    }

    return true;
  }

  const isRootFolder = () => {
    // cannot delete if it's a root folder
    if (rootFolders.find((element: string) => `${element}/` === selectedFolder) !== undefined) {
      return true;
    }
    return false;
  }

  const isFolderWritable = () : boolean => {
    // const { selectedFolder: folder } = this.state;

    // cannot delete if it's write protected folder
    for (let i = 0; i < writeProtectedFolders.length; i++) {
      const protectedFolder = writeProtectedFolders[i];
      if (selectedFolder.indexOf(protectedFolder) === 0) {
        return true;
      }
    }

    return false;
  }

  const isPreviousFile = (key: string = selectedFile) => {
    return key.indexOf(constants.FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER) === 0;
  }

  const isPreviousFolder = (key: string = selectedFolder) => {
    return key.indexOf(constants.FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER) === 0;
  }

  const isDiscoveryFile = (key: string = selectedFile) => {
    return key.indexOf(constants.FILE_EXPLORER_DISCOVERY) === 0;
  }

  const isDiscoveryFolder = (key: string = selectedFolder) => {
    return key.indexOf(constants.FILE_EXPLORER_DISCOVERY) === 0;
  }

  const handleCreateFolder = (key: string) => {
    console.log(key);
    dispatch(s3CreateFolder(projectId, key));
  }

  const handleDeleteFile = () => {
    // const { selectedFile: key, files } = this.state;
    const key = selectedFile;
    const localFilesClone = _.cloneDeep(localFiles);
    const index = localFilesClone.findIndex(element => element.key === key);

    if (index >= 0) {
      // delete file in state
      localFilesClone.splice(index, 1);
      setLocalFiles(localFilesClone);
      // hide the file detail widget
      setSelectedFile('');
      setShowFileDetail(false);

      dispatch(deleteObject(key));
    }
  }

  const handleCreateFiles = (files: Array<File>, prefix: string) => {
    return;
  }

  const handleSelectFolder = (folder: { key: any; }) => {
    if (!folder) {
      // setSelectedFolder('');
      setShowFolderDetail(false);
      return;
    }
    // this.props.selectFolder(folder.key);
    // this.setState({
    //   ...state,
    //   selectedFolder: folder.key,
    //   showFolderDetail: true,
    //   showFileDetail: false,
    // });
    setSelectedFolder(folder.key);
    setShowFolderDetail(true);
    setShowFileDetail(false);

    // get the files of this folder
    if (!isPreviousFolder(folder.key)) {
      dispatch(s3GetFiles(projectId, folder.key, isDiscoveryFolder(folder.key)));
    }

    // get fcs metadata of this folder
    // this.getFolderMetadata(folder.key);
  }

  const handleRenameFolder = (oldKey: string, newKey: string) => {
    console.log(oldKey, newKey);
  }

  const handleRenameFile = (oldKey: string, newKey: string) => {
    console.log(oldKey, newKey);
    setSelectedFile(newKey);
    dispatch(renameObject(oldKey, newKey));
  }

  const handleRenameZip = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setZipFileName(value);
  }

  const toggleDownloadFolder = () => {
    if (!showDownloadModal && !zipFileName) {
      const initialFilename = selectedFolder.replace(/\//g, '_');
      const now = moment().format('YYYY-MM-DDTHH_mm_ss');
      setZipFileName(`${initialFilename}_${now}.zip`);
    }
    setShowDownloadModal(!showDownloadModal);
  }

  const handleDownloadFolder = () => {
    let name = zipFileName.replace(/\s+/g, '-');
    if (!new RegExp(/.zip$/).test(name)) {
        if (name.length > 251) {
            name = name.substring(0, 251);
        }
        name += '.zip';
    }
    toggleDownloadFolder();
    setZipFileName('');
    let selectedFolderKey = selectedFolder;
    if (isPreviousFolder() || isDiscoveryFolder()) {
      const condition = new RegExp(selectedFolder, 'g');
      const originalFile = _.find(files, function(file) { return condition.test(file.key) });

      if (originalFile) {
        const folderHierarchy = selectedFolder.split('/');
        const originalFolderHierarchy = originalFile.originalKey!.split('/');
        _.remove(folderHierarchy, _.isEmpty);
        const takeLength = (folderHierarchy.length > 2) ? folderHierarchy.length : folderHierarchy.length - 1;
        const originalKey = _.take(originalFolderHierarchy, takeLength);
        selectedFolderKey = `${originalKey.join('/')}/`;
      }
    }
    dispatch(workspaceDownload(selectedFolderKey, name));
  }

  const confirmDeleteFolder = () => {
    // const { selectedFolder: key, files } = this.state;
    const key = selectedFolder;
    const localFilesClone = _.cloneDeep(localFiles);
    const index = localFilesClone.findIndex(element => element.key === key);

    if (index >= 0) {
      // delete file in state
      localFilesClone.splice(index, 1);
      setLocalFiles(localFilesClone);
      // hide the file detail widget
      setSelectedFolder('');
      setShowFolderDetail(false);

      dispatch(deleteObject(key, !isFolderDeletable()));
    }
    
  }

  const handleDeleteFolder = () => {
    if (!isFolderDeletable()) {
      // @ts-ignore
      swal({ buttons: true,
          title: "Are you sure?",
          text: "Folder is not empty. This process is permanent and cannot be undone",
          icon: "warning",
          dangerMode: true,
      })
      .then((willDelete: boolean | null) => {
          if (willDelete) {
            confirmDeleteFolder();
          } else {
            return;
          }
      });
    } else {
      confirmDeleteFolder();
    }
  }

  const handleSelectFile = (file: any) => {
    const filename = file.key;
    const folder = filename.substring(0, filename.lastIndexOf('/') + 1);

    // this.setState({
    //   ...state,
    //   selectedFile: file.key,
    //   showFileDetail: true,
    //   selectedFolder: folder,
    // });
    setSelectedFile(file.key);
    setShowFileDetail(true);
    setSelectedFolder(folder);
    setNewFileName(file.key.split('/').pop());

    if (isPreviousFile(file.key)) {
      dispatch(s3GetDownloadUrl({ ...file, key: file.originalKey }));
    } else {
      dispatch(s3GetDownloadUrl(file));
    }
  }

  const handleUpload = () => {
    setIsUploading(true);
    // upload the files    
    dispatch(s3UploadFiles(filesToUpload, projectId, selectedFolder));
  }

  // /**
  //  * Check if file is an FCS file
  //  *
  //  * @memberof CytoFileBrowser
  //  */
  // isFcsFile = (file) => {
  //   const filename = file.name;
  //   const extension = filename.split('.').pop();
  //   if (extension !== 'fcs' && extension !== 'FCS') {
  //     return false;
  //   }
  //   return true;
  // }

  // /**
  //  * Go and check FCS metadata from the list of the files to be uploaded. 
  //  * First, check if there's a need to read the metadata (i.e. if the file is FCS, 
  //  * or if the metadata has been read before).
  //  * After reading the metadata, check if the metadata of the FCS file is compatible
  //  * with the folder's metadata. 
  //  * Finally, store the metadata compatibility in the state.
  //  */
  // checkFcsMetadata = () => {
  //   const { filesToUpload: files, filesToUploadFcsMetadata: metadatas } = this.state;
  //   const newFiles = files.map((file) => {
  //     if (!this.isFcsFile(file)) {
  //       return file;
  //     }

  //     // i.e. metadata has been read before, then no need to read the metadata anymore
  //     if (metadatas.find(element => element.key === file.name) !== undefined) {
  //       return file;
  //     }

  //     // then read the FCS metadata. Upon success, update the state
  //     const onSuccess = (file, metadata) => {
  //       const isCompatible = this.isMetadataCompatibleWithFolder(metadata);
  //       metadatas.push({ key: file.name, value: isCompatible });

  //       this.setState({ filesToUploadFcsMetadata: metadatas }, () => console.log('onSuccess finished'));
  //     }
  //     this.props.readFcsMetadata(file, onSuccess);
  //   });
  // }

  const handleAddFile = (files: Array<File>, rejected: Array<File>) => {
    const readyFiles = _.cloneDeep(filesToUpload);
    const newFiles = _.unionBy(readyFiles, files, 'name');
    setFilesToUpload(newFiles);
    console.log(readyFiles);
  }

  const toggleUpload = () => {
    setShowUploadModal(!showUploadModal);
    setFilesToUpload([]);
    setIsUploading(false);
  }

  const toggleCreateFolder = () => {
    setNewFolderName('');
    setShowCreateFolderModal(!showCreateFolderModal);
  }

  const toggleRenameFile = () => {
    // setNewFileName('');
    setShowRenameFileModal(!showRenameFileModal);
  }

  const handleRetry = () => {
    // e.preventDefault();
    dispatch(s3GetFiles(projectId, ''));
  }

  const renderCreateFolderModal = (props: IFolder) => {
    return (
      <Modal
        isOpen={props.showCreateFolderModal}
        toggle={props.toggleCreateFolder}
        className="modal-create-folder"
      >
        <ModalHeader
          toggle={toggleCreateFolder}
        >
          Enter Folder Name
        </ModalHeader>
        <ModalBody>
          <code>{props.selectedFolder}</code>
          <input className='new-sub-folder-name'
            value={props.newFolderName}
            onChange={(e) => {
              const newFolderName = e.target.value.replace(/[^a-z0-9\-\_]/gi, '');
              setNewFolderName(newFolderName);
            }}

          />
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-labeled btn-danger"
            onClick={() => {
              setNewFolderName('');
              setShowCreateFolderModal(false);
            }}
          >
            <span className='btn-label'>
              <i className='fa fa-times'></i>
            </span>
            Cancel
          </button>
          <button
            className="btn btn-labeled btn-primary"
            onClick={() => {
              const folderName = `${props.selectedFolder}${props.newFolderName}/`
              handleCreateFolder(folderName);
              setNewFolderName('');
              setShowCreateFolderModal(false);
            }}
          >
            <span className='btn-label'>
              <i className='fa fa-check'></i>
            </span>
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  const renderRenameFileModal = () => {
    return (
      <Modal
        isOpen={showRenameFileModal}
        toggle={toggleRenameFile}
        className="modal-rename-file"
      >
        <ModalHeader
          toggle={toggleRenameFile}
        >
          Enter New File Name
        </ModalHeader>
        <ModalBody>
          <div className="input-wrapper">
            <code>{selectedFolder}</code>
            <input className='rename-file-input'
              value={newFileName}
              onChange={(e) => {
                setNewFileName(e.target.value);
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-labeled btn-danger"
            onClick={() => {
              setNewFileName('');
              setShowRenameFileModal(false);
            }}
          >
            <span className='btn-label'>
              <i className='fa fa-times'></i>
            </span>
            Cancel
          </button>
          <button
            className="btn btn-labeled btn-primary"
            onClick={() => {
              const newFile = `${selectedFolder}${newFileName}`;
              handleRenameFile(selectedFile, newFile);
              setShowRenameFileModal(false);
            }}
          >
            <span className='btn-label'>
              <i className='fa fa-check'></i>
            </span>
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  const renderDownloadModal = () => {
    return (
      <Modal isOpen={showDownloadModal} toggle={toggleDownloadFolder}>
        <ModalHeader toggle={toggleDownloadFolder}>Download as...</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              value={zipFileName}
              onChange={handleRenameZip}
              placeholder="File Name..."
              onFocus={(e) => e.target.select()}
              maxLength={255}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-labeled btn-danger"
            onClick={() => {
              setZipFileName('');
              toggleDownloadFolder();
            }}
          >
            <span className='btn-label'>
              <i className='fa fa-times'></i>
            </span>
            Cancel
          </button>
          <button className="btn btn-labeled btn-primary" onClick={handleDownloadFolder} >
            <span className='btn-label'>
              <i className='fa fa-check'></i>
            </span>
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  const renderUploadModal = (props: IUpload) => {
    const fileItem = props.filesToUpload.map((file) => {
      return (
        <li key={file.name}>
          {file.name}
        </li>
      );
    });

    const successfullyUploaded = fileItem.length - toBeUploadedCount;
    const progress = (successfullyUploaded / fileItem.length) * 100 + (uploadingPercentage / fileItem.length);

    const dropzoneStyle = {
      width: "100%",
      height: "auto",
      border: "1px dashed #ccc"
    }

    const dropzoneStyleActive = {
      border: "1px solid #ddd",
      backgroundColor: "#eee",
      height: "auto",
    }

    return (
      <Modal
        isOpen={props.showUploadModal}
        toggle={props.toggleUpload}
        className="modal-upload"
      >
        <ModalHeader>
          Upload Files to Workspace
        </ModalHeader>
        <ModalBody>
          Uploading a folder is not supported. Files will be uploaded to Cytographer folder:
          <code>{props.selectedFolder}</code>

          {!props.isUploading &&
            <div className="upload-zone">
              <div className="dropzone">
                <Dropzone
                  onDrop={handleAddFile}
                  multiple={true}
                  accept={''}
                  style={dropzoneStyle}
                  activeStyle={dropzoneStyleActive}
                >
                  <span className='btn btn-primary btn-sm add-file-btn'>
                    <i className="fa fa-plus"></i>&nbsp;
                    Click here to select files.
                  </span>
                  {fileItem.length > 0 &&
                    <div className="file-list">
                      <ol>{fileItem}</ol>
                    </div>
                  }
                </Dropzone>
              </div>

              <div className="note">
                Files count: {fileItem.length}
              </div>
            </div>
          }

          {props.isUploading &&
            <div className='upload-progress'>
              <div className="file-list">
                {fileItem.length > 0 &&
                  <ol>{fileItem}</ol>
                }
              </div>

              <div className="status-progress">
                Processed {successfullyUploaded} of {fileItem.length} files.
              </div>

              {progress !== 100 &&
                <Progress
                  value={progress}
                />
              }
            </div>
          }

        </ModalBody>

        <ModalFooter>
          {!props.isUploading &&
            <div className="upload-btn">
              <button
                className='btn btn-secondary'
                onClick={toggleUpload}
              >
                Cancel
              </button>

              <button
                className='btn btn-primary'
                onClick={handleUpload}
                disabled={fileItem.length === 0 ? true : false}
              >
                Upload files
              </button>
            </div>
          }

          {props.isUploading &&
            <div className="upload-btn">
              <button className='btn btn-primary finished-button'
                disabled={progress === 100 ? false : true}
                onClick={toggleUpload}
              >
                {progress === 100 ? "Done" : "Uploading. Please wait ..."}
              </button>
            </div>
          }

        </ModalFooter>

      </Modal>
    );
  }

  const renderUploadProgress = (props: IUploadProgress) => {
    const progress = (
      <div>
        <Progress value={props.uploadingPercentage} />
        {props.error &&
          <div>
            <div className="swal-footer">
              <p className="small text-danger">Error. Something wrong when uploading to S3.</p>
              <div className="swal-button-container">
                <button
                  onClick={() => swal.close!()}
                  className="swal-button swal-button--confirm"
                >OK</button>
              </div>
            </div>
          </div>
        }
        {props.uploadDone &&
          <div>
            <button
              onClick={() => swal.close!()}
            >Upload Done</button>
          </div>
        }
      </div>
    );
  }

  const renderDetail = (props: IDetail) => {
    return (
      <div className="detail">

        <div className='items'>
          {props.showFolderDetail &&
            <div className="folder item">
              Folder Name: <code>{props.selectedFolder}</code>

              <div className='actions'>
                {(!isPreviousFolder() && !isDiscoveryFolder()) && (
                  <button className='btn btn-primary btn-labeled' onClick={toggleUpload}>
                    <span className='btn-label'>
                      <i className='fa fa-file-text-o'></i>
                    </span>
                    Add Files to Folder
                  </button>
                )}
                {(!isPreviousFolder() && !isDiscoveryFolder()) && (
                  <button className='btn btn-primary btn-labeled' onClick={toggleCreateFolder}>
                    <span className='btn-label'>
                      <i className='fa fa-folder-o'></i>
                    </span>
                    Create New Folder
                  </button>
                )}
                {(!isRootFolder() && !isDiscoveryFolder()) && (
                  <button className='btn btn-primary btn-labeled' onClick={toggleDownloadFolder}>
                    <span className='btn-label'>
                      <i className='fa fa-save'></i>
                    </span>
                    Download as Zip
                  </button>
                )}
                {(!isRootFolder() && !isFolderWritable() && !isPreviousFolder() && !isDiscoveryFolder()) && (
                  <button className='btn btn-danger btn-labeled' onClick={handleDeleteFolder}>
                    <span className='btn-label'>
                      <i className='fa fa-times'></i>
                    </span>
                    Delete Folder
                  </button>
                )}
              </div>
            </div>
          }

          {props.showFileDetail &&
            <div className='file item'>
              File Name: <code>{props.selectedFile}</code>

              <div className='actions'>
                <a href={selectedUrl} target='_blank' className='btn btn-primary btn-labeled'>
                  <span className='btn-label'>
                    <i className='fa fa-save'></i>
                  </span>
                  Download
                </a>
                {!isPreviousFile() && !isDiscoveryFile && (
                <button className='btn btn-primary btn-labeled' onClick={toggleRenameFile}>
                  <span className='btn-label'>
                    <i className='fa fa-folder-o'></i>
                  </span>
                  Rename File
                </button>
                )}
                {(!isFolderWritable() && !isPreviousFile() && !isDiscoveryFile()) &&
                  <button
                    className='btn btn-danger btn-labeled'
                    onClick={handleDeleteFile}
                  >
                    <span className='btn-label'>
                      <i className='fa fa-times'></i>
                    </span>
                    Delete File
                  </button>
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (selectedUrl != s3.selectedUrl) {
      setSelectedUrl(s3.selectedUrl);
    }

    // // not showing the previous pipeline in files list
    if (files !== localFiles) {
      const upDatedFiles = files;
      // _.remove(upDatedFiles, (file) => {
      //   return file.key.indexOf(constants.FILE_EXPLORER_PREVIOUS_PIPELINE_FOLDER) === 0;
      // });
      setLocalFiles(upDatedFiles);
    }
  }, [files, s3.selectedUrl]);

  useEffect(() => {
    if (!isLoading && isError) {
      if (retryCount < constants.WORKSPACE_RETRY_COUNT) {
        // console.log('RETRYING', retryCount);
        const timeout = setTimeout(() => {
          setRetryCount(retryCount + 1);
          handleRetry();
          clearTimeout(timeout);
        }, 2000);
      }
    }
  }, [isLoading, isError]);

  return (
    <div className="file-browser">
      {showUploadModal && (
        renderUploadModal({
          filesToUpload,
          showUploadModal,
          toggleUpload,
          selectedFolder,
          isUploading
        })
      )}

      {isUploading && (
        renderUploadProgress({
          uploadingPercentage,
          error: error || false,
          uploadDone
        })
      )}

      {showCreateFolderModal && (
        renderCreateFolderModal({
          newFolderName,
          selectedFolder,
          showCreateFolderModal,
          toggleCreateFolder
        })
      )}

      {showDownloadModal && (renderDownloadModal())}

      {showRenameFileModal && (renderRenameFileModal())}

      <FileBrowser
        // files={get(this.props.s3FileBrowser, 'files', [])}
        files={localFiles}

        icons={Icons.FontAwesome(4)}

        onCreateFolder={handleCreateFolder}
        onCreateFiles={handleCreateFiles}
        onMoveFolder={handleRenameFolder}
        onMoveFile={handleRenameFile}
        onRenameFolder={handleRenameFolder}
        onRenameFile={handleRenameFile}
        onDeleteFolder={handleDeleteFolder}
        onDeleteFile={handleDeleteFile}
        onSelectFolder={handleSelectFolder}
        onSelectFile={handleSelectFile}
        detailRenderer={() => ''}
        noFilesMessage={(isLoading && !isError) ? 'Loading...' : constants.NO_FILES}
      />

      {isError && (
        <div className="retry-button">
          <span className="retry-text text-transparent">{constants.NO_FILES}</span>
          {(!isLoading && retryCount >= constants.WORKSPACE_RETRY_COUNT) ? (
            <span className="retry-text text-info" onClick={handleRetry}>
              Retry
            </span>
          ) : (
            <span className="retry-text">Retrying...</span>
          )}
        </div>
      )}

      {renderDetail({
        showFolderDetail,
        selectedFolder,
        showFileDetail,
        selectedFile
      })}

    </div>
  );
}

CytoFileBrowser.defaultProps = {
  error: false
};

export default CytoFileBrowser;
