import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import FileSelector from '../../../components/FileSelector/FileSelector';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { IStore } from '../../../types/store';
import { IPipeline } from '../../../types/type.pipeline';
import { INPUT_SMALL } from '../../../utils/constants';

const Url2s3 = () => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const { input, isAdvanced } = pipeline;
    
    return (
        <div className="url2s3">
            <h4 className="subtitle">Url2s3</h4>
            <div className="pipeline-form">
                <FormGroupCustom
                    pipeline="url2s3"
                    id="inputurl2s3"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name="input"
                            tray={input.input}
                            accept={['csv', '.csv', 'txt', '.txt']}
                            getPreviousPipelineOutput={false}
                            isMultiple={false}
                            isHideWorkspace
                        />
                    </Col>
                </FormGroupCustom>
            </div>
        </div>
    );
}

export default Url2s3;