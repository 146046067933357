import { combineReducers } from 'redux';
import execution from './reducer-execution';
import help from './reducer-help';
import pipeline from './reducer-pipeline';
import projects from './reducer-project';
import report from './reducer-report';
import sample from './reducer-sample';
import user from './reducer-user';
import notification from './reducer-notification';
import dashboard from './reducer-dashboard';
import s3 from './reducer-s3';
import visiblePipelines from './reducer-visible-pipeline';
import uiHistory from './reducer-ui-history';
import batch from './reducer-batch';
import coordinatesFiles from './reducer-coordinates-files';
import versionNotif from './reducer-version-notification';
import peptideAnnotation from './reducer-peptide-annotation';

const allReducers = combineReducers({
  execution,
  help,
  pipeline,
  projects,
  report,
  sample,
  user,
  notification,
  dashboard,
  s3,
  visiblePipelines,
  uiHistory,
  batch,
  coordinatesFiles,
  versionNotif,
  peptideAnnotation
});

export default allReducers;
