import React from 'react';
import { Link, Route } from 'react-router-dom';
import NotificationTable from './NotificationTable';
import NotificationDetail from './Detail';

interface IProps {
    match: any
}

const Notification = ({
    match
} : IProps) => (
    <div>
      <Route exact path={match.path} component={NotificationTable} />
      <Route path={`${match.url}/detail/:id`} name="PipelineDetail" component={NotificationDetail}/>
    </div>
)

export default Notification;
