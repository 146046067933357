import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';

import { loadState } from './utils/localStorage';
import allReducers from './reducers';

const loggedInUser = loadState();

const store = createStore(
    allReducers,
    loggedInUser,
    applyMiddleware(thunk, promise
        // , logger
    )
);

export default store;
