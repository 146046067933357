import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../types/store';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { Col } from 'reactstrap';
import { GATING_SUBSETTING_STEP, INPUT_SMALL } from '../../../utils/constants';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import FileSelector from '../../../components/FileSelector/FileSelector';
import MetasampleEditor from '../../../components/Views/MetasampleEditor';
import { defaultFileUpload, getExtension } from '../helper';
import { ICoordinatesFiles } from '../../../types/type.coordinates-files';
import { TDispatch } from '../../../types/action';
import { updateInput, updateMetadata } from '../../../actions/action.pipeline-input';
import { getCoordinatesFiles } from '../../../actions/action.coordinates-files';
import { IProfile } from '../../../types/type.user';
import Toggle from '../../../components/Form/Toggle';

const Histogram = () => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const profile: IProfile = useSelector((state : IStore) => state.user.profile);
    const coordinatesFiles: ICoordinatesFiles = useSelector((state: IStore) => state.coordinatesFiles);
    const projectId: number = useSelector((state : IStore) => state.projects.selected_project.id)!;
    const { input, isAdvanced, isShiny } = pipeline;
    const [calcFreq, setCalcFreq] = useState<boolean>(false);
    const dispatch = useDispatch<TDispatch<TPipeline>>();

    const dispatchInput = (key: string, value: any) => dispatch(updateInput(key, value));

    const isFcsFile = () : boolean => {
        const fileExtension = getExtension(input.input.length>0 ? input.input[0] : '');

        return fileExtension === 'fcs';
    }

    const handleToggle = (value: boolean, key: string) => {
        const toggledValue = !value;

        switch (key) {
            case 'doFreq':
                setCalcFreq(toggledValue);
                if (toggledValue) {
                    const prefix = defaultFileUpload(profile);
                    dispatch(getCoordinatesFiles(coordinatesFiles.filesToUpload, projectId, prefix));
                } else {
                    input.coordsFolder = [];
                }
                break;
            case 'allCells':
                if (_.isEmpty(input.coordsFolder)) {
                    dispatchInput(key, toggledValue ? true : null);
                }
                break;
            default:
                dispatchInput(key, toggledValue ? true : null);
        }
    }

    useEffect(() => {
        if (!isShiny) {
            dispatch(updateMetadata('isShiny', true));
        }
    }, [isShiny])

    useEffect(() => {
        if(_.isEmpty(input.input)){
            coordinatesFiles.filesToUpload = [];
            coordinatesFiles.files = [];
        }
        if (!_.isEmpty(input.coordsFolder)) {
            setCalcFreq(true);
        }
    }, []);

    return (
        <TabbedMetaContainer
            name='Histogram'
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.input)}
            containerClass="tsne-interactive"
            metamarker={(!_.isEmpty(input.input)) && (
                <FormGroupCustom
                    pipeline='histogram'
                    id='histogram'
                    input={input.metaMarker}
                    isAdvanced={isAdvanced}
                    row
                >
                    <Col sm={INPUT_SMALL}>
                        {(input.protein && input.protein.length > 0) ? (
                            <ProteinSelector
                                columns={['keep']}
                                protein={input.protein}
                                tray={input.metaMarker}
                                trayName="metaMarker"
                            />
                        ) : (
                            <FileSelector
                                name='metaMarker'
                                tray={input.metaMarker}
                                isMultiple={false}
                                accept={['csv', '.csv']}
                                checkFcsMetadata={false}
                                getPreviousPipelineOutput={false}
                            />
                        )}
                    </Col>
                </FormGroupCustom>
            )}
            metasample={
                <FormGroupCustom
                    pipeline='histogram'
                    id='metasamplehistogram'
                    input={input.metaSample}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <MetasampleEditor input='input' />
                    </Col>
                </FormGroupCustom>
            }
        >
            <div className='pipeline-form'>
                {/* Samples */}
                <FormGroupCustom
                    pipeline='histogram'
                    id='inputhistogram'
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            isMultiple={true}
                            accept={['fcs', '.fcs', 'csv', '.csv']}
                            checkFcsMetadata={true}
                            getPreviousPipelineOutput={true}
                            mixedExtension={true}
                            isShowPreviousPipeline
                            isConcatenateCsv
                            isPreviousPipelineFromPreProcessing
                            previousPipelineParams = {{
                                preprocessingStep: GATING_SUBSETTING_STEP.toString()
                            }}
                        />
                    </Col>
                </FormGroupCustom>

                {/* calculate freq */}
                { isFcsFile() && (
                    <FormGroupCustom
                        pipeline='histogram'
                        id='calculatefreqhistogram'
                        input={`${calcFreq}`}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <Toggle
                                value={calcFreq}
                                onToggle={(v: boolean) => handleToggle(v, 'doFreq')}
                            />
                            {calcFreq && (
                                <div className='mt-4'>
                                    <FileSelector
                                        name='coordsFolder'
                                        tray={input.coordsFolder}
                                        isMultiple={true}
                                        accept={['csv', '.csv']}
                                        isCoordinatesFiles={true}
                                        isShowGetCoordinatesFiles={calcFreq}
                                        isHideWorkspace={!calcFreq}
                                        isHideUpload={!calcFreq}
                                    />
                                </div>
                            )}
                        </Col>
                    </FormGroupCustom>
                )}
            </div>
        </TabbedMetaContainer>
    )
}

export default Histogram;