import React from 'react';
import {
    Col, Input
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import FileSelector from '../../../components/FileSelector/FileSelector';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import { updateInput } from '../../../actions/action.pipeline-input';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import _ from 'lodash';
import InputCustom from '../../../components/Form/InputCustom';
import { INPUT_SMALL } from '../../../utils/constants';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';

const Tetramer = () => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced } = pipeline;

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(updateInput(name, value));
    }

    return (
        <TabbedMetaContainer
            name="Tetramer Deconvolution"
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.inputCase)}
            containerClass="tetramer"
            metamarker={(!_.isEmpty(input.inputCase)) && (
                <FormGroupCustom
                    pipeline="tetdecon"
                    id="metamarkertetdecon"
                    input={input.metaMarker}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        {(input.protein.length > 0) ? (
                            <ProteinSelector
                                columns={['sav']}
                                protein={input.protein}
                                tray={input.metaMarker}
                                trayName="metaMarker"
                                resetInputName={'badSav'}
                            />
                        ) : (
                            <React.Fragment>
                                <span className="text-danger">
                                    Your input files don't have marker information, please upload your own marker file.
                                </span>
                                <FileSelector
                                    name="metaMarker"
                                    tray={input.metaMarker}
                                    isMultiple={false}
                                    accept={['csv', '.csv']}
                                    checkFcsMetadata={false}
                                    getPreviousPipelineOutput={false}
                                />
                            </React.Fragment>
                        )}
                    </Col>
                </FormGroupCustom>
            )}
        >
            <div className="pipeline-form">

                {/* inputCase */}
                <FormGroupCustom
                    pipeline="tetdecon"
                    id="inputcasetetdecon"
                    input={input.inputCase}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='inputCase'
                            tray={input.inputCase}
                            isMultiple={true}
                            accept={['fcs', '.fcs']}
                            checkFcsMetadata={true}
                            isShowPreviousPipeline
                        />
                    </Col>
                </FormGroupCustom>

                {/* inputControl */}
                <FormGroupCustom
                    pipeline="tetdecon"
                    id="inputcontroltetdecon"
                    input={input.inputControl}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='inputControl'
                            tray={input.inputControl}
                            isMultiple={true}
                            accept={['fcs', '.fcs']}
                            checkFcsMetadata={true}
                            clearMetadata={false}
                            isShowPreviousPipeline
                        />
                    </Col>
                </FormGroupCustom>

                {/* badSav */}
                {/* {(input.protein.length > 0) && ( */}
                <FormGroupCustom
                    pipeline="tetdecon"
                    id="badsavtetdecon"
                    input={input.badSav}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select" name="badSav" id="badSav"
                            placeholder="Bad SAV"
                            onChange={handleInput} value={input.badSav}>
                            <option value="none">none</option>
                            {
                                _.map(input.protein, (item) => item.sav && (
                                    <option value={item.name}>{item.name}</option>
                                ))
                            }
                        </InputCustom>
                        {/* <Input
                name="badSav" id="tetramer_badSav"
                onChange={handleInput} value={input.badSav}
              /> */}
                    </Col>
                </FormGroupCustom>
                {/* )} */}

                {/* clusterCodes */}
                <FormGroupCustom
                    pipeline="tetdecon"
                    id="clustercodestetdecon"
                    input={input.clusterCodes}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='clusterCodes'
                            tray={input.clusterCodes}
                            isMultiple={false}
                            accept={['csv', '.csv']}
                            isShowPreviousPipeline
                        />
                    </Col>
                </FormGroupCustom>

                {/* prefix */}
                <FormGroupCustom
                    pipeline="tetdecon"
                    id="prefixtetdecon"
                    input={input.prefix}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Input
                            name="prefix"
                            onChange={handleInput}
                            value={input.prefix}
                        />
                    </Col>
                </FormGroupCustom>

                {/* transformation */}
                <FormGroupCustom
                    pipeline="tetdecon"
                    id="transformationtetdecon"
                    input={input.transformation}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select" name="transformation" id="tetramer_transformation"
                            placeholder="Transformation"
                            onChange={handleInput} value={input.transformation}>
                            <option value="" disabled>Select Transformation</option>
                            <option value="cytof">cytof</option>
                            <option value="fluor">fluor</option>
                            <option value="linear1">linear1</option>
                            <option value="linear2">linear2</option>
                            <option value="arcsinh5">arcsinh5</option>
                            <option value="arcsinh150">arcsinh150</option>
                            <option value="auto">auto</option>
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                {/* config */}
                <FormGroupCustom
                    pipeline="tetdecon"
                    id="configtetdecon"
                    input={input.config}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select" name="config" id="tetramer_config"
                            placeholder="Config"
                            onChange={handleInput} value={input.config} >
                            <option value="" disabled>Select Configuration Setup</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </InputCustom>
                    </Col>
                </FormGroupCustom>
            </div>
        </TabbedMetaContainer>
    );
}

export default Tetramer;
