import { IBatchStepTemplate } from "../types/type.template";
import { BEAD_NORMALIZATION, BUTTON_TEXT_GOTO_ANALYSIS, BUTTON_TEXT_RE_RUN, BUTTON_TEXT_RUN, BUTTON_TEXT_SUBSET, CLUSTER_CODE_UPLOAD, DEBARCODE, FILE_UPLOAD, LIFE_DEATH_GATING, MARKER_GATING_SUBSETTING, PREPROCESSING_BEAD_NORMALIZATION, PREPROCESSING_CLUSTER_CODE_UPLOAD, PREPROCESSING_DEBARCODE, PREPROCESSING_FILE_UPLOAD, PREPROCESSING_LIFE_DEATH_GATING, PREPROCESSING_MARKER_GATING_SUBSETTING } from "../utils/constants";

const batchSteps: {[key: number]: IBatchStepTemplate} = {
    1: {
        title: FILE_UPLOAD,
        icon: 'robot',
        key: PREPROCESSING_FILE_UPLOAD,
        tooltip: 'Uploading of raw CyTOF .fcs files.',
        description: 'Uploading of raw CyTOF .fcs files for pre-processing. The files can be either uploaded from your local computer or from the \'result uploads\' folder in the cloud. Please note that \'File Upload\', once finished can not be changed anymore. If the wrong files were uploaded, please \'archive\', the whole batch and create a new one.',
        actions: [
            {
                type: 'runPipeline',
                text: 'Upload Files',
                url: `/pipeline/upload`,
                metadata: {
                },
            }
        ],
    },
    2: {
        title: CLUSTER_CODE_UPLOAD,
        icon: 'robot',
        key: PREPROCESSING_CLUSTER_CODE_UPLOAD,
        tooltip: 'Uploading of Cluster Code file from Robot pipeline.',
        description: 'Uploading of corresponding \'cluster code\' .csv file from the robot pipeline. This step is optional. When the cluster code file is uploaded, it will later be available online in the tetrramer deconvolution pipelines (manual tetdecon 2021, tetdecon 2021). Referencing is through the pre-processing batch name.',
        actions: [
            {
                type: 'skipPipeline',
                text: 'Skip',
                metadata: {
                }
            },
            {
                type: 'runPipeline',
                text: 'Upload Files',
                url: `/pipeline/cluster-code-upload`,
                metadata: {
                },
            }
        ]
    },
    3: {
        title: BEAD_NORMALIZATION,
        icon: 'bead-normalization',
        key: PREPROCESSING_BEAD_NORMALIZATION,
        tooltip: 'Bead normalization according Finck et al 2013.',
        description: 'Bead normalization according Finck et al 2013. The Curently, bead normalization is not part of ImmunoScapes standard protocol anymore since the CyTOF XT instrument performs automatic normalization after each data acquisition based on tuning data. The CyTOF XT normalization is needed for correct handling in the tetdecon pipelines. This additional bead normalization module based on Finck et al. is now only used for legacy CyTOF Helios data.',
        actions: [
            {
                type: 'skipPipeline',
                text: 'Skip',
                metadata: {
                }
            },
            {
                type: 'runPipeline',
                text: BUTTON_TEXT_RUN,
                url: '/pipeline/form',
                metadata: {
                    tabId: 1,
                    pipelineScript: 0,
                    pipelineId: 16,
                    isShiny: true,
                },
            }
        ]
    },
    4: {
        title: DEBARCODE,
        icon: 'debarcode',
        key: PREPROCESSING_DEBARCODE,
        tooltip: 'Debarcoding of data aquisition batches that includes multiple biological samples.',
        description: 'Debarcoding of data aquisition batches that includes multiple biological samples. All files to process are shown as a density map for gating. Gates can be selected either as a x range or as a polygon. After gating, the user chooses the gates either for inclusion (+) or exclusion (-) to produce output files that correspond to the selected gates.',
        actions: [
            {
                type: 'skipPipeline',
                text: 'Skip',
                metadata: {
                }
            },
            {
                type: 'runPipeline',
                text: BUTTON_TEXT_RUN,
                url: '/pipeline/form',
                metadata: {
                    tabId: 1,
                    pipelineScript: 0,
                    pipelineId: 19,
                    isShiny: true,
                },
            }
        ]
    },
    5: {
        title: LIFE_DEATH_GATING,
        icon: 'debarcode',
        key: PREPROCESSING_LIFE_DEATH_GATING,
        tooltip: 'Life/Death gating',
        description: 'Gating step to remove file wise unwanted events eg Life/Death, debris etc. To assure highest possible precision, gates have to be defined per file (biosample). In the subsetting step, gates are selected for inclusion or exclusion and new cleaned output files are produced for each input file.',
        actions: [
            {
                type: 'skipPipeline',
                text: 'Skip',
                metadata: {
                }
            },
            {
                type: 'runPipeline',
                text: BUTTON_TEXT_RUN,
                url: '/pipeline/form',
                metadata: {
                    tabId: 1,
                    pipelineScript: 0,
                    pipelineId: 9,
                    isShiny: true,
                    isLifeDeath: true
                },
            }
        ]
    },
    6: {
        title: MARKER_GATING_SUBSETTING,
        icon: 'manual-gating',
        key: PREPROCESSING_MARKER_GATING_SUBSETTING,
        tooltip: 'Marker Gating',
        description: 'Marker Gating of the cleaned biosample .fcs files. According to ImmunoScape procedures, all markers should be gated in any case so that they are available for later analysis. After saving all gates (\'Save List\'), the user has to return to the Dashboard. Now the user has the choice to  \'Re-Run\' the gating step to add or change some gates, or to proceed to subsetting. Re-running will replace the most recent pipeline execution while subsetting will add new executions. After at least one succesful subsetting execution, the user can no longer change the gates as this would impact the data consitency. The only way to change the gates then is to remove/archive all subsetting executions until the \'Re-Run\' button becomes visible again. The \'Goto Analysis\' button brings the user to the Dashboard in the current active project to start with data analysis.',
        actions: [
            {
                type: 'gotoAnalysis',
                text: BUTTON_TEXT_GOTO_ANALYSIS,
                url: '/projects/analysis',
                metadata: {
                    hidden: true
                },
            },
            {
                type: 'runPipeline',
                text: BUTTON_TEXT_RUN,
                url: '/pipeline/form',
                metadata: {
                    tabId: 1,
                    pipelineScript: 0,
                    pipelineId: 9,
                    isShiny: true,
                    hidden: false,
                    reRun: false
                },
            },
            {
                type: 'reRunPipeline',
                text: BUTTON_TEXT_RE_RUN,
                url: '/pipeline/form',
                metadata: {
                    tabId: 1,
                    pipelineScript: 0,
                    pipelineId: 9,
                    isShiny: true,
                    reRun: true,
                    hidden: true
                },
            },
            {
                type: 'runPipeline',
                text: BUTTON_TEXT_SUBSET,
                url: '/pipeline/form',
                metadata: {
                    tabId: 1,
                    pipelineScript: 0,
                    pipelineId: 9,
                    isShiny: true,
                    hidden: true,
                    reRun: false
                },
            }
        ]
    }

}

export default batchSteps;