import React from 'react';


const Aside = () => {
  return (
    <aside className="aside-menu">
      {/*Aside Menu*/}
    </aside>
  )
}

export default Aside;
