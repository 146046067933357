import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { default as Notif } from 'react-web-notification';
import { getNotification } from '../actions/action.notification';
import { INotification, TNotification, INotificationDetail } from '../types/type.notification';
import { IStore } from '../types/store';
import { TDispatch } from '../types/action';

const PollNotif = () => {
    const notification: INotification = useSelector((state : IStore) => state.notification);
    const dispatch = useDispatch<TDispatch<TNotification>>();

    const [title, setTitle] = useState<string>('');
    const [options, setOptions] = useState<any>({});
    const [, setTimeoutObject] = useState<any>(null);
    const [notifications, setNotifications] = useState<Array<INotificationDetail>>(notification.data);   

    const pushNotif = (title: string, body: string) => {
        const now = Date.now();
        // const icon = 'http://localhost:3000/Notifications_button_24.png';

        // Available options
        // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
        const options = {
            tag: now,
            body: body,
            // icon: icon,
            lang: 'en',
            dir: 'ltr',
            sound: './sound.mp3'  // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
        }
        setTitle(title);
        setOptions(options);
    }

    const poll = (fn: Function, timeout: number, interval: number) => {
        var checkCondition = function (resolve: any, reject: any) {
            // stop polling if user logout
            if (localStorage.getItem('state') === null) return false;

            var endTime = Number(new Date()) + (timeout || 2000);
            interval = interval || 2000;

            // If the condition is met, we're done! 
            var result = fn();
            if (result) {
                resolve(result);
            } else {
                // Didn't match and too much time, reject!
                reject(new Error('timed out for ' + fn + ': ' + arguments));
            }

            // If the condition isn't met but the timeout hasn't elapsed, go again
            if (Number(new Date()) < endTime) {
                let timeout = setTimeout(checkCondition, interval, resolve, reject);
                setTimeoutObject(timeout);
            }
        };

        return new Promise(checkCondition);
    }

    const handleNotificationOnClick = () => {
        location.replace("/#/notification")
    }

    useEffect(() => {
        if (notification.data.length > notifications.length) {
            setNotifications(notification.data);
            let notif: { type: string, message: string } | undefined = _.last(notification.data);
            pushNotif(notif!.type, notif!.message);
        }
    }, [notification.data]);

    useEffect(() => {
        poll(() => {
            dispatch(getNotification(false));
            return true;
        }, 25000, 25000);
    }, []);

    return (
        <Notif
            onClick={handleNotificationOnClick}
            timeout={5000}
            title={title}
            options={options}
        />
    );
}

export default PollNotif;
