import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { readNotification } from '../../actions/action.notification';
import { IStore } from '../../types/store';
import { INotificationDetail, TNotification } from '../../types/type.notification';
import { TDispatch } from '../../types/action';
import RequestWrapperView from '../../components/Views/RequestWrapperView';
import { Link } from 'react-router-dom';

interface IProps {
    match: any
} 

const NotificationDetail = ({
    match
} : IProps) => {
    const notification : INotificationDetail = useSelector((state : IStore) => state.notification.selected);
    const isLoading : boolean = useSelector((state : IStore) => state.notification.isLoading);
    const isError : boolean = useSelector((state : IStore) => state.notification.isError);
    const message : any = useSelector((state : IStore) => state.notification.message);
    const dispatch = useDispatch<TDispatch<TNotification>>();

    useEffect(() => {
        dispatch(readNotification(match.params.id));
    }, []);

    return (
        <RequestWrapperView isLoading={isLoading} isError={isError} message={message}>
            {!_.isEmpty(notification) && (
                <div className="container-notification table-responsive">
                    <Breadcrumb tag="nav">
                        <BreadcrumbItem tag="a" href="#/notification">Notifications</BreadcrumbItem>
                        <BreadcrumbItem active tag="span">{notification.type}</BreadcrumbItem>
                    </Breadcrumb>
            
                    <div className="card">
                        <h4 className="subtitle">Overview</h4>
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <th>Timestamp</th>
                                    <td>{moment.unix(notification.createdAt).format('DD MMMM YYYY h:mm:ss')}</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>{notification.type}</td>
                                </tr>
                                <tr>
                                    <th>Message</th>
                                    <td>{notification.message}</td>
                                </tr>
                                {notification.url && (
                                <tr>
                                    <th>URL</th>
                                    <td>
                                        <a href={notification.url} target='_blank'>Download
                                        </a>
                                    </td>
                                </tr>
                                )}
                                {(notification.pipelineId) && (
                                <tr>
                                    <th>Session</th>
                                    <td>
                                        <Link to={`/pipeline/detail/${notification.pipelineId}`}>
                                            <span className="view-action">View Result</span>
                                        </Link>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </RequestWrapperView>
    );
}

export default NotificationDetail;
