import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import RequestWrapperView from './RequestWrapperView';
import { getProject, getOneProject } from '../../actions/action.project';
import { getVisiblePipeline, changePipelineTab } from '../../actions/action.visible-pipeline';
import { TDispatch } from '../../types/action';
import { TProject, IProjectList } from '../../types/type.project';
import { TVisiblePipeline, IVisiblePipeline } from '../../types/type.visible-pipeline';
import { IStore } from '../../types/store';
import { updateMetadata } from '../../actions/action.pipeline-input';
import { TPipeline, IPipeline } from '../../types/type.pipeline';
import history from '../../views/history';

interface IProps {
    location: any,
    children: any,
}

const PipelineFormWrapper = ({ children, location } : IProps) => {
    const project: IProjectList = useSelector((state : IStore) => state.projects);
    const visiblePipelines: IVisiblePipeline = useSelector((state : IStore) => state.visiblePipelines);
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TProject | TVisiblePipeline | TPipeline>>();

    const { all_projects } = project;
    const { data } = visiblePipelines;
    const { projectId, tabId, pipelineId, batchId } = pipeline;
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (_.isEmpty(all_projects)) {
            dispatch(getProject());
        }

    if (_.isEmpty(data)) {
            dispatch(getVisiblePipeline());
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);
  
    useEffect(() => {
        const query : any = {};
        if (projectId) {
            query.project = projectId;
        }

        if (tabId) {
            const selectedTab = data.find((element) => element.id == tabId);
            if (selectedTab) {
                query.tab = selectedTab.name.toLowerCase();
            }
        }

        if (pipelineId) {
            const selectedTab = data.find((element) => element.id == tabId);
            const allPipeline = selectedTab ? selectedTab.pipelines : [];
            const selectedPipeline = allPipeline.find((element) => element.id === pipelineId);
            if (selectedPipeline) {
                query.pipeline = selectedPipeline.shortName;
            }
        }

        if (batchId) {
            query.batch = batchId;
        }

        if (!_.isEmpty(query)) {
            history.replace({
                pathname: `/pipeline/form?${queryString.stringify(query)}`
            });
        }
    }, [projectId, tabId, pipelineId, batchId]);
  
    useEffect(() => {
      if (!_.isEmpty(all_projects) && !_.isEmpty(data)) {
        const query = queryString.parse(location.search);
        if (!_.isEmpty(query)) {
            const projectId = query.project;
            const tabName = query.tab;
            const pipelineName = query.pipeline;
            const selectedProject = all_projects.find((element) => element.id == projectId);
            const selectedTab = data.find((element) => element.name.toLowerCase() === tabName);
            const allPipeline = selectedTab ? selectedTab.pipelines : [];
            const selectedPipeline = allPipeline.find((element) => element.shortName === pipelineName);
            const batchId = query.batch;
    
            if (selectedProject) {
                dispatch(updateMetadata('projectId', selectedProject.id));
                dispatch(getOneProject(selectedProject.id!, false));
            }
            if (selectedTab) {
                dispatch(updateMetadata('tabId', selectedTab.id));
                dispatch(changePipelineTab(selectedTab.id));
            }
            if (selectedPipeline) {
                dispatch(updateMetadata('pipelineId', selectedPipeline.id));
                if (selectedPipeline.id === 9) {
                    dispatch(updateMetadata('isShiny', true));
                }
            }
            if (batchId) {
                dispatch(updateMetadata('batchId', batchId));
            }
        }
      }
    }, [all_projects, data]);
    
    return (
        <RequestWrapperView isError={project.isError || pipeline.isError} isLoading={isLoading}>
            {children}
        </RequestWrapperView>
    );
}

export default PipelineFormWrapper;
