export default {
  // local
  development: {
    API_URL: "http://localhost:1337/v1",
  },
  // dev
  dev: {
    API_URL: "https://cytographeradmin-dev.immunoscape.com/v1",
  },
  // staging
  staging: {
    API_URL: "https://cytographeradmin-staging.immunoscape.com/v1",
  },
  // prod
  production: {
    API_URL: "https://cytographeradmin.immunoscape.com/v1",
  }
} 