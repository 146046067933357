import React from 'react';
import { Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { updateInput } from '../../../actions/action.pipeline-input';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import InputCustom from '../../../components/Form/InputCustom';
import { INPUT_SMALL } from '../../../utils/constants';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';

const Tetdecon2021 = () => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced } = pipeline;

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(updateInput(name, value));
    }

    return (
        <TabbedMetaContainer
            name="Tetdecon 2021"
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.input)}
            containerClass="tetdecon-2021"
            metamarker={(!_.isEmpty(input.input)) && (
                <FormGroupCustom
                    pipeline="tetdecon2021"
                    id="markercsvtetdecon2021"
                    input={input.markerCsv}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        {(input.protein && input.protein.length > 0) ? (
                            <ProteinSelector
                                columns={['keep', 'sav']}
                                protein={input.protein}
                                tray={input.markerCsv}
                                trayName="markerCsv"
                                customTitles={{
                                    keep: 'Phenotype',
                                    sav: 'Streptavidin'
                                }}
                            />
                        ) : (
                            <FileSelector
                                name="markerCsv"
                                tray={input.markerCsv}
                                isMultiple={false}
                                accept={['csv', '.csv']}
                                checkFcsMetadata={false}
                                getPreviousPipelineOutput={false}
                            />
                        )}
                    </Col>
                </FormGroupCustom>
            )}
        >
            <div className="pipeline-form">
                {/* input*/}
                <FormGroupCustom
                    pipeline="tetdecon2021"
                    id="inputtetdecon2021"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            isMultiple={true}
                            accept={['fcs', '.fcs']}
                            checkFcsMetadata
                        />
                    </Col>
                </FormGroupCustom>

                {/* clusterCodes */}
                <FormGroupCustom
                    pipeline="tetdecon2021"
                    id="clustercodestetdecon2021"
                    input={input.clusterCodes}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='clusterCodes'
                            tray={input.clusterCodes}
                            isMultiple={false}
                            accept={['csv', '.csv']}
                        />
                    </Col>
                </FormGroupCustom>

                {/* config */}
                <FormGroupCustom
                    pipeline="tetdecon2021"
                    id="configtetdecon2021"
                    input={input.config}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select" name="config"
                            onChange={handleInput} value={input.config} >
                            <option value="" disabled>Select Configuration Setup</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                {/* cutoff */}
                <FormGroupCustom
                    pipeline="tetdecon2021"
                    id="cutofftetdecon2021"
                    input={input.cutoff}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='cutoff'
                            tray={input.cutoff}
                            isMultiple={true}
                            accept={['csv', '.csv']}
                            pattern='(.+?)_cutoffs.csv'
                            mathchFilenameWithInput={{
                                prefix: '',
                                suffix: '_cutoffs.csv'
                            }}
                        />
                    </Col>
                </FormGroupCustom>

                {/* alpha */}
                <FormGroupCustom
                    pipeline="tetdecon2021"
                    id="alphatetdecon2021"
                    input={input.alpha}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select" name="alpha"
                            onChange={handleInput} value={input.alpha} >
                            <option value="" disabled>Select Alpha</option>
                            <option value="0.01">Discovery (0.01)</option>
                            <option value="0.005">Conservative (0.005)</option>
                        </InputCustom>
                    </Col>
                </FormGroupCustom>
            </div>
        </TabbedMetaContainer>
    );
}

export default Tetdecon2021;
