import React, { useState, useEffect, useRef } from 'react';
import {
    TabContent, TabPane, Nav, NavItem, NavLink,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Input, FormGroup, Alert, Breadcrumb, BreadcrumbItem, Col, Row
} from 'reactstrap';
import classnames from 'classnames';
import ReactTable, { Filter } from 'react-table';
import "react-table/react-table.css";
import DatePicker from 'react-datepicker';
import matchSorter from 'match-sorter';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getOneProject, archiveFile, addReport, getProjectMembers } from '../../actions/action.project';
import Dropzone from 'react-dropzone'
import FileBrowser from './FileBrowser';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import { updateMetadata } from '../../actions/action.pipeline-input';
import { TExecution } from '../../types/type.execution';
import { IStore } from '../../types/store';
import { TDispatch } from '../../types/action';
import { IProjectList } from '../../types/type.project';
import RequestWrapperView from '../../components/Views/RequestWrapperView';
import PipelineTab from '../../components/Views/PipelineTab';
import { IUiHistory } from '../../types/type.ui-history';
import { changePipelineSetting, changeReportSetting, setSelectedMenuTab } from '../../actions/action.ui-history';
import { IPipelineTab } from '../../types/type.visible-pipeline';
import { IUser } from '../../types/type.user';
import { resetPipeline } from '../../actions/action.pipeline';

interface IProps {
    match: any,
    history: any
}

interface IFormReport {
    name: string,
    description: string,
    file: Array<any>,
    projectId: number
}

const ProjectView  = ({ match, history }: IProps) => {
    const user: IUser = useSelector((state : IStore) => state.user);
    const projects: IProjectList = useSelector((state : IStore) => state.projects);
    const uiHistory: IUiHistory = useSelector((state : IStore) => state.uiHistory);
    const report: any = useSelector((state : IStore) => state.report);
    const tabs: Array<IPipelineTab> = useSelector((state : IStore) => state.visiblePipelines.data);
    const dispatch = useDispatch<TDispatch<TExecution>>();
    const { isLoading, isError, message, selected_project } = projects;
    const projectId = match.params.id;
    const { selectedMenuTab, pipelineSetting, reportSetting } = uiHistory;

    const [activeTab, setActiveTab] = useState<string>(selectedMenuTab);
    const [modalReport, setModalReport] = useState<boolean>(false);
    const [reportError, setReportError] = useState<boolean>(false);
    const [reportErrorMessage, setReportErrorMessage] = useState<string>('');
    const [formReport, setFormReport] = useState<IFormReport>({
        name: '',
        description: '',
        file: [],
        projectId: projectId
    });
    const [members, setProjectMembers] = useState<string[]>([]);

    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormReport({
            ...formReport,
            [name]: value
        });
    }

    const resetFormReport = () => {
        setFormReport({
            name: '',
            description: '',
            file: [],
            projectId: projectId
        })
    }

    const toggle = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            dispatch(setSelectedMenuTab(tab));
        }
    }

    const handleArchive = (type: string, id: number) => {
		// @ts-ignore
        swal({
            title: "Are you sure?",
            text: "This process is permanent and cannot be undone",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete: boolean | null) => {
            if (willDelete) {
                dispatch(archiveFile(type, id))
            } else {
                // swal("Your imaginary file is safe!");
            }
        });
    }

    const handleModalReport = () => {
        setModalReport(!modalReport);
        resetFormReport();
    }

    const handleAddReport = () => dispatch(addReport(formReport, handleModalReport));

    const handleDropReport = (file: Array<any>, reject: Array<any>) => {
        if (reject.length > 0) {
            setReportError(true);
            setReportErrorMessage('Type not allowed');
            return;
        }
        if (file.length == 0) {
            return;
        }
        setReportError(false);
        setFormReport({ ...formReport, file: file });
    }

    const handleRunPipeline = () => {
        dispatch(updateMetadata('projectId', selected_project.id));
    }

    const onPipelineFilterApplied = (filter: Array<any>) => {
        dispatch(changePipelineSetting(filter, pipelineSetting.page));
    }

    const onPipelinePageChanged = (page: number) => {
        dispatch(changePipelineSetting(pipelineSetting.filter, page));
    }

    const onReportFilterApplied = (filter: Array<any>) => {
        dispatch(changeReportSetting(filter, reportSetting.page));
    }

    const onReportPageChanged = (page: number) => {
        dispatch(changeReportSetting(reportSetting.filter, page));
    }

    useEffect(() => {
        dispatch(getOneProject(match.params.id, true));
        dispatch(changePipelineSetting([{
            id: "createdBy.username",
            value: user.username
        }], pipelineSetting.page));
    }, []);

    useEffect(() => {
        // reset pipeline every user visit project page
        dispatch(resetPipeline());

        getProjectMembers(match.params.id)
        .then((response) => setProjectMembers(response.data));
    }, [])

    return (
        <RequestWrapperView isError={isError} isLoading={isLoading} message={message}>
            <div className="container-project styled-tab-blue">
                <Breadcrumb tag="nav">
                    <BreadcrumbItem tag="a" href="#/projects/analysis">Projects</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{selected_project.name}</BreadcrumbItem>
                </Breadcrumb>
                <div className="long-card-detail">
                    {/* <div className="left">
                        <div className="roundrec-icon">
                            <div className="robot" />
                        </div>
                    </div> */}
                    <div className="right">
                        <h4>{selected_project.name}</h4>
                        <p>{selected_project.description}</p>
                        <p>Start Date: {moment.unix(selected_project.createdAt as number).format('DD MMMM YYYY h:mm:ss')}</p>
                    </div>
                </div>

                <PipelineTab projectId={projectId} />

                <div className="detail">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '4' })}
                                onClick={() => { toggle('4'); }}
                            >
                                Project Workspace
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                Pipeline Result
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}
                            >
                                Report
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="2">
                            {/* <div className="buttons text-right">
                                <Link to="/pipeline/form" onClick={handleRunPipeline}><button className="btn-primary"><i className="fa fa-gears"></i> Run Pipeline</button></Link>
                            </div> */}
                            <ReactTable
                                onPageChange={onPipelinePageChanged}
                                page={pipelineSetting.page}
                                onFilteredChange={onPipelineFilterApplied}
                                defaultFiltered={pipelineSetting.filter}
                                data={selected_project.pipeline}
                                filterable
                                columns={[
                                    {
                                        Header: "Session",
                                        accessor: "name",
                                        minWidth: 120,
                                        filterMethod: (filter: Filter, rows: any) =>
                                            matchSorter(rows, filter.value, { keys: ["name"] }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Description",
                                        accessor: "description",
                                        minWidth: 200,
                                        filterMethod: (filter: Filter, rows: any) =>
                                            matchSorter(rows, filter.value, { keys: ["description"] }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Pipeline Type",
                                        accessor: "type",
                                        minWidth: 150,
                                        filterMethod: (filter: Filter, rows: any) =>
                                            matchSorter(rows, filter.value, { keys: ["type"] }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Data Source",
                                        accessor: "dataSource",
                                        filterMethod: (filter, row) => {
                                            if (filter.value === "all") {
                                                return true;
                                            }
                                            return row[filter.id] == filter.value;
                                        },
                                        Filter: ({ filter, onChange }) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%", height: "100%", marginBottom: "-2px" }}
                                                value={filter ? filter.value : ""}
                                            >
                                                <option value="all">Show All</option>
                                                {
                                                    tabs.map((tab) => (
                                                        <option value={tab.name} key={tab.name}>{tab.name}</option>
                                                    ))
                                                }
                                            </select>
                                    },
                                    {
                                        Header: "Version",
                                        accessor: "version",
                                        minWidth: 150,
                                        filterMethod: (filter: Filter, rows: any) =>
                                            matchSorter(rows, filter.value, { keys: ["version"] }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Run By",
                                        accessor: "createdBy.username",
                                        minWidth: 150,
                                        filterMethod: (filter, row) => {
                                            if (filter.value === "all") {
                                                return true;
                                            }
                                            return row[filter.id] == filter.value;
                                        },
                                        Filter: ({ filter, onChange }) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%", height: "100%", marginBottom: "-2px" }}
                                                value={filter ? filter.value : ""}
                                            >
                                                <option value="all">Show All</option>
                                                {members.map((member) => <option value={member} key={member}>{member}</option>)}
                                            </select>
                                    },
                                    {
                                        Header: "Submitted Date",
                                        accessor: "createdAt",
                                        minWidth: 100,
                                        Filter: ({ filter, onChange }) => (
                                            <DatePicker
                                                selected={filter ? moment.unix(filter.value / 1000).toDate() : moment().toDate()}
                                                onChange={event => onChange(event)}
                                                dateFormat="dd MMM yyyy" />
                                        ),
                                        filterMethod: (filter, row) => {
                                            if (filter.value === null) { return true }
                                            return moment.unix(row[filter.id]).format('DD MMM YYYY') === moment(filter.value).format('DD MMM YYYY');
                                        },
                                        Cell: row => <div>{moment.unix(row.value).format('DD MMM YYYY')}</div>,
                                    },
                                    {
                                        Header: "Status",
                                        accessor: "batchStatus",
                                        minWidth: 100,
                                        Cell: row => <p className="text-left text-primary-immuno">{row.value}</p>,
                                        filterMethod: (filter, row) => {
                                            if (filter.value === "all") {
                                                return true;
                                            }
                                            return row[filter.id] == filter.value;
                                        },
                                        Filter: ({ filter, onChange }) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%", height: "100%", marginBottom: "-2px" }}
                                                value={filter ? filter.value : ""}
                                            >
                                                <option value="all">Show All</option>
                                                <option value="Success">Success</option>
                                                <option value="Failed">Failed</option>
                                                <option value="In Progress">In Progress</option>
                                            </select>
                                    }

                                ]}
                                defaultPageSize={10}
                                defaultSorted={[{ id: "createdAt", desc: true }]}
                                className="-striped -highlight table-immuno table-pipeline"
                                getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                                getTrProps={(state: any, rowInfo: any) => {
                                    return {
                                        className: rowInfo !== undefined ? '' : ' none',
                                        onClick: () => {
                                            if (rowInfo !== undefined) {
                                                history.push(`/pipeline/detail/${rowInfo.original.id}`);
                                            }
                                        }
                                    }
                                }}
                                getTdProps={(state: any, rowInfo: any, column: any) => {
                                    if (column.id == 'createdAt' || column.id == 'id') {
                                        return {};
                                    }
                                    return {
                                        title: rowInfo !== undefined ? rowInfo.row[column.id] : ' none'
                                    }
                                }}
                            />
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="buttons text-right"><button className="btn-primary" onClick={handleModalReport}><i className="fa fa-plus"></i> Add Report</button></div>
                            <Modal isOpen={modalReport} toggle={handleModalReport}>
                                <ModalHeader toggle={handleModalReport}>Add Report</ModalHeader>
                                <ModalBody>
                                    {report.isError && <Alert color="danger">{report.message}</Alert>}
                                    <FormGroup><Input value={formReport.name} name="name" onChange={handleChangeText} placeholder="Report Name..." /></FormGroup>
                                    <FormGroup><Input value={formReport.description} name="description" onChange={handleChangeText} type="textarea" placeholder="description..." /></FormGroup>
                                    <div className="dropzone">
                                        <Dropzone accept=".pdf, .docx, .doc" onDrop={handleDropReport} multiple={false}>
                                            <i className="fa fa-upload"></i>
                                            {
                                                formReport.file[0] ?
                                                    formReport.file.map(f => <p key={f.name}>{f.name} - {f.size} bytes</p>) :
                                                    <p>Drag or click here to select file. Only .pdf, .docx, .doc file allowed.</p>
                                            }
                                            {
                                                reportError ? (
                                                    <span className="text-center text-danger">{reportErrorMessage}</span>
                                                ) : null
                                            }
                                        </Dropzone>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    {isLoading ?
                                        <button color="primary" onClick={handleAddReport} disabled>Loading...</button> :
                                        <button className="btn-primary" color="primary" onClick={handleAddReport}>Submit</button>
                                    }
                                    <button className="btn-secondary" onClick={handleModalReport}>Cancel</button>
                                </ModalFooter>
                            </Modal>
                            <ReactTable
                                onPageChange={onReportPageChanged}
                                page={reportSetting.page}
                                onFilteredChange={onReportFilterApplied}
                                defaultFiltered={reportSetting.filter}
                                data={selected_project.reports}
                                filterable
                                columns={[
                                    {
                                        Header: "Report Name",
                                        accessor: "name",
                                        minWidth: 200,
                                        filterMethod: (filter: Filter, rows: any) =>
                                            matchSorter(rows, filter.value, { keys: ["name"] }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Submitted Date",
                                        accessor: "createdAt",
                                        minWidth: 150,
                                        Filter: ({ filter, onChange }) => (
                                            <DatePicker
                                                selected={filter ? moment.unix(filter.value/ 1000).toDate() : moment().toDate()}
                                                onChange={event => onChange(event)}
                                                dateFormat="dd MMM yyyy" />
                                        ),
                                        filterMethod: (filter, row) => {
                                            if (filter.value === null) { return true }
                                            return moment.unix(row[filter.id]).format('DD MMM YYYY') === moment(filter.value).format('DD MMM YYYY');
                                        },
                                        Cell: row => <div>{moment.unix(row.value).format('DD MMM YYYY')}</div>,
                                    },
                                    {
                                        Header: "",
                                        accessor: "fileUrl",
                                        minWidth: 100,
                                        Cell: row => <a className="report-download" href={row.value} target="_blank">Download</a>,
                                        filterable: false,
                                        sortable: false
                                    },
                                    {
                                        Header: "",
                                        accessor: "id",
                                        minWidth: 100,
                                        Cell: row => <button className="button btn-accent3" onClick={() => { handleArchive('report', row.value) }}>Archive</button>,
                                        filterable: false,
                                        sortable: false
                                    }

                                ]}
                                defaultPageSize={10}
                                defaultSorted={[{ id: "createdAt", desc: true }]}
                                className="-striped -highlight table-immuno table-report"
                                getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                                getTrProps={(state, rowInfo) => {
                                    return {
                                        className: rowInfo !== undefined ? '' : ' none'
                                    }
                                }}
                                getTdProps={(state, rowInfo, column) => {
                                    if (column.id == 'createdAt' || column.id == 'id' || column.id == 'fileUrl') {
                                        return {};
                                    }
                                    return {
                                        title: rowInfo !== undefined ? rowInfo.row[column.id] : ' none'
                                    }
                                }}
                            />
                        </TabPane>
                        <TabPane tabId="4">
                            {/* <div className="buttons text-right">
                                <Link to="/pipeline/form" onClick={handleRunPipeline}><button className="btn-primary"><i className="fa fa-gears"></i> Run Pipeline</button></Link>
                            </div> */}
                            <FileBrowser />
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </RequestWrapperView>
    );
}

export default ProjectView;
