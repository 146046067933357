import React, { useState } from 'react';
import {
    Label, UncontrolledTooltip, Badge,
    Modal, ModalHeader, ModalBody
} from 'reactstrap';
import _ from 'lodash';

import { docs } from '../../views/Documentation/Documents';
import { LABEL_SMALL } from '../../utils/constants';
import DocumentationItem from './DocumentationItem';

interface IProps {
    config: {
        id: string,
        pipeline: string
    }
}

type TData = {
    label: string,
    tooltipText: string,
    description: string,
    list?: any,
    images?: Array<any>,
    target: string,
    isRequired: boolean,
    isAdvanced: boolean
}

const LabelView = ({ config } : IProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { id = '', pipeline = '' } = config;
    const data : TData = {
        label: '',
        tooltipText: '',
        description: '',
        images: [],
        target: `${pipeline}_${id}`,
        isRequired: false,
        isAdvanced: false
    }

    const documentPipeline = docs.find(element => element.key === pipeline);
    if (_.isEmpty(documentPipeline)) {
        console.log('pipeline', pipeline); // debugger
    } else {
        const parameters = "parameters" in documentPipeline! ? documentPipeline!.parameters : [];
        let documentItem = parameters.find(element => element.key === id);
        if (_.isEmpty(documentItem)) {
            console.log('param', id); // debugger
        } else {
            data.label = documentItem ? documentItem.title : '';
            data.tooltipText = documentItem ? documentItem.tooltip : '';
            data.description = documentItem ? documentItem.text : '';
            data.list = documentItem ? documentItem.list : '';
            if (documentItem!.images) {
                data.images = documentItem!.images;
            }
            data.isRequired = documentItem ? documentItem.isRequired : false;
            data.isAdvanced = documentItem ? documentItem.isAdvanced : false;
        }
    }

    const onModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <React.Fragment>
            <Label for={id} sm={LABEL_SMALL} className={`label-custom ${data.isRequired ? 'required' : ''}`}>
                <UncontrolledTooltip autohide={false} placement="top" target={data.target}>
                    {data.tooltipText}
                </UncontrolledTooltip>
                <i id={data.target} className="fa fa-question-circle form-tooltip" onClick={onModalToggle}></i>
                {data.label}
                {data.isAdvanced && (<Badge color="dark" className="badge-custom">Advanced</Badge>)}
            </Label>

            <Modal isOpen={isModalOpen} toggle={onModalToggle} className="modal-lg modal-pipeline-tips">
                <ModalHeader toggle={onModalToggle}>{data.label}</ModalHeader>
                <ModalBody>
                    <DocumentationItem
                        className="modal-body-inner"
                        data={{
                            text: data.description,
                            images: data.images,
                            list: data.list
                        }}
                        isImageScrollable
                    />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

LabelView.defaultProps = {
    isAdvanced: false
};

export default LabelView;
