import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import RequestWrapperView from "./RequestWrapperView";
import { getOneProject } from "../../actions/action.project";
import { TDispatch } from "../../types/action";
import { TProject, IProjectList } from "../../types/type.project";
import { IStore } from "../../types/store";
import { IPipeline } from "../../types/type.pipeline";
import history from "../../views/history";
import { getOneBatch, setSelectedBatch } from "../../actions/action.batch";
import { IBatch, IBatchData, TBatch } from "../../types/type.batch";
import { getVisiblePipeline } from "../../actions/action.visible-pipeline";

interface IProps {
  location: any;
  children: any;
}

const BatchPreprocessingWrapper = ({ children, location }: IProps) => {
  const project: IProjectList = useSelector((state: IStore) => state.projects);
  const batch: IBatchData = useSelector((state: IStore) => state.batch);
  const dispatch = useDispatch<TDispatch<TProject | TBatch>>();

  const { id: batchId, projectId } = batch.selected_batch;

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (!projectId && query.project) {
      if (query.batch) {
        dispatch(setSelectedBatch(+query.batch, +query.project));
      } else {
        dispatch(setSelectedBatch(null, +query.project));
      }
    }
  }, [location.search]);

  useEffect(() => {
    const query: any = {};
    if (batchId) {
      query.batch = batchId;
      dispatch(getOneBatch(query.batch));
    }
    if (projectId) {
      query.project = projectId;
      dispatch(getOneProject(query.project, true));
    }
    if (!_.isEmpty(query)) {
      history.replace({
        pathname: `${location.pathname}?${queryString.stringify(query)}`,
      });
      dispatch(getVisiblePipeline());
    }
  }, [batchId, projectId]);

  return (
    <RequestWrapperView
      isError={project.isError || batch.isError}
      isLoading={project.isLoading || batch.isLoading}
      message={batch.message}
    >
      {children}
    </RequestWrapperView>
  );
};

export default BatchPreprocessingWrapper;
