import initialState from './initialState';
import * as types from './actionTypes';
import { IUser, TUser } from '../types/type.user';
import { removeState } from '../utils/localStorage';
import { IVersionNotif, TVersionNotif } from '../types/type.version-notification';

export default function(state: IVersionNotif = initialState.version, action: TVersionNotif) : IVersionNotif {
    switch (action.type) {
        case types.GET_VERSION:
            return {
                ...state,
                isShowUpdateNotif: action.data,
            }

        default: return state;
    }
}
