import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';

import { getNotification } from '../../actions/action.notification';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import Aside from '../../components/Aside/Aside';
import Footer from '../../components/Footer/Footer';

import Notification from '../../views/Notification/Notification';
import Dashboard from '../../views/Dashboard/Dashboard';
import Projects from '../../views/Projects/Projects';
import Pipeline from '../../views/Pipeline/Pipeline';
import Help from '../../views/Help/Help';
import Documentation from '../../views/Documentation/Documentation'
import Profile from '../../views/Profile/Profile';
import { PollNotif } from '../../utils';
import { TNotification } from '../../types/type.notification';
import { IUser } from '../../types/type.user';
import { IStore } from '../../types/store';
import { TDispatch } from '../../types/action';
import { removeState } from '../../utils/localStorage';
import { resetWelcome } from '../../actions/action.user';
import ProjectMenu from '../../views/Projects/ProjectMenu';
import { IVersionNotif } from '../../types/type.version-notification';
import { handleBulkProtein } from '../../actions/action.pipeline-input';

interface IProps {
    history: any,
    location: any
}

const Full = (props: IProps) => {
    const pipelineType: string = useSelector((state : IStore) => state.uiHistory.pipelineType);
    const dispatch = useDispatch<TDispatch<TNotification | IUser>>();
    const version: boolean = useSelector((state : IStore) => state.versionNotif.isShowUpdateNotif);

    const handleReload = () => {
        window.location.reload();
    }

    useEffect(() => {
        if (props.location.pathname === '/projects' || props.location.pathname === '/projects/') {
            dispatch(resetWelcome(true));
        }
        dispatch(getNotification(true));
    }, []);

    return (
        <div className="app">
            <PollNotif />
            <Header />
            <div className="app-body">
                <Sidebar {...props}/>
                <main className="main">
                    {version && (
                        <div aria-live="polite" aria-atomic="true" style={{position: "relative", zIndex: 99}}>
                            <div className="toast bg-success p-3 rounded position-fixed" style={{position: "absolute", top: 80, right: 10, }}>
                                <div className="toast-header">
                                <h5 className='m-0 text-white'><strong className="mr-auto">New Update!</strong></h5>
                                </div>
                                <div className="toast-body">
                                    Please reload your browser to get the new update.<br/>
                                    <a className='text-white font-weight-bold' href="#" onClick={handleReload}>Click here</a>
                                </div>
                            </div>
                        </div>
                    )}
                    <Switch>
                        <Route path="/notification" name="Notification" component={Notification}/>
                        {/* <Route path="/dashboard" name="Dashboard" component={Dashboard}/> */}
                        <Route path="/projects" name="Projects" component={Projects} />
                        <Route path="/pipeline" name="Pipeline" component={Pipeline}/>
                        <Route path="/help" name="Help" component={Help}/>
                        <Route path="/documentation" name="Documentation" component={Documentation}/>
                        <Route path="/profile" name="Profile" component={Profile}/>
                        <Route path="/groups/:id" name="Groups" component={ProjectMenu} exact />
                        <Redirect from="/" to={`/projects/${pipelineType}`}/>
                    </Switch>
                </main>
                <Aside />
            </div>
            <Footer />
        </div>
    );
}

export default Full;
