import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../../actions/action.project';
import { IProject, IProjectList, TProject } from '../../types/type.project';
import { IStore } from '../../types/store.d';
import { TDispatch } from '../../types/action';
import RequestWrapperView from '../../components/Views/RequestWrapperView';
import { IUser, TUser } from '../../types/type.user';
import { resetWelcome } from '../../actions/action.user';
import { resetUiSetting, setPipelineType } from '../../actions/action.ui-history';
import _ from 'lodash';

interface IProps {
    match: any,
    location: any
} 

const ProjectMenu = ({ match, location } : IProps) => {
    const projects: IProjectList = useSelector((state : IStore) => state.projects);
    const pipelineType: string = useSelector((state : IStore) => state.uiHistory.pipelineType);
    const user: IUser = useSelector((state : IStore) => state.user);
    const dispatch = useDispatch<TDispatch<TProject | TUser>>();
    const { isLoading, isError, all_projects, selected_project } = projects;

    const [filtered, setFiltered] = useState<Array<any>>(all_projects);

    const checkUiSetting = (id: number) => {
        if (id !== selected_project.id) {
            dispatch(resetUiSetting());
        }
    }

    useEffect(() => {
        dispatch(getProject());
        return () => {
            dispatch(resetWelcome(false));
        }
    }, [])

    useEffect(() => {
        if (pipelineType !== match.params.type) {
            dispatch(setPipelineType(match.params.type));
        }
    }, [match.params.type])

    useEffect(() => {
        const groupId = +match.params.id;
        if (!_.isNaN(groupId)) {
            const group = _.find(all_projects, { id: groupId, isGroup: true });
            if (group) {
                const projectsByGroup = _.filter(all_projects, (project) => {
                    return (_.includes(group.children, project.id) && !project.isGroup);
                });
                setFiltered(projectsByGroup);
            }
        } else {
            let initial: any = []
            const projectInGroups = all_projects.reduce((data, item) => {
                return _.union(data, item.children);
            }, initial);
            const filteredItem = _.filter(all_projects, (project) => {
                return (!_.includes(projectInGroups, project.id) || project.isGroup);
            });
            setFiltered(filteredItem);
        }
    }, [all_projects]);

    return (
        <RequestWrapperView isLoading={isLoading} isError={isError}>
            <div className="project-selection animated fadeIn">
                {!isLoading &&
                <div className="container-project">
                    {match.params.id && (
                        <React.Fragment>
                            <Breadcrumb tag="nav">
                                <BreadcrumbItem tag="a" href="#/projects">Projects</BreadcrumbItem>
                                <BreadcrumbItem active tag="span">Group</BreadcrumbItem>
                            </Breadcrumb>

                            <div className="buttons" style={{
                                display: 'flex: ,',
                                justifyContent: 'flex-end',
                                width: '100%'
                            }}>
                                <Link to={`/projects/${pipelineType}`}>
                                    <button className="btn-primary" type="button">
                                        <i className="fa fa-arrow-left"></i> Return to Dashboard
                                    </button>
                                </Link>
                            </div>
                        </React.Fragment>
                    )}

                    {user.isWelcome ? (
                        <div className="welcome-container">
                            <h1>Welcome to</h1>
                            <img src="/img/logo.png" />
                            <p>Please choose a project to work with: </p>
                        </div>
                    ) : (<div className="welcome-spacer" />)}
                    <Row>
                        {filtered.map((item,i)=>{
                            const linkPath = item.isGroup ? (
                                `/projects/${pipelineType}/groups/${item.id}`
                            ) : (
                                `/projects/${pipelineType}/${item.id}`
                            );
                            return(
                                <Col xs="12" md="6" xl="4" key={`project-${i}`} style={{padding: '0'}}>
                                    <Link to={linkPath} style={{textDecoration: 'none'}} onClick={() => checkUiSetting(item.id!)}>
                                        <div className="long-card">
                                            <div className="left">
                                                <div className="roundrec-icon">
                                                    {/* <div className="robot"/> */}
                                                    {item.isGroup ? (
                                                        <i className={'icon-folder custom'}></i>
                                                    ) : (
                                                        <i className={'icon-chart custom'}></i>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="title-wrapper">
                                                    <h4>{item.name}</h4>
                                                    {(item.organizationName && item.organizationName !== user.profile.organization) && (
                                                        <span>({item.organizationName})</span>
                                                    )}
                                                </div>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            );
                        })}
                    </Row>
                </div> }
            </div>
        </RequestWrapperView>
    );
}

export default ProjectMenu;
