import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addHelp } from '../../actions/action.help';
import { Breadcrumb, BreadcrumbItem, Col, Label, Row, FormGroup } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { TDispatch } from '../../types/action';
import { THelp } from '../../types/type.help';
import InputCustom from '../../components/Form/InputCustom';
import { INPUT_SMALL, LABEL_SMALL } from '../../utils/constants';

interface IState {
    subject: string,
    content: string,
    file: Array<any>
}

const subject = [
    { id: 1, name: 'Account Problem' },
    { id: 2, name: 'Login Problem' },
    { id: 3, name: 'Technical Problem' },
    { id: 4, name: 'Scientific Questions' },
    { id: 5, name: 'General Enquiries' },
];

const Help = () => {
    const [formHelp, setForm] = useState<IState>({
        subject: '',
        content: '',
        file: [],
    });
    const [fileError, setfileError] = useState<boolean>(false);
    const [fileErrorMessage, setfileErrorMessage] = useState<string>('');
    const dispatch = useDispatch<TDispatch<THelp>>();

    const _handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...formHelp, subject: e.target.value });
    }

    const _handleTextarea = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...formHelp, content: e.target.value });
    }

    const clearForm = () => {
        setForm({
            subject: '',
            content: '',
            file: []
        });
        setfileError(false);
        setfileErrorMessage('');
    }

    const attachFile = (file: Array<any>, reject: Array<any>) => {
        if (reject.length > 0) {
            setfileError(true);
            setfileErrorMessage('Type not allowed');
            return;
        }
        if (file.length == 0) {
            return;
        }
        setfileError(false);
        setForm({
            ...formHelp,
            file: file,
        });

    }

    const _handleAddHelp = () => {
        type IData = {
            subject: string,
            content: string,
            file?: any
        }
        let data : IData = { ...formHelp };
        if (data.file.length == 0) {
            delete data.file;
        } else {
            data.file = data.file[0];
        }
        dispatch(addHelp(data, clearForm));
    }

    return (
        <div className="animated fadeIn">
            <div className="container-project">
                <Breadcrumb tag="nav">
                    <BreadcrumbItem active tag="span">Help</BreadcrumbItem>
                </Breadcrumb>
                <div className="help-support">
                    <h4 className="blue-text">Helpdesk Support</h4>
                    <p className="grey-text">Kindly submit your request for issues you wish to report</p>
                    <div className="form-container">
                        <FormGroup row>
                            <Label sm={LABEL_SMALL} className="required">Subject</Label>
                            <Col sm={INPUT_SMALL}>
                                <InputCustom
                                    required type="select" name="help-select"
                                    onChange={_handleSelect} value={formHelp.subject}>
                                    <option disabled value="">Select Subject</option>
                                    {
                                        subject.map((s, idx) => {
                                            return (
                                                <option key={idx} value={s.name}>{s.name}</option>
                                            );
                                        })
                                    }
                                </InputCustom>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={LABEL_SMALL}>Message</Label>
                            <Col sm={INPUT_SMALL}>
                                <InputCustom
                                    required type="textarea" name="help-message"  placeholder="Type your issue here..."
                                    onChange={_handleTextarea} value={formHelp.content} 
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={LABEL_SMALL}>Attach File (optional)</Label>
                            <Col sm={INPUT_SMALL}>
                                <div className="dropzone">
                                    <Dropzone accept=".jpg, .jpeg, .png, .gif" onDrop={attachFile}>
                                        <i className="fa fa-upload"></i>
                                        {
                                            formHelp.file && formHelp.file.length > 0 ?
                                                <p>{formHelp.file[0].name}</p> :
                                                <p>Drag or click here to select file. </p>
                                        }
                                        {
                                            fileError?
                                            <span className="text-center text-danger">{fileErrorMessage}</span>
                                            :null
                                        }
                                    </Dropzone>
                                </div>
                            </Col>
                        </FormGroup>

                        <div className="btn-pull-right">
                            <button className="btn-primary" onClick={_handleAddHelp}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Help;
