import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import swal from "sweetalert";
import { updateProfile, resetPassword } from "../../actions/action.profile";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { IProfile, TUser } from "../../types/type.user";
import { IStore } from "../../types/store";
import { TDispatch } from "../../types/action";
import Toggle from "../../components/Form/Toggle";

const Profile = () => {
  const profile: IProfile = useSelector((state: IStore) => state.user.profile);
  const dispatch = useDispatch<TDispatch<TUser>>();
  const { avatarUrl } = profile;

  const [modalAvatar, setModalAvatar] = useState<boolean>(false);
  const [name, setName] = useState<string>(profile.name || "");
  const [avatar, setAvatar] = useState<Array<any>>([]);
  const [avatarCrop, setAvatarCrop] = useState<{ preview?: any; file?: any }>(
    {}
  );
  const [emailSubscription, setEmailSubscription] = useState<boolean>(
    !!profile.emailSubscription
  );

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const editor = useRef(AvatarEditor);

  const getBrowser = () => {
    let userAgent = window.navigator.userAgent;
    if ((userAgent.indexOf("Opera") || userAgent.indexOf("OPR")) != -1) {
      return "Opera";
    } else if (userAgent.indexOf("Chrome") != -1) {
      return "Chrome";
    } else if (userAgent.indexOf("Safari") != -1) {
      return "Safari";
    } else if (userAgent.indexOf("Firefox") != -1) {
      return "Firefox";
      // } else if ((userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) { //IF IE > 10
      //     return ('IE');
    } else {
      return "unknown";
    }
  };

  const browser = getBrowser();

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case "name":
        return setName(e.target.value);
      case "old_password":
        return setOldPassword(e.target.value);
      case "new_password":
        return setNewPassword(e.target.value);
      case "confirm_password":
        return setConfirmPassword(e.target.value);
    }
  };

  const handleModalAvatar = () => setModalAvatar(!modalAvatar);

  const handleDropAvatar = (avatar: Array<any>) => {
    setAvatar(avatar);
  };

  const handleAddPhoto = () => {
    if (editor.current) {
      const canvasScaled = editor.current.getImageScaledToCanvas();
      canvasScaled.toBlob((blob: any) => {
        let ext = blob.type.split("/")[1];
        let preview = URL.createObjectURL(blob);
        let file = new File([blob], "name." + ext, {
          type: blob.type,
        });
        setModalAvatar(false);
        setAvatarCrop({ preview, file });
      });
    } else {
      setModalAvatar(false);
    }
  };

  const handleUpdateProfile = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: { name?: string; avatar?: any; emailSubscription?: boolean } =
      {};
    if (name) data.name = name;
    if (avatarCrop) data.avatar = avatarCrop.file;
    if (browser === "Safari" && avatar.length > 0) {
      data.avatar = avatar[0];
    }
    data.emailSubscription = emailSubscription;
    dispatch(
      updateProfile(data, () => {
        setAvatar([]);
      })
    );
  };

  const handleResetPassword = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      swal("Failed", "Password not Match", "warning");
      return;
    }
    dispatch(resetPassword(oldPassword, newPassword));
  };

  let avatarToDisplay = avatarUrl ? avatarUrl : "../img/avatar-unset.jpg";
  avatarToDisplay = avatarCrop.preview ? avatarCrop.preview : avatarToDisplay;
  avatarToDisplay =
    browser === "Safari" && avatar.length > 0
      ? avatar[0].preview
      : avatarToDisplay;

  return (
    <div className="animated fadeIn">
      <div className="container-dashboard">
        <Breadcrumb tag="nav">
          <BreadcrumbItem active tag="span">
            Profile
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="card basic" id="card-profile">
          <Form onSubmit={handleUpdateProfile}>
            <h5 className="blue-text center-line">
              <span>Profile</span>
            </h5>
            <FormGroup row>
              <Label for="project" xs="3">
                Profile Picture
              </Label>
              <Col xs="9">
                <div className="avatar-container">
                  <div
                    className="avatar-photo"
                    style={{
                      backgroundImage: 'url("' + avatarToDisplay + '")',
                    }}
                  ></div>
                  {/* <img className="avatar-photo" src={avatar} /> */}
                  <i
                    onClick={handleModalAvatar}
                    className="icon fa fa-camera"
                  ></i>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="project" xs="3">
                Name
              </Label>
              <Col xs="9">
                <Input
                  name="name"
                  placeholder="Your name"
                  onChange={handleChangeText}
                  value={name}
                  maxLength={255}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="organization" xs="3">
                Organization
              </Label>
              <Col xs="9">
                <Input
                  name="organization"
                  placeholder="Your organization"
                  value={profile.organization}
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="project" xs="3">
                Email Subscription
              </Label>
              <Col xs="9">
                <Toggle
                  value={emailSubscription}
                  onToggle={() => setEmailSubscription(!emailSubscription)}
                />
              </Col>
            </FormGroup>
            <div className="text-right">
              <button
                type="submit"
                className="btn-primary"
                style={{ width: "200px" }}
              >
                Update Profile
              </button>
            </div>
          </Form>

          <Form onSubmit={handleResetPassword}>
            <h5 className="blue-text center-line">
              <span>Change Password</span>
            </h5>
            <FormGroup row>
              <Label for="project" xs="3">
                Old Password
              </Label>
              <Col xs="9">
                <Input
                  type="password"
                  name="old_password"
                  placeholder="Old password"
                  onChange={handleChangeText}
                  value={oldPassword}
                  maxLength={255}
                  required
                  minLength={8}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="project" xs="3">
                New Password
              </Label>
              <Col xs="9">
                <Input
                  type="password"
                  name="new_password"
                  placeholder="New password"
                  onChange={handleChangeText}
                  value={newPassword}
                  maxLength={255}
                  required
                  minLength={8}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="project" xs="3">
                Confirm Password
              </Label>
              <Col xs="9">
                <Input
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm password"
                  onChange={handleChangeText}
                  value={confirmPassword}
                  maxLength={255}
                  required
                  minLength={8}
                />
              </Col>
            </FormGroup>
            <div className="text-right">
              <button
                className="btn-primary"
                type="submit"
                style={{ width: "200px" }}
              >
                Change Password
              </button>
            </div>
          </Form>
        </div>
      </div>

      <Modal isOpen={modalAvatar} toggle={handleModalAvatar}>
        <ModalHeader toggle={handleModalAvatar}>Add Photo</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="dropzone">
              <Dropzone
                accept=".jpg, .jpeg, .png, .gif"
                onDrop={handleDropAvatar}
                multiple={false}
              >
                <i className="fa fa-upload"></i>
                {avatar.length > 0 ? (
                  avatar.map((f) => (
                    <p key={f.name}>
                      {f.name} - {f.size} bytes
                    </p>
                  ))
                ) : (
                  <p>
                    Try dropping some files here, or click to select files to
                    upload.
                  </p>
                )}
              </Dropzone>
            </div>
            {avatar.length > 0 && (
              <div className="crop-photo">
                {browser === "Safari" ? (
                  <div className="image-avatar-container">
                    <div
                      className="image-avatar"
                      style={{
                        backgroundImage: 'url("' + avatar[0].preview + '")',
                      }}
                    >
                      {/* <img src={avatar.length && avatar[0].preview} /> */}
                    </div>
                  </div>
                ) : (
                  <AvatarEditor
                    ref={editor}
                    image={avatar.length && avatar[0].preview}
                    width={200}
                    height={200}
                    border={120}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={1.25}
                    rotate={0}
                    borderRadius={500}
                  />
                )}
              </div>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button className="btn-primary" onClick={handleAddPhoto}>
            Upload
          </button>
          <button className="btn-secondary" onClick={handleModalAvatar}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Profile;
