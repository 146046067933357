import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup, Label } from 'reactstrap';
import { getResultTypes, updateInput } from '../../../actions/action.pipeline-input';
import FileSelector from '../../../components/FileSelector/FileSelector';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import InputCustom from '../../../components/Form/InputCustom';
import { TDispatch } from '../../../types/action';
import { IStore } from '../../../types/store';
import { IPipeline, TPipeline, TResultType } from '../../../types/type.pipeline';
import { INPUT_SMALL, LABEL_SMALL } from '../../../utils/constants';

const ResultUpload = () => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced } = pipeline;
    const [resultTypes, setResultTypes] = useState<TResultType[]>([]);
    const [filterPattern, setFilterPattern] = useState<string>('');
    const [extension, setExtension] = useState<string[]>([]);
    const [isMultiple, setIsMultiple] = useState<boolean>(true);

    const loadResultType = async () => {
        const res = await getResultTypes();
        setResultTypes(res);
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(updateInput(name, value));
    }

    useEffect(() => {
        loadResultType()
    }, []);
    
    useEffect(() => {
        const found = _.find(resultTypes, ["id", +input.resultType]);
        if (found) {
            setIsMultiple(found.isMultiple);
            setFilterPattern(found.validatorPattern);

            const extTest = new RegExp(/^\.(.+?)/);
            const extensions = found.fileType.split(',').map((ext) => {
                const trimmed = ext.trim();
                if (!extTest.test(trimmed)) return `.${trimmed}`;
                return trimmed;
            });
            setExtension(extensions);

            const filtered = _.filter(input.files, (s3File) => {
                const filename = s3File.substring(s3File.lastIndexOf("/")+1);
                return new RegExp(found.validatorPattern, 'g').test(filename);
            });
            dispatch(updateInput('files', filtered));
        }
    }, [input.resultType]);

    return (
        <div className="result-upload">
            <h4 className="subtitle">Result Upload</h4>
            <div className="pipeline-form">
                <FormGroupCustom
                    pipeline="resultupload"
                    id="resulttyperesultupload"
                    input={input.resultType}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="resultType" type="select"
                            onChange={handleInput} value={input.resultType}>
                            <option value="">Select Result Type</option>
                            {resultTypes.map((res) => (
                                <option key={res.id} value={res.id}>{res.name} ({res.validatorPattern})</option>
                            ))}
                            </InputCustom>
                    </Col>
                </FormGroupCustom>

                {/* {input.resultType && ( */}
                    <FormGroupCustom
                        pipeline="resultupload"
                        id="filesresultupload"
                        input={input.files}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <FileSelector
                                name="files"
                                tray={input.files}
                                accept={extension}
                                getPreviousPipelineOutput={false}
                                isMultiple={isMultiple}
                                isHideWorkspace
                                validateFilename={filterPattern}
                            />
                        </Col>
                    </FormGroupCustom>
                {/* )} */}
            </div>
        </div>
    );
}

export default ResultUpload;
