import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { MANUAL_GATING, PREPROCESSING } from '../../views/Pipeline/Form';
import swal from 'sweetalert';

interface INodeProps {
    node: {
        id: number,
        name: string,
        checked: boolean,
        expand: boolean,
        url: string,
        s3Url: string,
        viewUrl: string | null,
        key: string
    },
    checklist: boolean,
    download: boolean,
    view: boolean,
    updateData: Function,
    children: any,
    expand?: boolean,
    pipeline: {
        pipelineId: number,
        name: string,
    },
}

const Node = (props: INodeProps) => {
    const [expand, setExpand] = useState<boolean>(props.node.expand);

    const isImageProcessing = props.pipeline.pipelineId === MANUAL_GATING && props.pipeline.name.endsWith(' (Image)');

    const toggleCheck = (e: any) => {
        let node = props.node;
        node.checked = e.target.checked;
        props.updateData(node);
    }

    const toggleExpand = (e: any) => {
        let node = props.node;
        node.expand = !expand;
        // this.props.updateData(node);
        setExpand(!expand);
    }

    const copyToClipboard = (e: string) => {
        navigator.clipboard.writeText(e);
        swal({
            title: 'Success',
            text: 'Coppied to clipboard',
            icon: 'success',
            timer: 1000,
            closeOnClickOutside: true,
            buttons: { visible: false }
        });
    }
    
    let childnodes = null;

    // the Node component calls itself if there are children
    if(props.children && props.children.length > 0) {
        let { updateData, checklist, download, view } = props;
        childnodes = props.children.map(function(childnode: any, i: number) {
            return (
                <Node key={i} node={childnode} 
                    children={childnode.children} 
                    updateData={updateData}
                    checklist={checklist} 
                    download={download} 
                    view={!_.isNull(childnode.viewUrl)}
                    pipeline={props.pipeline}
                />
            );
        });
    }
    const attributes: any = {};
    if (childnodes) {
        attributes.className = "parent";
        attributes.onClick = toggleExpand;
    } else {
        attributes.className = "last";            
    }

    const listView = () => {
        return <div>
            <div className="super-parent">
                {props.checklist && (
                    <input
                        className="check" type="checkbox" name={props.node.key}
                        onChange={toggleCheck} checked={props.node.checked}
                    />
                )}
                <div {...attributes} >
                    {childnodes && (
                        <i className={props.node.expand? "fa fa-minus-square":"fa fa-plus-square"}></i> 
                    )}
                    <span className="file-name">{props.node.name}</span>

                    <div className="action-group">
                        {(!childnodes && props.node.s3Url.endsWith('.fcs')) && (
                            <a onClick={() => copyToClipboard(props.node.s3Url)} target="_blank">
                                <button className="btn btn-small btn-accent2">S3 Path</button>
                            </a>
                        )}

                        {(props.view && !_.isNull(props.node.viewUrl) && !childnodes) && (
                            <a href={props.node.viewUrl} target="_blank">
                                <button className="btn btn-small btn-accent2">View</button>
                            </a>
                        )}

                        {(props.download && !childnodes) && (
                            <a href={props.node.url} target="_blank">
                                <button className="btn btn-small btn-accent2">Download</button>
                            </a>
                        )}
                    </div>

                </div>
            </div>
            {(childnodes && props.node.expand) ? <ul>{childnodes}</ul> : null}
        </div>
    }

    // return our list element
    // display children if there are any
    if (isImageProcessing) {
        return (
            <li key={props.node.id}>
                {props.node.name.endsWith('.png') && (
                    listView()
                )}
            </li>
        );
    } else {
        return (
            <li key={props.node.id}>
                {listView()}
            </li>
        );
    }
}

const FileStructure = (props) => {
    const [files, setFiles] = useState<Array<any>>([]);
    const [result, setResult] = useState<Array<any>>([]);
    const [exclude, setExclude] = useState<Array<any>>([]);
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         files: [],
    //         result: [],
    //         exclude: [],
    //         config: [],
    //     }
    // }

    const sortArray = (data: Array<any>) => {
        // let sort = true;
        for (var i in data) {
            if (data[i].children.length > 0) {
                sortArray(data[i].children);
            } 
        }
        
        data.sort((a, b) => {
            let comparison = 0;
            var aName = a.name.toLowerCase();
            var bName = b.name.toLowerCase()    ;
            var x = a.children.length;
            var y = b.children.length;
            
            if (x > 0 && y > 0) {
                comparison = aName.localeCompare(bName, undefined, {numeric: true, sensitivity: 'base'});
            } else if (x > 0 || y > 0) {
                comparison = y - x; 
            } else {
                comparison = aName.localeCompare(bName, undefined, {numeric: true, sensitivity: 'base'});
            }

            return comparison;
        });
    }

    const arrangeIntoTree = (paths: Array<any>, config: Array<any>) => {
        var tree = [];
        const { pipeline } = props;

        const isPreprocessing = pipeline.id === PREPROCESSING;

        // This example uses the underscore.js library.
        _.each(paths, function(path) {
            var pathParts = path.path.split('/');
            // remove 2 top level of folders
            const isOldPreprocessing = (pathParts[3] !== 'output') && isPreprocessing;
            pathParts = pathParts.slice(isOldPreprocessing ? 3 : 4);
            // pathParts.shift(); // Remove first blank element from the parts array.

            var currentLevel: Array<any> = tree; // initialize currentLevel to root

            _.each(pathParts, function(part) {

                // check to see if the path already exists.
                var existingPath: any = _.find(currentLevel, {
                    name: part
                });

                if (existingPath) {
                    // The path to this item was already in the tree, so don't add it again.
                    // Set the current level to this path's children
                    currentLevel = existingPath.children;
                } else {
                    let key = path.path.split(part)[0] + part;
                    let checked = false;
                    if (config.length > 0) {
                        if (config.indexOf(key) > -1) {
                            checked = true;
                        }
                    } else {
                        checked = true;
                    }

                    let newPart: any = {
                        name: part,
                        key,
                        url: path.signedUrl,
                        viewUrl: path.viewUrl,
                        s3Url: path.s3Url,
                        checked,
                        children: [],
                    }

                    currentLevel.push(newPart);
                    currentLevel = newPart.children;
                }
            });
        });
        return tree;
    }
    
    const findAndReplace = (parent: Array<any>, children: any) => {
        let data = parent;
        if (data !== undefined) {
            let one = data.findIndex(e => e.key == children.key);
            if (one == -1) {
                for (let i=0;i<data.length;i++){
                    if (data[i].children) {
                        data[i].children = findAndReplace(data[i].children, children);
                        break;
                    }  

                }
            } else {
                if ("children" in children) {
                    let childArray: Array<any> = [];
                    for (let i of children.children) {
                        i.checked = children.checked;
                        childArray.push(i);

                        // trigger
                        findAndReplace(children.children, i);
                    }
                    children.children = childArray;
                }
                data[one] = children;
            }
        }
        return data;
    }

    const treetoFlat = (data: Array<any>, first: boolean = false) => {
        let resultClone = _.cloneDeep(result);
        let excludeClone = _.cloneDeep(exclude);
        for(var i in data) {
            if (data[i].children.length > 0) {
                treetoFlat(data[i].children);
            } else {
                let key = data[i].key;
                let resindex = resultClone.indexOf(key);
                let excindex = excludeClone.indexOf(key);
                if (data[i].checked) {
                    if (resindex == -1) {
                        resultClone.push(key);
                        setResult(resultClone);
                    }
                    if (excindex > -1) {
                        excludeClone.splice(excindex, 1);
                        setExclude(excludeClone);
                    }
                } else if (!data[i].checked){
                    if (resindex > -1) {
                        resultClone.splice(resindex, 1);
                        setResult(resultClone);
                    }

                    if (excindex == -1) {
                        excludeClone.push(key);
                        setExclude(excludeClone);
                    }
                }
            }
        }
    }

    const getOutput = () => {
        treetoFlat(files, true);
        return {
            checked: result,
            unchecked: exclude
        };
    }

    const updateData = (data: any) => {
        const newFiles = findAndReplace(files, data);
        setFiles(newFiles);
        if (props.onChange) {
            let output = getOutput();
            props.onChange(output);
        }
    }

    const manipulateData = (files: Array<any>, prefix: string, config: Array<any> = []) => {
        setResult([]);
        setExclude([]);
        let paths: Array<any> = [];
        const test = new RegExp('s3://');
        for(var i in files) {
            let f = files[i];
            let path = f.s3Url;
            if (test.test(f.s3Url)) {
                let s3Url = f.s3Url.replace('s3://', '');
                path = s3Url.substring(s3Url.indexOf('/') + 1);
            }
            let objek = {
                path,
                signedUrl: f.signedUrl,
                viewUrl: f.viewUrl,
                s3Url: f.s3Url,
            }
            paths.push(objek);
        }
        if (paths.length > 0) {
            let tree = arrangeIntoTree(paths, config);
            sortArray(tree);
            setFiles(tree);
        }
    }

    useEffect(() => {
        // if (JSON.stringify(props.files) !== JSON.stringify(nextProps.files)) {
        // }
        manipulateData(props.files, props.prefix);
    }, [props.files]);

    useEffect(() => {
        manipulateData(props.files, props.prefix, props.config);
    }, []);

    let nodes = files.map((file, i) => {
        return (
            <Node key={i} 
                node={file} 
                children={file.children} 
                updateData={updateData} 
                checklist={props.checklist} 
                download={props.download} 
                view={props.view}
                pipeline={props.pipeline}
            />
        );
    });

    return (
        <div className="tree-files">
            <ul className="org">
                {(files.length && props.batchDownload) ? (
                    <button className="btn btn-small btn-accent pull-right all"
                    onClick={props.batchDownload}>Download all</button>
                ) : null}
                {files.length ? nodes : <span>no output files</span>}
            </ul>
            {/* <button className="btn btn-danger" onClick={this.filterKey.bind(this)}>Flatten</button> */}
        </div>
    );
}

export default FileStructure;