import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter } from 'reactstrap'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import moment from 'moment';
import _ from 'lodash';

import { getOneExecution, requestBatchDownload, archivePipeline, cancelPipeline, downloadPipeline } from '../../actions/action.pipeline';
import { clone } from '../../actions/action.pipeline-input';
import InputSummary from './InputSummary';
import { IExecution, TExecution } from '../../types/type.execution';
import { IStore } from '../../types/store.d';
import { TDispatch } from '../../types/action';
import RequestWrapperView from '../../components/Views/RequestWrapperView';
import { IProfile } from '../../types/type.user';
import FileStructure from '../../components/Views/FileStructure';
import LogView from '../../components/Views/LogView';
import { getVisiblePipeline } from '../../actions/action.visible-pipeline';
import { IBatch } from '../../types/type.batch';

interface IProps {
    match: any,
    history: any
}

const TCR_ANALYSIS = 14;
const SCATTER_PLOT = 20;

const PIPELINE_DOWNLOAD = 'PIPELINE_DOWNLOAD';
const BATCH_DOWNLOAD = 'BATCH_DOWNLOAD';

const Detail = (props: IProps) => {
    const pipelineType: string = useSelector((state : IStore) => state.uiHistory.pipelineType);
    const execution: IExecution = useSelector((state : IStore) => state.execution);
    const profile: IProfile = useSelector((state : IStore) => state.user.profile);
    const selectedBatch: IBatch = useSelector((state: IStore) => state.batch.selected_batch);
    const dispatch = useDispatch<TDispatch<TExecution>>();
    const { isLoading, isError, message, selected_execution } = execution;
    const { projectId, status } = selected_execution;

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('');
    const [batchFileName, setBatchFileName] = useState<string>('');
    const [isDownloadPipeline, setIsDownloadPipeline] = useState<boolean>(false);

    const handleDownload = () => {
        const { id } = selected_execution;
        toggleModal();
        dispatch(downloadPipeline(id, validateFileName()));
    }

    const handleClone = () => {
        dispatch(clone());
        props.history.push('/pipeline/form');
    }

    const handleArchive = () => {
        // in progress pipeline
        if (status === 1) {
            swal("Failed", "Archiving of Pipelines in Progress not possible. Wait until Pipeline succeeded or failed.", "warning")
            return;
        }

		// @ts-ignore
		swal({ buttons: true,
			title: "Are you sure?",
			text: "This process is permanent and cannot be undone",
			icon: "warning",
			dangerMode: true,
		})
		.then((willDelete: boolean | null) => {
            const redirect = (pipelineType === 'preprocessing') ? (
                `/pipeline/preprocessing?batch=${selectedBatch.id}&project=${selectedBatch.projectId}`
            ) : (
                `/projects/${pipelineType}/${projectId}`
            );
			if (willDelete) {
                dispatch(archivePipeline(
                    props.match.params.id,
                    () => props.history.push(redirect)
                ));
			} else {
			}
		});
    }

    const handleCancel = () => {
		// @ts-ignore
		swal({ buttons: true,
			title: "Are you sure?",
			text: "This process is permanent and cannot be undone",
			icon: "warning",
			dangerMode: true,
		})
		.then((canceled: boolean | null) => {
			if (canceled) {
                dispatch(cancelPipeline(
                    props.match.params.id,
                    () => props.history.push(`/projects/${pipelineType}/${projectId}`)
                ));
			} else {
			}
		});
    }

    const userCanClone = () => {
        const clonnablePipeline = _.clone(profile.visiblePipelines) || [];
        // _.remove(clonnablePipeline, (pipeline) => pipeline == 16); // remove bead normalization for now

        const isMyProject = _.includes(profile.projects, selected_execution.projectId);
        const isPipelineAllowed = _.includes(clonnablePipeline, selected_execution.pipelineId);
        if (isMyProject && isPipelineAllowed) {
            return true;
        }
        return false;
    }

    const toggleModal = (downloadType: string = isDownloadPipeline ? PIPELINE_DOWNLOAD : BATCH_DOWNLOAD) => {
        if ( isArchived() ) {
            swal('Warning', 'This Execution has been Archived. Please contact Cytographer Admin to Retrieve Data.', 'warning');
        } else {
            setIsDownloadPipeline(downloadType == PIPELINE_DOWNLOAD);
            if (downloadType == PIPELINE_DOWNLOAD) {
                setFileName("internal-"+ selected_execution.id +".zip")
            }else{
                setFileName(batchFileName);
            }
            setIsModalOpen(!isModalOpen);
        }
    }

    const isArchived = (): boolean | undefined => {
        return selected_execution.archivedStatus && (selected_execution.archivedStatus.text === 'Archiving' || selected_execution.archivedStatus.text === 'Archived');
    }

    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFileName(value);
    }

    const validateFileName = ():string => {
        // replace space with "-"
        let name = fileName.replace(/\s+/g, '-');

        // make sure the file name ends with ".zip"
        if (!new RegExp(/.zip$/).test(fileName)) {
            if (name.length > 251) {
                name = name.substring(0, 251);
            }
            name += '.zip';
        }
        return name;
    }

    const downloadAll = () => {
        let pipelineId = (selected_execution.id as any);
        toggleModal(BATCH_DOWNLOAD);
        dispatch(requestBatchDownload(pipelineId, validateFileName()));
    }

    const gotoConnection = () => {
        props.history.push({ pathname: `/pipeline/detail/${selected_execution.connectionExecutionId}` });
    }

    useEffect(() => {
        dispatch(getOneExecution(props.match.params.id));
        dispatch(getVisiblePipeline());
    }, [props.match.params.id]);

    useEffect(() => {
        const now = moment().format('YYYY-MM-DDTHH_mm_ss');
        const defaultBatchFileName = `${selected_execution.type}_${selected_execution.name}_${now}.zip`.replace(/\s+/g, '-');
        setBatchFileName(defaultBatchFileName);
    }, [selected_execution])

    let input = [];
    let output: Array<any> = [];
    let hasLogs: boolean = false

    if (selected_execution.input != '') {
        input = JSON.parse(selected_execution.input);
    }
    if (selected_execution.output != '') {
        let awsOutput: any = JSON.parse(selected_execution.output)
        if (awsOutput != null && !_.isUndefined(awsOutput.files)) {
            output = awsOutput.files;
        } else if (awsOutput != null && !_.isUndefined(awsOutput.logs)) {
            output = awsOutput.logs;
            hasLogs = true;
        }
    }
    let s3Prefix = '';
    if ('s3Prefix' in selected_execution) {
        s3Prefix = selected_execution.s3Prefix;
    }
    // const paramsToExclude = _.includes([TCR_ANALYSIS], selected_execution.pipelineId) ? ['input']:[];
    const paramsToExclude = [];

    const backUrl = (pipelineType === 'preprocessing') ? (
        `/pipeline/preprocessing?batch=${selectedBatch.id}&project=${selectedBatch.projectId}`
    ) : (
        `/projects/${pipelineType}/${projectId}`
    );

    return (
        <RequestWrapperView isError={isError} isLoading={isLoading} message={message}>
            {!isLoading && (
                <div className={"pipeline execution detail "} >
                    <Breadcrumb tag="nav">
                        <BreadcrumbItem tag="a" href={`#/projects/${pipelineType}/${projectId}`}>Projects</BreadcrumbItem>
                        <BreadcrumbItem active tag="span">{selected_execution.name}</BreadcrumbItem>
                    </Breadcrumb>
                    <h1 className="title">{selected_execution.name}</h1>
                    <div className="buttons">
                        <Link to={backUrl} style={{marginTop:'20px'}}>
                            <button className="btn-primary mb-1" type="button">
                                <i className="fa fa-arrow-left"></i> Return to Dashboard
                            </button>
                        </Link>

                        {selected_execution.connectionExecutionId && 
                            <button 
                                className="btn-primary mb-1" 
                                type="button"
                                onClick={gotoConnection}
                            >
                                <i className="fa fa-arrow-right"></i> 
                                {
                                    selected_execution.isImageProcessing ? ' Goto Original Execution' : ' Goto Image Execution'
                                }
                            </button>
                        }

                        {(selected_execution.status === 1 && !selected_execution.isShiny) && (
                            <button
                                className="btn-danger mb-1"
                                type="button"
                                onClick={handleCancel}
                            >
                                <i className="fa fa-ban" /> Cancel This Session
                            </button>
                        )}

                        {selected_execution.isActive && (
                            <button
                                className="btn-danger mb-1"
                                type="button"
                                onClick={handleArchive}
                            >
                                <i className="fa fa-archive" /> Archive This Session
                            </button>
                        )}

                        {(selected_execution.isActive && userCanClone()) && (
                            <button
                                className="btn-primary mb-1"
                                type="button"
                                onClick={handleClone}
                            >
                                <i className="fa fa-copy" /> Clone This Session
                            </button>
                        )}

                        <button
                            className="btn-primary mb-1"
                            type="button"
                            onClick={()=>toggleModal(PIPELINE_DOWNLOAD)}
                        >
                            <i className="fa fa-download" /> Download Pipeline
                        </button>

                        <Link to="/pipeline/form">
                            <button className="btn-primary mb-1" type="button"><i className="fa fa-cog" /> Run New Session</button>
                        </Link>
                    </div>

                    <div className="card overview">
                        <h4 className="subtitle">Overview</h4>
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <th style={{ width: '200px' }}>Session Name</th>
                                    <td>{selected_execution.name}</td>
                                </tr>
                                <tr>
                                    <th>Notes</th>
                                    <td className="description-text">{selected_execution.description}</td>
                                </tr>
                                <tr>
                                    <th>Run Date</th>
                                    <td>{moment.unix(selected_execution.createdAt as number).format('DD MMM YYYY HH:mm:ss')}</td>
                                </tr>
                                <tr>
                                    <th>Project Name</th>
                                    <td>{selected_execution.project ? selected_execution.project.name : ''}</td>
                                </tr>
                                <tr>
                                    <th>Pipeline Type</th>
                                    <td>{selected_execution.type}</td>
                                </tr>
                                <tr>
                                    <th>Pipeline Version</th>
                                    <td>{selected_execution.version}</td>
                                </tr>
                                <tr>
                                    <th>Session Status</th>
                                    <td className={selected_execution.archivedStatus ? `text-${selected_execution.archivedStatus.type}` : ''}>{selected_execution.archivedStatus ? selected_execution.archivedStatus.text : '-'}</td>
                                </tr>
                            </tbody>
                        </table>
                        {selected_execution.pipelineInformation && (
                            <iframe style={{ padding: '0 4px' }} src={selected_execution.pipelineInformation} />
                        )}
                    </div>

                    {(!_.isEmpty(output) && !hasLogs) && (
                        <div className="card outputs">
                            <h4 className="subtitle">Output Files</h4>
                            <FileStructure files={output}
                                prefix={s3Prefix}
                                batchDownload={()=>toggleModal(BATCH_DOWNLOAD)}
                                pipeline={selected_execution}
                                download={isArchived() ? false : true}// checklist={true}
                                view={isArchived() ? false : true}
                            />
                        </div>
                    )}

                    <div className="card parameters">
                        <h4 className="subtitle">Pipeline Parameters</h4>
                        <InputSummary input={input} exclude={paramsToExclude} />
                    </div>

                    {hasLogs && (
                        <div className="card outputs">
                            <h4 className="subtitle">Logs</h4>
                            <LogView logs={output} />
                        </div>
                    )}

                    <Modal isOpen={isModalOpen} toggle={()=>toggleModal()}>
                        <ModalHeader toggle={()=>toggleModal()}>Download as...</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Input
                                    value={fileName}
                                    name="name"
                                    onChange={handleChangeText}
                                    placeholder="File Name..."
                                    onFocus={(e) => e.target.select()}
                                    maxLength={255}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <button 
                                className="btn-primary" 
                                color="primary" 
                                onClick={() => {
                                    isDownloadPipeline ? handleDownload() : downloadAll()
                                }}
                            >{ isDownloadPipeline ? "Download Pipeline" : "Download All"}</button>
                            <button 
                                className="btn-secondary" 
                                onClick={()=>toggleModal()}
                            >Cancel</button>
                        </ModalFooter>
                    </Modal>
                </div>
            )}
        </RequestWrapperView>
    );
}

export default Detail;
