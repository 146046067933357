import initialState from './initialState';
import * as types from './actionTypes';
import { IVisiblePipeline, TVisiblePipeline } from '../types/type.visible-pipeline';

export default function(state: IVisiblePipeline = initialState.visiblePipeline, action: TVisiblePipeline) : IVisiblePipeline {
    switch (action.type){
        case types.GET_VISIBLE_PIPELINE_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.GET_VISIBLE_PIPELINE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.data
            }
        case types.GET_VISIBLE_PIPELINE_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }
        case types.PIPELINE_TAB_CHANGED:
            const selected = state.data.find(element => element.id == action.tabId)!.pipelines;
            return {
                ...state,
                selected
            }
        default: return state;
    }
}
