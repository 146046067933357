import React, { useEffect, useState } from 'react';
import {
    Col, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';

import ColorPalette from '../../../components/ColorPalette/ColorPalette';
import FileSelector from '../../../components/FileSelector/FileSelector';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import { updateInput, changeExcludedParams, resetPipelineParams, updateMetadata } from '../../../actions/action.pipeline-input';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import InputCustom from '../../../components/Form/InputCustom';
import Toggle from '../../../components/Form/Toggle';
import { GATING_SUBSETTING_STEP, INPUT_SMALL } from '../../../utils/constants';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import pipelineBasicParams from '../../../reducers/pipeline-basic-params';
import MetasampleEditor from '../../../components/Views/MetasampleEditor';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';
import { IProfile } from '../../../types/type.user';
import { ICoordinatesFiles } from '../../../types/type.coordinates-files';
import { defaultFileUpload, getExtension } from '../helper';
import { compareClonedCoordinatesFiles, getCoordinatesFiles } from '../../../actions/action.coordinates-files';

const heatplot = [
    { name: 'Normal', isShiny: false },
    { name: 'Interactive', isShiny: true },
];

const HeatMap = () => {
    const [activeTab, setActiveTab] = useState<string>('params');
    const profile: IProfile = useSelector((state : IStore) => state.user.profile);
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const coordinatesFiles: ICoordinatesFiles = useSelector((state: IStore) => state.coordinatesFiles);
    const projectId: number = useSelector((state : IStore) => state.projects.selected_project.id)!;
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const {
        tabId, pipelineId, pipelineScript,
        input, isAdvanced, isShiny
    } = pipeline;
    const [isSingleCsv, setIsSingleCsv] = useState<boolean>(false);
    const [calcFreq, setCalcFreq] = useState<boolean>(false);
    const [clonedCoordinates, setClonedCoordinates] = useState<Array<string>>([]);

    const dispatchInput = (key: string, value: any) => dispatch(updateInput(key, value));

    const handleToggle = (value: boolean, key: string) => {
        const toggledValue = !value;

        switch (key) {
            case 'doFreq':
                setCalcFreq(toggledValue);
                if (toggledValue) {
                    const prefix = defaultFileUpload(profile);
                    dispatch(getCoordinatesFiles(coordinatesFiles.filesToUpload, projectId, prefix));
                } else {
                    input.coordsFolder = [];
                }
                break;
            case 'allCells':
                if (_.isEmpty(input.coordsFolder)) {
                    dispatchInput(key, toggledValue ? true : null);
                }
                break;
            default:
                dispatchInput(key, toggledValue ? true : null);
        }
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        dispatchInput(name, value);

        if (name === 'useCoords' && !value) {
            input.useCoords = null;
            dispatchInput('useCoords', null);
            dispatchInput('coordsFile', []);
        }

        if (name === 'transformation' && value !== 'custom') {
            dispatchInput('W', null);
            dispatchInput('T', null);
            dispatchInput('M', null);
            dispatchInput('A', null);
        }

        if (name === 'clustMarker') {
            dispatchInput('cutMarker', value === 'none' ? null : 1);
        }

        if (name === 'clustSample' && value === 'none') {
            dispatchInput('cutSample', value === 'none' ? null : 1);
        }

        if (name === 'cutMarker' && !value) {
            dispatchInput('cutMarker', null);
        }

        if (name === 'cutSample' && !value) {
            dispatchInput('cutSample', null);
        }
    }

    const clusters = isAdvanced ? [
        { value: 'none', name: 'none' },
        { value: 'correlation', name: 'Pearson Correlation' },
        { value: 'euclidean', name: 'Euclidean Distance' },
        { value: 'manhattan', name: 'Manhattan Distance' },
    ] : [
        { value: 'none', name: 'none' },
        { value: 'correlation', name: 'Pearson Correlation' },
        { value: 'euclidean', name: 'Euclidean Distance' },
    ];

    const checkEdited = () => {
        const currentForm = _.get(pipelineBasicParams, `${tabId}.${pipelineId}.${pipelineScript}`, []);
        return !_.isEqual(currentForm, input);
    }

    const onToggleMode = (value: number) => {
        const selectedHeatmap = heatplot[value];
        if (!selectedHeatmap) return;
        if (selectedHeatmap.isShiny !== isShiny) {
            if (checkEdited()) {
                // @ts-ignore
                swal({
                    title: "Are you sure?",
                    text: "Your current changes will reset to default",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willChanged: boolean | null) => {
                        if (willChanged) {
                            dispatch(updateMetadata('isShiny', selectedHeatmap.isShiny));
                            dispatch(updateMetadata('pipelineScript', value));
                            dispatch(resetPipelineParams(['isShiny', 'pipelineScript']));
                            setCalcFreq(false);
                        }
                    });
            } else {
                dispatch(updateMetadata('isShiny', selectedHeatmap.isShiny));
                dispatch(updateMetadata('pipelineScript', value));
                dispatch(resetPipelineParams(['isShiny', 'pipelineScript']));
                setCalcFreq(false);
            }
        }
    }

    const isFcsFile = () : boolean => {
        const fileExtension = getExtension(input.input.length>0 ? input.input[0] : '');

        return fileExtension === 'fcs';
    }

    useEffect(() => {
        const findCsv = _.find(input.input, (i) => i.split('.').pop() === 'csv');
        const exclude = (!!findCsv) ? ['transformation', 'useCoords'] : [];

        setIsSingleCsv(!!findCsv);
        dispatch(changeExcludedParams(exclude));
    }, [input.input]);

    useEffect(() => {
        if(_.isEmpty(input.input)){
            coordinatesFiles.filesToUpload = [];
            coordinatesFiles.files = [];
        }

        if (!_.isEmpty(input.coordsFolder)) {
            setClonedCoordinates([...input.coordsFolder]);
            setCalcFreq(true);
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(input.coordsFolder)) {
            dispatchInput('allCells', false);
        }
        
        if (calcFreq) {
            dispatch(compareClonedCoordinatesFiles(clonedCoordinates));
        }
    }, [input.coordsFolder, calcFreq]);

    const selected = _.find(heatplot, (item) => item.isShiny === isShiny) || { name: 'Normal' };

    return (
        <TabbedMetaContainer
            name={`Heat Plot (${selected.name})`}
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.input)}
            containerClass="heatmap"
            metamarker={(!_.isEmpty(input.input)) && (
                <FormGroupCustom
                    pipeline="heatplot"
                    id="metamarkerheatplot"
                    input={input.metaMarker}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        {(input.protein && input.protein.length > 0) ? (
                            <ProteinSelector
                                columns={['keep']}
                                protein={input.protein}
                                tray={input.metaMarker}
                                trayName='metaMarker'
                            />
                        ) : (
                            <FileSelector
                                name="metaMarker"
                                tray={input.metaMarker}
                                isMultiple={false}
                                accept={['csv', '.csv']}
                                checkFcsMetadata={false}
                                getPreviousPipelineOutput={false}
                            />
                        )}
                    </Col>
                </FormGroupCustom>
            )}
            metasample={
                <FormGroupCustom
                    pipeline="heatplot"
                    id="metasampleheatplot"
                    input={input.metaSample}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <MetasampleEditor input='input' />
                    </Col>
                </FormGroupCustom>
            }
        >
            <div className="manual-gating-state">
                {_.map(heatplot, (heat, index) => (
                    <span
                        key={`heat-${index}`}
                        className={`manual-gating-state-item ${heat.isShiny === isShiny ? 'active' : ''}`}
                        onClick={() => onToggleMode(index)}
                    >{heat.name}</span>
                ))}
            </div>

            {(!isShiny) ? (
                <div className="pipeline-form">
                    {/* input */}
                    <FormGroupCustom
                        pipeline="heatplot"
                        id="inputheatplot"
                        input={input.input}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <FileSelector
                                name='input'
                                tray={input.input}
                                isMultiple={true}
                                accept={['fcs', '.fcs', 'csv', '.csv']}
                                checkFcsMetadata={true}
                                getPreviousPipelineOutput={true}
                                mixedExtension={true}
                                isShowPreviousPipeline
                                isConcatenateCsv
                                isPreviousPipelineFromPreProcessing
                                previousPipelineParams = {{
                                    preprocessingStep: GATING_SUBSETTING_STEP.toString()
                                }}
                                isShowS3Paths
                            />
                        </Col>
                    </FormGroupCustom>

                    {/* calculate freq */}
                    { isFcsFile() &&
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="calculatefreqheatplot"
                            input={`${calcFreq}`}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <Toggle
                                    value={calcFreq}
                                    onToggle={(v: boolean) => handleToggle(v, 'doFreq')}
                                />
                                {calcFreq && (
                                    <div className='mt-4'>
                                        <FileSelector
                                            name='coordsFolder'
                                            tray={input.coordsFolder}
                                            isMultiple={true}
                                            accept={['csv', '.csv']}
                                            isCoordinatesFiles={true}
                                            isShowGetCoordinatesFiles={calcFreq}
                                            isHideWorkspace={!calcFreq}
                                            isHideUpload={!calcFreq}
                                        />
                                    </div>
                                )}
                            </Col>
                        </FormGroupCustom>
                    }

                    {/* prefix */}
                    <FormGroupCustom
                        pipeline="heatplot"
                        id="prefixheatplot"
                        input={input.prefix}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <Input
                                name="prefix"
                                id="heatmap_prefix"
                                onChange={handleInput}
                                value={input.prefix}
                            />
                        </Col>
                    </FormGroupCustom>

                    {/* transformation */}
                    {(!_.isEmpty(input.input) && !isSingleCsv) && (
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="transformationheatplot"
                            input={input.transformation}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <InputCustom
                                    type="select" name="transformation" id="heatmap_transformation"
                                    onChange={handleInput} value={input.transformation}>
                                    <option value="" disabled>Select Transformation</option>
                                    <option value="cytof">cytof</option>
                                    <option value="fluor">fluor</option>
                                    <option value="linear1">linear1</option>
                                    <option value="linear2">linear2</option>
                                    <option value="arcsinh5">arcsinh5</option>
                                    <option value="arcsinh150">arcsinh150</option>
                                    <option value="auto">auto</option>
                                    <option value="none">none</option>
                                </InputCustom>
                                {input.transformation === "custom" && (
                                    <Col sm={12} className="sub-params">
                                        <FormGroupCustom
                                            pipeline="heatplot"
                                            id="W"
                                            input={input.W}
                                            isAdvanced={isAdvanced}
                                            row>
                                            <Col sm={INPUT_SMALL}>
                                                <InputCustom
                                                    name="W" id="heatmap_W" placeholder=" 4.5"
                                                    type="number" step="any"
                                                    onChange={handleInput} value={input.W}
                                                />
                                            </Col>
                                        </FormGroupCustom>

                                        <FormGroupCustom
                                            pipeline="heatplot"
                                            id="t"
                                            input={input.T}
                                            isAdvanced={isAdvanced}
                                            row>
                                            <Col sm={INPUT_SMALL}>
                                                <InputCustom
                                                    name="T" id="heatmap_T" placeholder=" 4.5"
                                                    type="number" step="any"
                                                    onChange={handleInput} value={input.T}
                                                />
                                            </Col>
                                        </FormGroupCustom>

                                        <FormGroupCustom
                                            pipeline="heatplot"
                                            id="M"
                                            input={input.M}
                                            isAdvanced={isAdvanced}
                                            row>
                                            <Col sm={INPUT_SMALL}>
                                                <InputCustom
                                                    name="M" id="heatmap_M" placeholder=" 4.5"
                                                    type="number" step="any"
                                                    onChange={handleInput} value={input.M}
                                                />
                                            </Col>
                                        </FormGroupCustom>

                                        <FormGroupCustom
                                            pipeline="heatplot"
                                            id="A"
                                            input={input.A}
                                            isAdvanced={isAdvanced}
                                            row>
                                            <Col sm={INPUT_SMALL}>
                                                <InputCustom
                                                    name="A" id="heatmap_A" placeholder=" 4.5"
                                                    type="number" step="any"
                                                    onChange={handleInput} value={input.A}
                                                />
                                            </Col>
                                        </FormGroupCustom>
                                    </Col>
                                )}
                            </Col>
                        </FormGroupCustom>
                    )}

                    {/* ceiling */}
                    {(!_.isEmpty(input.input) && !isSingleCsv) && (
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="ceilingheatplot"
                            input={input.ceiling}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <InputCustom
                                    name="ceiling" placeholder=" Number of events to take per sample (unless sample has less)"
                                    type="number" step="100" min="100" max="50000"
                                    onChange={handleInput} value={input.ceiling}
                                    isAuto
                                    autoValue="all"
                                />
                            </Col>
                        </FormGroupCustom>
                    )}

                    {/* allcells */}
                    {isFcsFile() && (
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="allcellsheatplot"
                            input={input.allCells}
                            disabled = {(!_.isEmpty(input.input) && !isSingleCsv) && (!_.isEmpty(input.coordsFolder))}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <Toggle
                                    value={(!_.isEmpty(input.input) && !isSingleCsv) && (!_.isEmpty(input.coordsFolder)) ? false : input.allCells}
                                    onToggle={(v: boolean) => handleToggle(v, 'allCells')}
                                />
                            </Col>
                        </FormGroupCustom>
                    )}
                    
                    {/* seed */}
                    {(!_.isEmpty(input.input) && !isSingleCsv) && (
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="seedheatplot"
                            input={input.seed}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <InputCustom
                                    name="seed" type="number" step="any"
                                    onChange={handleInput} value={input.seed}
                                />
                            </Col>
                        </FormGroupCustom>
                    )}

                    {/* usecoords. Disabled */}
                    {/* {!isSingleCsv && (
                    <FormGroup row>
                        <LabelView config={{
                        isRequired: false,
                        id: 'usecoordsheatplot',
                        pipeline: 'heatplot',
                        }} />
                        <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.useCoords}
                            onToggle={(v: boolean) => handleToggle(v, 'useCoords')}
                        />
                        {input.useCoords &&
                            <Col sm={12} className="sub-params">
                            <FormGroup row>
                                <LabelView config={{
                                isRequired: false,
                                id: 'coordsfileheatplot',
                                pipeline: 'heatplot',
                                }} />
                                <Col sm={INPUT_SMALL}>
                                <FileSelector
                                    name='coordsFile'
                                    tray={input.coordsFile}
                                    isMultiple={false}
                                    accept={['csv', '.csv']}
                                />
                                </Col>
                            </FormGroup>
                            </Col>
                        }
                        </Col>
                    </FormGroup>
                    )} */}


                    {/* palette */}
                    <FormGroupCustom
                        pipeline="heatplot"
                        id="paletteheatplot"
                        input={input.palette}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <ColorPalette
                                name="palette"
                                value={input.palette}
                                onDataChange={handleInput}
                                reverse={input.revPalette}
                                addonComponent={() => (
                                    <div className="switch">
                                        <Label>Reverse Color</Label>
                                        <Toggle
                                            value={input.revPalette}
                                            onToggle={(v: boolean) => handleToggle(v, 'revPalette')}
                                        />
                                    </div>
                                )}
                            />
                        </Col>
                    </FormGroupCustom>

                    {/* groupColors */}
                    <FormGroupCustom
                        pipeline="heatplot"
                        id="groupcolorsheatplot"
                        input={input.groupColors}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <ColorPalette
                                name="groupColors"
                                value={input.groupColors}
                                onDataChange={handleInput}
                                addonComponent={() => (<div />)}
                            />
                        </Col>
                    </FormGroupCustom>

                    {/* scale */}
                    <FormGroupCustom
                        pipeline="heatplot"
                        id="scaleheatplot"
                        input={input.scale}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <InputCustom
                                type="select" name="scale"
                                onChange={handleInput} value={input.scale}>
                                <option value="marker">Marker (z-scores)</option>
                                <option value="none">No scaling</option>
                                <option value="sample">Sample (z-scores)</option>
                            </InputCustom>
                        </Col>
                    </FormGroupCustom>

                    {/* clustMarker */}
                    <FormGroupCustom
                        pipeline="heatplot"
                        id="clustmarkerheatplot"
                        input={input.clustMarker}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <InputCustom
                                type="select" name="clustMarker"
                                onChange={handleInput} value={input.clustMarker}>
                                {clusters.map((p, idx) => <option key={idx} value={p.value}>{p.name}</option>)}
                            </InputCustom>
                        </Col>
                    </FormGroupCustom>

                    {/* cutMarker */}
                    {(!_.isUndefined(input.clustMarker) && input.clustMarker !== 'none') && (
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="cutmarkerheatplot"
                            input={input.cutMarker}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <InputCustom
                                    name="cutMarker" placeholder=""
                                    type="number" step="any"
                                    onChange={handleInput} value={input.cutMarker}
                                />
                            </Col>
                        </FormGroupCustom>
                    )}

                    {/* clustSample */}
                    <FormGroupCustom
                        pipeline="heatplot"
                        id="clustsampleheatplot"
                        input={input.clustSample}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <InputCustom
                                type="select" name="clustSample"
                                onChange={handleInput} value={input.clustSample}>
                                {clusters.map((p, idx) => <option key={idx} value={p.value}>{p.name}</option>)}
                            </InputCustom>
                        </Col>
                    </FormGroupCustom>

                    {/* cutSample */}
                    {(!_.isUndefined(input.clustSample) && input.clustSample !== 'none') && (
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="cutsampleheatplot"
                            input={input.cutSample}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <InputCustom
                                    name="cutSample" placeholder=""
                                    type="number" step="any"
                                    onChange={handleInput} value={input.cutSample}
                                />
                            </Col>
                        </FormGroupCustom>
                    )}

                    {/* percentileRange */}
                    <FormGroupCustom
                        pipeline="heatplot"
                        id="percentilerangeheatplot"
                        input={input.percentileRange}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <Toggle
                                value={input.percentileRange}
                                onToggle={(v: boolean) => handleToggle(v, 'percentileRange')}
                            />
                        </Col>
                    </FormGroupCustom>

                    {/* transpose */}
                    <FormGroupCustom
                        pipeline="heatplot"
                        id="transposeheatplot"
                        input={input.transpose}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <Toggle
                                value={input.transpose}
                                onToggle={(v: boolean) => handleToggle(v, 'transpose')}
                            />
                        </Col>
                    </FormGroupCustom>
                </div>
            ) : (
                <div className="pipeline-form">
                    {/* input */}
                    <FormGroupCustom
                        pipeline="heatplot"
                        id="inputheatplot"
                        input={input.input}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <FileSelector
                                name='input'
                                tray={input.input}
                                isMultiple={true}
                                accept={['fcs', '.fcs', 'csv', '.csv']}
                                checkFcsMetadata={true}
                                getPreviousPipelineOutput={true}
                                mixedExtension={true}
                                isShowPreviousPipeline
                                isConcatenateCsv
                                isPreviousPipelineFromPreProcessing
                                previousPipelineParams = {{
                                    preprocessingStep: GATING_SUBSETTING_STEP.toString()
                                }}
                                isShowS3Paths
                            />
                        </Col>
                    </FormGroupCustom>

                    {/* calculate freq */}
                    { isFcsFile() &&
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="calculatefreqheatplot"
                            input={`${calcFreq}`}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <Toggle
                                    value={calcFreq}
                                    onToggle={(v: boolean) => handleToggle(v, 'doFreq')}
                                />
                                {calcFreq && (
                                    <div className='mt-4'>
                                        <FileSelector
                                            name='coordsFolder'
                                            tray={input.coordsFolder}
                                            isMultiple={true}
                                            accept={['csv', '.csv']}
                                            isCoordinatesFiles={true}
                                            isShowGetCoordinatesFiles={calcFreq}
                                            isHideWorkspace={!calcFreq}
                                            isHideUpload={!calcFreq}
                                        />
                                    </div>
                                )}
                            </Col>
                        </FormGroupCustom>
                    }

                    {/* transformation */}
                    {(!_.isEmpty(input.input) && !isSingleCsv) && (
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="transformationheatplot"
                            input={input.transformation}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <InputCustom
                                    type="select" name="transformation" id="heatmap_transformation"
                                    onChange={handleInput} value={input.transformation}>
                                    <option value="" disabled>Select Transformation</option>
                                    <option value="cytof">cytof</option>
                                    <option value="fluor">fluor</option>
                                    <option value="linear1">linear1</option>
                                    <option value="linear2">linear2</option>
                                    <option value="arcsinh5">arcsinh5</option>
                                    <option value="arcsinh150">arcsinh150</option>
                                    <option value="auto">auto</option>
                                    <option value="none">none</option>
                                </InputCustom>
                            </Col>
                        </FormGroupCustom>
                    )}

                    {/* ceiling */}
                    {(!_.isEmpty(input.input) && !isSingleCsv) && (
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="ceilingheatplot"
                            input={input.ceiling}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <InputCustom
                                    name="ceiling" placeholder=" Number of events to take per sample (unless sample has less)"
                                    type="number" step="100" min="100" max="50000"
                                    onChange={handleInput} value={input.ceiling}
                                    isAuto
                                    autoValue="all"
                                />
                            </Col>
                        </FormGroupCustom>
                    )}

                    {/* seed */}
                    {(!_.isEmpty(input.input) && !isSingleCsv) && (
                        <FormGroupCustom
                            pipeline="heatplot"
                            id="seedheatplot"
                            input={input.seed}
                            isAdvanced={isAdvanced}
                            row>
                            <Col sm={INPUT_SMALL}>
                                <InputCustom
                                    name="seed" type="number" step="any"
                                    onChange={handleInput} value={input.seed}
                                />
                            </Col>
                        </FormGroupCustom>
                    )}
                </div>
            )}
        </TabbedMetaContainer>
    );
}

export default HeatMap;
