import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Input } from 'reactstrap';
import FileSelector from '../../../components/FileSelector/FileSelector';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { IStore } from '../../../types/store';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { INPUT_SMALL, PIPELINE_NAME_MAX_LENGTH } from '../../../utils/constants';
import InputCustom from '../../../components/Form/InputCustom';
import { TDispatch } from '../../../types/action';
import { updateInput } from '../../../actions/action.pipeline-input';

const TcrSearch = () => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const { input, isAdvanced } = pipeline;
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const [isValid, setIsValid] = React.useState(false);


    const dispatchInput = (key: string, value: any) => dispatch(updateInput(key, value));

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatchInput(name, value);
    }
    
    return (
        <div className="tcrsearch">
            <h4 className="subtitle">TCR Search</h4>
            <div className="pipeline-form">
                {/* query clonotype */}
                <FormGroupCustom
                    pipeline="tcrsearch"
                    id="queryclonotype"
                    input={input.queryClonotype}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Input
                            required
                            name="queryClonotype"
                            placeholder="Query Clonotype"
                            onChange={handleInput}
                            value={input.queryClonotype}
                            maxLength={250}
                            autoFocus
                        />
                    </Col>
                </FormGroupCustom>

                {/* mode */}
                <FormGroupCustom
                        pipeline="tcrsearch"
                        id="mode"
                        input={input.mode}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <InputCustom
                                type="select" 
                                name="mode"
                                onChange={handleInput} 
                                value={input.mode}
                            >
                                <option value="pcblast">pcblast</option>
                                <option value="tcrdist3">tcrdist3</option>
                            </InputCustom>
                        </Col>
                    </FormGroupCustom>
            </div>
        </div>
    );
}

export default TcrSearch;