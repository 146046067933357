import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Label, Row, Table } from 'reactstrap';
import { selectProtein, deselectProtein, updateInput, handleBulkProtein } from '../../actions/action.pipeline-input';
import FileSelector from '../../components/FileSelector/FileSelector';
import { TDispatch } from '../../types/action';
import { TPipeline, IPipeline, IMarker } from '../../types/type.pipeline';
import { IStore } from '../../types/store';
import { DISPLAY_ID } from '../Views/MetasampleEditor';
import { ANALYSIS, HEATPLOT, HISTOGRAM, TCR_ANALYSIS, TSNE, TSNE_INTERACTIVE } from '../../views/Pipeline/Form';
import InputCustom from '../Form/InputCustom';

interface IProps {
  columns: string[],
  protein: Array<IMarker>,
  tray: Array<any>,
  trayName: string,
  resetInputName?: string,
  isShowAll?: boolean
  customTitles?: {
    keep: string,
    sav: string
  },
}

const defaultTitles = {
  'keep': 'Keep',
  'sav': 'SAV'
};

const ProteinSelector = (props: IProps) => {
  const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
  const dispatch = useDispatch<TDispatch<TPipeline>>();

  const { input, pipelineId } = pipeline;

  const [, setProtein] = useState<Array<IMarker>>(input.protein);
  const [isDefaultMarker, setIsDefaultMarker] = useState<boolean>(props.tray ? (props.tray.length === 0) : true)
  const [titles, setTitles] = useState<any>(defaultTitles);
  const [isAsc, setIsAsc] = useState<boolean>(false);
  const [isSorted, setIsSorted] = useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [proteinList, setProteinList] = useState<Array<IMarker>>(props.protein);
  const [tableColumns, setTableColumns] = useState<Array<string>>(props.columns);
  const includesDisplayId = [ANALYSIS, HEATPLOT, TCR_ANALYSIS, TSNE, TSNE_INTERACTIVE, HISTOGRAM];
  const includedCustumColumn = [ANALYSIS, HEATPLOT, TSNE, TSNE_INTERACTIVE, HISTOGRAM];

  const [newColumn, setNewColumn] = useState<string>('');
  const [columnPrefill, setColumnPrefill] = useState<string>('');

  const countSelected = (key: string): number => {
    let count = 0;
    proteinList.forEach((val) => {
      if (key === 'all') {
        count += (val['keep'] || val['sav']) ? 1 : 0;
      } else {
        count += val[key] ? 1 : 0;
      }
    })
    return count;
  }

  const handleSelect = (event: any, index: number, kind: string) => {
    const selectedProtein = proteinList[index];
    selectedProtein[kind] ? dispatch(deselectProtein(index, kind)) : dispatch(selectProtein(index, kind));

    // update spesific input if something deselect
    if (kind === 'sav' && !selectedProtein[kind] && props.resetInputName) {
      if (selectedProtein.name === input[props.resetInputName]) {
        dispatch(updateInput(props.resetInputName, null));
      }
    }

    // since redux connect() did a shallow equal, we need to force the render
    // We force rendering by triggering a change in the state
    setProtein({
      ...proteinList
    });
  }

  const handleSelectAll = (kind: string) => {
    const allChecked = proteinList.length === countSelected(kind);
    dispatch(handleBulkProtein(kind, !allChecked));
  }

  const handleToggleDefaultmarker = (event: any) => {
    event.preventDefault();
    setIsDefaultMarker(!isDefaultMarker);
  }

  const sortTable = (sortBy: string) => {
    const sorting = !isAsc;
    setSortedColumn(sortBy);
    setIsSorted(true);
    setIsAsc(!isAsc);
    setProteinList(_.orderBy(proteinList,[data => data[sortBy].toLowerCase()],[sorting ? 'asc' : 'desc']));
  }

  const handleAddDisplayId = (event: any) => {
    event.preventDefault();
    const newColumns = _.union(tableColumns, DISPLAY_ID);
    if (_.includes(includesDisplayId, pipelineId)) {
        const customColumn = DISPLAY_ID;
        if (!_.includes(newColumns, customColumn)) {
            newColumns.unshift(customColumn);
        }

        _.each(proteinList, (item, index) => {
            _.set(proteinList, `${index}.${customColumn}`, item.name);
        });
    }

    setTableColumns(newColumns);
  }

  const deleteColumn = (event, columnName: string) => {
    event.preventDefault();
    const columnClone = _.clone(tableColumns);
    _.remove(columnClone, (x) => x === columnName);
    setTableColumns(columnClone);
  }

  const changeColumnValue = (column: string, value: string | boolean, index: number) => {
    const proteinClone = _.cloneDeep(proteinList);
    _.set(proteinClone, `${index}.${column}`, value);
    setProteinList(proteinClone);
    dispatch(updateInput('protein', proteinClone));
  }

  const handleChangeNewColumn = (e) => {
    const name = e.target.value.replace(/[^a-z0-9]/gi, '');
    setNewColumn(name);
  }

  const addColumn = () => {
    if (_.isEmpty(newColumn)) {
        return;
    }

    if (_.includes(tableColumns, newColumn)) {
        // column already exists
    } else {
        const columnClone = _.clone(tableColumns);
        columnClone.push(newColumn);
        setTableColumns(columnClone);

        _.each(proteinList, (item, index) => {
          _.set(proteinList, `${index}.${newColumn}`, columnPrefill);
      });
        setNewColumn('');
        setColumnPrefill('');
    }
  }

  const displayIdButton = () => {
    return (
      <label>
          <a href="#" onClick={handleAddDisplayId}>Add {DISPLAY_ID}</a>
      </label>
    )
  }

  useEffect(() => {
    if (isDefaultMarker) {
      dispatch(updateInput(props.trayName, []));
    }
  }, [isDefaultMarker])

  useEffect(() => {
    if (props.customTitles) {
      setTitles(props.customTitles);
    }
    
    if (input.protein) {
      const excludedColumns = ['name', 'sav', 'original'];
      const keys = Object.keys(input.protein[0]);
      const newColumns: string[] = [];
      keys.forEach((key) => {
        if (!excludedColumns.includes(key)) {
          newColumns.push(key);
        }
      });
      setTableColumns(newColumns);
    }
  }, [])

  let selected = countSelected(tableColumns.length > 1 ? 'all' : tableColumns[0]);

  return (
    <div className="fcs-config">
      <div className="title">
        {isDefaultMarker &&
          <span>
            Select markers from default list,
            or <a href="#" onClick={handleToggleDefaultmarker}>use your own meta marker file (.csv)</a>.
          </span>
        }
        {!isDefaultMarker &&
          <span>
            Select your marker list (csv),
            or <a href="#" onClick={handleToggleDefaultmarker}>select markers from default list</a>.
          </span>
        }
      </div>

      <div className="body">
        {!isDefaultMarker &&
          <FileSelector
            name={props.trayName}
            tray={props.tray}
            isMultiple={false}
            accept={['csv', '.csv']}
            checkFcsMetadata={false}
            getPreviousPipelineOutput={false}
            isShowPreviousPipeline
          />
        }

        {isDefaultMarker &&
          <div className="default-metamarker">
            {pipelineId == TCR_ANALYSIS && (
              displayIdButton()
            )}
            {includedCustumColumn.includes(typeof pipelineId == 'string' ? parseInt(pipelineId) : pipelineId) && (
              <Row className="ml-auto w-100">
                {!_.includes(tableColumns, DISPLAY_ID) && _.includes(includesDisplayId, pipelineId) && (
                    displayIdButton()
                )}
                <Label className="ml-4">Custom Column</Label>
                <div className="custom-column-input">
                  <InputCustom
                      type="text"
                      name="new-column"
                      placeholder="Custom column..."
                      value={newColumn}
                      onChange={handleChangeNewColumn}
                      disabled={(input.length === 0)}
                  />
                  <InputCustom
                      type="text"
                      name="new-column-value"
                      placeholder="Prefill value..."
                      value={columnPrefill}
                      onChange={(e) => setColumnPrefill(e.target.value)}
                      disabled={(input.length === 0)}
                  />
                  <button
                      type="button"
                      onClick={addColumn}
                      className="btn-primary btn-form-summary"
                      disabled={(input.length === 0)}>
                      Add Column
                  </button>
                </div>
              </Row>
            )}
            <label className="config-label pull-right">
              Selected: {selected} Markers
            </label>
            <Table bordered style={{ marginBottom: '0px' }}>
              <thead>
                <tr>
                  <th>
                    <a href="#" className='text-secondary' onClick={(event) => {
                        event.preventDefault();
                        sortTable('name');
                    }}>
                    <i className={`fa fa-sort-desc` + (!isAsc && isSorted && sortedColumn === 'name' ? ' text-primary' : '')}/>
                    <i className={`fa fa-sort-asc ml-min-8` + (isAsc && isSorted && sortedColumn === 'name' ? ' text-primary' : '')}/>
                    </a>
                    &nbsp;Marker
                  </th>
                  {tableColumns.map((column, index) => {
                    if (column === 'keep') {
                      return (
                        <th style={{ flex: '1 0 0' }} key={`key-${index}`} className="col-md-2">
                          <span style={{ minWidth: '80px', display: 'block' }}>{titles[column] || ''}</span>
                        </th>
                      )
                    } else {
                      return(
                        <th key={`head-${index}`} className="col-md-4">
                            <a href="#" className='text-secondary' onClick={(event) => {
                                event.preventDefault();
                                sortTable(column);
                            }}>
                                <i className={`fa fa-sort-desc` + (!isAsc && isSorted && sortedColumn === column ? ' text-primary' : '')}/>
                                <i className={`fa fa-sort-asc ml-min-8` + (isAsc && isSorted && sortedColumn === column ? ' text-primary' : '')}/>
                            </a> 
                            &nbsp;{column}

                            <button
                                className="btn-danger button-rounded ml-2"
                                onClick={(e) => deleteColumn(e, column)}>
                                <i className="fa fa-remove"></i>
                            </button>
                        </th>
                      )
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Select/Deselect All</td>
                  {tableColumns.map((column, index) => {
                    if (column === 'keep') {
                      const allChecked = proteinList.length === countSelected(column);
                      return (
                        <td key={`key-${index}`} style={{ flex: '1 0 0' }} className="col-md-2">
                          <input
                            type="checkbox"
                            name={column}
                            checked={allChecked}
                            onChange={() => handleSelectAll(column)}
                          />
                        </td>
                      )
                    } else {
                      return (
                        <td key={`key-${index}`} className="col-md-4"></td>
                      )
                    }
                  })}
                </tr>
              </tbody>
            </Table>
            <div className="config-table" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
              <Table bordered>
                <tbody>
                  {proteinList &&
                    proteinList.map((p, idx) => {
                      // if (this.canShowMarker(p.name)) {
                        return (
                          <tr key={`markers-${idx}`}>
                            <td>{p.name}</td>
                            {tableColumns.map((column, index) => {
                              if (column === 'keep') {
                                return (
                                  <td style={{ flex: '1 0 0' }} key={`key-${index}`} className="col-md-2">
                                    <input
                                      type="checkbox"
                                      name={column}
                                      checked={p[column]}
                                      onChange={(event) => handleSelect(event, idx, column)}
                                    />
                                  </td>
                                );
                              } else {
                                return (
                                  <td key={`key-${index}`} className="col-md-4">
                                      <InputCustom
                                          type="text"
                                          name={p[column]}
                                          value={_.get(p, column, '')}
                                          onChange={(e) => {
                                              changeColumnValue(column, e.target.value, idx);
                                          }}
                                      />
                                  </td>
                                )
                              }
                            })}
                          </tr>
                        );
                      // }
                    })
                  }
                </tbody>
              </Table>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default ProteinSelector;
