import React  from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { saveState } from './utils/localStorage';
import store from './store';

// Styles
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import '../scss/style.scss';
// Temp fix for reactstrap
import '../scss/core/_dropdown-menu-right.scss';

// Containers
import Full from './containers/Full/Full';
import Login from './containers/Login/Login';
import ForgetPassword from './containers/Login/ForgetPassword';
import ResetPassword from './containers/Login/ResetPassword';
import Version from './views/Version/Version';
import { IStore } from './types/store.d';
import Subscription from './views/Subscription/Subscription';

store.subscribe(_.throttle(() => {
    const { user } = store.getState();
    if (user.id !== null) {
        saveState({
            user: store.getState().user,
        });
    }
}, 1000));

const PrivateRoute = ({ isLogin, children, ...rest }) => {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLogin ? (
                    <Switch>
                        {children}
                    </Switch>
                ) : (
                    <Redirect to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
                )
            }
        />
    );
}

const Root = () => {
    const user = useSelector((state : IStore) => state.user);
    const isLogin = user.id !== null;
    return (
        <HashRouter>
            <Switch>
                <Route exact path="/version" name="Version" component={Version}/>
                <Route exact path="/login" name="Login" component={Login}/>
                <Route exact path="/forget-password" name="ForgetPassword" component={ForgetPassword}/>
                <Route path="/reset-password" name="ResetPassword" component={ResetPassword} />
                <PrivateRoute isLogin={isLogin}>
                    <Route exact path="/unsubscribe" name="Subscription" component={Subscription}/>
                    <Route path="/" name="Home" component={Full}/>
                </PrivateRoute>
            </Switch> 
        </HashRouter>
    );
}

ReactDOM.render(
    (
        <Provider store={store}>
            <Root />
        </Provider>
    ),
    document.getElementById('root')
);