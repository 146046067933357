import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../actions/action.user";
import swal from "sweetalert";
import { TDispatch } from "../../types/action";
import { TUser } from "../../types/type.user";

interface IProps {
  history: any;
}

const ResetPassword = ({ history }: IProps) => {
  const dispatch = useDispatch<TDispatch<TUser>>();

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  let hash = window.location.hash.split("/");
  const code = hash[hash.length - 1];
  const userId = hash[hash.length - 2];

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case "password":
        return setPassword(e.target.value);
      case "confirm_password":
        return setConfirmPassword(e.target.value);
    }
  };

  const redirectToLogin = () => history.push({ pathname: "/login" });

  const onFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      swal("Failed", "Password not Match", "warning");
      return;
    }
    dispatch(
      resetPassword(
        {
          password,
          confirm_password: confirmPassword,
          userId,
          code,
        },
        redirectToLogin
      )
    );
  };

  return (
    <div id="login">
      <div className="container-login">
        <div className="logo"></div>

        <form onSubmit={onFormSubmit}>
          <h1 className="title text-center">Reset Password</h1>
          <div className="card border form-login">
            <div className="form-group">
              <label htmlFor="password">New Password</label>
              <input
                type="password"
                id="password"
                name="password"
                className="form-control"
                placeholder="New Password"
                onChange={handleChangeText}
                required
                minLength={8}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="confirm_password"
                name="confirm_password"
                className="form-control"
                placeholder="Confirm Password"
                onChange={handleChangeText}
                required
                minLength={8}
              />
            </div>
            <button className="btn-primary" type="submit">
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
