import { parse } from "js2xmlparser";
import _ from "lodash";
import moment from 'moment';
import { IChannel } from '../../types/type.cytof-template';
import { IProfile } from "../../types/type.user";
import { FILE_EXPLORER_WORKSPACE_FOLDER, PIPELINE_NAME_MAX_LENGTH } from "../../utils/constants";

const convertToXml = (objects: Array<IChannel>) => {
    const data: any = {
        "@": { "xmlns": "http://www.dvssciences.com/xsd/Cytof/MethodSchema_v1_0_4.xsd" }
    };
    _.each(objects, (channel, index) => {
        let label = `${channel.mass}${channel.element}`;
        if (channel.target) {
            label += `_${channel.target}`;
        }
        const arrangedData = {
            "Mass": channel.mass,
            "Element": channel.element,
            "Label": label,
            "ElementID": `${channel.element}(${channel.mass})`,
            "ReadOnly": channel.readonly
        }

        if (channel.target) {
            arrangedData["Target"] = channel.target;
        }

        _.set(data, `MassChannels${index}`, arrangedData);
    });

    // add params
    const params = {
        "WashingTime": 0,
        "BufferTime": 0,
        "OutputConvolutions": true,
        "NoiseReduction": true,
        "LowerConvolutionThreshold": 400,
        "LevelSubstraction": 0,
        "MinEventDuration": 10,
        "MaxEventDuration": 150,
        "Sigma": 3,
        "ThresholdFilteringCustom": false,
        "CustomExpression": "",
        "TemplateName": "20201217_operatorsvalidation",
        "SignalType": "Dual",
        "AnalysisType": "Real-time",
        "PreserveIMD": true,
        "DualDataCalibStart": 0,
        "DualDataCalibEnd": 2,
        "InstrumentDualCalibration": true,
        "DataDualCalibration": false,
        "IntensityUpperLimit": 50,
        "DualCountStart": 1,
        "TemplatePath": "E:\\Yovita\\20201217 operators validation\\20201217_operatorsvalidation.tem",
        "Randomization": true,
        "SolutionMode": false,
        "EventMode": true,
        "ViewPlot": true,
        "RefreshSeconds": 60,
        "PushesToShow": 400,
        "ModifierAuthorization": 3,
        "GaussianDiscrimination": true,
        "DualCountStartSoln": 0.1,
    }
    _.set(data, "Params", params);

    // other settings
    const otherOptions = [
        { "SignalType": "Dual" },
        { "SignalType": "Intensity" },
        { "SignalType": "Pulse" },
        { "AnalysisType": "Real-time" },
        { "AnalysisType": "Post-Acquisition" },
        { "AnalysisType": "NoEventProcessing" },
    ];
    _.each(otherOptions, (option, index) => {
        const key = _.first(_.keys(option));
        _.set(data, `${key}${index}`, option);
    });

    let results = parse("MethodSchema", data, {
        declaration: {
            standalone: "yes"
        },
        format: {
            doubleQuotes: true,
            indent: "  "
        }
    });

    results = results.replace(/MassChannels\d/g, "MassChannels")
    results = results.replace(/SignalType\d/g, "SignalType")
    results = results.replace(/AnalysisType\d/g, "AnalysisType")

    return results;
}

// get file extension name
const getExtension = (name: string): string => {
    const extension = name.split('.').pop();
    if (!extension) {
      return "";
    }
  
    return extension.toLowerCase();
}

// trim string if the length longer than the configured maximum length
const trimString = (name: string, maxLength: number = PIPELINE_NAME_MAX_LENGTH): string => {
    let trimmed = name;
    if (name.length > maxLength) {
        trimmed = name.substring(name.length - maxLength);
    }

    return trimmed;
}

// set default file upload
const defaultFileUpload = (profile: IProfile): string => {
    const today = moment().format('YYYY-MM-DD');
    const userName = profile.username || profile.email;
    const prefix = `${FILE_EXPLORER_WORKSPACE_FOLDER}/uploads/${userName}_${today}/`;
    return prefix;
}

export { convertToXml, getExtension, trimString, defaultFileUpload }
