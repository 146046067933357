import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import ProjectView from './ProjectView';
import ProjectMenu from './ProjectMenu';
import ProjectPreprocessing from './ProjectPreprocessing';

interface IProps {
    match: any
}

const Projects = ({
    match
} : IProps) => (
    <div>
        <Route exact path={`${match.url}/:type`} name="Group" component={ProjectMenu} />
        <Route exact path={`${match.url}/:type/groups/:id`} name="Group" component={ProjectMenu} />
        <Route exact path={`${match.url}/preprocessing/:id`} name="Preprocessing" component={ProjectPreprocessing} />
        <Route exact path={`${match.url}/analysis/:id`} name="ProjectView" component={ProjectView} />
    </div>
)

export default Projects;
