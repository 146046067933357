import { ITagResponse, ITargetResponse } from "../types/type.response";
import { GET } from "../utils/httprequest";

export const getTarget = async () => {
    return await GET('targets')
    .then((response: ITargetResponse) => {
        return response.data;
    })
    .catch((error) => {
        return [];
    });
}

export const getTag = async () => {
    return await GET('tags')
    .then((response: ITagResponse) => {
        return response.data;
    })
    .catch((error) => {
        return [];
    });
}