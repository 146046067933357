import swal from "sweetalert";
import _ from "lodash";
import * as types from "../reducers/actionTypes";
import {
  getPreviousPipelineOutputs,
  updateMetadata,
} from "./action.pipeline-input";
import { s3GetFiles, s3GetSequencingFiles } from "./action.s3";
import * as constants from "../utils/constants";
import { TAction } from "../types/action";
import { TProject } from "../types/type.project";
import { GET, POST, PUT, DELETE } from "../utils/httprequest";
import {
  IProjectResponse,
  IProjectDetailResponse,
  IProjectFilesResponse,
  IReportResponse,
  IGeneralResponse,
} from "../types/type.response";
import { IS3Files } from "../types/type.s3";
import { getVersion } from "./action.version-notification";

export const getProject = (): TAction<TProject> => {
  return (dispatch) => {
    dispatch({
      type: types.GET_PROJECTS_REQUEST,
    });
    GET("project")
      .then((response: IProjectResponse) => {
        dispatch(getVersion(response.app_version));
        dispatch({
          type: types.GET_PROJECTS_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch(getVersion(error.response.data.app_version));
        dispatch({
          type: types.GET_PROJECTS_FAILURE,
          message: error.response.statusText,
        });
      });
  };
};

export const getOneProject = (
  id: number,
  getPreviousResult: boolean = false
): TAction<TProject> => {
  return (dispatch) => {
    dispatch({ type: types.GET_ONE_PROJECT_REQUEST });
    dispatch({ type: types.S3_FILES_RESET });

    GET(`project/${id}`)
      .then((response: IProjectDetailResponse) => {
        dispatch(getVersion(response.app_version));
        dispatch({
          type: types.GET_ONE_PROJECT_SUCCESS,
          data: response.data,
        });

        return dispatch(s3GetFiles(id, "", false, getPreviousResult));
      })
      .catch((error) => {
        dispatch(getVersion(error.response.data.app_version));
        dispatch({
          type: types.GET_ONE_PROJECT_FAILURE,
          message: error.response.data.message,
        });
      });
  };
};

export const addReport = (data: any, modal: Function): TAction<TProject> => {
  return (dispatch, getState) => {
    let { reports } = getState().projects.selected_project;
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("projectId", parseInt(data.projectId) as any);
    formData.append("file", data.file[0]);

    dispatch({
      type: types.ADD_REPORT_REQUEST,
    });
    swal({
      title: "Please wait",
      text: "Your file still uploading.....",
      closeOnClickOutside: false,
      buttons: { visible: false },
    });

    POST("report", formData)
      .then((response: IReportResponse) => {
        dispatch(getVersion(response.app_version));
        if (response.status == "error") {
          dispatch({
            type: types.ADD_REPORT_FAILURE,
            message: response.message,
          });
          swal.close!();
          swal("Failed", response.message, "warning");
        } else {
          reports.push(response);
          dispatch({
            type: types.ADD_REPORT_SUCCESS,
            data: reports,
          });
          swal.close!();
          swal("Success", "Upload Report Success", "success");
          modal();
        }
      })
      .catch((error) => {
        dispatch(getVersion(error.response.data.app_version));
        swal.close!();
        dispatch({
          type: types.ADD_REPORT_FAILURE,
          message: error.response.statusText,
        });
      });
  };
};

export const archiveFile = (type: string, id: number): TAction<TProject> => {
  return (dispatch, getState) => {
    let selected_project = getState().projects.selected_project;
    let projectId = selected_project.id;

    dispatch({
      type: types.ARCHIVE_REQUEST,
    });
    swal({
      title: "Please wait",
      text: "Archiving.....",
      closeOnClickOutside: false,
      buttons: { visible: false },
    });

    DELETE(`${type}/${id}`)
      .then((response: IGeneralResponse) => {
        dispatch(getVersion(response.app_version));
        GET(`project/${projectId}`)
          .then((refresh: IProjectDetailResponse) => {
            swal.close!();
            swal("Success", response.message, "success");
            dispatch({
              type: types.ARCHIVE_RESULT,
              message: response.message,
              data: refresh.data,
            });
          })
          .catch((error) => {
            dispatch(getVersion(error.response.data.app_version));
          });
      })
      .catch((error) => {
        dispatch(getVersion(error.response.data.app_version));
        swal.close!();
        swal("Failed", "Archive failed", "warning");
        dispatch({
          type: types.ARCHIVE_RESULT,
          message: error.response.statusText,
          data: selected_project,
        });
      });
  };
};

export const getProjectMembers = async (projectId: number) => {
  return await GET(`project/${projectId}/users`);
};
