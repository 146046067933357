import initialState from './initialState';
import * as types from './actionTypes';
import { IDashboard, TDashboard } from '../types/type.dashboard';

export default function(state: IDashboard = initialState.dashboard, action: TDashboard) : IDashboard {
    switch (action.type){
        case types.GET_DASHBOARD_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
            }
        case types.GET_DASHBOARD_SUCCESS:
            return{
                ...state,
                data: action.data,
                isLoading: false,
                message: ''
            }
        case types.GET_DASHBOARD_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message
            }

        default: return state;
    }
}
