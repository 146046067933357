import React, { useEffect, useState } from 'react';
import { Col, Input, Label } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _, { set } from 'lodash';

import { updateInput, getMetasampleColNames, getMetasampleRowNames } from '../../../actions/action.pipeline-input';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import InputCustom from '../../../components/Form/InputCustom';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import ColorPalette from '../../../components/ColorPalette/ColorPalette';
import { GATING_SUBSETTING_STEP, INPUT_SMALL } from '../../../utils/constants';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';
import MetasampleEditor from '../../../components/Views/MetasampleEditor';
import Toggle from '../../../components/Form/Toggle';
import { defaultFileUpload } from '../helper';
import { IProfile } from '../../../types/type.user';

const Pfda = () => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced } = pipeline;
    const [patientCol, setPatientCol] = useState<Array<string>>([]);
    const [predictor1, setPredictor1] = useState<Array<string>>([]);
    const [predictor2, setPredictor2] = useState<Array<string>>([]);

    const profile: IProfile = useSelector((state : IStore) => state.user.profile);

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        let value = e.target.value;

        dispatchInput(name, value);
    }

    const dispatchInput = (key: string, value: any) => dispatch(updateInput(key, value));

    const handleToggle = (value: boolean, key: string) => {
        const toggledValue = !value;
        dispatchInput(key, toggledValue ? true : null);
    }

    useEffect( () => {
        if(!_.isEmpty(input.metaSample)){
            dispatch(updateInput('patientCol', ''));
            dispatch(getMetasampleColNames(setPatientCol));
            dispatch(getMetasampleColNames(setPredictor1));
            dispatch(getMetasampleColNames(setPredictor2));
        }
    }, [input.metaSample]);

    useEffect(() => {
        if (input.input.length > 0) {
            const prefix = defaultFileUpload(profile);
        }
    }, [input.input]);

    return (
        <TabbedMetaContainer
            name="Faust Pfda"
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.input)}
            containerClass="pfda"
            metamarker={false}
            metasample={
                <FormGroupCustom
                    pipeline="pfda"
                    id="metasamplepfda"
                    input={input.metaSample}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <MetasampleEditor
                            input='input'
                        />
                    </Col>
                </FormGroupCustom>
            }
        >
            <div className="pipeline-form">
                {/* input */}
                <FormGroupCustom
                    pipeline="pfda"
                    id="inputpfda"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            isMultiple={false}
                            accept={['rds', '.rds', 'zip', '.zip']}
                            checkFcsMetadata={false}
                            getPreviousPipelineOutput={true}
                        />
                    </Col>
                </FormGroupCustom>

                {/* template fcs */}
                <FormGroupCustom
                    pipeline="pfda"
                    id="fcspfda"
                    input={input.fcs}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='fcs'
                            tray={input.fcs}
                            isMultiple={false}
                            accept={['fcs', '.fcs']}
                            checkFcsMetadata={false}
                            getPreviousPipelineOutput={false}
                            isShowPreviousPipeline
                            isPreviousPipelineFromPreProcessing
                            previousPipelineParams = {{
                                preprocessingStep: GATING_SUBSETTING_STEP.toString()
                            }}
                        />
                    </Col>
                </FormGroupCustom>


                 {/* patientCol */}
                 <FormGroupCustom
                    pipeline="pfda"
                    id="patientcolpfda"
                    input={input.patientCol}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select"
                            name="patientCol"
                            id="pfda_patientCol"
                            placeholder=" patientCol"
                            onChange={handleInput}
                            value={input.patientCol}
                        >
                            <option value="" disabled>Select Patient Column</option>
                            {patientCol.map((patientCol) => (
                                <option value={patientCol}>{patientCol}</option>
                            ))}
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                {/* predictor1 */}
                <FormGroupCustom
                    pipeline="pfda"
                    id="predictor1pfda"
                    input={input.predictor1}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select"
                            name="predictor1"
                            id="pfda_predictor1"
                            placeholder=" predictor1"
                            onChange={handleInput}
                            value={input.predictor1}
                        >
                            <option value="" disabled>Select Predictor 1</option>
                            {predictor1.map((predictor1) => (
                                <option value={predictor1}>{predictor1}</option>
                            ))}
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                {/* predictor2 */}
                <FormGroupCustom
                    pipeline="pfda"
                    id="predictor2pfda"
                    input={input.predictor2}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select"
                            name="predictor2"
                            id="pfda_predictor2"
                            placeholder=" predictor2"
                            onChange={handleInput}
                            value={input.predictor2}
                        >
                            <option value="" disabled>Select Predictor 2</option>
                            {predictor2.map((predictor2) => (
                                <option value={predictor2}>{predictor2}</option>
                            ))}
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                {/* pvcut1 */}
                <FormGroupCustom
                    pipeline="pfda"
                    id="pvcutpfda"
                    input={input.pvCut}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="pvCut" id="pfda_pvcut"
                            type="number" step="0.01" min={Number(input.pvCut1) + 0.001} max={0.1}
                            fixedNumber={3}
                            onChange={handleInput} value={input.pvCut}
                        />
                    </Col>
                </FormGroupCustom>

                {/* Fdr */}
                <FormGroupCustom
                    pipeline="pfda"
                    id="fdrpfda"
                    input={input.fdr}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.fdr}
                            onToggle={(v: boolean) => handleToggle(v, 'fdr')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* palette */}
                <FormGroupCustom
                    pipeline="pfda"
                    id="palettepfda"
                    input={input.palette}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <ColorPalette
                            name="palette"
                            value={input.palette}
                            onDataChange={handleInput}
                            reverse={input.revPalette}
                            addonComponent={() => (
                                <div className="switch">
                                    <Label>Reverse Color</Label>
                                    <Toggle
                                        value={input.revPalette}
                                        onToggle={(v: boolean) => handleToggle(v, 'revPalette')}
                                    />
                                </div>
                            )}
                        />
                    </Col>
                </FormGroupCustom>


                {/* doUMAP */}
                <FormGroupCustom
                    pipeline="pfda"
                    id="umappfda"
                    input={input.doUmap}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doUmap}
                            onToggle={(v: boolean) => handleToggle(v, 'doUmap')}
                        />
                    </Col>
                </FormGroupCustom>

                {/* savePopulations */}
                <FormGroupCustom
                    pipeline="pfda"
                    id="savepopulationspfda"
                    input={input.saveFcs}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.saveFcs}
                            onToggle={(v: boolean) => handleToggle(v, 'saveFcs')}
                        />
                    </Col>
                </FormGroupCustom>



                {/* seed */}
                <FormGroupCustom
                    pipeline="pfda"
                    id="seedpfda"
                    input={input.seed}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="seed" type="number" step="any"
                            onChange={handleInput} value={input.seed}
                        />
                    </Col>
                </FormGroupCustom>



            </div>
        
        </TabbedMetaContainer>
    );
}

export default Pfda;
