import _ from "lodash";
import { EXECUTION } from "../utils/constants";
import batchSteps from "./batch-steps";

export default {
  version: {
    isShowUpdateNotif: false
  },
  user: {
    name: '',
    id: null,
    token: '',
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    company: '',
    user_level: '',
    projects: [],
    profile: null,
    isLoading: false,
    isError: false,
    message: '',
    isWelcome: false,
    organization: ''
  },
  projects: {
    data: {
      isLoading: false,
      isError: false,
      error_message: '',
      all_projects: [],
      selected_project: {
        id: null,
        createdAt: null,
        finishedAt: null,
        name: '',
        description: '',
        photoUrl: null,
        s3Uri: null,
        isActive: null,
        configTemplate: null,
        bucketName: '',
        samples: [],
        reports: [],
        pipeline: [],
        region: '',
        organizationId: 0
      },
      s3FileBrowser: { // project file browser
        prefix: '',
        files: [],
        selectedKey: '',
        selectedUrl: '',
        selectedS3Path: '',
      },
    }
  },
  samples: {
    id: null,
    projectId: null,
    project: '',
    name: '',
    isActive: '',
    note: '',
    createdAt: null,
    fcs: [],
    isLoading: false,
    isError: false,
  },
  sampleSets: {
    id: null,
    name: '',
    sampleId: null,
    sample: '',
    isActive: '',
    note: '',
    path: '',
    url: '',
    fcses: [],
    status: '',
    message: '',
  },
  fcs: {
    id: null,
    nama: '',
    fileName: '',
    path: '',
    isActive: '',
    url: '',
    config: '',
    configType: '',
    sampleSetId: null,
    sampleSet: null,
  },
  metal: {
    id: null,
    channel: '',
    metal: '',
    marker: '',
  },
  report: {
    isLoading: false,
    isError: false,
    message: '',
    id: null,
    projectId: '',
    project: '',
    fileUrl: '',
    isActive: '',
    name: '',
    note: '',
  },
  pipeline: {
    isLoading: false,
    isError: false,
    error_message: '',
    all_pipeline: [],
    selected_pipeline: {
      id: null,
      name: '',
      inputTemplate: '',
      outputTemplate: '',
      defaultDockerContainer: '',
      defaultDockerImage: '',
      samples: [],
      pipeline: [],
      report: [],
      selectedFiles: [],
      fileFolder: '',
    },
    tempFolder: '',
    selected_project_id: 0,
    // when running pipeline
    description: '',
    input: {},
    tabId: '',
    pipelineId: '',
    projectId: null,
    isAdvanced: false,
    isShiny: false,
    isLifeDeath: false,
    isStandalone: false,
    reRun: false,
    random: '',
    name: '',
    // previousOuptputs is to store previous pipeline output objects,
    // e.g. {id: ..., name: ...}
    // These are the outputs that can be used to run current pipeline
    previousOutputs: [],
    excludeInputs: [],
    pipelineScript: 0,
    cloneSource: null,
    shinyInput: {},
    batchId: '',
  },
  execution: {
    isLoading: false,
    isError: false,
    success: null,
    message: '',
    all_execution: [],
    selected_execution: {
      id: 0,
      createdAt: 0,
      finishedAt: null,
      name: '',
      description: '',
      input: '',
      output: '',
      isActive: false,
      dockerContainer: '',
      awsBatchId: '',
      awsBatchStatus: '',
      status: 0,
      createdBy: {
          id: 0,
          username: ''
      },
      projectId: 0,
      pipelineId: 0,
      batchStatus: '',
      type: '',
      project: {
          id: 0,
          name: ''
      },
      s3Prefix: '',
      pipelineScript: 0,
      tabId: 1,
      version: '-'
    },
  },
  help: {
    id: null,
    subject: '',
    content: '',
    isLoading: false,
    isError: false,
  },
  notification: {
    // id: null,
    // type: '',
    message: '',
    // read: false,
    // createdAt: null,
    isLoading: false,
    isError: false,
    data: [],
    selected: {
      id: 0,
      read: 0,
      type: '',
      createdAt: 0,
      message: '',
      url: null,
      pipelineId: null
    }
  },
  dashboard: {
    isLoading: false,
    isError: false,
    message: '',
    data: {
      data: {
        sample: 0,
        execution: 0,
        report: 0,
        recent_activity: [],
        recent_report: [],
      },
      app_version: '',
    },
  },
  s3: {
    bucket: '',
    files: [], // file list
    prefix: '',
    selectedKey: '',
    selectedUrl: '',
    selectedS3Path: '',
    selectedFolder: '',
    isSelectedFolderFcsOnly: false, // selected folder only accepts FCS files
    selectedFolderProtein: [], // the metadata/names file of the folder
    selectedFolderProteinUrl: '', // the s3 URI of the names file of selected folder
    selectedFolderMetadata: {},
    filesToUpload: [],
    uploadedFiles: [],

    uploadTotalCount: 0,
    uploadingPercentage: 0,
    uploadingCount: 0,
    uploadDone: false,
    toBeUploadedCount: 0,
    isLoading: false,
    isError: false,
    validatedS3Paths: []
  },
  visiblePipeline: {
    isLoading: false,
    isError: false,
    message: '',
    data: [],
    selected: []
  },
  uiHistory: {
    selectedPipelineTab: 'tab-1',
    selectedMenuTab: '4',
    pipelineSetting: {
      filter: [],
      page: 0
    },
    reportSetting: {
      filter: [],
      page: 0
    },
    pipelineType: 'analysis',
    batchSetting: {
      filter: [],
      page: 0
    },
  },
  batch: {
    isLoading: false,
    isError: false,
    success: null,
    message: '',
    all_batch: [],
    selected_batch: {
      id: null,
      createdAt: null,
      name: "",
      currentStep: 0,
      totalStep: _.keys(batchSteps).length,
      status: "",
      projectId: 0,
      steps: [],
      isActive: false,
    }
  },
  coordinatesFiles: {
    isLoading: false,
    isError: false,
    message: '',
    files: [],
    filesToUpload: [],
  },
  peptideAnnotation: {
    isLoading: false,
    isError: false,
    message: '',
    sequence: [],
  }
};
