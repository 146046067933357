import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Col, FormGroup, Label } from "reactstrap";
import swal from "sweetalert";
import { createBatchStep, getOneBatch } from "../../actions/action.batch";
import { updateMetadata } from "../../actions/action.pipeline-input";
import FileSelector from "../../components/FileSelector/FileSelector";
import BatchPreprocessingWrapper from "../../components/Views/BatchPreprocessingWrapper";
import { TDispatch } from "../../types/action";
import { IStore } from "../../types/store";
import { IBatch } from "../../types/type.batch";
import { TS3 } from "../../types/type.s3";
import { IProfile } from "../../types/type.user";
import * as constants from "../../utils/constants";
import history from "../history";
import { setPipelineType } from "../../actions/action.ui-history";

interface IProps {
  match: any;
  location: any;
}

const dropzoneStyle = {
  width: "100%",
  height: "auto",
  border: "1px dashed #ccc",
};

const dropzoneStyleActive = {
  border: "1px solid #ddd",
  backgroundColor: "#eee",
  height: "auto",
};

const PipelineUpload = ({ location, match }: IProps) => {
  const selectedBatchData: IBatch = useSelector(
    (state: IStore) => state.batch.selected_batch
  );
  const pipeline = useSelector((state: IStore) => state.pipeline);
  const profile: IProfile = useSelector((state: IStore) => state.user.profile);
  const dispatch = useDispatch<TDispatch<TS3>>();
  const { id: batchId, projectId } = selectedBatchData;
  const today = moment().format("YYYY-MM-DD");
  const userName = profile.username || profile.email;
  const folderName = `${userName}_${today}/`;
  const folderPath = `batch-preprocessing/batch-${batchId}/uploads/${folderName}`;
  const isUploadStep =
    selectedBatchData.currentStep === constants.UPLOAD_STEP - 1;

  const handleFinishedPopup = () => {
    // @ts-ignore
    swal({
      buttons: true,
      title: "Are you sure?",
      text: "Your files will be saved, you can't upload on this batch anymore",
      icon: "warning",
      dangerMode: true,
    }).then((willChanged: boolean | null) => {
      if (willChanged) {
        handleFinished();
      }
    });
  };

  const handleFinished = async () => {
    const path = `${constants.FILE_PROJECT_PREFIX}${projectId}/${folderPath}`;
    const uploaded = _.map(pipeline.input.batch, (file) => ({
      name: file.substr(file.lastIndexOf("/") + 1),
      url: "",
      s3Url: file,
    }));
    await createBatchStep({
      batchId: batchId!,
      executionId: null,
      status: "success",
      step: selectedBatchData.currentStep + 1,
      files: JSON.stringify({
        folderPath: path,
        files: uploaded,
      }),
    }).then(() => {
      // to clear tray in FileSelector
      pipeline.input.batch = [];

      history.goBack();
    });
  };

  useEffect(() => {
    dispatch(setPipelineType("preprocessing"));
    if (batchId && selectedBatchData.id != batchId) {
      dispatch(getOneBatch(+batchId));
    }
  }, [batchId]);

  useEffect(() => {
    // if upload already done, prevent user visit this page
    const currentStep =
      selectedBatchData.steps[constants.CLUSTER_CODE_UPLOAD_STEP - 1];
    if (currentStep && currentStep.status === "success") {
      history.push({ pathname: "/pipeline/preprocessing" });
    }
  }, [selectedBatchData]);

  useEffect(() => {
    dispatch(updateMetadata("projectId", projectId));
  }, [projectId]);

  return (
    <BatchPreprocessingWrapper location={location}>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href={`#/projects/preprocessing/${projectId}`}>
          Projects
        </BreadcrumbItem>
        <BreadcrumbItem
          tag="a"
          href={`#/pipeline/preprocessing?batch=${selectedBatchData.id}&project=${projectId}`}
        >
          {selectedBatchData.name}
        </BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          {isUploadStep ? constants.FILE_UPLOAD : constants.CLUSTER_CODE_UPLOAD}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="card">
        {/* <div className="upload-zone">
                    <div className="dropzone">
                        <Dropzone
                            onDrop={handleAddFile}
                            multiple={true}
                            accept={'.fcs'}
                            style={dropzoneStyle}
                            activeStyle={dropzoneStyleActive}
                        >
                            <span className='btn btn-primary btn-sm add-file-btn'>
                                <i className="fa fa-plus"></i>&nbsp;
                                Click here to select files.
                            </span>
                            {fileItem.length > 0 && (
                                <div className="file-list">
                                    <ol>{fileItem}</ol>
                                </div>
                            )}
                        </Dropzone>
                    </div>

                    <div className="note">
                        Files count: {fileItem.length}
                    </div>

                    {isUploading &&
                        <div className='upload-progress'>
                            <div className="file-list">
                                {fileItem.length > 0 &&
                                    <ol>{fileItem}</ol>
                                }
                            </div>

                            <div className="status-progress">
                                Processed {successfullyUploaded} of {fileItem.length} files.
                            </div>

                            {progress !== 100 && (
                                <Progress value={progress} style={{ marginBottom: '1rem' }} />
                            )}
                        </div>
                    }

                </div> */}
        <FormGroup row>
          <Label sm={constants.LABEL_SMALL}>
            {isUploadStep ? "Batch Files" : "Cluster Code File"}
          </Label>
          <Col sm={constants.INPUT_SMALL}>
            <FileSelector
              name={"batch"}
              tray={pipeline.input.batch}
              accept={isUploadStep ? ["fcs", ".fcs"] : ["csv", ".csv"]}
              getPreviousPipelineOutput={true}
              uploadPath={folderPath}
              isMultiple={isUploadStep}
              checkFcsMetadata={isUploadStep}
              isShowPreviousPipeline
              previousPipelineParams={{
                pipelineId: "5",
                preprocessingStep: isUploadStep ? "1" : "2",
              }}
            />
          </Col>
        </FormGroup>
      </div>
      <div style={{ marginTop: "16px" }}>
        <button
          className="btn-primary pull-right"
          type="button"
          style={{ marginLeft: "14px" }}
          onClick={handleFinishedPopup}
          disabled={_.isEmpty(pipeline.input.batch)}
        >
          Done
        </button>
      </div>
    </BatchPreprocessingWrapper>
  );
};

export default PipelineUpload;
