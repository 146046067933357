import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Loading from '../Loading/Loading';
import { IPipeline } from '../../types/type.pipeline';
import { useSelector } from 'react-redux';
import { IStore } from '../../types/store';

interface IProps {
    params: any,
    shinyUrl: string
}

const ShinyIframeView = ({
    params,
    shinyUrl
} : IProps) => {
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const [url, setUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const encodeFilename = (url: string) => {
        const filenameIndex = url.lastIndexOf('/') + 1;
        const path = url.substr(0, filenameIndex);
        const filename = url.substr(filenameIndex);

        // decode filename first to prevent double encode
        const decode = decodeURIComponent(filename);
        // then encode
        const encodedFilename = encodeURIComponent(decode);
        return `${path}${encodedFilename}`;
    }

    const objectToQuerystring = (input) : string => {
        const isSingleFile = !(new RegExp(/\/input$/).test(input.input));
        const urlKey: any = {
            bucket: 'aws_bucket',
            input: isSingleFile ? 'input_file' : 'input_folder',
            output: 'output_folder',
            metaMarker: 'meta_marker_file',
            markerCsv: 'meta_marker_file',
            metaSample: 'meta_sample_file',
            region: 'aws_region',
            windowSize: 'window_size',
            transformation: 'transform',
            ceiling: 'ceiling',
            seed: 'seed',
            normalized: 'normalized_folder',
            clusterCodes: 'cluster_codes_file',
            config: 'config',
            alpha: 'alpha',
            beadChannels: 'bead_channels',
            peptideAnnotations: 'peptide_annotations',
            // inputScatter: 'input_scatter',
            bulk: 'bulk_file',
            subsetting: 'subsetting',
            cloned: 'cloned',
            lifeDeath: 'life_death',
            coordsFolder: 'coords_folder',
            standalone: 'standalone',
        }
        const queryString: Array<string> = [];
        const paramNeedToEscape = ['input_file', 'meta_marker_file', 'meta_sample_file', 'peptide_annotations'];
        _.each(Object.keys(input), (key) => {
            if (_.has(urlKey, key)) {
                let value = input[key];
                if (_.includes(paramNeedToEscape, urlKey[key])) {
                    value = encodeFilename(value);
                }
                if (value && typeof value === 'boolean') {
                    queryString.push(urlKey[key]);
                } else {
                    queryString.push(`${urlKey[key]}=${value}`);
                }
            }
        })
        return queryString.join('&');
    }

    const onPageLoaded = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }

    useEffect(() => {
        const input = _.cloneDeep(params);

        const singleFileTrays = [
            'input',
            'metaMarker',
            'metaSample',
        ];
        singleFileTrays.map(tray => {
            if (_.isEmpty(input[tray])) {
                delete input[tray];
            }
            if ((tray in input) && input[tray].length > 0) {
                if (_.isArray(input[tray])) {
                    input[tray] = input[tray][0];
                } else {
                    input[tray] = input[tray];
                }
            }
        });
        const queryText = objectToQuerystring(input);
        setUrl(`${shinyUrl}?${queryText}`);
    }, [params]);

    return (
        <div className="card">
            {(url !== '?') ? (
                <React.Fragment>
                {!pipeline.isLoading && (
                    <iframe src={url} onLoad={onPageLoaded} />
                )}
                {(pipeline.isLoading || isLoading) && (
                    <div className={'loading-container'}>
                        <Loading message={`Loading...`} />
                    </div>
                )}
                </React.Fragment>
            ) : ((
                <Loading message={`Loading...`} />)
            )}
        </div>
    );
}

export default ShinyIframeView;
