import React, { useState } from 'react';
import {
    Col, Input, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';

import { updateInput, updateMetadata, resetPipelineParams } from '../../../actions/action.pipeline-input';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import { INPUT_SMALL } from '../../../utils/constants';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import InputCustom from '../../../components/Form/InputCustom';
import pipelineBasicParams from '../../../reducers/pipeline-basic-params';

const PreProcessing = () => {
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const {
        tabId, pipelineId, pipelineScript,
        input, isAdvanced
    } = pipeline;

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(updateInput(name, value));
    }

    const checkEdited = () => {
        const currentForm = _.get(pipelineBasicParams, `${tabId}.${pipelineId}.${pipelineScript}`, []);
        return !_.isEqual(currentForm, input);
    }

    const toggleTab = (tab: number) => {
        if (tab !== pipelineScript) {
            if (checkEdited()) {
                // @ts-ignore
                swal({
                    title: "Are you sure?",
                    text: "Your current changes will reset to default",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                .then((willChanged: boolean | null) => {
                    if (willChanged) {
                        dispatch(updateMetadata('pipelineScript', tab));
                        dispatch(resetPipelineParams(['pipelineScript']));
                    }
                });
            } else {
                dispatch(updateMetadata('pipelineScript', tab));
                dispatch(resetPipelineParams(['pipelineScript']));
            }
        }
    }

    return (
        <div className="preprocessing styled-tab-blue">
            <h4 className="subtitle">Pre-Processing</h4>
            {/* <div className="manual-gating-state">
                {_.map(['Randomization', 'Cytospill'], (tab, index) => (
                    <span
                        className={classnames({ 'manual-gating-state-item': true, active: index === pipelineScript })}
                        onClick={() => toggleTab(index)}
                    >{tab}</span>
                ))}
            </div> */}

            <div className="detail">
                <Nav tabs>
                    {_.map(['Randomization', 'Cytospill'], (tab, index) => (
                        <NavItem key={`tab-with-id${index}`}>
                            <NavLink
                                className={classnames({ active: index === pipelineScript })}
                                onClick={() => { toggleTab(index); }}
                            >
                                {tab}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                <TabContent activeTab={pipelineScript}>
                    <TabPane tabId={0}>
                        <div className="pipeline-form">
                            {/* input */}
                            <FormGroupCustom
                                pipeline="prepro"
                                id="sampleprepro"
                                input={input.input}
                                isAdvanced={isAdvanced}
                                row>
                                <Col sm={INPUT_SMALL}>
                                    <FileSelector
                                        name='input'
                                        tray={input.input}
                                        isMultiple={true}
                                        accept={['fcs', '.fcs']}
                                        checkFcsMetadata={true}
                                    />
                                </Col>
                            </FormGroupCustom>
                            
                            {/* seed */}
                            <FormGroupCustom
                                pipeline="prepro"
                                id="seedprepro"
                                input={input.seed}
                                isAdvanced={isAdvanced}
                                row>
                                <Col sm={INPUT_SMALL}>
                                    <InputCustom
                                        name="seed" id="preprocessing_seed" type="number" step="1"
                                        onChange={handleInput} value={input.seed}
                                    />
                                </Col>
                            </FormGroupCustom>
                        </div>
                    </TabPane>
                    <TabPane tabId={1}>
                        <div className="pipeline-form">
                            {/* input */}
                            <FormGroupCustom
                                pipeline="prepro"
                                id="sampleprepro"
                                input={input.input}
                                isAdvanced={isAdvanced}
                                row>
                                <Col sm={INPUT_SMALL}>
                                    <FileSelector
                                        name='input'
                                        tray={input.input}
                                        isMultiple={true}
                                        accept={['fcs', '.fcs']}
                                        checkFcsMetadata={true}
                                    />
                                </Col>
                            </FormGroupCustom>
                        </div>
                    </TabPane>
                    <TabPane tabId={2}>
                        <div className="pipeline-form">
                            {/* input */}
                            <FormGroupCustom
                                pipeline="prepro"
                                id="sampleprepro"
                                input={input.input}
                                isAdvanced={isAdvanced}
                                row>
                                <Col sm={INPUT_SMALL}>
                                    <FileSelector
                                        name='input'
                                        tray={input.input}
                                        isMultiple={true}
                                        accept={['fcs', '.fcs']}
                                        checkFcsMetadata={true}
                                    />
                                </Col>
                            </FormGroupCustom>

                            {/* normalized */}
                            <FormGroupCustom
                                pipeline="prepro"
                                id="normalizedprepro"
                                input={input.normalized}
                                isAdvanced={isAdvanced}
                                row>
                                <Col sm={INPUT_SMALL}>
                                    <FileSelector
                                        name='normalized'
                                        tray={input.normalized}
                                        isMultiple={true}
                                        accept={['fcs', '.fcs']}
                                        checkFcsMetadata={false}
                                    />
                                </Col>
                            </FormGroupCustom>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
}

export default PreProcessing;
