import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import { updateMetadata } from "../../../actions/action.pipeline-input";
import FileSelector from "../../../components/FileSelector/FileSelector";
import FormGroupCustom from "../../../components/Form/FormGroupCustom";
import { TDispatch } from "../../../types/action";
import { IStore } from "../../../types/store";
import { IPipeline, TPipeline } from "../../../types/type.pipeline";
import { INPUT_SMALL } from "../../../utils/constants";

const SequencingGating = () => {
    const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
    const { input, isAdvanced, isShiny } = pipeline;
    const dispatch = useDispatch<TDispatch<TPipeline>>();

    useEffect(() => {
        if (!isShiny) {
            dispatch(updateMetadata('isShiny', true));
        }
    }, [isShiny])

    return (
        <div className="sequencinggating">
            <h4 className="subtitle">Sequencing Gating</h4>
            <div className="pipeline-form">
                {/* input */}
                <FormGroupCustom
                    pipeline="sequencinggating"
                    id="inputsequencinggating"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name="input"
                            tray={input.input}
                            accept={['fcs', '.fcs']}
                            isMultiple={false}
                            mixedExtension
                            getPreviousPipelineOutput
                            isShowPreviousPipeline
                        />
                    </Col>
                </FormGroupCustom>
            </div>
        </div>
    )
}

export default SequencingGating;