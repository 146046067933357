import * as types from '../reducers/actionTypes';
import swal from 'sweetalert';
import { TAction } from '../types/action';
import { THelp } from '../types/type.help';
import { IHelpResponse } from '../types/type.response';
import { POST } from '../utils/httprequest';

interface IHelpData {
    subject: string,
    content: string,
    file?: File
}

export const addHelp = (data: IHelpData, setState: Function) : TAction<THelp> => {
    return(dispatch) => {
        const formData = new FormData();
        Object.keys(data).map(function(key, index) {
            formData.append(key, data[key])
        });
        
        dispatch({
            type: types.CREATE_HELP_REQUEST,
            status: 'uploading',
            message: 'Submitting...',
        })

        swal("Please wait", "Submitting..");

        POST('help', formData)
        .then((response: IHelpResponse) => {
            if (response.status == 'error') {
                dispatch({
                    type: types.CREATE_HELP_FAILURE,
                    message: response.message!
                });
                swal.close!();
                swal("Failed", response.message!, "warning");
            } else {
                dispatch({
                    type: types.CREATE_HELP_SUCCESS,
                    data: 'Submit Success',
                });
                setState();
                swal.close!();
                swal("Success", "Submit Success", "success");
            }
        })
        .catch((error) => {
            swal.close!();
            dispatch({
                type: types.CREATE_HELP_FAILURE,
                message: error.response.statusText
            });
        });
    }
}
