import React from 'react';
import { ISidebar } from '../../types/type.nav';
import DocumentationSidebar from '../Views/DocumentationSidebar';

const sidebarMenu: ISidebar = {
    items: [
        {
            name: 'Notifications',
            url: '/notification',
            icon: 'icon-bell',
            badge: {
                name: 'notification',
                class: 'notif-badge',
                variant: 'danger',
                text: '2',
            }
        },
        // {
        //     name: 'Dashboard',
        //     url: '/dashboard',
        //     icon: 'icon-home'
        // },
        {
            name: 'Projects',
            url: '/projects',
            icon: 'icon-chart',
            children: [
                {
                    name: 'Preprocessing',
                    url: '/projects/preprocessing',
                    icon: 'icon-info'
                },
                {
                    name: 'Analysis',
                    url: '/projects/analysis',
                    icon: 'icon-layers',
                },
            ]
        },
        // {
        //     name: 'Pipeline',
        //     url: '/pipeline',
        //     icon: 'icon-layers'
        // },
        {
            name: 'Help',
            url: '/help',
            icon: 'icon-bulb'
        },
        {
            name: 'Documentation',
            url: '/documentation',
            icon: 'icon-book-open',
            customItemComponent: (props, key) => <DocumentationSidebar key={key} {...props} />
        },
        {
            name: 'Profile',
            url: '/profile',
            icon: 'icon-user'
        }
    ]
};

export default sidebarMenu;