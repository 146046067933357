import React, { useEffect, useState } from 'react';
import { Col, Input, Label } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { updateMetadata, resetPipelineParams, updateInput } from '../../../actions/action.pipeline-input';
import FileSelector from '../../../components/FileSelector/FileSelector';
import { IPipeline, TPipeline } from '../../../types/type.pipeline';
import { IStore } from '../../../types/store';
import { TDispatch } from '../../../types/action';
import FormGroupCustom from '../../../components/Form/FormGroupCustom';
import { INPUT_SMALL, SYMBOL_PARAMETERS } from '../../../utils/constants';
import ProteinSelector from '../../../components/ProteinSelector/ProteinSelector';
import pipelineBasicParams from '../../../reducers/pipeline-basic-params';
import ColorPalette from '../../../components/ColorPalette/ColorPalette';
import Toggle from '../../../components/Form/Toggle';
import InputCustom from '../../../components/Form/InputCustom';
import TabbedMetaContainer from '../../../components/Views/TabbedMetaContainer';
import MetasampleEditor from '../../../components/Views/MetasampleEditor';

const TcrAnalysis = () => {
    const pipeline: IPipeline = useSelector((state : IStore) => state.pipeline);
    const dispatch = useDispatch<TDispatch<TPipeline>>();
    const { input, isAdvanced, isShiny, tabId, pipelineId, pipelineScript } = pipeline;

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      dispatch(updateInput(name, value));
    }

    const dispatchInput = (key: string, value: any) => dispatch(updateInput(key, value));

    const handleToggle = (value: boolean, key: string) => {

        const toggledValue = !value;
        dispatch(updateInput(key, toggledValue ? true : null));

                // if toggled value is true,
        // then, activate other inputs
        switch (key) {
            case 'doUmap':
                dispatchInput('kValueUmap', toggledValue ? "auto" : null);
                dispatchInput('spreadUmap', toggledValue ? 1 : null);
                dispatchInput('minDistUmap', toggledValue ? 0.1 : null);
                break;
        }
    }

    const handleInputCustom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const symbol = ['*', '+', 'o', 'x'];
        // +value => convert string to number
        if (_.includes(symbol, value) || _.inRange(+value, 0, 26) || _.isEmpty(value)) {
            dispatch(updateInput(name, value));
        }
    }

    return (
        <TabbedMetaContainer
            name="TCR Analysis"
            isDisabled={(tab) => tab.id === 'metamarker' && _.isEmpty(input.bulk)}
            containerClass="tcr-analysis"
            metamarker={(!_.isEmpty(input.bulk)) && (
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="metamarkertcranalysis"
                    input={input.metaMarker}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        {(input.protein && input.protein.length > 0) ? (
                            <ProteinSelector
                                columns={['keep']}
                                protein={input.protein}
                                tray={input.metaMarker}
                                trayName="metaMarker"
                            />
                        ) : (
                            <FileSelector
                                name="metaMarker"
                                tray={input.metaMarker}
                                isMultiple={false}
                                accept={['csv', '.csv']}
                                checkFcsMetadata={false}
                                getPreviousPipelineOutput={false}
                            />
                        )}
                    </Col>
                </FormGroupCustom>
            )}
            metasample={
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="metasampletcranalysis"
                    input={input.metaSample}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <MetasampleEditor input='input' />
                    </Col>
                </FormGroupCustom>
            }
        >
            <div className="pipeline-form">
                {/* h5ad */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="h5adtcranalysis"
                    input={input.h5ad}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='h5ad'
                            tray={input.h5ad}
                            accept={['h5ad', '.h5ad']}
                            pattern='^((?!raw).)*.h5ad$'
                        />
                    </Col>
                </FormGroupCustom>

                {/* input bulk file */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="bulktcranalysis"
                    input={input.bulk}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='bulk'
                            tray={input.bulk}
                            accept={['fcs', '.fcs']}
                            checkFcsMetadata
                            getPreviousPipelineOutput
                            isShowPreviousPipeline
                        />
                    </Col>
                </FormGroupCustom>

                {/* hits/input */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="hitstcranalysis"
                    input={input.input}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='input'
                            tray={input.input}
                            accept={['fcs', '.fcs']}
                            checkFcsMetadata
                            metadataName='gatedProtein'
                            isMultiple
                            getPreviousPipelineOutput
                            isShowPreviousPipeline
                        />
                    </Col>
                </FormGroupCustom>

                {/* tcr */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="tcrtcranalysis"
                    input={input.tcr}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <FileSelector
                            name='tcr'
                            tray={input.tcr}
                            accept={['csv', '.csv']}
                        />
                    </Col>
                </FormGroupCustom>

                {/* prefix */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="prefixtcranalysis"
                    input={input.prefix}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Input
                            name="prefix"
                            id="tcranalysis_prefix"
                            onChange={handleInput}
                            value={input.prefix}
                        />
                    </Col>
                </FormGroupCustom>

                {/* transformation */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="transformationtcranalysis"
                    input={input.transformation}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select" name="transformation"
                            onChange={handleInput} value={input.transformation}>
                            <option value="" disabled>Select Transformation</option>
                            <option value="cytof">cytof</option>
                            <option value="fluor">fluor</option>
                            <option value="linear1">linear1</option>
                            <option value="linear2">linear2</option>
                            <option value="arcsinh5">arcsinh5</option>
                            <option value="arcsinh150">arcsinh150</option>
                            <option value="auto">auto</option>
                            <option value="none">none</option>
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                {/* ceiling */}
                {!_.isEmpty(input.bulk) && (
                    <FormGroupCustom
                        pipeline="tcranalysis"
                        id="ceilingtcranalysis"
                        input={input.ceiling}
                        isAdvanced={isAdvanced}
                        row>
                        <Col sm={INPUT_SMALL}>
                            <InputCustom
                                name="ceiling" id="tsne_ceiling" placeholder=" Number of events to take per sample (unless sample has less)"
                                type="number" step="100" min="100" max="50000"
                                onChange={handleInput} value={input.ceiling}
                                isAuto
                                autoValue="all"
                            />
                        </Col>
                    </FormGroupCustom>
                )}

                {/* UMAP */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="umaptcranalysis"
                    input={input.doUmap}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <Toggle
                            value={input.doUmap}
                            onToggle={(v: boolean) => handleToggle(v, 'doUmap')}
                        />
                        {input.doUmap && (
                            <Col sm={12} className="sub-params">
                                {/* kValueUmap */}
                                <FormGroupCustom
                                    pipeline="tcranalysis"
                                    id="kvalueumaptcranalysis"
                                    input={input.kValueUmap}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="kValueUmap" id="kValueUmap" placeholder=""
                                            type="number" step="any" min="1" max="100" defaultValue="25"
                                            onChange={handleInput} value={input.kValueUmap}
                                            isAuto
                                        />
                                    </Col>
                                </FormGroupCustom>

                                {/* spreadUmap */}
                                <FormGroupCustom
                                    pipeline="tcranalysis"
                                    id="spreadumaptcranalysis"
                                    input={input.spreadUmap}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="spreadUmap" placeholder=""
                                            type="number" step="0.1" min="0.1" max="20"
                                            onChange={handleInput} value={input.spreadUmap}
                                        />
                                    </Col>
                                </FormGroupCustom>

                                {/* minDistUmap */}
                                <FormGroupCustom
                                    pipeline="tcranalysis"
                                    id="mindistumaptcranalysis"
                                    input={input.minDistUmap}
                                    isAdvanced={isAdvanced}
                                    row>
                                    <Col sm={INPUT_SMALL}>
                                        <InputCustom
                                            name="minDistUmap" id="minDistUmap" placeholder=""
                                            type="number" step="0.01" min={0.0001} max={input.spreadUmap - 0.001}
                                            fixedNumber={4}
                                            onChange={handleInput} value={input.minDistUmap}
                                        />
                                    </Col>
                                </FormGroupCustom>
                            </Col>
                        )}
                    </Col>
                </FormGroupCustom>

                {/* kValueUmap */}
{/*                 <FormGroupCustom
                    pipeline="tcranalysis"
                    id="kvalueumaptcranalysis"
                    input={input.kValueUmap}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="kValueUmap" id="kValueUmap" placeholder=""
                            type="number" step="any" min="1" max="100" defaultValue="25"
                            onChange={handleInput} value={input.kValueUmap}
                            isAuto
                        />
                    </Col>
                </FormGroupCustom> */}

                {/* spreadUmap */}
{/*                 <FormGroupCustom
                    pipeline="tcranalysis"
                    id="spreadumaptcranalysis"
                    input={input.spreadUmap}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="spreadUmap" placeholder=""
                            type="number" step="0.1" min="0.1" max="20"
                            onChange={handleInput} value={input.spreadUmap}
                        />
                    </Col>
                </FormGroupCustom> */}

                {/* minDistUmap */}
{/*                 <FormGroupCustom
                    pipeline="tcranalysis"
                    id="mindistumaptcranalysis"
                    input={input.minDistUmap}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="minDistUmap" id="minDistUmap" placeholder=""
                            type="number" step="0.01" min={0.0001} max={input.spreadUmap - 0.001}
                            fixedNumber={4}
                            onChange={handleInput} value={input.minDistUmap}
                        />
                    </Col>
                </FormGroupCustom> */}

                {/* topPercentile */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="toppercentiletcranalysis"
                    input={input.topPercentile}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="topPercentile" id="hda-topPercentile" placeholder=" 0.99"
                            type="number" step="0.01" min="0.51" max="1"
                            onChange={handleInput} value={input.topPercentile}
                            isDecimal
                        />

                    </Col>
                </FormGroupCustom>

                {/* bottomPercentile */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="bottompercentiletcranalysis"
                    input={input.bottomPercentile}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="bottomPercentile" id="hda-BottomPercentile" placeholder=" 0.01"
                            type="number" step="0.01" min="0" max="0.49"
                            onChange={handleInput} value={input.bottomPercentile}
                            isDecimal
                        />
                    </Col>
                </FormGroupCustom>

                {/* pch */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="pchtcranalysis"
                    input={input.pch}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            type="select" 
                            name="pch" 
                            id="hda-pch"
                            onChange={handleInput} 
                            value={input.pch}
                        >
                            <option value="" disabled>Select Symbol</option>
                            {SYMBOL_PARAMETERS.map((element) => {
                                return (
                                    <option value={element} key={element}>{element}</option>
                                );
                            })}
                        </InputCustom>
                    </Col>
                </FormGroupCustom>

                {/* plotCeiling */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="plotceilingtcranalysis"
                    input={input.plotCeiling}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="plotCeiling" id="tsne_plotCeiling" placeholder=" Number of points to plot"
                            type="number" step="100" min="100" max="50000"
                            onChange={handleInput} value={input.plotCeiling}
                            isAuto
                            autoValue="all"
                        />
                    </Col>
                </FormGroupCustom>

                {/* palette */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="palettetcranalysis"
                    input={input.palette}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                    <ColorPalette
                        name="palette"
                        value={input.palette}
                        onDataChange={handleInput}
                        reverse={input.revPalette}
                        addonComponent={() => (
                        <div className="switch">
                            <Label>Reverse Color</Label>
                            <Toggle
                            value={input.revPalette}
                            onToggle={(v: boolean) => handleToggle(v, 'revPalette')}
                            />
                        </div>
                        )}
                    />
                    </Col>
                </FormGroupCustom>

                {/* groupColors */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="groupcolorstcranalysis"
                    input={input.groupColors}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                    <ColorPalette
                        name="groupColors"
                        value={input.groupColors}
                        onDataChange={handleInput}
                        addonComponent={() => ( <div /> )}
                    />
                    </Col>
                </FormGroupCustom>

                {/* seed */}
                <FormGroupCustom
                    pipeline="tcranalysis"
                    id="seedtcranalysis"
                    input={input.seed}
                    isAdvanced={isAdvanced}
                    row>
                    <Col sm={INPUT_SMALL}>
                        <InputCustom
                            name="seed" type="number" step="any"
                            onChange={handleInput} value={input.seed}
                        />
                    </Col>
                </FormGroupCustom>
            </div>
        </TabbedMetaContainer>
    );
}

export default TcrAnalysis;
