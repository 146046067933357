export default {
  1: {
    // cytof
    1: [
      // robot (cytof)
      {
        input: [],
        table: [],
        prefix: "robot", // advanced
        cutoff: 85,
        coding: 3,
        volume: 20,
        samples: 3,
        config: 1,
        well: 1,
        eppendorf: 1.5,
        flat: false, // advanced
      },
    ],
    2: [
      // highdim (cytof)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        protein: [],
        coordsFolder: [], //advanced
        prefix: "highdim", // advanced
        transformation: "cytof", // advanced
        ceiling: 20000,
        doTsne: true,
        perplexityTsne: "auto", // advanced
        doPhate: false, // advanced
        kValuePhate: null, // advanced
        doIsomap: false, // advanced
        doDiffmap: false, // advanced
        doPca: false,
        doUmap: true,
        kValueUmap: "auto", // advanced
        spreadUmap: 1, // advanced
        minDistUmap: 0.1, // advanced
        doOneSense: false, // advanced
        doPhenograph: true,
        kValuePheno: "auto", // advanced
        doFlowsom: false,
        // kValueFlow: null, // advanced
        // xDim: null, // advanced
        // yDim: null, // advanced
        plot: true,
        plotSample: false, // advanced
        topPercentile: 1, // advanced
        bottomPercentile: 0, // advanced
        pch: "circle", // advanced
        plotCeiling: 20000, // advanced
        svg: null, // advanced
        palette: 1,
        revPalette: false,
        seed: 42, // advanced
        metaSampleEditor: [],
      },
    ],
    3: [
      // heatmap (cytof)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        protein: [],
        coordsFolder: [], //advanced
        prefix: "heatplot", // advanced
        transformation: "cytof", // advanced
        ceiling: 20000, //advanced
        seed: 42, // advanced
        palette: 1,
        revPalette: false,
        groupColors: "Set1",
        scale: "marker",
        clustMarker: "none",
        cutMarker: 1, // advanced
        clustSample: "none",
        cutSample: 1, // advanced
        percentileRange: false, // advanced
        transpose: false,
        metaSampleEditor: [],
        allCells: false,
      },
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        coordsFolder: [], //advanced
        transformation: "cytof",
        ceiling: 20000, //advanced
        seed: 42, // advanced
        bucket: "",
        output: [],
        region: "",
        metaSampleEditor: [],
      },
    ],
    4: [
      // tetramer (cytof)
      {
        inputCase: [],
        inputControl: [],
        metaMarker: [],
        protein: [],
        badSav: "none", // advanced
        clusterCodes: [],
        prefix: "tetramer", // advanced
        transformation: "cytof",
        config: 1,
        peptideAnnotations: "",
      },
    ],
    5: [
      // preprocessing (cytof)
      {
        input: [],
        seed: 42, // advanced,
        functionSelector: "randomize",
      },
      {
        input: [],
        functionSelector: "cytoSpill",
      },
      {
        input: [],
        normalized: [],
        functionSelector: "bead_normalization",
      },
    ],
    8: [
      // analysis (cytof)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        pairedFile: [], // advanced
        correlationFile: [], //advanced
        coordsFolder: [], //advanced
        ceiling: 20000, //advanced
        prefix: "stats", // advanced
        transformation: "cytof", // advanced
        pvCut1: 0.01, // advanced
        pvCut2: 0.05, // advanced
        fdr: true,
        palette: 1,
        revPalette: false,
        groupColors: "Set1",
        protein: [],
        outliers: true, // advanced
        pch: "circle", // advanced
        plotCeiling: 20000, // advanced
        seed: 42, // advanced
        metaSampleEditor: [],
      },
    ],
    9: [
      // manual gating (cytof)
      {
        input: [],
        metaMarker: [],
        bucket: "",
        region: "",
      },
      {
        input: [],
        coordinatesFile: [],
        bucket: "",
      },
    ],
    12: [
      // batch (cytof)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        prefix: "batch",
        metaSampleEditor: [],
        transformation: "cytof",
        zipFile: [],
        doAnnotation: true,
        doDiscovery: false,
        depthScoreCutoff: 0.1,
        selectionQuantile: 0.5,
        seed: 42,
      },
    ],
    13: [
      // generate meta files (cytof)
      {
        input: [],
        bucket: "",
        region: "",
        output: [],
      },
    ],
    14: [
      // tcr analysis (cytof)
      {
        input: [],
        metaSample: [],
        h5ad: [],
        bulk: [],
        prefix: "tcr", // advanced
        transformation: "cytof", // advanced
        ceiling: 20000,
        doUmap: true,
        kValueUmap: "auto", // advanced
        spreadUmap: 1, // advanced
        minDistUmap: 0.1, // advanced
        topPercentile: 1, // advanced
        bottomPercentile: 0, // advanced
        pch: "circle", // advanced
        plotCeiling: 20000, // advanced
        palette: 1,
        revPalette: false,
        groupColors: "Set1",
        seed: 42, // advanced,
        protein: [],
        gatedProtein: [],
        tcr: [],
        metaSampleEditor: [],
      },
    ],
    15: [
      // Cytof Run Template
      {
        channels: [],
      },
    ],
    16: [
      // Bead normalization
      {
        input: [],
        windowSize: 200,
        normalized: [],
        beadChannels: "89,115,140,159,175,209",
        bucket: "",
        region: "",
        output: [],
      },
    ],
    17: [
      // Tetdecon 2021
      {
        input: [],
        clusterCodes: [],
        config: 1,
        markerCsv: [],
        protein: [],
        cutoff: [],
        peptideAnnotations: "",
        alpha: 0.01,
      },
    ],
    18: [
      // manual tetdecon 2021 (cytof)
      {
        input: [],
        clusterCodes: [],
        config: 1,
        markerCsv: [],
        protein: [],
        alpha: 0.01,
        bucket: "",
        region: "",
        output: [],
        peptideAnnotations: "",
      },
    ],
    19: [
      // debarcode (cytof)
      {
        input: [],
        bucket: "",
        region: "",
        output: [],
      },
    ],
    20: [
      // scatter plot (cytof)
      {
        input: [],
        bulk: [],
        bucket: "",
        region: "",
        output: [],
        metaSample: [],
        metaSampleEditor: [],
      },
    ],
    21: [
      // result upload
      {
        resultType: "",
        files: [],
      },
    ],
    22: [
      // pyir (cytof)
      {
        input: [],
        prefix: "pyir", // advanced
      },
    ],
    23: [
      // highdim interactive
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        coordsFolder: [],
        bucket: "",
        output: [],
        region: "",
        metaSampleEditor: [],
      },
    ],
    24: [
      // url2s3
      {
        input: [],
      },
    ],
    25: [
      // sequencing gating
      {
        input: [],
        bucket: "",
        region: "",
      },
    ],
    26: [
      // Cytof histogram
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        coordsFolder: [],
        bucket: "",
        output: [],
        region: "",
        metaSampleEditor: [],
      },
    ],
    31: [
      // pfda (cytof)
      {
        input: [],
        metaSample: [],
        fcs: [],
        patientCol: "",
        predictor1: "",
        predictor2: "",
        pvCut: 0.05,
        fdr: true,
        palette: 1,
        revPalette: false,
        doUmap: false,
        saveFcs: false,
        seed: 42,
        bucket: "",
        output: [],
        region: ""
      }
    ],
    32: [
      // igblast (cytof)
      {
        input: [],
        type: "1",
        prefix: "igblast", // advanced
      },
    ],
  },

  2: {
    // flow
    1: [
      // robot (flow)
      {
        input: [],
        table: [],
        prefix: "robot", // advanced
        cutoff: 85,
        coding: 3,
        volume: 20,
        samples: 3,
        config: 1,
        well: 1,
        eppendorf: 1.5,
        flat: false, // advanced
      },
    ],
    2: [
      // highdim (flow)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        protein: [],
        coordsFolder: [], //advanced
        prefix: "highdim", // advanced
        transformation: "fluor", // advanced
        ceiling: 20000,
        doTsne: true,
        perplexityTsne: "auto", // advanced
        doPhate: false, // advanced
        kValuePhate: null, // advanced
        doIsomap: false, // advanced
        doDiffmap: false, // advanced
        doPca: false,
        doUmap: true,
        kValueUmap: "auto", // advanced
        spreadUmap: 1, // advanced
        minDistUmap: 0.1, // advanced
        doOneSense: false, // advanced
        doPhenograph: true,
        kValuePheno: "auto", // advanced
        doFlowsom: false,
        // kValueFlow: null, // advanced
        // xDim: null, // advanced
        // yDim: null, // advanced
        plot: true,
        plotSample: false, // advanced
        topPercentile: 1, // advanced
        bottomPercentile: 0, // advanced
        pch: "circle", // advanced
        plotCeiling: 20000, // advanced
        svg: null, // advanced
        palette: 1,
        revPalette: false,
        seed: 42, // advanced
        metaSampleEditor: [],
      },
    ],
    3: [
      // heatmap (flow)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        protein: [],
        coordsFolder: [], //advanced
        prefix: "heatplot", // advanced
        transformation: "fluor", // advanced
        ceiling: 20000, //advanced
        seed: 42, // advanced
        palette: 1,
        revPalette: false,
        groupColors: "Set1",
        scale: "marker",
        clustMarker: "none",
        cutMarker: 1, // advanced
        clustSample: "none",
        cutSample: 1, // advanced
        percentileRange: false, // advanced
        transpose: false,
        metaSampleEditor: [],
        allCells: false,
      },
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        coordsFolder: [], //advanced
        transformation: "fluor",
        ceiling: 20000, //advanced
        seed: 42, // advanced
        bucket: "",
        output: [],
        region: "",
        metaSampleEditor: [],
      },
    ],
    4: [
      // tetramer (flow)
      {
        inputCase: [],
        inputControl: [],
        metaMarker: [],
        protein: [],
        badSav: "none", // advanced
        clusterCodes: [],
        prefix: "tetramer", // advanced
        transformation: "fluor",
        config: 1,
        peptideAnnotations: "",
      },
    ],
    5: [
      // preprocessing (flow)
      {
        input: [],
        seed: 42, // advanced,
        functionSelector: "randomize",
      },
      {
        input: [],
        functionSelector: "cytoSpill",
      },
      {
        input: [],
        normalized: [],
        functionSelector: "bead_normalization",
      },
    ],
    8: [
      // analysis (flow)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        pairedFile: [], // advanced
        correlationFile: [], //advanced
        coordsFolder: [], //advanced
        ceiling: 20000, //advanced
        prefix: "stats", // advanced
        transformation: "fluor", // advanced
        pvCut1: 0.01, // advanced
        pvCut2: 0.05, // advanced
        fdr: true,
        palette: 1,
        revPalette: false,
        groupColors: "Set1",
        protein: [],
        outliers: true, // advanced
        pch: "circle", // advanced
        plotCeiling: 20000, // advanced
        seed: 42, // advanced
        metaSampleEditor: [],
      },
    ],
    9: [
      // manual gating (flow)
      {
        input: [],
        metaMarker: [],
        bucket: "",
        region: "",
      },
      {
        input: [],
        coordinatesFile: [],
        bucket: "",
      },
    ],
    12: [
      // batch (flow)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        prefix: "batch",
        metaSampleEditor: [],
        transformation: "fluor",
        zipFile: [],
        doAnnotation: true,
        doDiscovery: false,
        depthScoreCutoff: 0.1,
        selectionQuantile: 0.5,
        seed: 42,
      },
    ],
    13: [
      // generate meta files (flow)
      {
        input: [],
        bucket: "",
        region: "",
        output: [],
      },
    ],
    14: [
      // tcr analysis (flow)
      {
        input: [],
        metaSample: [],
        h5ad: [],
        bulk: [],
        prefix: "tcr", // advanced
        transformation: "fluor", // advanced
        ceiling: 20000,
        doUmap: true,
        kValueUmap: "auto", // advanced
        spreadUmap: 1, // advanced
        minDistUmap: 0.1, // advanced
        topPercentile: 1, // advanced
        bottomPercentile: 0, // advanced
        pch: "circle", // advanced
        plotCeiling: 20000, // advanced
        palette: 1,
        revPalette: false,
        groupColors: "Set1",
        seed: 42, // advanced
        protein: [],
        gatedProtein: [],
        tcr: [],
        metaSampleEditor: [],
      },
    ],
    15: [
      // Cytof Run Template
      {
        channels: [],
      },
    ],
    16: [
      // Bead normalization
      {
        input: [],
        windowSize: 200,
        normalized: [],
        beadChannels: "89,115,140,159,175,209",
        bucket: "",
        region: "",
        output: [],
      },
    ],
    17: [
      // Tetdecon 2021
      {
        input: [],
        clusterCodes: [],
        config: 1,
        markerCsv: [],
        protein: [],
        cutoff: [],
        peptideAnnotations: "",
        alpha: 0.01,
      },
    ],
    18: [
      // manual tetdecon 2021 (flow)
      {
        input: [],
        clusterCodes: [],
        config: 1,
        markerCsv: [],
        protein: [],
        alpha: 0.01,
        bucket: "",
        region: "",
        output: [],
        peptideAnnotations: "",
      },
    ],
    19: [
      // debarcode (flow)
      {
        input: [],
        bucket: "",
        region: "",
        output: [],
      },
    ],
    20: [
      // scatter plot (flow)
      {
        input: [],
        bulk: [],
        bucket: "",
        region: "",
        output: [],
        metaSample: [],
        metaSampleEditor: [],
      },
    ],
    21: [
      // result upload
      {
        resultType: "",
        files: [],
      },
    ],
    22: [
      // pyir (flow)
      {
        input: [],
        prefix: "pyir", // advanced
      },
    ],
    23: [
      // highdim interactive
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        coordsFolder: [],
        bucket: "",
        output: [],
        region: "",
        metaSampleEditor: [],
      },
    ],
    24: [
      // url2s3
      {
        input: [],
      },
    ],
    25: [
      // sequencing gating
      {
        input: [],
        bucket: "",
        region: "",
      },
    ],
    26: [
      // Flow histogram
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        coordsFolder: [],
        bucket: "",
        output: [],
        region: "",
        metaSampleEditor: [],
      },
    ],
    31: [
      // pfda (flow)
      {
        input: [],
        metaSample: [],
        fcs: [],
        patientCol: "",
        predictor1: "",
        predictor2: "",
        pvCut: 0.05,
        fdr: true,
        palette: 1,
        revPalette: false,
        doUmap: false,
        saveFcs: false,
        seed: 42,
        bucket: "",
        output: [],
        region: ""
      }
    ],
    32: [
      // igblast (flow)
      {
        input: [],
        type: "1",
        prefix: "igblast", // advanced
      },
    ]
  },

  3: {
    // Sequencing
    1: [
      // robot (RNAseq)
      {
        input: [],
        table: [],
        prefix: "robot", // advanced
        cutoff: 85,
        coding: 3,
        volume: 20,
        samples: 3,
        config: 1,
        well: 1,
        eppendorf: 1.5,
        flat: false, // advanced
      },
    ],
    2: [
      // highdim (RNAseq)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        protein: [],
        coordsFolder: [], //advanced
        prefix: "highdim", // advanced
        transformation: "cytof", // advanced
        ceiling: 20000,
        doTsne: true,
        perplexityTsne: "auto", // advanced
        doPhate: false, // advanced
        kValuePhate: null, // advanced
        doIsomap: false, // advanced
        doDiffmap: false, // advanced
        doPca: false,
        doUmap: true,
        kValueUmap: "auto", // advanced
        spreadUmap: 1, // advanced
        minDistUmap: 0.1, // advanced
        doOneSense: false, // advanced
        doPhenograph: true,
        kValuePheno: "auto", // advanced
        doFlowsom: false,
        // kValueFlow: null, // advanced
        // xDim: null, // advanced
        // yDim: null, // advanced
        plot: true,
        plotSample: false, // advanced
        topPercentile: 1, // advanced
        bottomPercentile: 0, // advanced
        pch: "circle", // advanced
        plotCeiling: 20000, // advanced
        svg: null, // advanced
        palette: 1,
        revPalette: false,
        seed: 42, // advanced
        metaSampleEditor: [],
      },
    ],
    3: [
      // heatmap (RNAseq)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        protein: [],
        coordsFolder: [], //advanced
        prefix: "heatplot", // advanced
        transformation: "cytof", // advanced
        ceiling: 20000, //advanced
        seed: 42, // advanced
        palette: 1,
        revPalette: false,
        groupColors: "Set1",
        scale: "marker",
        clustMarker: "none",
        cutMarker: 1, // advanced
        clustSample: "none",
        cutSample: 1, // advanced
        percentileRange: false, // advanced
        transpose: false,
        metaSampleEditor: [],
        allCells: false,
      },
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        coordsFolder: [], //advanced
        transformation: "cytof",
        ceiling: 20000, //advanced
        seed: 42, // advanced
        bucket: "",
        output: [],
        region: "",
        metaSampleEditor: [],
      },
    ],
    4: [
      // tetramer (RNAseq)
      {
        inputCase: [],
        inputControl: [],
        metaMarker: [],
        protein: [],
        badSav: "none", // advanced
        clusterCodes: [],
        prefix: "tetramer", // advanced
        transformation: "cytof",
        config: 1,
        peptideAnnotations: "",
      },
    ],
    5: [
      // preprocessing (RNAseq)
      {
        input: [],
        seed: 42, // advanced,
        functionSelector: "randomize",
      },
      {
        input: [],
        functionSelector: "cytoSpill",
      },
      {
        input: [],
        normalized: [],
        functionSelector: "bead_normalization",
      },
    ],
    8: [
      // analysis (RNAseq)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        pairedFile: [], // advanced
        correlationFile: [], //advanced
        coordsFolder: [], //advanced
        ceiling: 20000, //advanced
        prefix: "stats", // advanced
        transformation: "cytof", // advanced
        pvCut1: 0.01, // advanced
        pvCut2: 0.05, // advanced
        fdr: true,
        palette: 1,
        revPalette: false,
        groupColors: "Set1",
        protein: [],
        outliers: true, // advanced
        pch: "circle", // advanced
        plotCeiling: 20000, // advanced
        seed: 42, // advanced
        metaSampleEditor: [],
      },
    ],
    9: [
      // manual gating (RNAseq)
      {
        input: [],
        metaMarker: [],
        bucket: "",
        region: "",
      },
      {
        input: [],
        coordinatesFile: [],
        bucket: "",
      },
    ],
    12: [
      // batch (RNAseq)
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        prefix: "batch",
        metaSampleEditor: [],
        transformation: "cytof",
        zipFile: [],
        doAnnotation: true,
        doDiscovery: false,
        depthScoreCutoff: 0.1,
        selectionQuantile: 0.5,
        seed: 42,
      },
    ],
    13: [
      // generate meta files (RNAseq)
      {
        input: [],
        bucket: "",
        region: "",
        output: [],
      },
    ],
    14: [
      // tcr analysis (Sequencing)
      {
        input: [],
        metaSample: [],
        h5ad: [],
        bulk: [],
        prefix: "tcr", // advanced
        transformation: "cytof", // advanced
        ceiling: 20000,
        doUmap: true,
        kValueUmap: "auto", // advanced
        spreadUmap: 1, // advanced
        minDistUmap: 0.1, // advanced
        topPercentile: 1, // advanced
        bottomPercentile: 0, // advanced
        pch: "circle", // advanced
        plotCeiling: 20000, // advanced
        palette: 1,
        revPalette: false,
        groupColors: "Set1",
        seed: 42, // advanced
        protein: [],
        gatedProtein: [],
        tcr: [],
        metaSampleEditor: [],
      },
    ],
    15: [
      // Cytof Run Template
      {
        channels: [],
      },
    ],
    16: [
      // Bead normalization
      {
        input: [],
        windowSize: 200,
        normalized: [],
        beadChannels: "89,115,140,159,175,209",
        bucket: "",
        region: "",
        output: [],
      },
    ],
    17: [
      // Tetdecon 2021
      {
        input: [],
        clusterCodes: [],
        config: 1,
        markerCsv: [],
        protein: [],
        cutoff: [],
        peptideAnnotations: "",
        alpha: 0.01,
      },
    ],
    18: [
      // manual tetdecon 2021 (RNAseq)
      {
        input: [],
        clusterCodes: [],
        config: 1,
        markerCsv: [],
        protein: [],
        alpha: 0.01,
        bucket: "",
        region: "",
        output: [],
        peptideAnnotations: "",
      },
    ],
    19: [
      // debarcode (RNAseq)
      {
        input: [],
        bucket: "",
        region: "",
        output: [],
      },
    ],
    20: [
      // scatter plot (RNAseq)
      {
        input: [],
        bulk: [],
        bucket: "",
        region: "",
        output: [],
        metaSample: [],
        metaSampleEditor: [],
      },
    ],
    21: [
      // result upload
      {
        resultType: "",
        files: [],
      },
    ],
    22: [
      // pyir (RNAseq)
      {
        input: [],
        prefix: "pyir", // advanced
      },
    ],
    23: [
      // highdim interactive
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        coordsFolder: [],
        bucket: "",
        output: [],
        region: "",
        metaSampleEditor: [],
      },
    ],
    24: [
      // url2s3
      {
        input: [],
      },
    ],
    25: [
      // sequencing gating
      {
        input: [],
        bucket: "",
        region: "",
      },
    ],
    26: [
      // Seq histogram
      {
        input: [],
        metaMarker: [],
        metaSample: [],
        coordsFolder: [],
        bucket: "",
        output: [],
        region: "",
        metaSampleEditor: [],
      },
    ],
    27: [
      // Seq TCR Query
      {
        bucket: "",
        output: [],
        region: "",
      },
    ],
    28: [
      // Seq StiTChR
      {
        bucket: "",
        output: [],
        region: "",
      },
    ],
    29: [
      // Seq TCR Search
      {
        bucket: "",
        output: [],
        region: "",
        queryClonotype: "",
        mode: "pcblast",
        targetTsv: "s3://immunoscape-tcrsearch/tests/all-paired-tcrs-tcrnet-20231114.tsv",
        peptidesCsv: "s3://immunoscape-tcrsearch/peptide-ref/peptides_2023-08-21.csv",
        tissueOverlapTsv: "s3://immunoscape-tcrsearch/tissue-orphans/all_orphan_20230918.tsv.gz",
        cpuCount: "8",
        tcrDist3Args: "-r  50",
        pcblastArgs: "-t 0.98 -w 0.85",
      },
    ],
    30: [
      // ONT TCR Consensus
      {
        input: [],
        experiment: [],
        bucket: "",
        output: [],
        region: "",
      }
    ],
    31: [
      // pfda (RNAseq)
      {
        input: [],
        metaSample: [],
        fcs: [],
        patientCol: "",
        predictor1: "",
        predictor2: "",
        pvCut: 0.05,
        fdr: true,
        palette: 1,
        revPalette: false,
        doUmap: false,
        saveFcs: false,
        seed: 42,
        bucket: "",
        output: [],
        region: ""
      }
    ],
    32: [
      // igblast (RNAseq)
      {
        input: [],
        type: "1",
        prefix: "igblast", // advanced
      },
    ]
  },
  
};
