import React from 'react';
import { FormGroup, FormGroupProps } from 'reactstrap';
import _ from 'lodash';
import LabelView from '../Views/LabelView';
import { docs } from '../../views/Documentation/Documents';

interface IProps extends FormGroupProps {
    id: string,
    pipeline: string,
    input: string,
    isAdvanced?: boolean
}

const FormGroupCustom = (props : IProps) => {
    const filteredProps = _.omit(props, ['id', 'pipeline', 'input', 'isAdvanced']);
    let isAdvanced = false;

    const documentPipeline = docs.find(element => element.key === props.pipeline);
    if (_.isEmpty(documentPipeline)) {
        console.log('pipeline', props.pipeline); // debugger
    } else {
        const parameters = "parameters" in documentPipeline! ? documentPipeline!.parameters : [];
        let documentItem = parameters.find(element => element.key === props.id);
        if (_.isEmpty(documentItem)) console.log('param', props.id); // debugger
    
        isAdvanced = documentItem ? documentItem.isAdvanced : false;
    }

    const isShow = props.isAdvanced && !_.isUndefined(props.input);
    if (!isShow && isAdvanced) return null;
    return (
        <FormGroup {...filteredProps}>
            <LabelView config={{
                id: props.id,
                pipeline: props.pipeline
            }} />
            {props.children}
        </FormGroup>
    );
}

FormGroupCustom.defaultProps = {
    isAdvanced: false
};

export default FormGroupCustom;
