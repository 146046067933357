import _ from 'lodash';
import initialState from './initialState';
import * as types from './actionTypes';
import { IPipeline, TPipeline } from '../types/type.pipeline';
import basicParams from './pipeline-basic-params';

export default function (state: IPipeline = initialState.pipeline, action: TPipeline) : IPipeline {
  switch (action.type) {
    case types.GET_PIPELINE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.GET_PIPELINE_SUCCESS:
      return {
        ...state,
        all_pipeline: action.data,
        isLoading: false,
      };
    case types.GET_PIPELINE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error_message: action.error_message,
      };

    case types.PIPELINE_SELECT_PROJECT:
      return {
        ...state,
        tempFolder: action.tempFolder,
        selected_project_id: action.projectId,
      };

    case types.PIPELINE_CHANGE_INPUT: {
      const { input } = state;
      input[action.name] = action.value;

      return {
        ...state,
        input,
      };
    }

    case types.PIPELINE_CHANGE_METADATA: {
      const newState: any = state;
      newState[action.name] = action.value;
      return { ...newState };
    }

    case types.PIPELINE_RESET_INPUT: {
      // reset input and random folder
      const random = Math.random().toString().replace('0.', '');
      const tempFolder = `temp/${random}`;

      let input = {};
      const { tabId, pipelineId, batchId, pipelineScript } = state;
      if (tabId && pipelineId) {
        const id = parseInt(pipelineId as string, 10);
        const basicParamsClone = _.cloneDeep(basicParams);
        const basicPipelineInputClone: any = basicParamsClone[tabId];
        input = basicPipelineInputClone[id][pipelineScript];
        if (batchId) {
          input = {
            ...input,
            ...state.input,
          }
        }
      }

      // keep some params when reset
      const keepState = {};
      if (!_.isEmpty(action.keep)) {
        _.each(action.keep, (param) => {
          if (_.includes(Object.keys(state), param)) {
            keepState[param] = state[param];
          }
        });
      }

      return {
        ...state,
        input,
        random,
        tempFolder,
        // isAdvanced: false,
        pipelineScript: 0,
        isShiny: false,
        ...keepState
      };
    }

    case types.PIPELINE_ADD_FILE: {
      const path = action.name;
      let { input } = state;
      let files = _.get(input, path, []);

      // add file to the store
      files = _.unionWith(files, [action.value], (a, b) => a === b);
      input = _.set(input, path, files);

      return {
        ...state,
        input,
      };
    }

    case types.PIPELINE_REMOVE_FILE: {
      const path = action.name;
      let { input } = state;
      let files = _.get(input, path, []);

      // remove file from the store
      files = _.remove(files, a => a !== action.value);
      input = _.set(input, path, files);

      return {
        ...state,
        input,
      };
    }

    case types.PIPELINE_TETRAMER_SELECT_SAV: {
      return {
        ...state,
        input: {
          ...state.input,
          CD4: action.cd4,
          CD8: action.cd8,
        },
      };
    }

    // update protein list
    case types.PIPELINE_SELECT_NAMEFILE_ITEM: {
      const { protein } = state.input;
      const { index, name, value } = action;
      protein[index][name] = value;

      return {
        ...state,
        input: {
          ...state.input,
          protein,
        },
      };
    }

    case types.PIPELINE_SELECT_NAMEFILE_BULK: {
      const { protein } = state.input;
      const { name, value } = action;

      _.each(protein, (p, index) => {
        _.set(protein, `${index}.${name}`, value);
      });

      return {
        ...state,
        input: {
          ...state.input,
          protein,
        },
      };
    }

    case types.PIPELINE_GET_PREVIOUS_OUTPUTS: {
      return {
        ...state,
        previousOutputs: action.data,
      };
    }

    case types.RUN_PIPELINE_SUCCESS: {
      // reset pipeline
      return {
        ...initialState.pipeline,
        projectId: state.projectId // keep projectId
      };
    }

    case types.PIPELINE_CLONE: {
      const { projectId, tabId, pipelineId, input } = action;
      const { reRun, name } = state;
      const random = Date.now();

      // ensure if input variable is an array, then
      // it stays as array
      const keys = Object.keys(input);
      keys.map((key) => {
        if (Array.isArray(state.input[key]) && !Array.isArray(input[key])) {
          input[key] = [input[key]];
        }
      });

      return {
        ...state,
        name: reRun ? `${name}` : `cloned from ${state.cloneSource}`,
        random,
        projectId,
        input,
        pipelineId,
        tabId,
      };
    }

    case types.PIPELINE_CHANGE_EXCLUDE: {
      return {
        ...state,
        excludeInputs: action.data,
      };
    }

    case types.TOGGLE_ADVANCED_PARAMS: {
      // const { tabId, input, pipelineId, isAdvanced } = state;
      // let adjustedInput = {};
      // const id = parseInt(pipelineId as string, 10);
      // const advancedParamsClone = _.cloneDeep(advancedParams);
      // const advancedPipelineInputClone: any = advancedParamsClone[tabId];
      // if (!isAdvanced) {
      //   adjustedInput = _.assign(input, advancedPipelineInputClone[id]);
      // } else {
      //   adjustedInput = _.omit(input, _.keys(advancedPipelineInputClone[id]));
      // }
      return {
        ...state,
        // input: adjustedInput,
        isAdvanced: !state.isAdvanced
      };
    }

    case types.PIPELINE_CHANGE_ALL_METADATA: {
      return {
        ...state,
        ...action.metadata
      }
    }

    default: return state;
  }
}
