import initialState from './initialState';
import * as types from './actionTypes';
import { ISample, TSample } from '../types/type.sample';

export default function(state : ISample=initialState.samples, action: TSample) : ISample {
    switch (action.type){
        case types.GET_SAMPLES_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
                status: action.status,
                message: action.message,
            }
        case types.GET_SAMPLES_SUCCESS:
            return{
                ...state,
                samples: action.data
            }
        case types.GET_SAMPLES_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.message,
            }
        case types.ADD_SAMPLES_REQUEST:
            return{
                ...state,
                isLoading: true,
                isError: false,
                status: action.status,
                message: action.message,
            }
        case types.ADD_SAMPLES_SUCCESS:
            let newstate = initialState.projects.data.selected_project
            return{
                ...newstate,
                samples: action.data,
                ...state,
                isLoading: false,
                isError: false,
            }
        case types.ADD_SAMPLES_FAILURE:
            return{
                ...state,
                isLoading: false,
                isError: true,
                message: action.error_message,
            }
        default: return state;
    }
}
