import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap';
import classNames from 'classnames';
import nav from './_nav';
import SidebarFooter from '../SidebarFooter/SidebarFooter';
import SidebarForm from '../SidebarForm/SidebarForm';
import SidebarHeader from '../SidebarHeader/SidebarHeader';
import SidebarMinimizer from '../SidebarMinimizer/SidebarMinimizer';
import { getLogout } from '../../actions/action.user';
import { useSelector, useDispatch } from 'react-redux';
import { IProfile, TUser } from '../../types/type.user';
import { IStore } from '../../types/store';
import { TDispatch } from '../../types/action';
import { IBadge, INavItem } from '../../types/type.nav';

const Sidebar = (props: any) =>{
    const unreadCount: any = useSelector((state : IStore) => state.notification.unread);
    const profile: IProfile = useSelector((state : IStore) => state.user.profile);
    const { isPreprocessingEnabled } = profile;
    const dispatch = useDispatch<TDispatch<TUser>>();
    
    const handleClick = (e: any) => {
        e.preventDefault();
        e.target.parentElement.classList.toggle('open');
    }

    const activeRoute = (routeName: string, props: any) => {
        // return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
        return props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    }

    const logout = () => {
        dispatch(getLogout());
    }

    // badge addon to NavItem
    const getBadge = (badge: IBadge) => {
        if (badge) {
            const classes = classNames( badge.class );
            if (badge.name == 'notification') {
                const unread = unreadCount ? unreadCount : 0;
                let hide = unread > 0 ? '': ' hidden';
                return (<Badge className={ classes + hide } color={ badge.variant }>{ unread }</Badge>);
            }
            return (<Badge className={ classes } color={ badge.variant }>{ badge.text }</Badge>);
        }
    };

    // simple wrapper for nav-title item
    const getWrapper = (item: INavItem) => {
        return (
            (item.wrapper && item.wrapper.element) ? (
                React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)
            ) : item.name 
        ); 
    };

    // nav list section title
    const getTitle =  (title: INavItem, key: number) => {
        const classes = classNames( "nav-title", title.class);
        return (<li key={key} className={ classes }>{getWrapper(title)} </li>);
    };

    // nav list divider
    const getDivider = (divider: any, key: number) => (<li key={key} className="divider"></li>);

    // nav item with nav link
    const navItem = (item: INavItem, key: number) => {
        const classes = classNames( item.class, "disabled" );
        const isExternal = (url: string) => {
            return url.substring(0, 4) === 'http' ? true : false
        }
        const variant = classNames( "nav-link", item.variant ? `nav-link-${item.variant}` : "");
        return (
            <NavItem key={key} className={classes}>
                { isExternal(item.url) ?
                    <RsNavLink href={item.url} className={variant} active>
                        <i className={item.icon}></i>{item.name}{getBadge(item.badge!)}
                    </RsNavLink> : (
                        (item.name === "Preprocessing" && !isPreprocessingEnabled) ? (
                            <a className="nav-link disabled">
                                <i className={item.icon}></i>{item.name}{getBadge(item.badge!)}
                            </a>
                        ) : (
                            <NavLink to={item.url} className={variant} activeClassName="active">
                                <i className={item.icon}></i>{item.name}{getBadge(item.badge!)}
                            </NavLink>
                        )
                    )
                }
            </NavItem>
        )
    };

    // nav dropdown
    const navDropdown = (item: INavItem, key: number) => {
        return (
            <li key={key} className={activeRoute(item.url, props)}>
                <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}><i className={item.icon}></i>{item.name}</a>
                <ul className="nav-dropdown-items">
                    {navList(item.children)}
                </ul>
            </li>
        )
    };

    // nav link
    const navLink = (item: INavItem, idx: number) =>
        item.title ? getTitle(item, idx) :
        item.divider ? getDivider(item, idx) :
        item.children ? navDropdown(item, idx) :
        item.customItemComponent ? item.customItemComponent(props, idx)
        : navItem(item, idx) ;

    // nav list
    const navList = (items: Array<INavItem>) => {
        return items.map( (item, index) => navLink(item, index) );
    };

    // sidebar-nav root
    return (
        <div className="sidebar">
            <SidebarHeader/>
            <SidebarForm/>
            <nav className="sidebar-nav">
                <Nav>
                    {navList(nav.items)}
                    <NavItem>
                        <NavLink to="/login" className="nav-link" onClick={logout}>
                            <i className="icon-logout"></i>Logout
                        </NavLink>
                    </NavItem>
                </Nav>
            </nav>
            <SidebarFooter/>
            <SidebarMinimizer/>
        </div>
    );
}

// function mapStateToProps(state) {
//     return {
//         notif : state.notification.data,
//         unread: state.notification.unread
//     };
// }

// function mapDispatchToProps(dispatch) {
//     return bindActionCreators({getLogout, getNotification},dispatch)
// }

export default Sidebar;
