import React, { useState, useEffect } from "react";
import _ from "lodash";
import TabbedMetaContainer from "../../../components/Views/TabbedMetaContainer";
import { IPipeline, TPipeline } from "../../../types/type.pipeline";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../types/store";
import { TDispatch } from "../../../types/action";
import { IProfile } from "../../../types/type.user";

const Stitchr = () => {
  const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
  const profile: IProfile = useSelector((state: IStore) => state.user.profile);
  const projectId: number = useSelector(
    (state: IStore) => state.projects.selected_project.id
  )!;
  const { isShiny } = pipeline;

  return (
    <TabbedMetaContainer
      name="StiTChR"
      isDisabled={(tab) => tab.id === "metamarker" && isShiny}
      containerClass="stitchr"
      metamarker={!isShiny}
      metasample={!isShiny}
    >
      <div></div>
    </TabbedMetaContainer>
  );
};

export default Stitchr;
